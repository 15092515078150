import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashheadComponent } from './dashhead.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [DashheadComponent],
  exports: [DashheadComponent]
})
export class DashheadModule { }
