import { DeviceSshDataListItem } from './device-ssh-data-list-item';

export class Device {
  constructor(
    public activated: boolean,
    public authenticationKey: string,
    public deviceGroupId: number,
    public type: string,
    public id?: number,
    public description?: string,
    public clientId?: number,
    public expirationDate?: string,
    public deviceSSHDataList?: Array<DeviceSshDataListItem>,
    public password?: string
  ) {}
}
