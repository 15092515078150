import { Component, EventEmitter, Output } from '@angular/core';
import { GoogleAnalyticsDashboardTypes } from '../../../models';

@Component({
  selector: 'app-google-analytics-dashboard-selector',
  templateUrl: './google-analytics-dashboard-selector.component.html',
  styleUrls: ['./google-analytics-dashboard-selector.component.css']
})
export class GoogleAnalyticsDashboardSelectorComponent {
  @Output() currentGADashboardType = new EventEmitter<GoogleAnalyticsDashboardTypes>();

  types = Object.values(GoogleAnalyticsDashboardTypes).filter(key => !isNaN(Number(key)));
  current = 0;

  changeType(type: GoogleAnalyticsDashboardTypes) {
    this.current = type;
    this.currentGADashboardType.emit(type);
  }

  getValue(type: GoogleAnalyticsDashboardTypes): string {
    return GoogleAnalyticsDashboardTypes[type];
  }

  checkType(type: GoogleAnalyticsDashboardTypes): boolean {
    return GoogleAnalyticsDashboardTypes[this.current] === GoogleAnalyticsDashboardTypes[type];
  }
}
