import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { DeviceGroupService, PoiService, MainPageService } from '../../services';
import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';
import { Poi } from '../../models';
import { PlacesRequestBody } from '../../models/poi';
import { StaticMapComponent } from '../../map/static-map/static-map.component';

@Component({
  selector: 'app-poi-list',
  templateUrl: 'poi-list.component.html',
  styleUrls: ['poi-list.component.css']
})
export class PoiListComponent implements OnInit, OnDestroy {
  static oldPoisConverted = new Map<string, boolean>();

  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('poiImportModal')
  private poiImportModal: CustomModalComponent;
  pois: Poi[];
  clientID: number | string;
  clientSelected = true;
  sortBy: string;
  filterString: '';
  categories: any;
  assignMode = false;
  private deviceGroupID: number = null;
  private currentClient$: any;
  private currentDeviceGroup$: any;
  @ViewChild('deviceGroupSelector') deviceGroupSelector;
  @ViewChild('staticMapComponent')
  private staticMapComponent: StaticMapComponent;
  placesRequestBody = new PlacesRequestBody(0, 0, 2000, null, null, new Array);
  poiTypes: any;

  constructor (
    private poiService: PoiService,
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private utils: Utils,
    private mainPageService: MainPageService
  ) { }

  ngOnInit() {
    if (this.clientService.getCurrentClient() &&
      this.clientService.getCurrentClient().id) {
      this.reload(this.clientService.getCurrentClient().id);
    } else {
      this.clientSelected = false;
    }

    // Subscribe to current client to reload on change
    this.currentClient$ = this.clientService.currentClient$.subscribe(client => {
      this.reload(client.id);
    });

    // Subscribe to current device to reload on change
    this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
      this.deviceGroupID = dt.id;
    });

    this.sortBy = '+login'; // sorting defaults to the name field (asc)
  }

  deletePoi(poiID: number) {
    this.modal.closeModal();
    this.poiService.remove(this.clientService.getCurrentClient().id, poiID).subscribe(
      result => { },
      error => {
        console.error(error);
      },
      () => {
        // if http request returns reload pages
        this.poiService.getAll(this.clientService.getCurrentClient().id).subscribe(pois => {
          this.pois = pois;
          this.pois.sort(this.comparePoisByOrder.bind(this));
        });
      }
    );
  }

  getReference(param, poi: Poi) {
    return typeof poi[param] === 'object' ? poi[param]._ : poi[param];
  }

  private reload(clientID: number | string, keepAssignMode?: boolean) {
    if (!keepAssignMode) {
      this.assignMode = false;
    }
    if (clientID) {
      this.clientSelected = true;
      this.poiService.getAll(clientID).subscribe(pois => {
        if (pois && pois.length > 0) {
          this.pois = pois;
          this.pois.sort(this.comparePoisByOrder.bind(this));
        } else {
          this.pois = new Array();
        }
      });
      this.poiService.getCategories(clientID).subscribe(categories => {
        const newCats: Object = {};
        categories.forEach(cat => {
          newCats[cat.id] = cat.label;
        });
        this.categories = newCats;
      });
    } else {
      this.clientSelected = false;
    }
  }

  private isAssigned(poi: any): boolean {
    const dtId = this.getDeviceGroupId();
    if (dtId) {
      if (poi.pageContents && poi.pageContents.length !== 0) {
        for (const pageContent of poi.pageContents) {
          if (pageContent.page_id === dtId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  private comparePoisByOrder(poi1, poi2: Poi) {
    const p1 = this.getPoiOrder(poi1);
    const p2 = this.getPoiOrder(poi2);
    return (p1 - p2);
  }

  private getPoiOrder(poi: Poi): number {
    for (const pageContent of poi.pageContents) {
      if (!pageContent.page_id) {
        return pageContent.sortOrder;
      }
    }
    return 1024;
  }

  reassign(poi: Poi) {
    if (this.isAssigned(poi)) {
      poi['isDetachCandidate'] = true;
    } else {
      poi['isAttachCandidate'] = true;
    }
    this.saveReassigningPages(false);
  }

  saveReassigningPages(forceReload: boolean) {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id &&
      this.deviceGroupSelector.currentDeviceGroup.id && (this.assignMode || forceReload)) {
      const poiIds: number[] = [];
      for (const poi of this.pois) {
        if ((this.isAssigned(poi) && !poi['isDetachCandidate']) || poi['isAttachCandidate']) {
          poiIds.push(poi['id']);
        }
      }
      this.poiService.assign(
        this.clientService.getCurrentClient().id,
        this.deviceGroupSelector.currentDeviceGroup.id,
        poiIds
      ).subscribe(res => {
        const stayAssignMode = forceReload ? false : true;
        this.reload(this.clientService.getCurrentClient().id, stayAssignMode);
      });
    }
  }

  /**
   * this function sets the sorting attribute and keeps track of the sorting direction
   * @param {string} sorter - the attribute to sortBy
   * @return {void}
   */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
   * Notify the filter if the search string has changed
   * @param {Event} event - the sender event
   * @return {void}
   */
  changeFilter(event) {
    this.filterString = event.target.value;
  }

  getPoiReferenceInfo(poi): String {
    if (poi.pageContents.length > 1) {
      return this.utils.getTranslation('confirmation.multiple-references');
    } else {
      return '';
    }
  }

  private getDeviceGroupId() {
    if (this.deviceGroupSelector.currentDeviceGroup.id) {
      let dtId = this.deviceGroupSelector.currentDeviceGroup.id;
      if (this.deviceGroupID !== null) {
        dtId = this.deviceGroupID;
      }
      return dtId;
    }
    return null;
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  openPoiImportModal() {
    const deviceGroupID = this.getDeviceGroupId();
    const clientID = this.clientService.getCurrentClient().id;
    this.placesRequestBody.clientId = clientID;
    this.placesRequestBody.deviceGroupId = deviceGroupID;
    this.mainPageService.get(clientID, deviceGroupID).subscribe(p => {
      if (p && p['value']) {
        this.placesRequestBody.longitude = p['value'].location.lng;
        this.placesRequestBody.latitude = p['value'].location.lat;
        this.staticMapComponent.enableCircleRange = true;
        this.staticMapComponent.circleRange = this.placesRequestBody.radius;
        this.staticMapComponent.currentLocation = [this.placesRequestBody.longitude, this.placesRequestBody.latitude];
        this.poiImportModal.showModal('', 'lists.poi.popupTitle', '', false, false);

        this.poiService.getPlacesTypes().subscribe(res => {
          this.poiTypes = res;
          this.placesRequestBody.placesTypes.push(...res);
        });
      }
    });
  }

  placesImport() {
    this.poiImportModal.closeModal();
    this.utils.displayGrowlMessage('success', 'forms.importing', '');
    this.poiService.getNearbyPlaces(this.placesRequestBody).subscribe(
      result => {
        this.placesRequestBody = new PlacesRequestBody(0, 0, 2000, null, null, new Array);
        for (const poi of result) {
          if (this.isAssigned(poi)) {
            poi['isDetachCandidate'] = true;
          } else {
            poi['isAttachCandidate'] = true;
          }
          this.pois.push(poi);
        }
        this.saveReassigningPages(true);
        this.utils.displayGrowlMessage('success', 'forms.completed', this.utils.getTranslation('lists.poi.importResult') + result.length);
      },
      error => {
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
      }
    );
  }

  poiTypeCheckboxChange(event, poiType) {
    if (event.target.checked) {
      this.placesRequestBody.placesTypes.push(poiType);
    } else {
      this.placesRequestBody.placesTypes.splice( this.placesRequestBody.placesTypes.indexOf(poiType), 1 );
    }
  }

  ngOnDestroy() {
    if (this.currentClient$) {
      this.currentClient$.unsubscribe();
    }
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
