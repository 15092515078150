export class GoogleCalendarIntegration {
  constructor(
    public id: number,
    public name: string,
    public authorized: boolean,
    public createDate: Date,
    public registeredCalendars: Array<GoogleCalendarRegistered>
  ) { }
}

export class GoogleCalendarRegistered {
  constructor(
    public id: number,
    public calendarId: number,
    public active: boolean
  ) { }
}

export class GoogleCalendar {
  constructor(
    public id: number,
    public name: string
  ) { }
}

export class GoogleIntegrationCalendar {
  constructor(
    public integrationId: number,
    public calendars: Array<GoogleCalendar>
  ) { }
}
