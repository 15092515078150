import { Component, OnInit, Input } from '@angular/core';
import { Widget } from '../../../models';

@Component({
  selector: 'app-widget-dishset-type',
  templateUrl: './widget-dishset-type.component.html',
  styleUrls: ['../widget-add-edit.component.css']
})
export class WidgetDishsetTypeComponent implements OnInit {

  dishSetsOptions: string[] = ['DAYS', 'WEEKS'];
  displayDishSetsForCurrentDay = false;

  @Input() editable;
  @Input() widget: Widget;
  @Input() selectedLanguageKey;
  @Input() languageSelector;

  constructor(
  ) { }

  ngOnInit() {
    if (this.widget.dishSetNoOfDays === 1) {
      this.displayDishSetsForCurrentDay = true;
    }
  }

  displayDishSetsForCurrentDayChange(event) {
    if (event && this.widget) {
      this.widget.dishSetNoOfDays = 1;
      this.widget.dishSetNoOfDaysAhead = 0;
    }
  }


}
