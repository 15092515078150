export class Advertising {
  constructor(
    public activated: boolean = true,
    public name: string = '',
    public dateRange: Date[] = [null, null],
    public timeFrom: Date = null,
    public timeTo: Date = null,

    public image = '',
    public websiteUrl: string = '',
    public lowerBanner = { active: false },

    public mapBanner = { active: false, image: {}, websiteUrl: '' },
    public screensaver = { active: false, image: {} }
  ) { }
}

export enum AdvertisingType {
  COMMON_CLIENT, GLOBAL
}
