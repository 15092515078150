import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiService, AuthService, ClientService } from '@b4m/b4m-frontend-core';
import { LanguageService } from './language.service';
import { MainPage, Content, ThemeColors } from '../models';
import { DeviceGroupService } from './device-group.service';

@Injectable()
export class MainPageService {
  public newThemeColors = new ThemeColors('#fff', '#fff', '#fff', '#fff', '#fff');
  private baseURL = environment.contentService;
  constructor(
    private api: ApiService,
    private authService: AuthService,
    private languageService: LanguageService,
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService
  ) { }

  get(clientID: number | string, deviceGroupID: number): Observable<Content> {
    return this.api.getData(this.baseURL + '/' + clientID + '?key=' + 'mainPage.' + deviceGroupID).pipe(
      map(this.extractMainPage)
    );
  }

  create(clientID: number | string, deviceGroupID: number, mainPage: MainPage): Observable<Content> {
    return this.store(clientID, deviceGroupID, false, mainPage);
  }

  update(clientID: number | string, deviceGroupID: number, mainPage: MainPage, id: number): Observable<Content> {
    return this.store(clientID, deviceGroupID, true, mainPage, id);
  }

  store(clientID: number | string, deviceGroupID: number, update: boolean, mainPage: MainPage, mainPageId?: number): Observable<Content> {
    const url = this.baseURL + '/' + clientID;
    const token: string = this.authService.token;
    let id = 0;
    if (mainPageId !== 0) {
      id = mainPageId;
    }
    const bodyObj: Content = {
      'id': id,
      'key': 'mainPage.' + deviceGroupID,
      'value': mainPage
    };
    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  updateProperty(mainPage: MainPage, key: string, newValue: any, localized?: boolean, localized_default?: boolean): Observable<Content> {
    if (!newValue) {
      return of(null);
    }
    if (!mainPage) {
      mainPage = new MainPage('', null, null, null, '', null);
    }
    if (localized) {
      const lang = this.languageService.currentLanguage;
      if (!mainPage[key]) {
        mainPage[key] = {};
      }
      if (!lang || lang === '_') {
        mainPage[key]['_'] = newValue;
      } else if (localized_default) {
        mainPage[key]['_'] = newValue;
        mainPage[key][lang] = newValue;
      } else {
        mainPage[key][lang] = newValue;
      }
    } else {
      mainPage[key] = newValue;
    }
    const client = this.clientService.getCurrentClient();
    const deviceGroup = this.deviceGroupService.currentDeviceGroup;
    if (mainPage.id) {
      return this.store(client.id, deviceGroup.id, true, mainPage, mainPage.id);
    } else {
      return this.store(client.id, deviceGroup.id, false, mainPage);
    }
  }

  private extractMainPage(res: Response): Content {
    // There should be always only one main page per client/device group combination.
    return res[0];
  }

}
