import { Client } from '@b4m/b4m-frontend-core';

export class DeviceGroup {
  activated: boolean;
  id: number;
  description: string;
  clientId: number;
  idleTime: number | string;
  client: Client;
  deviceGroupContents: DeviceGroupContent[];
  constructor(
    activated: boolean,
    id: number,
    description: string,
    client?: Client,
    deviceGroupContents?: DeviceGroupContent[],
    public locales?: any
  ) {
    this.activated = activated;
    this.id = id;
    this.description = description;
    this.locales = {};
    this.client = client;
    this.deviceGroupContents = deviceGroupContents;
  }
}

export class DeviceGroupContent {
  id: number | string;
  content: any;
  sortOrder: number;
  constructor(id: number | string, content: any, sortOrder: number) {
    this.id = id;
    this.content = content;
    this.sortOrder = sortOrder;
  }
}
