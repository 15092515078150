import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { PagedWatchdogActivityLogs, User } from '@b4m/b4m-frontend-core';
import { Pageable } from '../../shared/pagination/pagination.component';
import { LocalWatchdogService } from '../../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-watchdog-logs',
  templateUrl: 'watchdog-logs.component.html',
  styleUrls: ['watchdog-logs.component.css']
})
export class WatchdogLogsComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  private deviceId: number = null;
  private deviceGroupId: number = null;
  private clientId: number = null;
  private userLogin: string = null;
  private userMode = false;

  private showDeviceInfo = false;
  private backURL = '';

  private pageable = new Pageable(0, 'timestamp', 'desc');

  pagedWatchdogActivityLogs: PagedWatchdogActivityLogs = null;

  constructor(
    private route: ActivatedRoute,
    private localWatchdogService: LocalWatchdogService
  ) { }

  ngOnInit() {
    // TODO: fix
    this.sub = this.route.params.subscribe(params => {
      this.route.data.subscribe(data => {
        this.deviceId = params['deviceId'] ? params['deviceId'] : this.deviceId;
        this.deviceGroupId = params['deviceGroupId'] ? params['deviceGroupId'] : this.deviceGroupId;
        this.clientId = params['clientId'] ? params['clientId'] : this.clientId;
        this.userLogin = params['userLogin'] ? params['userLogin'] : this.userLogin;
        if (data['user']) {
          this.userMode = true;
        }
        if (this.userMode) {
          if (this.userLogin) {
            this.backURL = '/user/edit/' + this.userLogin;
          } else {
            this.backURL = '/user/list';
          }
        } else if (!this.deviceId) {
          this.showDeviceInfo = true;
          if (this.deviceGroupId) {
            this.backURL = '/device-group/edit/' + this.deviceGroupId;
          } else if (this.clientId) {
            this.backURL = '/client/edit/' + this.clientId;
          }
        } else {
          this.backURL = '/device/edit/' + this.deviceId;
        }
        this.getLogs();
      });
    });
  }

  private getLogs() {
    let optionalParamUrl = '';
    if (this.deviceGroupId) {
      optionalParamUrl = '?deviceGroupId=' + this.deviceGroupId;
    } else if (this.clientId) {
      optionalParamUrl = '?clientId=' + this.clientId;
    } else if (this.userLogin) {
      optionalParamUrl = '?userLogin=' + this.userLogin;
    } else if (this.userMode) {
      optionalParamUrl = '?allUsers=true';
    }
    this.localWatchdogService.getWatchdogActivityLogs(
      this.deviceId,
      this.pageable,
      optionalParamUrl
    ).subscribe(
      activityLogs => {
        this.pagedWatchdogActivityLogs = activityLogs;
      },
      error => {
        //
      }
      );
  }

  updateSorting(sort: string) {
    if (sort !== this.pageable.sort) {
      this.pageable.sort = sort;
      this.pageable['property'] = sort;
      this.pageable.direction = 'asc';
    } else {
      this.switchDirection();
    }
    this.pageable.page = 0;
    this.getLogs();
  }

  updatePage(page: number) {
    this.pageable.page = page;
    this.getLogs();
  }

  private switchDirection() {
    this.pageable.direction = this.pageable.direction === 'asc' ? 'desc' : 'asc';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

export class WatchdogActivityLog {
  constructor(
    public timestamp: string,
    public user: User,
    public ipAddress: string,
    public path: string
  ) { }
}
