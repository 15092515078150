import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroup, MainPage, CoordsWithName } from '../../models';
import { MainPageService, DeviceGroupService } from '../../services';
import { Utils } from '../../shared/utils';
import { Subscription } from 'rxjs/Subscription';
import { CanComponentDeactivate } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';

@Component({
  selector: 'app-color-settings',
  templateUrl: './color-settings.component.html',
  styleUrls: ['./color-settings.component.css']
})
export class ColorSettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  mainPageId: any;
  client: Client;
  deviceGroup: DeviceGroup;
  mainPage: MainPage;
  currentDeviceGroup$: Subscription;
  switchDeviceGroupRequest$: Subscription;
  @ViewChild('locationForm')
  userForm;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(
    private route: ActivatedRoute,
    private mainPageService: MainPageService,
    private utils: Utils,
    private deviceGroupService: DeviceGroupService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.route.data.subscribe((data: { client: Client }) => {
      this.client = data[0].client;
      this.deviceGroup = data[0].deviceGroup;
      this.loadMainPage();

      // Subscribe to current device to reload on change
      this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
        if (this.client && this.client.id && dt.id) {
          this.deviceGroup = dt;
          this.loadMainPage();
        }
      });
    });
  }

  // loadMainPage loads the main page from the server and
  // sets it and the current coordinates of the main page in the component
  loadMainPage(newMainPage?: any) {
    if (newMainPage) {
      this.mainPage = newMainPage.value;
      this.mainPage.id = newMainPage.id;
      this.mainPageId = newMainPage.id;
    } else {
      this.mainPageService.get(this.client.id, this.deviceGroup.id).subscribe(content => {
        if (content) {
          this.mainPage = content.value;
          this.mainPage.id = content.id;
          this.mainPageId = content.id;
        } else {
          this.mainPage = new MainPage(
            '',
            '',
            this.mainPageService.newThemeColors,
            new CoordsWithName('', 0, 0),
            '',
            []
          );
          this.mainPageId = null;
        }
      });
    }
  }

  setColor(type: string, newValue: string) {
    this.mainPage.colors[type] = newValue;
  }

  onSubmit() {
    if (this.mainPageId) {
      this.mainPageService.updateProperty(this.mainPage, 'colors', this.mainPage.colors).subscribe(newMainPage => {
        this.mainPageUpdated(newMainPage);
      });
    } else {
      this.mainPageService.create(this.client.id, this.deviceGroup.id, this.mainPage).subscribe(newMainPage => {
        this.mainPageUpdated(newMainPage);
      });
    }
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  private mainPageUpdated(mainPage) {
    this.loadMainPage(mainPage);
    this.utils.displayGrowlMessage('success', 'forms.submitted', '');
    this.userForm.form.markAsPristine();
    this.userForm.form.markAsUntouched();
    this.userForm.form.updateValueAndValidity();
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      if (this.userForm.touched) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
