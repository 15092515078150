import { Pipe, PipeTransform } from '@angular/core';
/**
 * FilterPipe
 * This pipe filters list for given words.
 * The pipe itself is unpure so the filtering process can happen at any change. E.g if a user types in the filter field the
 * items get filtered right away.
 * So far the pipe has no restriction mechanism, so every attribute will be searched through
 * Basic usage: filter:'abc' or filter:iam_some_string_var
*/

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  isArray(arr: any): boolean {
    return Object.prototype.toString.call(arr) === '[object Array]';
  }

  isObject(obj: any): boolean {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  filterString(string: string, filter: string): boolean {
    return string.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1;
  }

  filterArray(arr: Array<any>, filter: string): boolean {
    for (const item of arr) {
      if (typeof item === 'string') {
        if (this.filterString(item, filter)) { return true; }
      }
      if (this.isArray(item)) {
        if (this.filterArray(item, filter)) { return true; }
      }
      if (this.isObject(item)) {
        if (this.filterObject(item, filter)) { return true; }
      }
    }
    return false;
  }

  filterObject(obj: Object, filter: string): boolean {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        if (this.filterString(obj[key], filter)) { return true; }
      } else if (this.isArray(obj[key])) {
        if (this.filterArray(obj[key], filter)) { return true; }
      } else if (this.isObject(obj[key])) {
        if (this.filterObject(obj[key], filter)) { return true; }
      }
    }
    return false;
  }

  transform(value: any[], filter: any): any {
    if (!value || !filter || value.length < 1) { return value; }
    return value.filter(item => {
      if (typeof item === 'string') {
        return this.filterString(item, filter);
      }
      if (this.isArray(item)) {
        return this.filterArray(item, filter);
      }
      if (this.isObject(item)) {
        return this.filterObject(item, filter);
      }
      return false;
    });
  }

}
