import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { RemoteAccessComponent } from './remote-access.component';
import { DeviceSshListComponent } from './device-ssh/device-ssh-list/device-ssh-list.component';
import { DeviceSshAddEditComponent } from './device-ssh/device-ssh-add-edit/device-ssh-add-edit.component';
import { ClientResolve } from '../client-guard.service';
import { ServerSshListComponent } from './server-ssh/server-ssh-list/server-ssh-list.component';
import { ServerSshAddEditComponent } from './server-ssh/server-ssh-add-edit/server-ssh-add-edit.component';

const remoteAccessRoutes: Routes = [
  {
    path: 'remote-access',
    component: RemoteAccessComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['SYSTEM_ADMIN'] },
    children: [
      {
        path: 'device/add',
        component: DeviceSshAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      },
      {
        path: 'device/edit/:id',
        component: DeviceSshAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      },
      {
        path: 'device/list',
        component: DeviceSshListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      },
      {
        path: 'server/edit/:id',
        component: ServerSshAddEditComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      },
      {
        path: 'server/add',
        component: ServerSshAddEditComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      },
      {
        path: 'server/list',
        component: ServerSshListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['SYSTEM_ADMIN'] }
      }
    ]
  }
];

export const remoteAccessRouting = RouterModule.forChild(remoteAccessRoutes);
