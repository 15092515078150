export class Page {
  constructor(
    public activated: boolean,
    public title: string,
    public content: string,
    public background: any,
    public icon: string,
    public order: number
  ) { }
}
