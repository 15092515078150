import { DateFormatterPipe } from './../../../../../shared/pipes/date-formatter.pipe';
import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-google-analytics-filter-date',
  templateUrl: './google-analytics-filter-date.component.html',
  styleUrls: ['./google-analytics-filter-date.component.css']
})
export class GoogleAnalyticsFilterDateComponent {
  @Output() refreshData = new EventEmitter<any>();
  DateFormatterPipe = DateFormatterPipe;
  dateRange: Date[] = [null, null];

  constructor(
    public translateService: TranslateService
  ) {}

  loadData() {
    this.refreshData.emit();
  }

  resetDateRange() {
    this.dateRange = [null, null];
    this.refreshData.emit();
  }
}
