import { PageContent } from './content';

export class Events {
  constructor(
    public activated: boolean = true,
    public title: string = '',
    public dateRange: Date[] = [null, null],
    public timeFrom: Date = null,
    public timeTo: Date = null,
    public description: string = '',
    public location: string = '',
    public tags: string[] = [],
    public canceled: boolean = false
  ) { }
}

export class EventContent {
  constructor(
    public key: string,
    public value: any,
    public id?: number,
    public pageContents?: Array<PageContent>,
    public googleEventId: number = null,
    public googleStatus: string = ''
  ) {}
}
