import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DeviceGroupService, DeviceService } from '../../services';
import { DeviceGroup, Device } from '../../models';
import { ClientService, CanComponentDeactivate } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';
import {WatchdogEditComponent} from '../../watchdog/watchdog-edit/watchdog-edit.component';

@Component({
  selector: 'app-device-add-edit',
  templateUrl: 'device-add-edit.component.html',
  styleUrls: ['device-add-edit.component.css']
})
export class DeviceAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  isNew = false;
  device: Device = new Device(true, '', null, null);
  deviceGroups: DeviceGroup[] = new Array();
  deviceTypes = new Array();
  clients: Client[];
  selectedID: number;
  private sub: Subscription;
  @ViewChild('deviceForm') deviceForm;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild(WatchdogEditComponent)
  appWatchdogEdit: WatchdogEditComponent;
  private duplicatedKey = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceGroupService: DeviceGroupService,
    private deviceService: DeviceService,
    private clientService: ClientService,
    private utils: Utils
  ) {
    this.deviceTypes = this.deviceGroupService.deviceTypes;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.selectedID = params['id'];
      if (this.selectedID) {
        if (this.clientService.getCurrentClient()) {
          this.deviceService.getByID(this.selectedID, this.clientService.getCurrentClient().id).subscribe(device => {
            this.device = device;
          });
        }
      } else {
        this.isNew = true;
      }
    });

    if (this.clientService.getCurrentClient()) {
      this.deviceGroupService.getAll(this.clientService.getCurrentClient().id).subscribe(deviceGroups => {
        this.deviceGroups = deviceGroups;
      });
    }

    // Subscribe to current client to reload on change
    this.clientService.currentClient$.subscribe(client => {
      this.reload(client.id, this.selectedID);
    });

    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });
  }

  private reload(clientID: number | string, deviceID: number) {
    this.deviceForm.form.reset();
    if (clientID) {
      this.deviceGroupService.getAll(clientID).subscribe(deviceGroups => {
        this.deviceGroups = deviceGroups;
      });
      if (deviceID) {
        this.deviceService.getByID(deviceID, clientID).subscribe(device => {
          this.device = device;
        });
      }
    }
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (this.deviceForm.form.dirty) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  onSubmit() {
    if (!this.device.password) {
      this.device.password = undefined;
    }
    if (this.isNew) {
      this.deviceService.create(this.device, this.clientService.getCurrentClient().id).subscribe(
        result => this.handleServerResponse(true),
        error => this.handleApiError(error)
      );
    } else {
      this.deviceService.update(this.device, this.clientService.getCurrentClient().id).subscribe(
        result => this.handleServerResponse(true),
        error => this.handleApiError(error)
      );
    }
    if (this.appWatchdogEdit) {
      this.appWatchdogEdit.onSubmit();
    }
  }

  private handleApiError(error) {
    console.error(error);
    let errorMessage = error;
    try {
      const errorBody = JSON.parse(error['_body']);
      if (errorBody.message === 'error.authKeyDuplicationError') {
        this.duplicatedKey = true;
      }
      errorMessage = this.utils.getTranslation(errorBody.message);
    } catch (ignored) {}
    this.handleServerResponse(false, errorMessage);
  }

  private handleServerResponse(success: boolean, message?: string) {
    if (success) {
      this.deviceForm.form.reset();
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/device/list']);
    } else {
      if (message === undefined) {
        message = 'forms.submit-failed-detail';
      }
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', message);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.switchClientRequest$.unsubscribe();
  }
}
