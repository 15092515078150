export const dimension = 'ga:deviceCategory';
const options = {
    plugins: {
        labels: {
            render: 'value'
        }
    }
};

export const cards = {
    c2: {
        data: [],
        labels: [],
        dataType: 'int',
        dimension: dimension,
        metric: 'ga:users',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: options
    },
    c3: {
        data: [],
        labels: [],
        dataType: 'int',
        dimension: dimension,
        metric: 'ga:newUsers',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: options
    },
    c4: {
        data: [],
        labels: [],
        dataType: 'int',
        dimension: dimension,
        metric: 'ga:sessions',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: options
    },
    c5: {
        data: [],
        labels: [],
        dataType: 'double',
        dimension: dimension,
        metric: 'ga:bounceRate',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: {
            tooltips: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: (tooltipItem, data) => {
                        return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                    },
                },
            },
            plugins: {
                labels: {
                    render: (data) => {
                        return data.value + '%';
                    }
                }
            }
        }
    },
    c6: {
        data: [],
        labels: [],
        dataType: 'double',
        dimension: dimension,
        metric: 'ga:pageviewsPerSession',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: options
    },
    c7: {
        data: [],
        labels: [],
        dataType: 'date',
        dimension: dimension,
        metric: 'ga:avgSessionDuration',
        metricData: undefined,
        type: 'pie',
        legend: true,
        options: {
            tooltips: {},
            plugins: {}
        }
    },
};
