import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomModalComponent } from './custom-modal.component';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { Utils } from '../shared/utils';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ModalModule.forRoot(),
    TranslateModule
  ],
  declarations: [
    CustomModalComponent
  ],
  exports: [
    CustomModalComponent
  ],
  providers: [ AuthGuard, Utils ]
})
export class CustomModalModule { }
