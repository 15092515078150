import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { ClientResolve } from '../client-guard.service';
import {PropsSettingsComponent} from '@app/props-settings/props-settings.component';
import {PropsSettingsPanelComponent} from '@app/props-settings/props-settings-panel/props-settings-panel.component';

const propsSettingsRoutes: Routes = [
  {
    path: 'props-settings',
    component: PropsSettingsComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'panel',
        component: PropsSettingsPanelComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
    ]
  }
];

export const propsSettingsRouting = RouterModule.forChild(propsSettingsRoutes);
