import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { navigationRouting } from './navigation.routing';
import { NavigationComponent } from './navigation.component';
import { TopBarComponent } from './top-bar.component';
import { ContentUpdaterModule } from '../content-updater/content-updater.module';

import { ClientModule } from '../client/client.module';
import { LogoModule } from '../logo/logo.module';
import { Utils } from '../shared/utils';
import { ContentPreviewComponent } from '../main-page/content-preview/content-preview.component';
import { AlertModule } from 'ngx-bootstrap';
import { AuthService } from '@b4m/b4m-frontend-core';

@NgModule({
  imports: [
    CommonModule,
    navigationRouting,
    TranslateModule,
    ClientModule,
    LogoModule,
    ContentUpdaterModule,
    AlertModule.forRoot()
  ],
  declarations: [NavigationComponent, TopBarComponent, ContentPreviewComponent],
  exports: [NavigationComponent, TopBarComponent],
  providers: [Utils, AuthService]
})
export class NavigationModule {}
