import { UserRightsType } from './../models/user-rigts-type';
import { UserAclAuthorities } from './../models/user-acl-authorities';
import { AuthService, ClientService, Client } from '@b4m/b4m-frontend-core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Utils } from '../shared/utils';
import { AlertComponent } from 'ngx-bootstrap/alert';
import { Message } from '../models';
import { AclService } from '@app/services';


@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {
  UserRightsType = UserRightsType;

  isAdmin: boolean;
  isUserRightsAdmin: boolean;
  isSystemAdmin: boolean;
  msgs: Message[] = [];
  private displayGrowlMessage$: any;
  aclAuth: UserAclAuthorities;
  currentClient: Client;

  constructor(
    private utils: Utils,
    private clientService: ClientService,
    private authService: AuthService,
    private aclService: AclService
  ) {
    this.clientService.currentClient$.subscribe(client => {
      this.currentClient = client;
    });
    this.aclService.getUserAuthorities(this.authService.getUsername()).subscribe(authorities => {
      if (authorities) {
        this.aclAuth = authorities;
      }
    });
  }

  ngOnInit() {
    // this.isAdmin = this.authService.isAdmin();
    this.isAdmin = this.utils.isAdmin();
    this.isUserRightsAdmin = this.utils.isUserRightsAdmin();
    this.isSystemAdmin = this.utils.isSystemAdmin();
    this.displayGrowlMessage$ = this.utils.displayGrowlMessage$.subscribe(res => {
      this.msgs.push(res);
    });
  }

  ngOnDestroy() {
    this.displayGrowlMessage$.unsubscribe();
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.msgs = this.msgs.filter(alert => {
      return alert !== dismissedAlert;
    });
  }

  isEnabled(userRightsType: UserRightsType) {
    if (this.isLoadedData()) {
      return false;
    }
    return this.aclAuth.aclAuthorities.indexOf(this.getAclAuth(userRightsType)) > -1 ||
      this.isUserRightsAdmin ||
      this.isAdmin;
  }

  private isLoadedData() {
    return this.currentClient === undefined || this.aclAuth === undefined;
  }

  private getAclAuth(userRightsType: UserRightsType) {
    return userRightsType.view + '_' + this.currentClient.id.toString();
  }

  hasContentsNav() {
    return this.hasAtLeastOneNavToShowTitle(UserRightsType.getContentsNav());
  }

  hasSettingsNav() {
    return this.hasAtLeastOneNavToShowTitle(UserRightsType.getSettingsNav()) ||
      !this.isAdmin && !this.isSystemAdmin;
  }

  private hasAtLeastOneNavToShowTitle(data: UserRightsType[]) {
    if (this.isLoadedData()) {
      return false;
    }
    return data.some(t => this.aclAuth.aclAuthorities.includes(this.getAclAuth(t))) ||
      this.isUserRightsAdmin ||
      this.isAdmin;
  }
}


