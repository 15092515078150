import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { WatchdogComponent } from './watchdog.component';
import { WatchdogEditComponent } from './watchdog-edit/watchdog-edit.component';
import { WatchdogLogsComponent } from './watchdog-logs/watchdog-logs.component';
import { ClientResolve } from '../client-guard.service';

const watchdogRoutes: Routes = [
  {
    path: 'watchdog',
    component: WatchdogComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'edit',
        component: WatchdogEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'logs/device/:deviceId',
        component: WatchdogLogsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'logs/devicegroup/:deviceGroupId',
        component: WatchdogLogsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'logs/client/:clientId',
        component: WatchdogLogsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'logs/user',
        component: WatchdogLogsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'], user: true }
      },
      {
        path: 'logs/user/:userLogin',
        component: WatchdogLogsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'], user: true }
      }
    ]
  }
];

export const watchdogRouting = RouterModule.forChild(watchdogRoutes);
