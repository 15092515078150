import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';

@Component({
  selector: 'app-logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.css']
})
export class LogoComponent implements OnInit {
  logo = 'app/logo/projectavatar.png';
  title = 'Lobbit';
  clientName: string;
  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientService.currentClient$.subscribe(client => {
      this.clientName = client.name;
    });
  }

}
