import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ClientService, Client } from '@b4m/b4m-frontend-core/';
import { DeviceGroupService, LanguageService } from '../../services';
import { DeviceGroup } from '../../models';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';
import { PreviewService } from '../../main-page/content-preview/preview.service';

@Component({
  selector: 'app-client-list',
  templateUrl: 'client-list.component.html',
  styleUrls: ['client-list.component.css']
})
export class ClientListComponent implements OnInit {
  @ViewChild('clientRemoveModal')
  private clientRemoveModal: CustomModalComponent;
  isAdmin: boolean;
  clients: Client[];
  sortBy: string;
  filterString: '';
  constructor(
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private language: LanguageService,
    private previewService: PreviewService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.getClients();
    this.isAdmin = this.utils.isAdmin();
    this.sortBy = '+name'; // sorting defaults to the name field (asc)
  }

  private getClients() {
    this.clientService.getClients().subscribe(clients => {
      this.clients = clients;
    });
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  /**
	 * this function sets the sorting attribute and keeps track of the sorting direction
	 * @param {string} sorter - the attribute to sortBy
	 * @return {void}
	 */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
	 * Notify the filter if the search string has changed
	 * @param {Event} event - the sender event
	 * @return {void}
	 */
  changeFilter(event) {
    this.filterString = event.target.value;
  }

  impersonate(clientID: number | string) {
    if (clientID) {
      this.previewService.close();
      this.setClient(clientID);
      this.language.setCurrentLanguage('_');
    }
  }

  private setClient(clientId) {
    this.clientService.getClientbyId(clientId).subscribe(
      client => {
        this.clientService.setCurrentClient(client);
        localStorage.setItem('client', '' + client.id);
        this.deviceGroupService.getAll(clientId).subscribe(deviceGroups => {
          if (deviceGroups[0]) {
            this.deviceGroupService.setCurrentDeviceGroup(deviceGroups[0]);
          } else {
            this.deviceGroupService.setCurrentDeviceGroup(new DeviceGroup(true, null, '', null, [], ''));
          }
        });
      },
      (err) => {
        throw new Error(`Error: Cannot fetch client with ID ${clientId}`);
      }
    );
  }

  private deleteClient(clientId: number) {
    this.clientRemoveModal.closeModal();
    this.clientService.deleteClient(clientId).subscribe(() => {
      if (this.clientService.getCurrentClient().id === clientId && this.clients[0]) {
        this.impersonate(this.clients[0].id);
      }
      this.getClients();
    });
  }

}
