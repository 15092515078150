import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DeviceGroupService } from '../../services';
import { DeviceGroup } from '../../models';
import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-device-group-list',
  templateUrl: 'device-group-list.component.html',
  styleUrls: ['device-group-list.component.css']
})
export class DeviceGroupListComponent implements OnInit {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  deviceGroups: Observable<DeviceGroup[]>;
  sortBy: string;
  filterString: '';
  constructor(
    private deviceGroupService: DeviceGroupService,
    private clientService: ClientService,
    private utils: Utils
  ) {}

  ngOnInit() {
    // If client changes, reload device groups
    this.clientService.currentClient$.subscribe(client => {
      this.deviceGroups = this.deviceGroupService.getAll(client.id);
    });
    // If client is set and no device groups are loaded, reload device groups
    if (!this.deviceGroups && this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.deviceGroups = this.deviceGroupService.getAll(this.clientService.getCurrentClient().id);
    }
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  deleteDeviceGroup(id: number) {
    this.modal.closeModal();
    this.deviceGroupService.delete(id, this.clientService.getCurrentClient().id).subscribe(
      result => {},
      error => {
        console.error(error);
      },
      () => {
        // if http request returns reload device groups
        this.deviceGroups = this.deviceGroupService.getAll(this.clientService.getCurrentClient().id);
      }
    );
  }

  /**
   * this function sets the sorting attribute and keeps track of the sorting direction
   * @param {string} sorter - the attribute to sortBy
   * @return {void}
   */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
   * Notify the filter if the search string has changed
   * @param {Event} event - the sender event
   * @return {void}
   */
  changeFilter(event) {
    this.filterString = event.target.value;
  }
}
