import { Screensaver } from './../../models/screensaver';
import { AdvertisingService } from './../../services/advertising.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroup, MainPage, Widget, Content, CoordsWithName } from '../../models';
import { MainPageService, WidgetService, PageService, DeviceGroupService } from '../../services';
import { Observable } from 'rxjs';
import { Utils } from '../../shared/utils';
import { forkJoin } from 'rxjs';
import { merge } from 'rxjs';

@Component({
  selector: 'app-screensaver-settings',
  templateUrl: './screensaver-settings.component.html',
  styleUrls: ['./screensaver-settings.component.css']
})
export class ScreensaverSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('locationForm') form;
  mainPageId: any;
  currentDeviceGroup$: any;
  client: Client;
  deviceGroup: DeviceGroup;
  mainPage: MainPage;
  widgets: Widget[] = [];
  advertisements: Content[] = [];

  constructor(
    private route: ActivatedRoute,
    private mainPageService: MainPageService,
    private widgetService: WidgetService,
    private pageService: PageService,
    private utils: Utils,
    private deviceGroupService: DeviceGroupService,
    private advertisingService: AdvertisingService,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.route.data.subscribe((data: { client: Client }) => {
      this.client = data[0].client;
      this.deviceGroup = data[0].deviceGroup;
      this.loadMainPage();
      this.loadWidgets(this.client.id);

      // Subscribe to current device to reload on change
      this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
        if (this.client && this.client.id && dt.id) {
          this.deviceGroup = dt;
          this.loadMainPage();
        }
      });
      this.advertisingService.getAll(this.client.id).subscribe(content => {
        if (content) {
          this.advertisements = content.filter(c => c.value && c.value.screensaver && c.value.screensaver.active);
        }
      });
    });
  }

  onSubmit() {
    if (this.form.form.valid) {
      if (this.mainPageId) {
        this.mainPageService
          .updateProperty(this.mainPage, 'screensaver', this.mainPage.screensaver)
          .subscribe(newMainPage => {
            this.loadMainPage(newMainPage);
            this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          });
      } else {
        this.mainPageService.create(this.client.id, this.deviceGroup.id, this.mainPage).subscribe(newMainPage => {
          this.loadMainPage(newMainPage);
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
        });
      }
    }
  }

  // loadMainPage loads the main page from the server and
  // sets it and the current coordinates of the main page in the component
  private loadMainPage(newMainPage?: any) {
    if (newMainPage) {
      this.mainPage = newMainPage.value;
      this.mainPage.id = newMainPage.id;
      this.mainPageId = newMainPage.id;
    } else {
      this.mainPageService.get(this.client.id, this.deviceGroup.id).subscribe(content => {
        if (content) {
          this.mainPage = content.value;
          this.mainPage.id = content.id;
          this.mainPageId = content.id;
          if (!this.mainPage.screensaver) {
            this.mainPage.screensaver = new Screensaver();
          }
          if (!this.mainPage.screensaver.ads) {
            this.mainPage.screensaver.ads = { activated: true };
          }
        } else {
          this.mainPage = new MainPage(
            '',
            '',
            this.mainPageService.newThemeColors,
            new CoordsWithName('', 0, 0),
            '',
            [],
            new Screensaver()
          );
          this.mainPage.screensaver.ads = { activated: true }; // maybe redundant
          this.mainPageId = null;
        }
      });
    }
  }

  private loadWidgets(clientID: number | string) {
    this.pageService.getAll(clientID).subscribe(pages => {
      if (pages) {
        const pageBatch: Observable<Content[]>[] = [];
        for (const page of pages) {
          pageBatch.push(this.widgetService.getAll(clientID, page.id));
        }
        if (pageBatch.length > 0) {
          merge(forkJoin(pageBatch))
            .subscribe((res: Content[][]) => {
              // flatten array
              const con: Content[] = [].concat.apply([], res);
              const unique: Content[] = con.filter(this.onlyUnique);
              const newWidgets: Widget[] = [];
              unique.forEach((c: Content) => {
                if (c) {
                  const newWidget: Widget = c.value;
                  newWidget.id = c.id;
                  newWidgets.push(newWidget);
                }
              });
              this.widgets = newWidgets;
            });
        }
      }
    });
  }

  private onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
