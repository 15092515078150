import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute } from '@angular/router';
import { WidgetListComponent } from '../../widget/widget-list/widget-list.component';
import { TranslateService } from '@ngx-translate/core';
import { CanComponentDeactivate, ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { MaterialIconsService } from '../../material-icons/material-icons.service';
import { Utils } from '../../shared/utils';
import { ImageResult, ResizeOptions } from 'ng2-imageupload';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageService, UploadService, LanguageService, AclService } from '../../services';
import { AuthService } from '@b4m/b4m-frontend-core';
import { Page, UploadResponse, UploadProgressType } from '../../models';

@Component({
  selector: 'app-page-add-edit',
  templateUrl: 'page-add-edit.component.html',
  styleUrls: ['page-add-edit.component.css']
})
export class PageAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  pageId: number = null;
  page: Page = new Page(false, '', '', '', '', Math.pow(2, 10));
  isNew = false;
  fileIsLoading = false;
  progress = 0;
  backgroundURL: string;
  hasEditAuthority: boolean;
  private selectedID: number;
  private sub: Subscription;
  @ViewChild('widgetListComponent')
  private widgetListComponent: WidgetListComponent;
  selectedLanguageKey = '_';
  @ViewChild('pageForm') pageForm;
  private defaultLanguageBackground = true;
  @ViewChild('browse') browse;
  @ViewChild('filename') filename;
  @ViewChild('languageSelector') languageSelector;
  private contentModified = false;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('materialIconsModal')
  private materialIconsModal: CustomModalComponent;
  @ViewChild('saveCurrentPageModal')
  private saveCurrentPageModal: CustomModalComponent;
  icons: any = [];
  selectedIconGroup = 'action';
  private deletedBackgroundIds: number[] = [];
  pageMaxSize: ResizeOptions = {
    resizeMaxHeight: 1280,
    resizeMaxWidth: 1920
  };
  private fileSize: number;
  private refererDeviceGroupId: number;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private pageService: PageService,
    private clientService: ClientService,
    private aclService: AclService,
    private route: ActivatedRoute,
    private router: Router,
    private uploadService: UploadService,
    private translate: TranslateService,
    private materialIconsService: MaterialIconsService,
    private utils: Utils,
    private languageService: LanguageService,
    private authService: AuthService,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.selectedID = params['id'];
      if (params['refererDeviceGroupId'] !== undefined) {
        this.refererDeviceGroupId = params['refererDeviceGroupId'];
      }
      if (this.selectedID) {
        // if client are set on initalization, load pages
        if (this.clientService.getCurrentClient() &&
          this.clientService.getCurrentClient().id
        ) {
          this.pageService.getByID(
            this.clientService.getCurrentClient().id,
            this.selectedID
          ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(content => {
            if (content) {
              this.pageId = content.id;
              this.page = content['value'];
              if (typeof this.page.background === 'object' &&
                this.page.background[this.selectedLanguageKey] === '' &&
                this.languageSelector.languages[0].key === this.selectedLanguageKey
              ) {
                this.page.background[this.selectedLanguageKey] = this.page.background._;
              }
              this.setBackground();
            }
          });
        }

        // Subscribe to current client to reload on change
        this.clientService.currentClient$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(client => {
          this.reload(client.id, this.selectedID);
        });

        if (this.utils.isAdmin() || this.utils.isUserRightsAdmin()) {
          this.hasEditAuthority = true;
        } else {
          this.aclService.checkUserAuthority(this.authService.getUsername(), 'PAGE_EDIT_' + this.selectedID).subscribe(response => {
            if (response && response.result) {
              this.hasEditAuthority = !!response.result;
            } else {
              this.hasEditAuthority = false;
            }
          });
        }
      } else {
        this.isNew = true;
      }
    });

    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });

    this.materialIconsService.getIconList().subscribe(icons => {
      this.icons = icons;
      this.selectedIconGroup = 'action';
    });

    this.languageService.currentLanguage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(lang => {
      this.setBackground();
    });

  }

  private reload(clientID: number | string, pageID: number) {
    this.pageForm.form.reset();
    this.contentModified = false;
    if (clientID && pageID) {
      this.pageService.getByID(clientID, pageID).pipe(takeUntil(this.ngUnsubscribe)).subscribe(p => {
        if (p) {
          this.pageId = p.id;
          this.page = p['value'];
          if (
            typeof this.page.background === 'object' &&
            this.page.background[this.selectedLanguageKey] === '' &&
            this.languageSelector.languages[0].key === this.selectedLanguageKey
          ) {
            this.page.background[this.selectedLanguageKey] = this.page.background._;
          }
          this.setBackground();
        } else {
          this.page = new Page(false, '', '', '', '', 0);
        }
      });
    }
  }

  handleBrowseClick() {
    this.browse.nativeElement.click();
  }

  onFileChange(imageResult: ImageResult) {
    this.fileIsLoading = true;
    this.contentModified = true;
    this.renderer.setProperty(this.filename.nativeElement, 'value', imageResult.file.name);

    const files = imageResult.resized;
    const fileBlob = files.dataURL;
    if (fileBlob && fileBlob.length) {
      this.fileSize = fileBlob.length;
    }
    const clientID = this.clientService.getCurrentClient().id;
    this.uploadService.saveFile(fileBlob, clientID).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (result: UploadResponse) => {
        if (result.resType === UploadProgressType.PROGRESS) {
          this.progress = result.content;
        } else if (result.resType === UploadProgressType.BODY) {
          this.fileIsLoading = false;
          const id = this.getReference('background');
          if (id) {
            this.deletedBackgroundIds.push(id);
          }
          this.setReference('background', result.content[0].key);
          this.backgroundURL = result.content[0].url;
          this.defaultLanguageBackground = false;
        }
      }
    );
  }

  deleteBackground() {
    this.deletedBackgroundIds.push(this.getReference('background'));
    this.setReference('background', '');
    this.backgroundURL = '';
    this.renderer.setProperty(this.filename.nativeElement, 'value', '');
    this.contentModified = true;
    this.fileSize = null;
  }

  selectIcon(param, value) {
    this.setReference(param, value);
    this.materialIconsModal.closeModal();
    this.contentModified = true;
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (this.pageForm.form.dirty || this.contentModified) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  getProgress() {
    if (this.fileSize) {
      let currentProgress = Math.round((this.uploadService.progress / this.fileSize) * 100);
      if (currentProgress > 100) {
        currentProgress = 100;
      }
      return currentProgress;
    }
    return 0;
  }

  onSubmit() {
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.pageForm.form.valid) {
        this.submit(true);
      } else {
        this.submit(false);
      }
    }, 500);
  }

  private submit(validated: boolean) {
    if (validated && !this.fileIsLoading) {
      const page: Page = this.page;
      if (this.isNew) {
        this.pageService.create(this.clientService.getCurrentClient().id, page).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
          res => {
            this.pageId = res.id;
            this.widgetListComponent.pageID = res.id;
            this.widgetListComponent.saveReorderingWidgets();
            if (this.refererDeviceGroupId) {
              this.pageService.attach(
                this.clientService.getCurrentClient().id,
                this.refererDeviceGroupId,
                this.pageId
              ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(attached => {
                this.handleCreatePageSuccessServerResponse();
              });
            } else {
              this.handleCreatePageSuccessServerResponse();
            }
          },
          error => {
            this.handleServerResponse(false);
          }
        );
      } else {
        this.widgetListComponent.saveReorderingWidgets();
        this.pageService.update(this.clientService.getCurrentClient().id, page, this.selectedID)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
            res => {
              this.deleteUnusedBackgrounds();
              this.handleServerResponse(true);
            },
            error => {
              this.handleServerResponse(false);
            }
          );
      }
    }
  }

  private deleteUnusedBackgrounds() {
    if (this.deletedBackgroundIds.length > 0) {
      for (const deletedBackgroundId of this.deletedBackgroundIds) {
        this.uploadService.delete(
          this.clientService.getCurrentClient().id,
          deletedBackgroundId
        ).pipe(takeUntil(this.ngUnsubscribe)).subscribe(deleted => {

        });
      }
    }
  }

  private handleCreatePageSuccessServerResponse() {
    this.saveCurrentPageModal.closeModal();
    const redirectUrl = '/widgets/add/' + this.pageId;
    this.deleteUnusedBackgrounds();
    this.handleServerResponse(true, redirectUrl);
  }

  private handleServerResponse(success: boolean, url: string = '/pages/list/') {
    if (success) {
      this.pageForm.form.reset();
      this.contentModified = false;
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate([url]);
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  getReference(param) {
    if (this.page[param]) {
      this.synchronizeDefault(param);
      return typeof this.page[param] === 'object' ?
        ((this.page[param][this.selectedLanguageKey] && this.page[param][this.selectedLanguageKey] !== '') ?
          this.page[param][this.selectedLanguageKey] :
          (this.languageSelector.languages[0].key === this.selectedLanguageKey ? this.page[param]._ : '')) :
        (this.languageSelector.languages[0].key === this.selectedLanguageKey ? this.page[param] : '');
    } else {
      return '';
    }
  }

  setReference(param, value) {
    if (typeof this.page[param] !== 'object') {
      this.page[param] = {};
    }
    this.page[param][this.selectedLanguageKey] = value;
    if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      this.page[param]._ = value;
    }
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
    this.setBackground();
  }

  getPlaceholder(param, i18nKey): string {
    let placeholder: string;
    if (typeof this.page[param] === 'object') {
      if (this.page[param]._ && this.page[param]._ !== '') {
        placeholder = this.page[param]._;
      }
    } else {
      if (this.page[param] && this.page[param] !== '') {
        placeholder = this.page[param];
      }
    }
    if (!placeholder || placeholder === '') {
      this.translate.get(i18nKey).subscribe((res: string) => {
        placeholder = res;
      });
    }
    return placeholder;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.switchClientRequest$) {
      this.switchClientRequest$.unsubscribe();
    }
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  showMaterialIconsModal() {
    if (this.isNew || this.hasEditAuthority) {
      const iconCategory = this.getIconCategory();
      if (iconCategory) {
        this.selectedIconGroup = iconCategory;
      }
      this.materialIconsModal.showModal('', 'forms.icon', '', true, false);
    }
  }

  private getIconCategory() {
    const currentIcon = this.icons.icons.filter(icon => icon.ligature === this.getReference('icon'))[0];
    if (!currentIcon) {
      return false;
    }
    return currentIcon.group_id;
  }

  private synchronizeDefault(param) {
    if (this.selectedLanguageKey !== '_' && this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      if (typeof this.page[param] === 'object') {
        if (
          (!this.page[param][this.selectedLanguageKey] || this.page[param][this.selectedLanguageKey] === '') &&
          this.page[param]._ &&
          this.page[param]._ !== ''
        ) {
          this.page[param][this.selectedLanguageKey] = this.page[param]._;
        } else if (
          this.page[param][this.selectedLanguageKey] &&
          this.page[param][this.selectedLanguageKey] !== '' &&
          this.page[param][this.selectedLanguageKey] !== this.page[param]._
        ) {
          this.page[param]._ = this.page[param][this.selectedLanguageKey];
        }
      } else {
        if (
          (!this.page[param][this.selectedLanguageKey] || this.page[param][this.selectedLanguageKey] === '') &&
          this.page[param] &&
          this.page[param] !== ''
        ) {
          const value = this.page[param];
          this.page[param] = {};
          this.page[param][this.selectedLanguageKey] = value;
          this.page[param]._ = value;
        }
      }
    }
  }

  private setBackground() {
    let background: string = null;
    this.backgroundURL = '';

    this.defaultLanguageBackground = true;
    if (typeof this.page.background !== 'object') {
      background = this.page.background;
    } else {
      background = this.page.background._;
      if (this.page.background[this.selectedLanguageKey] && this.page.background[this.selectedLanguageKey] !== '') {
        background = this.page.background[this.selectedLanguageKey];
        this.defaultLanguageBackground = false;
      }
    }
    if (background && background != null) {
      this.backgroundURL = this.utils.buildAwsImageURL(this.clientService.getCurrentClient().id, background);
    }
  }
}
