import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ng2-dnd';

import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { languageRouting } from './language.routing';
import { LanguageAddEditComponent } from './language-add-edit';
import { LanguageComponent } from './language.component';
import { DashheadModule } from '@app/dashhead/dashhead.module';

@NgModule({
  imports: [
    languageRouting, CommonModule, FormsModule, BrowserModule, TranslateModule, DndModule.forRoot(),
    CustomModalModule, DashheadModule
  ],
  declarations: [LanguageSelectorComponent, LanguageAddEditComponent, LanguageComponent],
  exports: [LanguageSelectorComponent, LanguageAddEditComponent, LanguageComponent],
  providers: [AuthGuard, Utils]
})
export class LanguageModule { }
