import { Component, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/takeUntil';

import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';
import { DishSetService, LanguageService } from '../../services';
import { DishSet, Dish } from '../../models';
import { DateFormatterPipe } from '@app/shared/pipes/date-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dish-set-add-edit',
  templateUrl: './dish-set-add-edit.component.html',
  styleUrls: ['./dish-set-add-edit.component.css']
})
export class DishSetAddEditComponent implements OnInit, OnDestroy {
  DateFormatterPipe = DateFormatterPipe;
  sub: Subscription;
  dishSet = new DishSet(true, '', [null, null], []);
  tempDish = new Dish('', 0, false);
  editId: number = null;
  dishId: number = null;

  private switchClientRequest$: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('languageSelector') languageSelector;
  selectedLanguageKey = '_';
  @ViewChild('dishSetForm') dishSetForm;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(
    private clientService: ClientService,
    private dishSetService: DishSetService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private utils: Utils,
    private languageService: LanguageService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.editId = params['id'];
      if (this.editId) {
        if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
          this.reload(this.clientService.getCurrentClient().id, this.editId);
        }
      }
    });

    // Subscribe to current client to reload on change
    this.clientService.currentClient$.takeUntil(this.ngUnsubscribe).subscribe(client => {
      this.reload(client.id, this.editId);
    });
  }

  private reload(clientID: number | string, dishSetID: number) {
    this.dishSetForm.form.reset();
    if (clientID && dishSetID) {
      this.dishSetService.getByID(clientID, this.editId) //
        .takeUntil(this.ngUnsubscribe).subscribe(dishSet => {
          if (dishSet) {
            this.dishSet = dishSet;
            if (this.dishSet.dateRange && this.dishSet.dateRange.length === 2) {
              this.dishSet.dateRange[0] = new Date(this.dishSet.dateRange[0]);
              this.dishSet.dateRange[1] = new Date(this.dishSet.dateRange[1]);
            }
          } else {
            this.dishSet = new DishSet(true, '', [null, null], []);
          }
        });
    }
  }

  addDish() {
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.getI18NAttribute('tempDish.name') !== '') {
        this.dishSet.dishes.push(JSON.parse(JSON.stringify(this.tempDish)));
        this.tempDish = new Dish('', 0, false);
      }
    }, 500);
  }

  removeDish(id: number) {
    this.dishSet.dishes.splice(id, 1);
  }

  startEditDish(dishId: number) {
    const tempDish = this.dishSet.dishes[dishId];
    this.dishId = dishId;
    if (tempDish) {
      this.tempDish = JSON.parse(JSON.stringify(tempDish));
    }
  }

  abortEditDish() {
    this.tempDish = new Dish('', 0, false);
    this.dishId = null;
  }

  finishEditDish() {
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.getI18NAttribute('tempDish.name') !== '') {
        this.dishSet.dishes[this.dishId] = this.tempDish;
        this.tempDish = new Dish('', 0, false);
        this.dishId = null;
      }
    }, 500);
  }

  onSubmit() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.switchLanguage(this.languageSelector.languages[0].key);
      setTimeout(() => {
        if (this.dishSetForm.form.valid) {
          this.dishSetService.store( //
            this.clientService.getCurrentClient().id, this.editId ? true : false, this.dishSet, this.editId) //
            .takeUntil(this.ngUnsubscribe).subscribe(
              res => {
                this.dishSetForm.form.reset();
                this.utils.displayGrowlMessage('success', 'forms.submitted', '');
                this.router.navigate(['/dish-sets/list/']);
              },
              error => {
                this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
              }
            );
        }
      }, 500);
    }
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  getI18NAttribute(dottedName: string) {
    return this.utils.getI18NAttribute(this, dottedName);
  }

  setI18NAttribute(dottedName, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
  }

  private synchronizeDefault(param) {
    this.utils.synchronizeDefault(this, param);
  }

  getPlaceholder(dottedName, i18nKey): string {
    return this.utils.getPlaceholder(this, dottedName, i18nKey);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
