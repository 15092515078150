import { Component, OnInit, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.css']
})
export class StaticMapComponent implements OnInit {
  mapLoaded = false;
  currentLocation: number[] = [0, 0];
  zoom = 13;
  @Input()
  enableCircleRange: boolean;
  @Input()
  circleRange: number;

  styles = [
    {
     'featureType': 'poi',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'poi.school',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'transit',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    }
  ];

  constructor(
    private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.mapLoaded = true;
    });
  }

}
