import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { UserComponent } from './user.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const userRoutes: Routes = [
  {
    path: 'user',
    component: UserComponent,
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      { path: 'add', component: UserAddEditComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard], data: { roles: ['ADMIN'] } },
      {
        path: 'edit/:id',
        component: UserAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        data: { roles: ['ADMIN', 'USER'] }
      },
      { path: 'list', component: UserListComponent, canActivate: [AuthGuard], data: { roles: ['ADMIN'] } }
    ]
  }
];

export const userRouting = RouterModule.forChild(userRoutes);
