export class Widget {
  constructor(
    public activated: boolean = true,
    public title: string = '',
    public order: number = Math.pow(2, 10),
    public icon: string = '',
    public description: string = '',
    public shortDescription: string = '',
    public image: any = {},
    public type: string = '',
    public content: any = '',
    public smsType: string = '',
    public smsNumber: string = '',
    public email: string = '',
    public emailType: string = '',
    public emailSubject: string = '',
    public popupTitle: string = '',
    public poiCategory: number = null,
    public location: number = null,
    public url: string = '',
    public urlRedirect: boolean = false,
    public opacity: number = 1,
    public questions: any[] = [],
    public choiceQuestion: any = {active: false, question: '', choices: []},
    public additionalTextForm: any = {active: false, labelName: ''},
    public askForRoomNumber: boolean = false,
    public feedbackRecipients: string[] = [],
    public slides: any[] = new Array(),
    public slideInterval: number = null,
    public dishSetNoOfDays: number = null,
    public dishSetNoOfDaysAhead: number = 0,
    public dishSetNoOfWeeks: number = null,
    public dishSetNoOfWeeksAhead: number = 0,
    public eventOption: string = 'SINGLE',
    public dishSetsOption: string = 'DAYS',
    public event: number = null,
    public eventListOption: string = 'CURRENT_DAY',
    public eventDateRange: Date[] = [null, null],
    public eventTags: string[] = [],
    public wideOverlay: boolean = false,
    public largeLabels: boolean = false,
    public centerImage?: string,
    public id?: number | string
  ) { }
}
