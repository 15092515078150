import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { WatchdogService } from '@b4m/b4m-frontend-core';
import { LocalWatchdogService } from '../../services';
import { WatchdogSettings, WatchdogRecipients } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';


export class UpdateWatchdogSettingsDTO {
  constructor(
    public deviceId: number,
    public activated: boolean,
    public acceptedQuietTime: number
  ) { }
}

export class WatchdogRecipient {
  constructor(
    public deviceId: number,
    public email: string
  ) { }
}
@Component({
  selector: 'app-watchdog-edit',
  templateUrl: 'watchdog-edit.component.html',
  styleUrls: ['watchdog-edit.component.css']
})
export class WatchdogEditComponent implements OnInit {

  private EMAIL_REGEXP = new RegExp([
    '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.',
    '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:',
    '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+',
    '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?'].join(''));
  private AQT_MIN_VALUE = 5;

  @Input() selectedDeviceID: number;

  watchdogSettings: WatchdogSettings = new WatchdogSettings(null, false, null, '');
  private watchdogRecipients: WatchdogRecipients;
  private tempRecipient: WatchdogRecipient = new WatchdogRecipient(null, '');
  private savingRecipients: Array<any> = new Array();
  private deletingRecipients: Array<number> = new Array();

  settingsFetchResponded = false;
  private emailValid = true;

  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(
    private watchdogService: WatchdogService,
    private localWatchdogService: LocalWatchdogService,
    private utils: Utils,
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.watchdogService.getWatchdogSettings(this.selectedDeviceID ? this.selectedDeviceID : null, 'deviceId').subscribe(
      watchdogSettings => {
        if (watchdogSettings.id) {
          this.watchdogSettings = watchdogSettings;
        } else {
          this.watchdogSettings.global = watchdogSettings.global;
        }
        this.settingsFetchResponded = true;
      },
      error => {
        this.settingsFetchResponded = true;
      }
    );
    this.watchdogService.getWatchdogRecipients(this.selectedDeviceID ? this.selectedDeviceID : null, 'deviceId').subscribe(
      watchdogRecipients => {
        this.watchdogRecipients = watchdogRecipients;
      },
      error => {
        //
      }
    );
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  onSubmit() {
    const watchdogSettings = new UpdateWatchdogSettingsDTO(
      this.selectedDeviceID,
      this.watchdogSettings.activated,
      this.watchdogSettings.acceptedQuietTime
    );

    this.watchdogService.updateWatchdogSettings(watchdogSettings).subscribe(
      result => {
        this.handleServerResponse(true);
      },
      error => {
        this.handleServerResponse(false);
      }
    );
    if (this.savingRecipients.length) {
      this.localWatchdogService.saveWatchdogRecipientsList(this.savingRecipients).subscribe(
        result => {
          this.savingRecipients = [];
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    }
    if (this.deletingRecipients.length) {
      this.localWatchdogService.deleteWatchdogRecipientsList(this.deletingRecipients).subscribe(
        result => {
          this.deletingRecipients = [];
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    }
  }

  onCancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.savingRecipients = [];
    this.deletingRecipients = [];
    this.loadData();
  }

  private addRecipient() {
    if (this.validateEmail(this.tempRecipient.email)) {
      if (!this.watchdogRecipients.watchdogRecipients.some(e => e.email === this.tempRecipient.email)) {
        const copyRecipient = new WatchdogRecipient(this.selectedDeviceID, this.tempRecipient.email);
        this.savingRecipients.push(copyRecipient);
        this.watchdogRecipients.watchdogRecipients.push(copyRecipient);
      }
    } else {
      this.emailValid = false;
    }
  }

  private deleteWatchdogRecipient(item: any) {
    const recipients = this.useGlobalRecipients()
      ? this.watchdogRecipients.globalWatchdogRecipients
      : this.watchdogRecipients.watchdogRecipients;
    recipients.splice(recipients.findIndex(r => r.email === item.email ), 1);
    if (this.savingRecipients.some(r => r.email === item.email)) {
      this.savingRecipients.splice(this.savingRecipients.findIndex( r => r.email === item.email ), 1);
    } else {
      this.deletingRecipients.push(item.id);
    }
    this.modal.closeModal();
  }

  private deleteWatchdogRecipients() {
    this.watchdogService.deleteWatchdogRecipients(this.selectedDeviceID, 'deviceId').subscribe(
      result => {
        this.watchdogService.getWatchdogRecipients(this.selectedDeviceID ? this.selectedDeviceID : null, 'deviceId').subscribe(
          watchdogRecipients => {
            this.watchdogRecipients = watchdogRecipients;
          }
        );
      },
      error => { }
    );
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.tempRecipient.email = '';
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  private onBlurAqtInput() {
    if (this.watchdogSettings.acceptedQuietTime < this.AQT_MIN_VALUE) {
      this.watchdogSettings.acceptedQuietTime = this.selectedDeviceID ? null : this.AQT_MIN_VALUE;
    }
  }

  private onFocusEmailInput() {
    this.emailValid = true;
  }

  private validateEmail(email): boolean {
    if (!this.EMAIL_REGEXP.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  private useGlobalRecipients(): boolean {
    return (this.selectedDeviceID && this.watchdogRecipients && this.watchdogRecipients.watchdogRecipients.length === 0);
  }

}

