import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { environment } from '../../environments/environment';
import { Locale, Language, Content } from '../models';


@Injectable()
export class LanguageService {
  currentLanguage: string;
  currentLanguage$: Observable<any>;
  private _currentLanguageObserver;
  locales$: Observable<any>;
  private _localesObserver;
  locales: Locale[] = [];

  private baseURL = environment.contentService;

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private authService: AuthService
  ) {
    this.currentLanguage$ = new Observable(observer => this._currentLanguageObserver = observer);
    this.locales$ = new Observable(observer => this._localesObserver = observer);
  }

  setLocaleList() {
    this.api.getData('app/language/locales.json').subscribe(langs => {
      this.locales = <Locale[]>langs;
      if (this._localesObserver && this._localesObserver.next) {
        this._localesObserver.next(langs);
      }
    });
  }

  setCurrentLanguage(languageKey: string) {
    this.currentLanguage = languageKey;
    this._currentLanguageObserver.next(languageKey);
  }

  create(clientID: number | string, language: Language) {
    return this.store(clientID, false, language);
  }

  update(clientID: number | string, language: Language, languageID: number) {
    return this.store(clientID, true, language, languageID);
  }

  assign(clientID: number | string, deviceGroupID: number, languageIds: number[]) {
    const url = this.baseURL +
      '/' +
      clientID +
      '/assign?' +
      (deviceGroupID ? ('deviceGroupId=' + deviceGroupID + '&') : ('')) +
      'contentIds=' +
      languageIds +
      '&keyContains=locales.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    const options = { headers: headers, observe: 'response' as 'response', responseType: 'text' as 'text' };

    return this.http.put(url, null, options);
  }

  batchAssign(clientID: number | string, batch: Map<string, any[]>) {
    const sendBatch = [];
    batch.forEach((value, key) => {
      if (key === '-1') {
        const add = this.assign(clientID, null, value);
        sendBatch.push(add);
      } else {
        const add = this.assign(clientID, +key, value);
        sendBatch.push(add);
      }
    });
    return forkJoin(sendBatch);
  }

  attach(clientID: number | string, deviceGroupID: number, languageId: number) {
    const url = this.baseURL + '/' + clientID + '/attach?deviceGroupId=' + deviceGroupID + '&contentId=' +
      languageId + '&keyContains=locales.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };

    return this.http.put(url, null, options);
  }

  getAll(clientID: number | string) {
    return this.api.getData(this.baseURL + '/' + clientID + '?key=' + 'locales.' + clientID);
  }

  getAllLanguages(clientID: number | string): Observable<Language[]> {
    return this.api.getData(this.baseURL + '/' + clientID + '?key=' + 'locales.' + clientID).pipe(
      map(this.transformToLanguages)
    );
  }

  getByID(clientID: number | string, id: number) {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + id);
  }

  delete(clientID: number | string, id: number) {
    const url = this.baseURL + '/' + clientID;
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    const options = { headers: headers };
    return this.api.deleteData(url, id);
  }

  private store(clientID: number | string, update: boolean, language: Language, languageID: number = null): Observable<any> {
    const url = this.baseURL + '/' + clientID;
    const token: string = this.authService.token;
    const bodyObj = {
      'key': 'locales.' + clientID,
      'value': language
    };
    if (languageID) {
      bodyObj['id'] = languageID;
    }
    const body = JSON.stringify(bodyObj);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token });
    const options = { headers: headers };
    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  private transformToLanguages(contents: Content[]): Language[] {
    if (contents.length === 0) {
      return [];
    }
    const languages: Language[] = [];
    contents.forEach((content: Content) => {
      const newLang: Language = new Language('', '', '', false);
      if (content.id) {
        newLang.id = content.id;
      }
      newLang.active = content.value['active'];
      newLang.key = content.value['key'];
      newLang.description = content.value['description'];
      newLang.icon = content.value['icon'];
      languages.push(newLang);
    });
    return languages;
  }
}
