import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AclComponent } from './acl.component';
import { AclListComponent } from './acl-list/acl-list.component';
import { aclRouting } from './acl.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DashheadModule } from '@app/dashhead/dashhead.module';
import { DndModule } from 'ng2-dnd';
import { AclSelectorComponent } from './acl-selector/acl-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    aclRouting,
    BrowserModule,
    TranslateModule,
    CustomModalModule,
    SharedModule,
    AccordionModule.forRoot(),
    DndModule.forRoot(),
    DashheadModule
  ],
  declarations: [AclComponent, AclListComponent, AclSelectorComponent],
  exports: [AclComponent, AclListComponent],
  providers: [AuthGuard, Utils]
})
export class AclModule {}
