import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback.component';
import { Utils } from '../shared/utils';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { feedbackRouting } from './feedback.routing';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { FeedbackAnalyticsComponent } from './feedback-analytics/feedback-analytics.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from 'ng2-charts';
import 'chartjs-plugin-labels';

@NgModule({
  imports: [
    CommonModule,
    feedbackRouting,
    CustomModalModule,
    TranslateModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    SharedModule,
    ChartsModule
  ],
  declarations: [
    FeedbackComponent,
    FeedbackListComponent,
    FeedbackAnalyticsComponent
  ],
    exports: [
      FeedbackComponent,
      FeedbackListComponent,
      FeedbackAnalyticsComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class FeedbackModule { }
