import { Component, OnInit } from '@angular/core';

import { GoogleCalendarService } from '../services';
import { GoogleCalendarIntegration, GoogleIntegrationCalendar } from '../models';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Utils } from '../shared/utils';

@Component({
  selector: 'app-google-calendar-integration',
  templateUrl: './google-calendar-integration.component.html',
  styleUrls: ['./google-calendar-integration.component.css']
})
export class GoogleCalendarIntegrationComponent implements OnInit {

  googleIntegrationUrl = '';
  clientIntegrations = new Array<GoogleCalendarIntegration>();
  clientCalendars = new Array<GoogleIntegrationCalendar>();
  clientID = null;

  constructor( private googleCalendarService: GoogleCalendarService, private clientService: ClientService, private utils: Utils ) { }

  ngOnInit() {
    this.googleCalendarService.getGoogleAuthUrl().subscribe(res => {
      this.googleIntegrationUrl = res.value;
    });
    this.clientID = this.clientService.getCurrentClient().id;

    this.loadData();
  }

  private loadData() {
    this.googleCalendarService.getIntegrations(this.clientID).subscribe(res => {
      this.clientIntegrations = res.filter(i => i.authorized);
      for (const integration of this.clientIntegrations) {
        this.googleCalendarService.getGoogleCalendars(this.clientID, integration.id).subscribe(resp => {
          this.clientCalendars.push(new GoogleIntegrationCalendar(integration.id, resp.values));
        },
        error => this.handleError(error) );
      }
    },
    error => this.handleError(error) );
  }

  public getUnregisteredCalendars(integration: GoogleCalendarIntegration) {
    const integrationCalendars = this.clientCalendars.find(c => c.integrationId === integration.id);
    if (integrationCalendars) {
      const registeredCalendarsIds = integration.registeredCalendars.map(i => i.calendarId);
      return integrationCalendars.calendars.filter(c => !registeredCalendarsIds.includes(c.id));
    } else {
      return new Array();
    }
  }

  public registerCalendar(calendarId, integrationId) {
    this.googleCalendarService.registerCalendar(calendarId, this.clientID, integrationId).subscribe(res => {
      this.loadData();
    },
    error => this.handleError(error) );
  }

  public unregisterCalendar(id, calendarId, integrationId) {
    this.googleCalendarService.unregisterCalendar(id, calendarId, this.clientID, integrationId).subscribe(res => {
      this.loadData();
    },
    error => this.handleError(error) );
  }

  public disconnectAccount(integrationId) {
    this.googleCalendarService.unregisterAllCalendars(integrationId, this.clientID).subscribe(res => {
      this.googleCalendarService.disintegrateAccount(integrationId, this.clientID).subscribe(resp => {
        this.loadData();
      },
      error => this.handleError(error) );
    },
    error => this.handleError(error) );
  }

  private handleError(error) {
    let message = error;
    if (message === undefined) {
      message = 'error';
    }
    this.utils.displayGrowlMessage('danger', 'Error', message);
  }
}
