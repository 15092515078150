export const environment = {
  production: false,
  serviceBaseURL: '',
  previewUrl: 'https://panel-ci.lobbit.app',
  apiPath: '/api/v2',
  userService: '/user',
  deviceGroupService: '/devicegroup',
  deviceGroupContentService: '/device-group-content',
  authenticateService: '/account/authenticate',
  clientService: '/client',
  contentService: '/content',
  contentGlobalService: '/global-content',
  googleAnalyticsService: '/google-analytics',
  propsSettingsService: '/refreshable-properties',
  deviceService: '/device',
  sanitizerService: '/sanitize',
  sshServerService: '/ssh/server',
  sshDeviceService: '/ssh/device',
  watchdogSettingsService: '/watchdogsettings',
  watchdogRecipientService: '/watchdogrecipient',
  watchdogActivityLogService: '/accountactivitylog',
  fileService: '/file',
  aclService: '/acl',
  googleOAuth2: '/google/oauth2',
  googleIntegrationUrl: '/integrations/google-calendars',
  googleCalendars: '/google/calendars',
  googleCalendarsRegistered: '/integrations/google-calendars/calendars',
  eventContent: '/content/event',
  analytics: false,
  amazonAwsBucketName: 'testing-bit4m',
  amazonAwsBucketNamePlaceholder: '{bucketname-placeholder}',
  googleMapsApiKey: 'AIzaSyB3Pc9egdsPP5OMXzYxWywnRJ1g5QqIuCA',
  froalaKey: 'AA9B7C6B2B3B3zE2A1A6A3A9A1B1A5E1A3mswyA-16xF2kmH-8G-7yB5rpbckwH3dvw=='
};
