import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { PageComponent } from './page.component';
import { PageListComponent } from './page-list/page-list.component';
import { PageAddEditComponent } from './page-add-edit/page-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const pageRoutes: Routes = [
  {
    path: 'pages',
    component: PageComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      {
        path: 'add',
        component: PageAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'edit/:id',
        component: PageAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      { path: 'list', component: PageListComponent, canActivate: [AuthGuard], resolve: [ClientResolve], data: { roles: ['ADMIN', 'USER'] } }
    ]
  }
];

export const pageRouting = RouterModule.forChild(pageRoutes);
