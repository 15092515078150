import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DndModule } from 'ng2-dnd';
import { TranslateModule } from '@ngx-translate/core';

import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { dishSetRouting } from './dish-set.routing';
import { DishSetComponent } from './dish-set.component';
import { DishSetListComponent } from './dish-set-list/dish-set-list.component';
import { DishSetAddEditComponent } from './dish-set-add-edit/dish-set-add-edit.component';
import { LanguageModule } from '../language/language.module';
import { SharedModule } from '../shared/shared.module';
import { DishSetService } from '../services';

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    dishSetRouting,
    DndModule.forRoot(),
    CommonModule,
    CustomModalModule,
    FormsModule,
    LanguageModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [DishSetComponent, DishSetListComponent, DishSetAddEditComponent],
  providers: [DishSetService]
})
export class DishSetModule { }
