import { DeviceGroupContentService } from './../../services/device-group-content.service';
import { DeviceGroup } from './../../models/device-group';
import { AdvertisingService } from './../../services/advertising.service';
import { Utils } from './../../shared/utils';
import { Component, OnInit } from '@angular/core';
import { Content } from '@app/models';
import { DeviceGroupService } from '@app/services';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-global-advertising-clients',
  templateUrl: './global-advertising-clients.component.html',
  styleUrls: ['./global-advertising-clients.component.css'],
  animations: [
    trigger('expandAdsContent', [
      state('open', style({
        'height': '*'
      })),
      state('close', style({
        'height': '0px'
      })),
      transition('open <=> close', animate(350))
    ])
  ]
})
export class GlobalAdvertisingClientsComponent implements OnInit {

  deviceGroups: DeviceGroup[] = [];
  states: GlobalAdvertisingState[] = [];

  sortBy = '+client.name';
  filterString: '';

  constructor(
    private deviceGroupService: DeviceGroupService,
    private advertisingService: AdvertisingService,
    private deviceGroupContentService: DeviceGroupContentService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.deviceGroupService.getAllDeviceGroups(true, 'ads').subscribe(d => {
      this.deviceGroups = d;
      this.advertisingService.getAllGlobal().subscribe(ads => {
        this.states = this.deviceGroups.map(dg => {
          let maxSortOrder = dg.deviceGroupContents.length > 0 ? Math.max(...dg.deviceGroupContents.map(dgc => dgc.sortOrder)) : -1;
          return {
            deviceGroupId: dg.id,
            expandAdsContent: 'close',
            ads: ads.map(ad => {
              const c = dg.deviceGroupContents.find(dgc => dgc.content.id === ad.id);
              return {
                edited: false,
                assigned: c ? true : false,
                content: ad,
                sortOrder: c ? c.sortOrder : ++maxSortOrder
              };
            }).sort((n1, n2) => n1.sortOrder - n2.sortOrder)
          };
        });
      });
    });
  }

  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  toggleExpandAdsContent(deviceGroupId) {
    const currentState = this.getState(deviceGroupId);
    currentState.expandAdsContent = (currentState.expandAdsContent === 'open') ? 'close' : 'open';
  }

  isStateExpandAdsContentOpen(deviceGroupId): boolean {
    const currentState = this.getState(deviceGroupId);
    return currentState ? (currentState.expandAdsContent === 'open' ? true : false) : false;
  }

  getStateAds(deviceGroupId): Array<GlobalAdvertisingStateAd> {
    const currentState = this.getState(deviceGroupId);
    return currentState ? currentState.ads : [];
  }

  getStateExpandAdsContentState(deviceGroupId): string {
    const currentState = this.getState(deviceGroupId);
    return currentState ? currentState.expandAdsContent : 'close';
  }

  private getState(deviceGroupId: any): GlobalAdvertisingState {
    return this.states.find(s => s.deviceGroupId === deviceGroupId);
  }

  assignedChanged(e, deviceGroupId, contentId) {
    const ad = this.getState(deviceGroupId).ads.find(a => a.content.id === contentId);
    if (ad) {
      ad.assigned = e.target.checked;
      ad.edited = !ad.edited;
    }
  }

  onDropSuccess(deviceGroupId) {
    let counter = 0;
    this.getState(deviceGroupId).ads.forEach(ad => {
      ad.sortOrder = counter++;
      if (ad.assigned) {
        ad.edited = true;
      }
    });
  }

  save() {
    const body: Array<{ deviceGroupId, contentId, assigned, sortOrder }> = [];
    this.states.forEach(s => {
      s.ads
        .filter(ad => ad.edited)
        .forEach(ad => {
          body.push({
            deviceGroupId: s.deviceGroupId,
            contentId: ad.content.id,
            assigned: ad.assigned,
            sortOrder: ad.sortOrder
          });
        });
    });
    this.deviceGroupContentService.manageByFieldAssigned(body).subscribe(() => {
      this.initData();
    });
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.initData();
  }
}

export class GlobalAdvertisingState {
  constructor(
    public deviceGroupId: number,
    public expandAdsContent: string,
    public ads: Array<GlobalAdvertisingStateAd>
  ) { }
}

export class GlobalAdvertisingStateAd {
  constructor(
    public edited: boolean,
    public assigned: boolean,
    public sortOrder: number,
    public content: Content
  ) { }
}
