import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { DeviceSshListComponent } from './device-ssh/device-ssh-list/device-ssh-list.component';
import { DeviceSshAddEditComponent } from './device-ssh/device-ssh-add-edit/device-ssh-add-edit.component';
import { remoteAccessRouting } from './remote-access.routing';
import { RemoteAccessComponent } from './remote-access.component';
import { ServerSshListComponent } from './server-ssh/server-ssh-list/server-ssh-list.component';
import { ServerSshAddEditComponent } from './server-ssh/server-ssh-add-edit/server-ssh-add-edit.component';
import { SharedModule } from '../shared/shared.module';
import { RemoteAccessService } from '../services';

@NgModule({
  imports: [
    CommonModule,
    CustomModalModule,
    FormsModule,
    remoteAccessRouting,
    SharedModule,
    TranslateModule
  ],
  declarations: [DeviceSshListComponent, DeviceSshAddEditComponent, ServerSshListComponent,
                ServerSshAddEditComponent, RemoteAccessComponent],
  providers: [RemoteAccessService]
})
export class RemoteAccessModule { }
