import { ThemeColors } from './theme-colors';
import { CoordsWithName } from './coords-with-name';
import { Screensaver } from './screensaver';
import { Language } from './language';

export enum DisplayTypeEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export class MainPage {
  constructor(
    public title: string,
    public logo: any,
    public colors: ThemeColors,
    public location: CoordsWithName,
    public version: string,
    public locales: Language[],
    public screensaver: Screensaver = new Screensaver(),
    public displayType: DisplayTypeEnum = DisplayTypeEnum.HORIZONTAL,
    public id?: number
  ) { }
}
