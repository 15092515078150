import { Component } from '@angular/core';

@Component({
  selector: 'app-props-settings',
  templateUrl: './props-settings.component.html'
})
export class PropsSettingsComponent {

  constructor() { }
}
