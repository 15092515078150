import { Component, OnInit, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute } from '@angular/router';
import { PageService } from '../../services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { I18nPipe } from '../../shared/pipes/i18n.pipe';
import { Utils } from '../../shared/utils';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import { environment } from '../../../environments/environment';
import { AuthService, ClientService, CanComponentDeactivate } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { ImgCropSettings } from '../../image-crop-uploader/img-cropper/img-cropper.model';
import { WidgetService, UploadService, LanguageService, AclService } from '../../services';
import { Widget, Category, UploadResponse, UploadProgressType } from '../../models';
import { WidgetType } from '@app/models/widget-type';

@Component({
  selector: 'app-widget-add-edit',
  templateUrl: 'widget-add-edit.component.html',
  styleUrls: ['widget-add-edit.component.css']
})
export class WidgetAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  WidgetType = WidgetType;
  widgetId: number = null;
  widget = new Widget();
  isNew = false;
  clients: Client[];
  clientID: number | string;
  pageId: number;
  saving: boolean;
  messageTypes: string[];
  fileIsLoading = false;
  editable: boolean;
  progress: 0;
  imageURL: string;
  editorOptions = {
    language: 'de',
    key: environment.froalaKey,
    events: {
      'froalaEditor.file.beforeUpload': function(event, editor, file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = e => {
          editor.file.insert(reader.result);
        };
        return false;
      }
    },
    imageDefaultWidth: 0,
    tableMultipleStyles: false,
    tableStyles: {
      'froala-table-no-vertical-border': 'No vertical border',
      'froala-table-no-border': 'No border',
      'froala-table-alternating-rows': 'Alternating rows',
      'froala-table-alternating-rows-no-horizontal-border': 'Alternating rows with no horizontal border'
    }
  };
  private selectedID: number;
  private sub: Subscription;
  selectedLanguageKey = '_';
  @ViewChild('widgetForm')
  widgetForm;
  private defaultLanguageImage = true;
  @ViewChild('languageSelector')
  languageSelector;
  private contentModified = false;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('filename')
  filename;
  @ViewChild('cropper')
  cropper;
  @ViewChild('widgetSlideshowType')
  private widgetSlideshowType;
  @ViewChild('widgetEventsType')
  private widgetEventsType;

  imgCropSetting: ImgCropSettings = {
    transformToPixels: 300,
    transformToAttribute: 'height',
    allowSmallBoundingBox: false,
    squareBox: true
  };

  icons: any = [];
  selectedIconGroup = 'action';
  loaded = false;
  private contentEditor: any = null;
  private deletedImageIds: number[] = [];
  private fileSize: number;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  widgetMaxSize = {
    resizeMaxHeight: 300,
    resizeMaxWidth: 300
  };

  widgets: any[] = new Array();
  displayEditor = true;
  private pageWidgetElements = 0;

  constructor(
    private widgetService: WidgetService,
    private pageService: PageService,
    public clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private uploadService: UploadService,
    private translate: TranslateService,
    public utils: Utils,
    private languageService: LanguageService,
    private authService: AuthService,
    private i18nPipe: I18nPipe,
    private aclService: AclService
  ) {
    this.messageTypes = this.widgetService.messageTypes;

    this.editorOptions.language = this.translate.currentLang;

    const that = this;
    this.editorOptions.events['froalaEditor.image.beforeUpload'] = function(event, editor, image) {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = e => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function() {
          let width = img.width;
          let height = img.height;

          const resizeMaxWidth = 1920;
          const resizeMaxHeight = 1280;

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > resizeMaxWidth) {
              height = Math.round((height *= resizeMaxWidth / width));
              width = resizeMaxWidth;
            }
          } else {
            if (height > resizeMaxHeight) {
              width = Math.round((width *= resizeMaxHeight / height));
              height = resizeMaxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          that.uploadService
            .saveFile(canvas.toDataURL('image/jpeg', 0.95), that.clientService.getCurrentClient().id)
            .subscribe((result: UploadResponse) => {
              if (result.resType === UploadProgressType.BODY) {
                editor.image.insert(result.content[0].url);
              }
            });
        };
        img.src = <string>reader.result;
      };
      return false;
    };
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.selectedID = params['id'];
      this.pageId = params['pageId'];

      if (params['pageWidgetElements'] !== undefined) {
        this.pageWidgetElements = params['pageWidgetElements'];
      }
      if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
        this.clientID = this.clientService.getCurrentClient().id;
        if (!this.selectedID) {
          this.isNew = true;
          this.loaded = true;
        }
        this.loadData(this.clientID, this.selectedID);
      }

      this.aclService.checkUserAuthority(this.authService.getUsername(), 'PAGE_EDIT_' + this.pageId).subscribe(response => {
        if (response) {
          this.editable = response.result || this.utils.isAdmin() || this.utils.isUserRightsAdmin();
        } else {
          this.editable = false;
        }
      });
    });

    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });

    this.languageService.currentLanguage$.takeUntil(this.ngUnsubscribe).subscribe(lang => {
      this.setImage();
    });

    this.translate.onLangChange.subscribe(() => {
      this.updateFroalaLanguage();
    });
  }

  private loadData(clientID: number | string, selectedID: number) {
    this.contentModified = false;
    this.updateFroalaLanguage();
    if (this.cropper) {
      this.cropper.isVisibleToCrop = false;
      this.cropper.isUploading = false;
    }
    if (clientID && selectedID) {
      this.widgetService
        .getByID(clientID, selectedID)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(p => {
          if (p) {
            this.loaded = true;
            this.widgetId = p.id;
            this.widget = p['value'];
            if (!this.widget.slides) {
              this.widget.slides = new Array();
            }
            if (
              typeof this.widget.image === 'object' &&
              this.widget.image[this.selectedLanguageKey] === '' &&
              this.languageSelector.languages[0].key === this.selectedLanguageKey
            ) {
              this.widget.image[this.selectedLanguageKey] = this.widget.image._;
            }
            if (this.widget.eventDateRange && this.widget.eventDateRange[0] && this.widget.eventDateRange[1]) {
              this.widget.eventDateRange[0] = new Date(this.widget.eventDateRange[0]);
              this.widget.eventDateRange[1] = new Date(this.widget.eventDateRange[1]);
            }
            this.widget = this.assignAwsBucket(this.widget);
            this.setImage();
          } else {
            this.widget = new Widget();
          }
        });
    }
    this.loadClientWidgets();
  }

  private loadClientWidgets() {
    this.widgets = new Array();
    this.pageService.getAll(this.clientID).subscribe(pages => {
      if (pages) {
        if (this.widgets.length === 0) {
          this.widgets.push({ id: null, value: { title: '' } });
        }
        for (const page of pages) {
          this.widgetService.getAll(this.clientID, page.id).subscribe(content => {
            if (content) {
              content
                .filter(widget => {
                  return (
                    (this.isNew || String(widget.id) !== String(this.selectedID)) &&
                    widget.value.type !== 'static' &&
                    widget.value.type !== 'weather'
                  );
                })
                .forEach(widget => {
                  if (this.widgets.filter(storedWidget => storedWidget.id === widget.id).length === 0) {
                    this.widgets.push(widget);
                  }
                });
            }
          });
        }
      }
    });
  }

  private setImage() {
    let image = null;
    this.imageURL = '';
    this.defaultLanguageImage = true;
    if (typeof this.widget.image !== 'object') {
      image = this.widget.image;
    } else {
      image = this.widget.image._;
      if (this.widget.image[this.selectedLanguageKey] && this.widget.image[this.selectedLanguageKey] != null) {
        image = this.widget.image[this.selectedLanguageKey];
        this.defaultLanguageImage = false;
      }
    }
    if (image && image !== null) {
      this.imageURL = this.utils.buildAwsImageURL(this.clientService.getCurrentClient().id, image);
    }
  }

  onFileChange(imageResult: any) {
    if (this.cropper && this.cropper.imgIsPresent) {
      this.fileIsLoading = true;
      this.contentModified = true;
      const fileBlob = imageResult.src;
      if (fileBlob && fileBlob.length) {
        this.fileSize = fileBlob.length;
      }
      const clientID = this.clientService.getCurrentClient().id;
      this.uploadService.saveFile(fileBlob, clientID).subscribe((result: UploadResponse) => {
        if (result.resType === UploadProgressType.PROGRESS) {
          this.progress = result.content;
        } else if (result.resType === UploadProgressType.BODY) {
          const key = this.getReference('image');
          if (key) {
            this.deletedImageIds.push(key);
          }
          this.setReference('image', result.content[0].key);
          this.imageURL = result.content[0].url;
          this.defaultLanguageImage = false;
          this.fileIsLoading = false;
          this.progress = 0;
        } else {
          this.fileIsLoading = false;
          this.progress = 0;
        }
      });
    }
  }

  deleteImage() {
    this.deletedImageIds.push(this.getReference('image'));
    this.setReference('image', '');
    this.imageURL = '';
    this.cropper.setSavedImage(this.imageURL);
    this.contentModified = true;
    this.fileSize = null;
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      if (this.widgetForm.form.dirty || this.contentModified || (this.cropper && this.cropper.isVisibleToCrop)) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            if (this.contentEditor) {
              this.contentEditor.destroy();
            }
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        if (this.contentEditor) {
          this.contentEditor.destroy();
        }
        resolve(true);
      }
    });
    return promise;
  }

  getProgress() {
    if (this.fileSize) {
      let currentProgress = Math.round((this.uploadService.progress / this.fileSize) * 100);
      if (currentProgress > 100) {
        currentProgress = 100;
      }
      return currentProgress;
    }
    return 0;
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  onSubmit() {
    this.saving = true;
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.widgetForm.form.valid && !this.fileIsLoading) {
        // copy of this.widget
        const widget = <Widget>JSON.parse(JSON.stringify(this.widget));
        this.sanitizeWidgetContent(widget).then(resolve => {
          if (resolve) {
            if (this.isNew) {
              widget.order = this.pageWidgetElements;
              this.widgetService.create(this.clientService.getCurrentClient().id, this.pageId, widget).subscribe(
                res => {
                  this.deleteUnusedImages();
                  this.handleServerResponse(true);
                  this.saving = false;
                  this.widgetService.attachToPageContent(this.clientService.getCurrentClient().id, this.pageId, res.id)
                    .subscribe(content => { });
                },
                error => {
                  this.handleServerResponse(false);
                  this.saving = false;
                }
              );
            } else {
              this.widgetService
                .update(this.clientService.getCurrentClient().id, this.pageId, widget, this.selectedID)
                .subscribe(
                  res => {
                    this.deleteUnusedImages();
                    this.handleServerResponse(true);
                    this.saving = false;
                  },
                  error => {
                    this.handleServerResponse(false);
                    this.saving = false;
                  }
                );
            }
          } else {
            this.handleServerResponse(false);
            this.saving = false;
          }
        });
      } else {
        this.saving = false;
      }
    }, 500);
  }

  private deleteUnusedImages() {
    if (this.deletedImageIds.length > 0) {
      for (const deletedImageId of this.deletedImageIds) {
        if (deletedImageId !== -1) {
          this.uploadService.delete(this.clientService.getCurrentClient().id, deletedImageId).subscribe(() => {});
        }
      }
    }
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.widgetForm.form.reset();
      this.contentModified = false;
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/pages/edit/' + this.pageId]);
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  private sanitizeWidgetContent(widget: Widget): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const propertyNames: string[] = [];
      for (const propertyName in this.widget.content) {
        if (this.widget.content.hasOwnProperty(propertyName)) {
          propertyNames.push(propertyName);
        }
      }
      if (propertyNames.length > 0) {
        const me = this;
        this.sanitizeNextContent(0, me, propertyNames, resolve, widget);
      } else {
        resolve(true);
      }
    });
  }

  private sanitizeNextContent(i: number, me: any, propertyNames: string[], resolve: any, widget: Widget) {
    if (i < propertyNames.length) {
      const propertyName = propertyNames[i];
      me.widgetService.sanitize(me.widget.content[propertyName]).subscribe(
        res => {
          widget.content[propertyName] = res.replace(
            me.getAwsBucketUrlRegex(me.authService['amazonAwsBucketName']),
            'https://' + environment.amazonAwsBucketNamePlaceholder + '.s3.amazonaws.com/img/'
          );
          this.sanitizeNextContent(i + 1, me, propertyNames, resolve, widget);
        },
        error => {
          resolve(false);
        }
      );
    } else {
      resolve(true);
    }
  }

  private assignAwsBucket(widget: Widget): Widget {
    for (const propertyName in widget.content) {
      if (widget.content.hasOwnProperty(propertyName)) {
        widget.content[propertyName] = widget.content[propertyName].replace(
          this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
          'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
        );
        // replace aws url when value is encoded
        if (widget.content[propertyName].includes(encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase())) {
          widget.content[propertyName] = widget.content[propertyName].replace(
            this.getAwsBucketUrlRegex(
              encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase()
            ),
            'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
          );
        }
      }
    }
    return widget;
  }

  private getAwsBucketUrlRegex(bucket: string) {
    const oldValue = 'https://' + bucket + '.s3.amazonaws.com/img/';
    return new RegExp(oldValue, 'g');
  }

  showField(widgetType: string, fieldType: string) {
    const wType = WidgetType.parse(widgetType);
    return wType ? wType.fields.indexOf(fieldType) > -1 : false;
  }

  getReference(param) {
    if (this.widget[param]) {
      this.synchronizeDefault(param);
      return typeof this.widget[param] === 'object'
        ? this.widget[param][this.selectedLanguageKey] && this.widget[param][this.selectedLanguageKey] !== ''
          ? this.widget[param][this.selectedLanguageKey]
          : this.languageSelector.languages[0].key === this.selectedLanguageKey
            ? this.widget[param]._
            : ''
        : this.languageSelector.languages[0].key === this.selectedLanguageKey
          ? this.widget[param]
          : '';
    } else {
      return '';
    }
  }

  setReference(param, value) {
    if (typeof this.widget[param] !== 'object') {
      this.widget[param] = {};
    }
    this.widget[param][this.selectedLanguageKey] = value;
    if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      this.widget[param]._ = value;
    }
  }

  getPlaceholder(param, i18nKey): string {
    let placeholder: string;
    if (typeof this.widget[param] === 'object') {
      if (this.widget[param]._ && this.widget[param]._ !== '') {
        placeholder = this.widget[param]._;
      }
    } else {
      if (this.widget[param] && this.widget[param] !== '') {
        placeholder = this.widget[param];
      }
    }
    if (!placeholder || placeholder === '') {
      this.translate.get(i18nKey).subscribe((res: string) => {
        placeholder = res;
      });
    }
    return placeholder;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.switchClientRequest$) {
      this.switchClientRequest$.unsubscribe();
    }

    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  froalaInit(value) {
    this.contentEditor = value;
    this.contentEditor.initialize();
  }

  private getIconCategory() {
    const currentIcon = this.icons.icons.filter(icon => icon.ligature === this.getReference('icon'))[0];
    if (!currentIcon) {
      return false;
    }
    return currentIcon.group_id;
  }

  private synchronizeDefault(param) {
    if (this.selectedLanguageKey !== '_' && this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      if (typeof this.widget[param] === 'object') {
        if (
          (!this.widget[param][this.selectedLanguageKey] || this.widget[param][this.selectedLanguageKey] === '') &&
          this.widget[param]._ &&
          this.widget[param]._ !== ''
        ) {
          this.widget[param][this.selectedLanguageKey] = this.widget[param]._;
        } else if (
          this.widget[param][this.selectedLanguageKey] &&
          this.widget[param][this.selectedLanguageKey] !== '' &&
          this.widget[param][this.selectedLanguageKey] !== this.widget[param]._
        ) {
          this.widget[param]._ = this.widget[param][this.selectedLanguageKey];
        }
      } else {
        if (
          (!this.widget[param][this.selectedLanguageKey] || this.widget[param][this.selectedLanguageKey] === '') &&
          this.widget[param] &&
          this.widget[param] !== ''
        ) {
          const value = this.widget[param];
          this.widget[param] = {};
          this.widget[param][this.selectedLanguageKey] = value;
          this.widget[param]._ = value;
        }
      }
    }
  }

  private updateFroalaLanguage() {
    this.editorOptions.language = this.translate.currentLang;
    this.displayEditor = false;
    setTimeout(() => {
      this.displayEditor = true;
    }, 0);
  }

  globalMouseUp(event) {
    if (this.widgetSlideshowType) {
      this.widgetSlideshowType.hotspotDragging = false;
    }
  }
}
