import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropUploaderComponent } from './image-crop-uploader.component';
import { FileDropModule } from 'ngx-file-drop';
import { ImgCropperComponent } from './img-cropper/img-cropper.component';

@NgModule({
  imports: [CommonModule, FileDropModule, TranslateModule],
  declarations: [ImageCropUploaderComponent, ImgCropperComponent],
  exports: [ImageCropUploaderComponent]
})
export class ImageCropUploaderModule { }
