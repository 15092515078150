import { GlobalAdvertisingClientsComponent } from './global-advertising-clients/global-advertising-clients.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { ClientResolve } from '../client-guard.service';
import { GlobalAdvertisingListComponent } from './global-advertising-list/global-advertising-list.component';
import { GlobalAdvertisingComponent } from './global-advertising.component';
import { AdvertisingAddEditComponent } from '@app/advertising/advertising-add-edit/advertising-add-edit.component';

const globalAdvertisingRoutes: Routes = [
  {
    path: 'global-advertising',
    component: GlobalAdvertisingComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'add',
        component: AdvertisingAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'edit/:id',
        component: AdvertisingAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'list',
        component: GlobalAdvertisingListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'clients-assigned/list',
        component: GlobalAdvertisingClientsComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      }
    ]
  }
];

export const globalAdvertisingRouting = RouterModule.forChild(globalAdvertisingRoutes);
