import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroupService } from './services';
import { DeviceGroup } from './models';

class ClientAndDeviceGroup {
  constructor(
    public client: Client,
    public deviceGroup: DeviceGroup
  ) {

  }
}
@Injectable()
export class ClientResolve implements Resolve<ClientAndDeviceGroup> {
  constructor(
    private deviceGroupService: DeviceGroupService,
    private clientService: ClientService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientAndDeviceGroup> {
    if (!this.clientService.getCurrentClient() || this.clientService.reloadClients) {
      this.clientService.reloadClients = false;
      const clientID = localStorage.getItem('client');
      return this.clientService.getClients().pipe(mergeMap(clients => {
        let client = clients[0];
        if (clientID) {
          for (const tmpClient of clients) {
            if (clientID === String(tmpClient.id)) {
              client = tmpClient;
              break;
            }
          }
        }
        this.clientService.setCurrentClient(client);
        localStorage.setItem('client', '' + client.id);
        return this.deviceGroupService.getAll(client.id).pipe(map(deviceGroups => {

          if (deviceGroups[0]) {
            this.deviceGroupService.setCurrentDeviceGroup(deviceGroups[0]);
            return new ClientAndDeviceGroup(client, deviceGroups[0]);
          } else {
            const newDeviceGroup = new DeviceGroup(true, null, '', null, [], '');
            this.deviceGroupService.setCurrentDeviceGroup(newDeviceGroup);
            return new ClientAndDeviceGroup(client, newDeviceGroup);
          }
        }));
      }));
    }
    return of(new ClientAndDeviceGroup(
      this.clientService.getCurrentClient(),
      this.deviceGroupService.currentDeviceGroup)
    );
  }
}
