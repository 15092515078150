import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { Content, Widget } from '../models';

@Injectable()
export class WidgetService {
  messageTypes: string[] = ['taxi', 'restaurant'];
  private baseURL = environment.contentService;
  private pageContentService = '/pageContent';
  constructor(private http: HttpClient, private api: ApiService, private authService: AuthService) { }

  getAll(clientID: number | string, pageId: number): Observable<Content[]> {
    return this.api
      .getData(this.baseURL + '/' + clientID + '?key=' + 'widgets.' + clientID + '&pageId=' + pageId)
      .pipe(
        map(this.extractContents, this)
      );
  }

  getAllByClient(clientID: number | string): Observable<Content[]> {
    return this.api
      .getData(this.baseURL + '/' + clientID + '?key=' + 'widgets.' + clientID)
      .pipe(
        map(this.extractContents, this)
      );
  }

  getAllByType(clientID: number | string, type: string): Observable<Content[]> {
    return this.api
      .getData(this.baseURL + '/' + clientID + '/widget?type=' + type)
      .pipe(
        map(this.extractContents, this)
      );
  }

  getByID(clientID: number | string, id: number): Observable<Content> {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + id).pipe(
      map(this.extractContent, this)
    );
  }

  create(clientID: number | string, pageId: number, widget: Widget): Observable<Content> {
    return this.store(clientID, false, pageId, widget);
  }

  update(clientID: number | string, pageId: number, widget: Widget, widgetID: number): Observable<Content> {
    return this.store(clientID, true, pageId, widget, widgetID);
  }

  delete(clientID: number | string, id: number): Observable<null> {
    const url = this.baseURL + '/' + clientID;
    return this.api.deleteData(url, id);
  }

  sanitize(raw: string): Observable<string> {
    const url = environment.sanitizerService;
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    const body = { html: raw };
    return this.http.post(url, body, options);
  }

  private store(
    clientID: number | string,
    update: boolean,
    pageId: number,
    widget: Widget,
    widgetID: number = null
  ): Observable<any> {
    const url = this.baseURL + '/' + clientID;
    const bodyObj = {
      key: 'widgets.' + clientID,
      value: widget
    };
    if (widgetID) {
      bodyObj['id'] = widgetID;
    }
    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  private extractContents(res: any): Content[] {
    const body: Content[] = res;
    return body.map(content => {
      return this.extractContent(content);
    });
  }

  private extractContent(res: any): Content {
    const body: Content = res;
    if (body && body.value) {
      if (typeof body.value.activated !== 'boolean') {
        body.value.activated = true;
      }
      if (typeof body.value.opacity === 'undefined') {
        body.value.opacity = 1;
      }
    }
    return body;
  }

  getPageAttachedWidgets(clientID: number | string, pageId: number): Observable<Content[]> {
    return this.api
      .getData(this.pageContentService + '/' + clientID + '?key=' + 'widgets.' + clientID + '&pageId=' + pageId);
  }

  assignToPageContent(clientID: number | string, pageID: number, widgetIds: number[]): Observable<string> {
    const url = this.baseURL + '/' + clientID + '/pageContent/assign?pageId=' + pageID + '&contentIds=' + widgetIds +
      '&key=widgets.' + clientID;
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options);
  }

  attachToPageContent(clientID: number | string, pageID: number, widgetId: number): Observable<Content> {
    const url = this.baseURL + '/' + clientID + '/pageContent/attach?pageId=' + pageID + '&contentId=' + widgetId +
      '&key=widgets.' + clientID;
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options).pipe(map(body => JSON.parse(body)));
  }

  getAllPageContentByWidget(clientID: number | string, widgetId: number) {
    return this.api
      .getData(this.pageContentService + '/' + clientID + '/content' + '?contentId=' + widgetId);
  }
}
