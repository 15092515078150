import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserService } from '@b4m/b4m-frontend-core';
import { User } from '@b4m/b4m-frontend-core';

@Component({
  selector: 'app-user-detail',
  templateUrl: 'user-detail.component.html',
  styleUrls: ['user-detail.component.css']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  user: User;
  private selectedID: string;
  private sub: Subscription;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.selectedID = params['id'];
      this.userService.getByID(this.selectedID).subscribe(user => {
        this.user = user;
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
