import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService, UtilsService } from '../../../../services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cards } from './google-analytics-panel-active-users.data';
import { GoogleAnalyticsFilterDateComponent } from '../components/google-analytics-filter-date/google-analytics-filter-date.component';

@Component({
  selector: 'app-google-analytics-panel-active-users',
  templateUrl: './google-analytics-panel-active-users.component.html',
  styleUrls: [
    './google-analytics-panel-active-users.component.css',
    '../../google-analytics-dashboard.component.css'
  ]
})
export class GoogleAnalyticsPanelActiveUsersComponent implements OnInit {
  private readonly translateKeyPrefix = 'dashboard.google-analytics.panels.activeusers.';

  @ViewChild(GoogleAnalyticsFilterDateComponent) filterDate: GoogleAnalyticsFilterDateComponent;

  gaReports = null;
  cards = cards;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private translate: TranslateService,
    public utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.googleAnalyticsService.getReportsData(this.cards.c1.dimension, this.cards.c1.metric,
      this.filterDate.dateRange[0], this.filterDate.dateRange[1]).subscribe(reports => {
        this.gaReports = reports;
        this.cards.c1.labels = this.getLabels('c1');
        this.cards.c1.datasets = this.getData('c1');
      });
  }

  private getData(c: string) {
    const dimensions = this.getDimension(c);
    const out = [];
    this.cards[c].metric.split(';').forEach(mType => {
      const metrics = dimensions
        .map(d => this.googleAnalyticsService.getMetricByDimensionAndType(d, mType))
        .filter(m => m && m !== null);
      if (metrics && metrics.length > 0) {
        out.push({ data: metrics[0].values, label: this.translate.instant(this.translateKeyPrefix + mType) });
      }
    });
    return out;
  }

  private getLabels(c: string) {
    const dimensions = this.getDimension(c);
    if (dimensions && dimensions.length > 0) {
      if (dimensions[0].values && dimensions[0].values.length > 0) {
        return dimensions[0].values.map(date => {
          const y = date[0].substring(0, 4);
          const m = date[0].substring(4, 6);
          const d = date[0].substring(6, 8);
          return d + '/' + m + '/' + y;
        });
      }
    }
    return [];
  }

  private getDimension(c: string) {
    return this.googleAnalyticsService.getDimensionByType(
      this.utilsService.clone(this.gaReports), this.cards[c].dimension.split(';')[0]
    );
  }
}
