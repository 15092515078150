import { PageContent } from './content';

// Poi's are designed like geojson http://geojson.org/geojson-spec.html
export class Poi {
  constructor(
    public type: string, // eg: Feature
    public geometry: GeometryObj,
    public properties: PoiProperties,
    public id?: number,
    public pageContents?: Array<PageContent>
  ) {}
}

export class GeometryObj {
  constructor(
    public type: string, // eg: Point, Polygon, LineString
    public coordinates: Coords
  ) {}
}

export class Coords {
  constructor(public longitude: number, public latitude: number) {}
}

export class PoiProperties {
  constructor(
    public title: string | Object,
    public category: string,
    public description: string | Object,
    public activated: boolean,
    public symbol: string,
    public url: string
  ) {}
}

export class Category {
  constructor(public id: number, public label: string) {}
}

export class PlacesRequestBody {
  constructor(
    public latitude: number,
    public longitude: number,
    public radius: number,
    public clientId: string | number,
    public deviceGroupId: string | number,
    public placesTypes: Array<any>
  ) {}
}
