export class Feedback {
  constructor(
    public id: number,
    public roomNumber: number,
    public widgetId: number,
    public widgetName: string,
    public questionAnswer: any,
    public choiceQuestion: any,
    public date: number,
    public additionalText: any
  ) { }
}
