import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { LocationSettingsComponent } from './location-settings/location-settings.component';
import { LogoSettingsComponent } from './logo-settings/logo-settings.component';
import { ColorSettingsComponent } from './color-settings/color-settings.component';
import { ScreensaverSettingsComponent } from './screensaver-settings/screensaver-settings.component';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { DisplayTypeComponent } from './display-type/display-type.component';
import { ClientResolve } from '../client-guard.service';

const mainPageRoutes: Routes = [
  {
    path: 'settings/location',
    component: LocationSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  },
  {
    path: 'settings/logo',
    component: LogoSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  },
  {
    path: 'settings/color',
    component: ColorSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  },
  {
    path: 'settings/screensaver',
    component: ScreensaverSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  },
  {
    path: 'settings/languages',
    component: LanguageSettingsComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  },
  {
    path: 'settings/display-type',
    component: DisplayTypeComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  }
];

export const mainPageRouting = RouterModule.forChild(mainPageRoutes);
