import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { LanguageService, PoiService } from '../../services';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-poi-category-list',
  templateUrl: 'poi-category-list.component.html',
  styleUrls: ['poi-category-list.component.css']
})
export class PoiCategoryListComponent implements OnInit, OnDestroy {
  clientSelected = false;
  poiCategories: any;
  @ViewChild('modal')
  modalComponent: any;
  private currentClient$: any;
  constructor(
    private poiService: PoiService,
    private clientService: ClientService,
    private languageService: LanguageService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.languageService.currentLanguage$.subscribe(lang => {
    });
    if (this.clientService.getCurrentClient() &&
      this.clientService.getCurrentClient().id) {
      this.poiService.getCategories(this.clientService.getCurrentClient().id).subscribe(categories => {
        this.clientSelected = true;
        this.poiCategories = categories;
      });
    } else {
      this.clientSelected = false;
    }

    this.currentClient$ = this.clientService.currentClient$.subscribe(client => {
      this.poiService.getCategories(client.id).subscribe(categories => {
        this.clientSelected = true;
        this.poiCategories = categories;
      });
    });
  }

  deletePoiCategory(catID: number) {
    this.poiService.removeCategory(this.clientService.getCurrentClient().id, catID).subscribe(res => {
      this.poiService.getCategories(this.clientService.getCurrentClient().id).subscribe(categories => {
        this.poiCategories = categories;
        this.modalComponent.modal.hide();
      });
    });
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  ngOnDestroy() {
    if (this.currentClient$) {
      this.currentClient$.unsubscribe();
    }
  }

}
