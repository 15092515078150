import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/takeUntil';

import { ClientService } from '@b4m/b4m-frontend-core';
import { Utils } from '../../shared/utils';
import { Events, UploadResponse, UploadProgressType } from '../../models';
import { EventsService, LanguageService, UploadService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '@b4m/b4m-frontend-core';
import { DateFormatterPipe } from '@app/shared/pipes/date-formatter.pipe';

@Component({
  selector: 'app-events-add-edit',
  templateUrl: './events-add-edit.component.html',
  styleUrls: ['./events-add-edit.component.css']
})
export class EventsAddEditComponent implements OnInit, OnDestroy {
  DateFormatterPipe = DateFormatterPipe;
  private sub: Subscription;
  currentEvent = new Events(true, '', [null, null], null, null, '', '', []);
  editId: number = null;
  isGoogleEvent = false;
  currentTag = '';
  editorOptions = {
    language: 'de',
    key: environment.froalaKey,
    events: {
      'froalaEditor.file.beforeUpload': function(event, editor, file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = e => {
          editor.file.insert(reader.result);
        };
        return false;
      }
    },
    imageDefaultWidth: 0,
    tableMultipleStyles: false,
    tableStyles: {
      'froala-table-no-vertical-border': 'No vertical border',
      'froala-table-no-border': 'No border',
      'froala-table-alternating-rows': 'Alternating rows',
      'froala-table-alternating-rows-no-horizontal-border': 'Alternating rows with no horizontal border'
    }
  };

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private contentEditor: any = null;
  @ViewChild('languageSelector')
  languageSelector;
  selectedLanguageKey = '_';
  @ViewChild('eventsForm')
  eventsForm;

  constructor(
    private clientService: ClientService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: Utils,
    private languageService: LanguageService,
    private uploadService: UploadService,
    public translate: TranslateService,
    private authService: AuthService
  ) {
    const that = this;
    this.editorOptions.events['froalaEditor.image.beforeUpload'] = function(event, editor, image) {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = e => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function() {
          let width = img.width;
          let height = img.height;

          const resizeMaxWidth = 1920;
          const resizeMaxHeight = 1280;

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > resizeMaxWidth) {
              height = Math.round((height *= resizeMaxWidth / width));
              width = resizeMaxWidth;
            }
          } else {
            if (height > resizeMaxHeight) {
              width = Math.round((width *= resizeMaxHeight / height));
              height = resizeMaxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          that.uploadService
            .saveFile(canvas.toDataURL('image/jpeg', 0.95), that.clientService.getCurrentClient().id)
            .subscribe((result: UploadResponse) => {
              if (result.resType === UploadProgressType.BODY) {
                editor.image.insert(result.content[0].url);
              }
            });
        };
        img.src = <string>reader.result;
      };
      return false;
    };
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.editId = params['id'];
      this.isGoogleEvent = params['isGoogleEvent'];
      if (this.editId) {
        if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
          this.reload(this.clientService.getCurrentClient().id, this.editId);
        }
      }
    });

    // Subscribe to current client to reload on change
    this.clientService.currentClient$.takeUntil(this.ngUnsubscribe).subscribe(client => {
      this.reload(client.id, this.editId);
    });

    this.editorOptions.language = this.translate.currentLang;
  }

  private reload(clientID: number | string, eventID: number) {
    this.eventsForm.form.reset();
    if (clientID && eventID) {
      this.eventsService
        .getByID(clientID, this.editId) //
        .takeUntil(this.ngUnsubscribe)
        .subscribe(content => {
          if (content) {
            this.currentEvent = content['value'];
            this.currentEvent = this.assignAwsBucket(this.currentEvent);
            if (this.currentEvent.dateRange && this.currentEvent.dateRange.length === 2) {
              this.currentEvent.dateRange[0] = new Date(this.currentEvent.dateRange[0]);
              this.currentEvent.dateRange[1] = new Date(this.currentEvent.dateRange[1]);
            }
          } else {
            this.currentEvent = new Events(true, '', [null, null], null, null, '', '', []);
          }
        });
    }
  }

  saveTag() {
    if (this.currentEvent.tags.indexOf(this.currentTag) === -1) {
      this.currentEvent.tags.push(this.currentTag);
    }

    this.currentTag = '';
  }

  removeTag(tagToRemove) {
    this.currentEvent.tags = this.currentEvent.tags.filter(tag => tag !== tagToRemove);
  }

  onSubmit() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.switchLanguage(this.languageSelector.languages[0].key);
      const savingEvent = <Events>JSON.parse(JSON.stringify(this.currentEvent));
      setTimeout(() => {
        if (this.eventsForm.form.valid) {
          this.sanitizeDescription(savingEvent);
          this.eventsService
            .store(this.clientService.getCurrentClient().id, this.editId ? true : false, savingEvent, this.editId, this.isGoogleEvent)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(
              res => {
                this.eventsForm.form.reset();
                this.utils.displayGrowlMessage('success', 'forms.submitted', '');
                this.router.navigate(['/events/list/']);
              },
              error => {
                this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
              }
            );
        }
      }, 500);
    }
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  getI18NAttribute(dottedName: string) {
    return this.utils.getI18NAttribute(this, dottedName);
  }

  setI18NAttribute(dottedName, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
  }

  getPlaceholder(dottedName, i18nKey): string {
    return this.utils.getPlaceholder(this, dottedName, i18nKey);
  }

  sanitizeDescription(event: Events) {
    const description: any = event.description;
    for (const propertyName in description) {
      if (event.description[propertyName] && event.description.hasOwnProperty(propertyName)) {
        event.description[propertyName] = event.description[propertyName].replace(
          this.getAwsBucketUrlRegex(this.authService['amazonAwsBucketName']),
          'https://' + environment.amazonAwsBucketNamePlaceholder + '.s3.amazonaws.com/img/'
        );
      }
    }
  }

  private assignAwsBucket(event: Events): Events {
    const description: any = event.description;
    for (const propertyName in description) {
      if (description[propertyName] && description.hasOwnProperty(propertyName)) {
        description[propertyName] = description[propertyName].replace(
          this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
          'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
        );
        // replace aws url when value is encoded
        if (description[propertyName].includes(encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase())) {
          description[propertyName] = description[propertyName].replace(
            this.getAwsBucketUrlRegex(
              encodeURIComponent(environment.amazonAwsBucketNamePlaceholder).toLowerCase()
            ),
            'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
          );
        }
      }
    }
    event.description = description;
    return event;
  }

  private getAwsBucketUrlRegex(bucket: string) {
    const oldValue = 'https://' + bucket + '.s3.amazonaws.com/img/';
    return new RegExp(oldValue, 'g');
  }

  froalaInit(value) {
    this.contentEditor = value;
    this.contentEditor.initialize();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
