import { Component, OnInit, ViewChild } from '@angular/core';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, enGbLocale } from 'ngx-bootstrap/locale';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@b4m/b4m-frontend-core';
import { supportedLanguages } from './shared/supported-languages';
import { LanguageService } from './services';
import { Utils } from './shared/utils';
import { interval } from 'rxjs/observable/interval';
import * as moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Lobbit';
  isAuthenticated = false;
  sessionTime: string;
  @ViewChild('keepLoggedIn')
  public keepLoggedInModal: ModalDirective;
  @ViewChild('sessionEnded')
  public sessionEndedModal: ModalDirective;
  jwtHelper = new JwtHelperService();

  constructor(
    translate: TranslateService,
    private bsLocaleService: BsLocaleService,
    private authService: AuthService,
    private languageService: LanguageService,
    private utils: Utils,
  ) {
    this.keepAlive();
    defineLocale('de', deLocale);
    defineLocale('en', enGbLocale);
    translate.setDefaultLang('en');
    let selectedLang: string = localStorage.getItem('backend_language')
      ? localStorage.getItem('backend_language')
      : translate.getBrowserLang();
    selectedLang = selectedLang.substring(0, 2);
    const selectedLangSupported = supportedLanguages.find(supportedLanguage => supportedLanguage === selectedLang);
    if (selectedLangSupported) {
      translate.use(selectedLang);
      moment.locale(selectedLang);
      this.bsLocaleService.use(selectedLang);
    } else {
      translate.use('en');
      moment.locale('en');
      this.bsLocaleService.use('en');
    }
    this.authService.isAuthenticated$.subscribe(isAuth => {
      this.isAuthenticated = isAuth;
    });
    this.isAuthenticated = this.authService.isAuthenticated();
    this.languageService.currentLanguage$.subscribe(lang => {
      // console.log('change Language to: ' + lang);
    });
    this.languageService.setCurrentLanguage('_');
  }

  ngOnInit() {
    // TODO update session time after language change
    interval(1000).subscribe(() => this.configureSessionTime());
  }

  public keepLoginDialog() {
    this.keepLoggedInModal.show();
  }

  public sessionEndedDialog() {
    if (this.keepLoggedInModal.isShown) {
      this.keepLoggedInModal.hide();
    }
    this.sessionEndedModal.show();
  }

  public keepSeesion() {
    this.utils.keepAlive().then(res => {
      this.keepLoggedInModal.hide();
    });
  }

  public gotoLogin() {
    localStorage.removeItem('token');
    window.location.reload();
  }

  private keepAlive() {
    interval(10000).subscribe(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const payload = this.jwtHelper.decodeToken(token);
        const exp = payload.exp;

        const now = new Date().getTime() / 1000;
        if (exp - now <= 0 && !this.sessionEndedModal.isShown) {
          this.sessionEndedDialog();
        } else if (exp - now < 300 && exp - now > 0 && !this.keepLoggedInModal.isShown) {
          this.keepLoginDialog();
        }
      }
    });
  }

  // method from configureSessionTime() cannot be used as there are issues with setting locale for 'moment' globally
  configureSessionTime() {
    let token;
    if (this.authService.token || this.authService.token !== '') {
      token = this.authService.token;
    }
    const localToken = localStorage.getItem('token');
    if (localToken && localToken !== '') {
      token = localToken;
    }
    if (!token || token === '') {
      this.sessionTime = '';
    } else {
      const from = moment(new Date());
      const end = moment(this.jwtHelper.decodeToken(token).exp * 1000);
      this.sessionTime = from.to(end);
    }
  }
}
