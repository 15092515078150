import { Component, OnInit, Input } from '@angular/core';
import { EventsService } from '../../../services';
import { Widget } from '../../../models';
import { ClientService } from '@b4m/b4m-frontend-core';
import { DateFormatterPipe } from '@app/shared/pipes/date-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widget-event-type',
  templateUrl: './widget-event-type.component.html',
  styleUrls: ['../widget-add-edit.component.css']
})
export class WidgetEventTypeComponent implements OnInit {

  eventOptions: string[] = ['SINGLE', 'LIST'];
  eventListOptions: string[] = ['CURRENT_DAY', 'CURRENT_WEEK', 'CURRENT_MONTH', 'DATERANGE', 'NEXT_DAY', 'NEXT_WEEK', 'NEXT_MONTH'];
  currentEventTag = '';
  events: any[] = new Array();
  clientID = null;
  DateFormatterPipe = DateFormatterPipe;

  @Input() editable;
  @Input() widget: Widget;

  constructor(
    private eventsService: EventsService,
    public clientService: ClientService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientID = this.clientService.getCurrentClient().id;
      this.loadEvents();
    }
  }

  private loadEvents() {
    this.eventsService.getAll(this.clientID).subscribe(events => {
      if (events) {
        if (this.events.length === 0) {
          this.events.push({ id: null, value: { title: '' } });
        }
        events.forEach(event => {
          if (this.events.filter(filteredEvent => filteredEvent.id === event.id).length === 0) {
            this.events.push(event);
          }
        });
        this.events.sort(this.compareEvents);
      }
    });
  }

  private compareEvents(event1, event2) {
    if (event1.value.dateRange && event2.value.dateRange) {
      if (event1.value.dateRange[0] < event2.value.dateRange[0]) {
        return -1;
      }
      if (event1.value.dateRange[0] > event2.value.dateRange[0]) {
        return 1;
      }
    }
    return 0;
  }

  saveEventTag() {
    if (!this.widget.eventTags) {
      this.widget.eventTags = [];
    }
    if (this.widget.eventTags.indexOf(this.currentEventTag) === -1) {
      this.widget.eventTags.push(this.currentEventTag);
    }

    this.currentEventTag = '';
  }

  removeEventTag(tagToRemove) {
    this.widget.eventTags = this.widget.eventTags.filter(eventTag => eventTag !== tagToRemove);
  }
}
