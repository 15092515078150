import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ClientService, CanComponentDeactivate, UserService, AuthService } from '@b4m/b4m-frontend-core';
import { AclService } from '../../services';
import { User, Password, CreateUserDTO } from '@b4m/b4m-frontend-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

export class AuthObj {
  title: string;
  auth: string;
  constructor(title: string, auth: string) {
    this.title = title;
    this.auth = auth;
  }
}

export interface SelectItem {
  label: string;
  value: any;
}

@Component({
  selector: 'app-user-add-edit',
  templateUrl: 'user-add-edit.component.html',
  styleUrls: ['user-add-edit.component.css']
})
export class UserAddEditComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  EMAIL_REGEXP = new RegExp(
    [
      '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.',
      '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:',
      '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+',
      '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?'
    ].join('')
  );

  isNew = false;
  user: User = new User('', '', '', '', null, [], true);
  authorities: Array<AuthObj> = new Array();
  isAdmin: boolean;
  isUserRightsAdmin: boolean;
  isSSHServer: boolean;
  selectedID: string;
  private sub: Subscription;
  usersList$: Observable<Array<User>>;
  usersList: Array<User>;
  clients: SelectItem[];
  password: Password = new Password();
  isCurrentUserAdmin: boolean;
  isCurrentUserUserRightsAdmin: boolean;
  isCurrentUserSystemAdmin: boolean;
  @ViewChild('userForm')
  userForm;
  private switchClientRequest$: Subscription;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('passwordModal')
  private passwordModal: CustomModalComponent;
  @ViewChild('authSelect')
  authSelect;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private clientService: ClientService,
    private translate: TranslateService,
    private utils: Utils,
    private authService: AuthService,
    private aclService: AclService
  ) { }

  ngOnInit() {
    this.isCurrentUserAdmin = this.utils.isAdmin();
    this.isCurrentUserUserRightsAdmin = this.utils.isUserRightsAdmin();
    this.isCurrentUserSystemAdmin = this.utils.isSystemAdmin();
    this.sub = this.route.params.subscribe(params => {
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('forms.choose').subscribe(translation => {
          if (this.authSelect && this.user.authorities.length === 0) {
            this.authSelect.valuesAsString = translation;
          }
        });
      });

      this.translate.get('forms.choose').subscribe((res: string) => {
        if (this.authSelect && this.user.authorities.length === 0) {
          this.authSelect.defaultLabel = res;
        }
      });

      this.selectedID = params['id'];
      if (this.selectedID) {
        this.userService.getByID(this.selectedID).subscribe(user => {
          this.user = user;
          // change state of admin checkbox
          const index = this.user.authorities.indexOf('ROLE_ADMIN');
          if (index > -1) {
            this.isAdmin = true;
            this.user.authorities.splice(index, 1);
          } else {
            this.isAdmin = false;
          }
          // change state of ssh server checkbox
          const sshServerIndex = this.user.authorities.indexOf('ROLE_SSH_SERVER');
          if (sshServerIndex > -1) {
            this.isSSHServer = true;
            this.user.authorities.splice(index, 1);
          } else {
            this.isSSHServer = false;
          }
          const userRightsAdminIndex = this.user.authorities.indexOf('ROLE_USER_RIGHTS_ADMIN');
          if (userRightsAdminIndex > -1) {
            this.isUserRightsAdmin = true;
            this.user.authorities.splice(userRightsAdminIndex, 1);
          } else {
            this.isUserRightsAdmin = false;
          }
        });
      } else {
        this.isNew = true;
      }
    });
    this.clients = [];
    this.clientService.getClients().subscribe(clients => {
      clients.forEach(client => {
        this.authorities.push(new AuthObj(client.name, 'ROLE_IC:' + client.id));
        this.clients.push({ label: client.name, value: 'ROLE_IC:' + client.id });
      });
    });

    if (this.isCurrentUserAdmin) {
      this.loadUsersListOnInit();
    }
    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });
  }

  checkboxChange(e, client) {
    if (e.target.checked) {
      this.user.authorities.push(client.value);
    } else {
      const clientIndex = this.user.authorities.indexOf(client.value);
      this.user.authorities.splice(clientIndex, 1);
    }
  }

  private loadUsersListOnInit() {
    this.userService.getAll().subscribe(users => {
      this.usersList = users;
    });
  }

  showPasswordModal() {
    this.password = new Password(this.selectedID);
    this.passwordModal.showModal('', '', '', false, false);
  }

  closePasswordModal() {
    this.passwordModal.closeModal();
  }

  submitPasswordModal() {
    this.userService.changePassword(this.password).subscribe(
      res => {
        this.passwordModal.closeModal();
        this.handleServerResponse(true);
      },
      error => {
        const errorBody = error;
        const message = this.utils.getTranslation(errorBody.message);
        this.passwordModal.closeModal();
        this.handleServerResponse(false, message);
      }
    );
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      if (this.userForm.form.dirty) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  onSubmit() {
    if (this.isAdmin) {
      this.user.authorities = ['ROLE_ADMIN'];
    } else if (this.isSSHServer) {
      this.user.authorities = ['ROLE_SSH_SERVER'];
    } else if (this.isUserRightsAdmin && this.checkIfSelectedClient()) {
      this.user.authorities.push('ROLE_USER_RIGHTS_ADMIN');
    } else {
      const adminIndex = this.user.authorities.indexOf('ROLE_ADMIN');
      if (adminIndex > -1) {
        this.user.authorities.splice(adminIndex, 1);
      }
      const sshServerIndex = this.user.authorities.indexOf('ROLE_SSH_SERVER');
      if (sshServerIndex > -1) {
        this.user.authorities.splice(sshServerIndex, 1);
      }
      const userRightsAdminIndex = this.user.authorities.indexOf('ROLE_USER_RIGHTS_ADMIN');
      if (userRightsAdminIndex > -1) {
        this.user.authorities.splice(userRightsAdminIndex, 1);
      }
    }
    if (this.isNew) {
      const u = this.user;
      const createUserDTO = new CreateUserDTO(
        u.login,
        u['password'],
        u.firstName,
        u.lastName,
        u.email,
        '',
        u.authorities,
        u.activated
      );
      this.userService.create(createUserDTO).subscribe(
        res => {
          this.handleServerResponse(true);
          this.aclService.setUserAllAuthorities(res.login).subscribe();
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    } else {
      this.userService.update(this.user).subscribe(
        res => {
          this.handleServerResponse(true);
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    }
  }

  private handleServerResponse(success: boolean, message?: string) {
    // isAdmin is set to null after form reset
    const isAdmin = this.utils.isAdmin();
    if (success) {
      this.userForm.form.reset();
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      if (isAdmin) {
        this.router.navigate(['/user/list']);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      if (message === undefined) {
        message = 'error';
      }
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', message);
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.switchClientRequest$) {
      this.switchClientRequest$.unsubscribe();
    }
  }

  checkIfUserLoginIsUnique() {
    let isUnique = true;
    this.usersList.forEach(user => {
      if (user.login === this.user.login && this.selectedID !== this.user.login) {
        isUnique = false;
      }
    });
    return isUnique;
  }

  isAuthoritiesValid() {
    if (!this.isAdmin && !this.isSSHServer && this.user.authorities.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  checkIfSelectedClient() {
    return this.clients.some(c => this.user.authorities.includes(c.value));
  }

  validateEmail(email): boolean {
    if (!this.EMAIL_REGEXP.test(email)) {
      return false;
    } else {
      return true;
    }
  }
}
