import { GoogleAnalyticsFilterDateComponent } from './../components/google-analytics-filter-date/google-analytics-filter-date.component';
import { GoogleAnalyticsFilterModalComponent } from './../components/google-analytics-filter-modal/google-analytics-filter-modal.component';
import { GoogleAnalyticsService, UtilsService } from '../../../../services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cards } from './google-analytics-panel-device.data';

@Component({
  selector: 'app-google-analytics-panel-device',
  templateUrl: './google-analytics-panel-device.component.html',
  styleUrls: [
    './google-analytics-panel-device.component.css',
    '../../google-analytics-dashboard.component.css'
  ]
})
export class GoogleAnalyticsPanelDeviceComponent implements OnInit {
  @ViewChild(GoogleAnalyticsFilterModalComponent) filterModal: GoogleAnalyticsFilterModalComponent;
  @ViewChild(GoogleAnalyticsFilterDateComponent) filterDate: GoogleAnalyticsFilterDateComponent;

  gaReports = null;
  cards = cards;
  dimension;
  leftCardOptionValue = '2';
  rightCardOptionValue = '5';

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    public utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.convertCardsC7();
    this.loadData();
  }

  loadData() {
    let metric = '';
    for (const i of [2, 3, 4, 5, 6, 7]) {
      metric += this.cards['c' + i].metric + ',';
    }
    this.googleAnalyticsService.getReportsData(this.cards.c2.dimension, metric,
      this.filterDate.dateRange[0], this.filterDate.dateRange[1], this.filterModal.getFilterIds()).subscribe(reports => {
        this.gaReports = reports;
        this.dimension = this.getDimensions('c2')[0];
        for (const i of [2, 3, 4, 5, 6, 7]) {
          const card = this.cards['c' + i];
          card.data = this.getData('c' + i);
          card.labels = this.getLabels('c' + i);
          if (card.dataType === 'double') {
            card.data = this.roundData(card.data);
          }
        }
      });
  }

  private getData(c: string) {
    const dimensions = this.getDimensions(c);
    if (dimensions && dimensions.length > 0) {
      const metric = this.googleAnalyticsService.getMetricByDimensionAndType(dimensions[0], this.cards[c].metric);
      this.cards[c].metricData = metric;
      return metric.values;
    } else {
      return [];
    }
  }

  private getLabels(c: string) {
    const dimensions = this.getDimensions(c);
    if (dimensions && dimensions.length > 0 && dimensions[0].values && dimensions[0].values.length > 0) {
      return dimensions[0].values.map(v => v[0]);
    }
    return [];
  }

  private getDimensions(c: string) {
    return this.googleAnalyticsService.getDimensionByType(this.utilsService.clone(this.gaReports), this.cards[c].dimension);
  }

  private roundData(metricValues) {
    return metricValues.map(v => this.utilsService.round(v, 2));
  }

  getMetric(metric: string) {
    return this.googleAnalyticsService.getMetricByDimensionAndType(this.dimension, metric);
  }

  resetFilter() {
    this.filterModal.resetFilter();
    this.loadData();
  }

  convertDateValue(value): string {
    const v = this.utilsService.convertSecondsToTime(value);
    if (!v || v === '') {
      return '0s';
    } else {
      return v;
    }
  }

  private convertCardsC7() {
    const tooltips = {
      callbacks: {
        title: (tooltipItem, data) => {
          return data['labels'][tooltipItem[0]['index']];
        },
        label: (tooltipItem, data) => {
          return this.utilsService.convertSecondsToTime(data['datasets'][0]['data'][tooltipItem['index']]);
        },
      },
    };
    const render = {
      labels: {
        render: (data) => {
          return this.utilsService.convertSecondsToTime(data.value);
        }
      }
    };

    this.cards.c7.options.tooltips = tooltips;
    this.cards.c7.options.plugins = render;
  }
}
