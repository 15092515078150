import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { CustomModalComponent } from '../../../custom-modal/custom-modal.component';
import { Utils } from '../../../shared/utils';
import { RemoteAccessService } from '../../../services';

@Component({
  selector: 'app-server-ssh-list',
  templateUrl: './server-ssh-list.component.html',
  styleUrls: ['./server-ssh-list.component.css']
})
export class ServerSshListComponent implements OnInit, OnDestroy {

  serverSSHDataList: any[];
  sortBy: string;
  filterString: '';
  @ViewChild('modal')
  private modal: CustomModalComponent;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private remoteAccessService: RemoteAccessService, private utils: Utils) { }

  ngOnInit() {
    this.sortBy = '+name'; // sorting defaults to the name field (asc)
    this.getAll();
  }

  private getAll() {
    this.remoteAccessService.getAllServerSSHData().subscribe(res => {
      this.serverSSHDataList = res;
    });
  }

  /**
	 * this function sets the sorting attribute and keeps track of the sorting direction
	 * @param {string} sorter - the attribute to sortBy
	 * @return {void}
	 */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter)  {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
	 * Notify the filter if the search string has changed
	 * @param {Event} event - the sender event
	 * @return {void}
	 */
  changeFilter(event) {
    this.filterString = event.target.value;
  }

  removeServerSSHData(serverSSHDataId: number) {
    this.modal.closeModal();
    this.remoteAccessService.deleteServerSSHData(serverSSHDataId).takeUntil(this.ngUnsubscribe).subscribe(res => {
      this.getAll();
    },
    error => {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
    });
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
