import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { ClientResolve } from '../client-guard.service';
import { GoogleAnalyticsDashboardComponent } from './google-analytics-dashboard/google-analytics-dashboard.component';
import { GoogleAnalyticsComponent } from './google-analytics.component';

const googleAnalyticsRoutes: Routes = [
  {
    path: 'google-analytics',
    component: GoogleAnalyticsComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'dashboard',
        component: GoogleAnalyticsDashboardComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
    ]
  }
];

export const googleAnalyticsRouting = RouterModule.forChild(googleAnalyticsRoutes);
