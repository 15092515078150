import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService, UserService } from '@b4m/b4m-frontend-core';
import { User } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  users: Array<User>;
  active = true;
  sortBy: string;
  filterString: '';
  constructor(private userService: UserService, private clientService: ClientService, private utils: Utils) {}

  ngOnInit() {
    this.getUsers();
    this.sortBy = '+login'; // sorting defaults to the name field (asc)
  }

  deleteUser(id: string): void {
    this.modal.closeModal();
    this.userService.delete(id).subscribe(() => {
      // update user list after delete
      this.getUsers();
    });
  }

  /**
   * this function sets the sorting attribute and keeps track of the sorting direction
   * @param {string} sorter - the attribute to sortBy
   * @return {void}
   */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
   * Notify the filter if the search string has changed
   * @param {Event} event - the sender event
   * @return {void}
   */
  changeFilter(event) {
    this.filterString = event.target.value;
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  /**
   * This function gets all users from the userService and replaces the authorities with
   * the respective clear string. The function doesnt return the list but set it for the component directy
   * @return {void}
   */
  private getUsers() {
    this.userService.getAll().subscribe(users => {
      this.users = users;
      this.clientService.getClients().subscribe(clients => {
        this.users.forEach((user, uIndex) => {
          user.authorities.forEach((auth, aIndex) => {
            if (auth === 'ROLE_ADMIN') {
              this.users[uIndex].authorities[aIndex] = 'Admin';
            } else if (auth === 'ROLE_SYSTEM_ADMIN') {
              this.users[uIndex].authorities[aIndex] = 'System Admin';
            } else if (auth === 'ROLE_SSH_SERVER') {
              this.users[uIndex].authorities[aIndex] = 'SSH Server';
            } else if (auth === 'ROLE_USER_RIGHTS_ADMIN') {
              this.users[uIndex].authorities[aIndex] = 'User Rights Admin';
            } else {
              const clientID = auth.substr(8);
              clients.forEach(client => {
                if (String(client.id) === clientID) {
                  this.users[uIndex].authorities[aIndex] = client.name;
                }
              });
            }
          });
        });
        this.users = users;
      });
    });
  }
}
