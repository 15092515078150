import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit {

    displayStyle: string;

    ngOnInit() {
      this.displayStyle = 'none';
    }

    upload() {
      this.displayStyle = 'inline-block';
    }

    finish() {
      this.displayStyle = 'none';
    }

}
