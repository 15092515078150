import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { EventsComponent } from './events.component';
import { EventsAddEditComponent } from './events-add-edit/events-add-edit.component';
import { EventsListComponent } from './events-list/events-list.component';
import { ClientResolve } from '../client-guard.service';

const eventsRoutes: Routes = [
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      {
        path: 'add',
        component: EventsAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'edit/:id',
        component: EventsAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'list',
        component: EventsListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      }
    ]
  }
];

export const eventsRouting = RouterModule.forChild(eventsRoutes);
