import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/takeUntil';

import { CustomModalComponent } from '../../../custom-modal/custom-modal.component';
import { Pageable } from '../../../shared/pagination/pagination.component';
import { Utils } from '../../../shared/utils';
import { DeviceService, RemoteAccessService } from '../../../services';
import { DeviceSSHData } from '../../../models';
import { ClientService } from '@b4m/b4m-frontend-core';

@Component({
  selector: 'app-device-ssh-add-edit',
  templateUrl: './device-ssh-add-edit.component.html',
  styleUrls: ['./device-ssh-add-edit.component.css']
})
export class DeviceSshAddEditComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  currentDeviceSshData = new DeviceSSHData(null, null, '0', '', '', '');
  editId: number = null;

  devices: any[];
  serverSSHDataList: any[];

  private portAvailability = {
    initialPort: '0',
    lastCheckedPort: null,
    lastCheckedPortStatus: '0'
  };

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('deviceSshForm') deviceSshForm;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(private deviceService: DeviceService, private remoteAccessService: RemoteAccessService, private clientService: ClientService,
              private route: ActivatedRoute, private router: Router, private utils: Utils) { }

  ngOnInit() {
    this.currentDeviceSshData['username'] = this.currentDeviceSshData['username'] ? this.currentDeviceSshData['username'] : 'lobbit';
    this.sub = this.route.params.subscribe(params => {
      this.editId = params['id'];
      this.reload(this.editId);
    });
  }

  private reload(deviceSshDataID: number) {
    this.deviceSshForm.form.reset();
    if (deviceSshDataID) {
      this.remoteAccessService.getDeviceSSHDataByDeviceId(this.editId) //
      .takeUntil(this.ngUnsubscribe).subscribe(deviceSshData => {
        if (deviceSshData) {
          this.currentDeviceSshData = deviceSshData;
          this.currentDeviceSshData['username'] = this.currentDeviceSshData['username'] ? this.currentDeviceSshData['username'] : 'lobbit';
          this.currentDeviceSshData['deviceId'] = deviceSshData.device ? deviceSshData.device.id : null;
          this.portAvailability.initialPort = this.currentDeviceSshData['port'];
          this.devices = [this.currentDeviceSshData['device']];
        } else {
          this.router.navigate(['/remote-access/device/list/']);
        }
      });
    } else {
      this.loadDevices();
    }
    this.loadServerSSHDataList();
  }

  private loadDevices() {
    const clientId = this.clientService.getCurrentClient().id;
    console.log('clientId: ' + clientId);
    if (clientId) {
      this.remoteAccessService.getAllDeviceSSHData(new Pageable(0, '', ''), '10000').subscribe(deviceSSHDataList => {
        this.devices = [];
        this.deviceService.getAllByClientId(clientId).subscribe(devices => {
          if (devices) {
            const tempDevices = devices.filter(
              device => {
                if (device['clientSeparator']) {
                  return true;
                }
                if (device['deviceSSHDataList'] && device['deviceSSHDataList'].length > 0) {
                  return false;
                }
                if (deviceSSHDataList.content.filter(deviceSSHData => {
                  return deviceSSHData.device.id === device.id;
                }).length === 0) {
                  return true;
                }
                return false;
              }
            );
            tempDevices.sort(this.compareDevices);
            let tmpClientId = 0;
            tempDevices.forEach(device => {
              if (device['clientId'] !== tmpClientId) {
                tmpClientId = device['clientId'];
                this.devices.push({id: -1, clientId: tmpClientId, clientSeparator: true});
              }
              this.devices.push(device);
            });
          }
        });
      });
    } else {
      console.log('clientId is null');
    }
  }

  private loadServerSSHDataList() {
    this.serverSSHDataList = [];
    this.remoteAccessService.getAllServerSSHData().subscribe(serverSSHDataList => {
      if (serverSSHDataList) {
        const tempServerSSHDataList = serverSSHDataList;
        tempServerSSHDataList.sort(this.compareServerSSHData);
        this.serverSSHDataList = tempServerSSHDataList;
      }
    });
  }

  checkPortAvailability() {
    if (!this.portAvailability.initialPort || this.portAvailability.initialPort === '0' ||
          this.portAvailability.initialPort !== this.currentDeviceSshData['port']) {
      this.remoteAccessService.checkPortAvailability(this.currentDeviceSshData['serverSSHDataId'], this.currentDeviceSshData['port']) //
      .subscribe(
        res => {
          if (!isNaN(res)) {
            if (res !== this.currentDeviceSshData['port']) {
              this.utils.displayGrowlMessage( 'error',
                                              'remote-access.port-availability-failed-summary',
                                              'remote-access.port-availability-failed-detail'
              );
              this.currentDeviceSshData['port'] = res;
            } else {
              this.utils.displayGrowlMessage('success', 'remote-access.port-availability-success-summary', '');
            }
          } else {
            this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
          }
        },
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
        }
      );
    }
  }

  onSubmit(action?: string) {
    if (this.deviceSshForm.form.valid) {
      this.remoteAccessService.storeDeviceSSHData(this.currentDeviceSshData) //
      .takeUntil(this.ngUnsubscribe).subscribe(
        res => {
          if (action) {
            if (action === 'ASSIGN') {
              this.remoteAccessService.assignDeviceSSHData(this.currentDeviceSshData['deviceId']).subscribe(assignRes => {
                this.handleSuccessSubmit();
              });
            } else if (action === 'UNASSIGN') {
              this.remoteAccessService.unassignDeviceSSHData(this.currentDeviceSshData['deviceId']).subscribe(unassignRes => {
                this.handleSuccessSubmit();
              });
            } else {
              this.handleSuccessSubmit();
            }
          } else {
            this.handleSuccessSubmit();
          }
        },
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
        }
      );
    }
  }

  handleSuccessSubmit() {
    this.deviceSshForm.form.reset();
    this.utils.displayGrowlMessage('success', 'forms.submitted', '');
    this.router.navigate(['/remote-access/device/list/']);
  }

  private compareDevices(device1, device2) {
    if (device1.clientId && device2.clientId) {
      if (device1.clientId < device2.clientId) {
        return -1;
      }
      if (device1.clientId > device2.clientId) {
        return 1;
      }
    }
    return 0;
  }

  private compareServerSSHData(compareServerSSHData1, compareServerSSHData2) {
    if (compareServerSSHData1.name && compareServerSSHData2.name) {
      if (compareServerSSHData1.name < compareServerSSHData2.name) {
        return -1;
      }
      if (compareServerSSHData1.name > compareServerSSHData2.name) {
        return 1;
      }
    }
    return 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
