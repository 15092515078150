import { DeviceGroupContentService } from './../../services/device-group-content.service';
import { CustomModalComponent } from './../../custom-modal/custom-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AdvertisingService, UploadService } from '@app/services';
import { Utils } from '@app/shared/utils';

@Component({
  selector: 'app-global-advertising-list',
  templateUrl: './global-advertising-list.component.html',
  styleUrls: ['./global-advertising-list.component.css'],
})
export class GlobalAdvertisingListComponent implements OnInit {

  @ViewChild('modal') private modal: CustomModalComponent;
  @ViewChild('modalAssign') private modalAssign: CustomModalComponent;
  @ViewChild('modalUnassign') private modalUnassign: CustomModalComponent;
  advertisements: any;
  private clientID = 0;

  constructor(
    private advertisingService: AdvertisingService,
    private uploadService: UploadService,
    private deviceGroupContentService: DeviceGroupContentService,
    public utils: Utils
  ) { }

  ngOnInit() {
    this.advertisingService.getAllGlobal().subscribe((ads) => {
      if (ads) {
        this.advertisements = ads;
      }
    });
  }

  deleteAd(id: number, image: number) {
    this.modal.closeModal();
    this.advertisingService.deleteGlobal(id).subscribe(() => {
        this.uploadService.delete(this.clientID, image).subscribe(() => { });
        this.reload();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private reload() {
    this.advertisingService.getAllGlobal().subscribe((ads) => {
      if (ads) {
        this.advertisements = ads;
      } else {
        this.advertisements = new Array();
      }
    });
  }

  assignAllById(id: number) {
    this.modalAssign.closeModal();
    this.deviceGroupContentService.saveAllByContentIds([id], 'ads').subscribe(() => {});
  }

  unassignAllById(id: number) {
    this.modalUnassign.closeModal();
    this.deviceGroupContentService.deleteAllByContentIds([id]).subscribe(() => {});
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }
}
