import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { DeviceGroupService, DeviceService } from '../../services';
import { DeviceGroup, Device } from '../../models';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-device-list',
  templateUrl: 'device-list.component.html',
  styleUrls: ['device-list.component.css']
})
export class DeviceListComponent implements OnInit {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  devices: Observable<Array<Device>>;
  deviceGroups$: Observable<Array<DeviceGroup>>;
  deviceGroups: Array<DeviceGroup>;
  currentClient$: Observable<any>;
  sortBy: string;
  filterString: '';
  constructor(
    private clientService: ClientService,
    private deviceService: DeviceService,
    private deviceGroupService: DeviceGroupService,
    private utils: Utils
  ) {}

  ngOnInit() {
    // If client changes, reload device groups

    this.currentClient$ = this.clientService.currentClient$;
    this.currentClient$.subscribe(client => {
      this.devices = this.deviceService.getAllByClientId(client.id);
      this.deviceGroups$ = this.deviceGroupService.getAll(client.id);
      this.deviceGroups$.subscribe(deviceGroups => {
        this.deviceGroups = deviceGroups;
      });
    });
    // If client is set and no device groups are loaded, reload device groups
    if (!this.devices && this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.devices = this.deviceService.getAllByClientId(this.clientService.getCurrentClient().id);
      this.deviceGroups$ = this.deviceGroupService.getAll(this.clientService.getCurrentClient().id);
      this.deviceGroups$.subscribe(deviceGroups => {
        this.deviceGroups = deviceGroups;
      });
    }
    this.sortBy = '+authenticationKey'; // sorting defaults to the name field (asc)
  }

  deleteDevice(id: number) {
    this.modal.closeModal();
    this.deviceService.delete(id, this.clientService.getCurrentClient().id).subscribe(
      result => {},
      error => {
        console.error(error);
      },
      () => {
        // if http request returns reload device groups
        this.devices = this.deviceService.getAllByClientId(this.clientService.getCurrentClient().id);
      }
    );
  }

  getDeviceGroupName(id: number): string {
    let name: string;
    this.deviceGroups.forEach(deviceGroup => {
      if (deviceGroup.id === id) {
        name = deviceGroup.description;
      }
    });
    return name;
  }

  /**
   * this function sets the sorting attribute and keeps track of the sorting direction
   * @param {string} sorter - the attribute to sortBy
   * @return {void}
   */
  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }

  /**
   * Notify the filter if the search string has changed
   * @param {Event} event - the sender event
   * @return {void}
   */
  changeFilter(event) {
    this.filterString = event.target.value;
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }
}
