import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { WatchdogComponent } from './watchdog.component';
import { WatchdogEditComponent } from './watchdog-edit/watchdog-edit.component';
import { WatchdogLogsComponent } from './watchdog-logs/watchdog-logs.component';
import { watchdogRouting } from './watchdog.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';
import { LocalWatchdogService } from '../services';

@NgModule({
  imports: [
    CommonModule,
    watchdogRouting,
    FormsModule,
    BrowserModule,
    SharedModule,
    TranslateModule,
    CustomModalModule
  ],
  declarations: [WatchdogComponent, WatchdogEditComponent, WatchdogLogsComponent],
  exports: [WatchdogComponent, WatchdogEditComponent, WatchdogLogsComponent],
  providers: [AuthGuard, Utils, LocalWatchdogService]
})
export class WatchdogModule {}
