import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PreviewService, PreviewStates } from './preview.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-content-preview',
  templateUrl: './content-preview.component.html',
  styleUrls: ['./content-preview.component.css']
})
export class ContentPreviewComponent implements OnInit {

  PreviewStates = PreviewStates;
  status = new Observable<PreviewStates>();
  constructor(
    private previewService: PreviewService
  ) { }

  ngOnInit() {
      this.status = this.previewService.status$;
  }


  onClick() {
    this.previewService.createPreview();
  }

  refreshPreview() {
    this.previewService.refresh();
  }

  closePreview() {
    this.previewService.close();
  }

}
