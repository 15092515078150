import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropUploaderModule } from '../image-crop-uploader/image-crop-uploader.module';
import { FormsModule } from '@angular/forms';

import { AdvertisingComponent } from './advertising.component';
import { AdvertisingListComponent } from './advertising-list/advertising-list.component';
import { AdvertisingAddEditComponent } from './advertising-add-edit/advertising-add-edit.component';
import { advertisingRouting } from './advertising.routing';
import { DeviceGroupModule } from '../device-group/device-group.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { DndModule } from 'ng2-dnd';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import {
  AdvertisingBannerSettingsComponent
} from './advertising-add-edit/advertising-banner-settings/advertising-banner-settings.component';

@NgModule({
  imports: [
    CommonModule,
    advertisingRouting,
    TranslateModule,
    ImageCropUploaderModule,
    FormsModule,
    DeviceGroupModule,
    SharedModule,
    CustomModalModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DndModule.forRoot()
  ],
  declarations: [AdvertisingComponent, AdvertisingListComponent, AdvertisingAddEditComponent, AdvertisingBannerSettingsComponent]
})
export class AdvertisingModule { }
