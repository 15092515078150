import { Component, Renderer2, Input, ViewChild } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Widget, UploadResponse, UploadProgressType } from '../../../models';
import { I18nPipe } from '../../../shared/pipes/i18n.pipe';
import { Utils } from '../../../shared/utils';
import { UploadService } from '../../../services';

@Component({
  selector: 'app-widget-slideshow-type',
  templateUrl: './widget-slideshow-type.component.html',
  styleUrls: ['../widget-add-edit.component.css']
})
export class WidgetSlideshowTypeComponent {

  slideMaxSize = {
    resizeMaxHeight: 1080,
    resizeMaxWidth: 1920
  };

  tempSlide: any = {};
  editSlide = false;
  editSlideIndex = 0;
  tempSlideUrl = '';
  fileIsLoading = false;
  contentModified = false;
  slideProgress = 0;
  private hotspotDragging = false;
  @Input() editable;
  @Input() widget: Widget;
  @Input() selectedLanguageKey;
  @Input() languageSelector;
  @Input() deletedImageIds;
  @Input() widgets;
  @ViewChild('slideBrowse')
  slideBrowse;
  @ViewChild('slideFilename')
  slideFilename;
  @ViewChild('browse')
  browse;

  constructor(
    private renderer: Renderer2,
    public clientService: ClientService,
    private uploadService: UploadService,
    private i18nPipe: I18nPipe,
    public utils: Utils
  ) { }

  onSlideFileChange(imageResult: any) {
    this.fileIsLoading = true;
    this.contentModified = true;
    this.renderer.setProperty(this.slideFilename.nativeElement, 'value', imageResult.file.name);

    let fileBlob;
    const clientID = this.clientService.getCurrentClient().id;
    const isImage: boolean = imageResult.file.type.includes('image');
    if (isImage) {
      fileBlob = imageResult.resized.dataURL;
    } else {
      if (this.editSlide) {
        return;
      }
      fileBlob = imageResult.dataURL;
    }

    this.uploadService.saveFile(fileBlob, clientID).subscribe((result: UploadResponse) => {
      if (result.resType === UploadProgressType.PROGRESS) {
        this.slideProgress = result.content;
      } else if (result.resType === UploadProgressType.BODY) {
        this.fileIsLoading = false;
        this.slideProgress = 0;
        if (isImage) {
          this.tempSlide.key = result.content[0].key;
          this.tempSlideUrl = result.content[0].url;
          if (!this.editSlide) {
            this.editSlide = true;
            this.addSlide();
          }
        } else {
          for (const entry of result.content) {
            const tempSlide: any = {};
            tempSlide.key = entry.key;
            this.addSlide(tempSlide);
          }
          this.editSlide = false;
        }
      }
    });
  }

  handleBrowseClick() {
    this.browse.nativeElement.click();
  }

  handleSlideBrowseClick() {
    this.slideBrowse.nativeElement.click();
  }

  private getLesser(val1, val2) {
    return this.isNull(val1) || this.isNull(val2) ? '0%' : val1 < val2 ? val1 + '%' : val2 + '%';
  }

  private getGreater(val1, val2) {
    return this.isNull(val1) || this.isNull(val2) ? '0%' : val1 > val2 ? 100 - val1 + '%' : 100 - val2 + '%';
  }

  private addSlide(tempSlide?: any) {
    if (tempSlide && tempSlide.key !== '' && tempSlide.key !== 'undefined') {
      const slide = {};
      slide[this.selectedLanguageKey] = tempSlide;
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
        slide['_'] = tempSlide;
      }
      this.widget.slides.push(JSON.parse(JSON.stringify(slide)));
    } else if (this.tempSlide && this.tempSlide.key !== '' && typeof this.tempSlide.key !== 'undefined') {
      const slide = {};
      slide[this.selectedLanguageKey] = this.tempSlide;
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
        slide['_'] = this.tempSlide;
      }
      this.widget.slides.push(JSON.parse(JSON.stringify(slide)));
      this.editSlideIndex = this.widget.slides.length - 1;
    }
  }

  private removeSlide(id: number) {
    this.deletedImageIds.push(this.i18nPipe.transform(this.widget.slides[id], this.selectedLanguageKey)['key']);
    this.widget.slides.splice(id, 1);
  }

  private startEditSlide(id: number) {
    this.editSlide = true;
    this.editSlideIndex = id;
    const slide = this.widget.slides[this.editSlideIndex];
    this.tempSlide = JSON.parse(
      JSON.stringify(
        slide[this.selectedLanguageKey] && slide[this.selectedLanguageKey] !== ''
          ? slide[this.selectedLanguageKey]
          : slide['_']
      )
    );
    const clientID = this.clientService.getCurrentClient().id;
    this.tempSlideUrl = this.utils.buildAwsImageURL(
      clientID,
      this.i18nPipe.transform(slide, this.selectedLanguageKey)['key']
    );
  }

  private abortEditSlide() {
    this.tempSlide = {};
    this.editSlide = false;
    this.editSlideIndex = 0;
    this.tempSlideUrl = '';
    this.renderer.setProperty(this.slideFilename.nativeElement, 'value', '');
  }

  private finishEditSlide() {
    if (this.tempSlide && this.tempSlide.key !== '' && typeof this.tempSlide.key !== 'undefined') {
      const slide = this.widget.slides[this.editSlideIndex];
      slide[this.selectedLanguageKey] = this.tempSlide;
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
        slide['_'] = this.tempSlide;
      }
      this.widget.slides[this.editSlideIndex] = slide;
      this.tempSlide = {};
      this.editSlide = false;
      this.editSlideIndex = 0;
      this.tempSlideUrl = '';
      this.renderer.setProperty(this.slideFilename.nativeElement, 'value', '');
    }
  }

  private hotspotMouseDown(event) {
    if (!this.isNull(this.tempSlide.hotspotWidgetId)) {
      this.hotspotDragging = true;
      this.tempSlide.hotspotX1 = (event.offsetX / event.target.width) * 100;
      this.tempSlide.hotspotY1 = (event.offsetY / event.target.height) * 100;
      this.tempSlide.hotspotX2 = (event.offsetX / event.target.width) * 100;
      this.tempSlide.hotspotY2 = (event.offsetY / event.target.height) * 100;
    }
  }

  private hotspotMove(event) {
    if (this.hotspotDragging && !this.isNull(this.tempSlide.hotspotWidgetId)) {
      this.tempSlide.hotspotX2 = (event.offsetX / event.target.width) * 100;
      this.tempSlide.hotspotY2 = (event.offsetY / event.target.height) * 100;
    }
  }

  private hotspotMouseUp(event) {
    if (this.hotspotDragging && !this.isNull(this.tempSlide.hotspotWidgetId)) {
      this.tempSlide.hotspotX2 = (event.offsetX / event.target.width) * 100;
      this.tempSlide.hotspotY2 = (event.offsetY / event.target.height) * 100;
    }
    this.hotspotDragging = false;

    if (
      Math.abs(this.tempSlide.hotspotX1 - this.tempSlide.hotspotX2) < 1 ||
      Math.abs(this.tempSlide.hotspotY1 - this.tempSlide.hotspotY2) < 1
    ) {
      this.resetHotspotArea();
    }
  }

  private hotspotWidgetChanged(widgetId) {
    if (this.isNull(widgetId)) {
      this.resetHotspotArea();
    }
  }

  private resetHotspotArea() {
    this.tempSlide.hotspotX1 = 0;
    this.tempSlide.hotspotY1 = 0;
    this.tempSlide.hotspotX2 = 100;
    this.tempSlide.hotspotY2 = 100;
  }

  private isNull(val) {
    if (!val || val == null || val.toString() === 'null') {
      return true;
    }
    return false;
  }
}
