import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { UserAclAuthorities } from '../models';
@Injectable({
  providedIn: 'root'
})
export class AclService {
  private aclURL: string = environment.aclService;

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) { }

  getAllClientUsersAclAuthorities(clientID: number | string): Observable<UserAclAuthorities[]> {
    return this.api.getData(this.aclURL + '/client/' + clientID);
  }

  updateAuthorities(authorities: UserAclAuthorities[]): Observable<any> {
    return this.api.putData(this.aclURL + '/update', authorities);
  }

  getUserAuthorities(login: string): Observable<UserAclAuthorities> {
    return this.api.getData(this.aclURL + '/user/' + login);
  }

  setUserAllAuthorities(login: string): Observable<any> {
    return this.api.postData(this.aclURL + '/all/' + login, {});
  }

  checkUserAuthority(login: string, aclAuthority: string): Observable<any> {
    return this.api.getData(this.aclURL + '/check/' + login + '/' + aclAuthority);
  }
}
