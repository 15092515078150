import { Component } from '@angular/core';

@Component({
  selector: 'app-google-analytics',
  templateUrl: './google-analytics.component.html'
})
export class GoogleAnalyticsComponent {

  constructor() { }
}
