import { Component, OnInit, Input, Output, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '@b4m/b4m-frontend-core';
import { WidgetService, PageService } from '../../services';
import { Widget } from '../../models';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-widget-list',
  templateUrl: 'widget-list.component.html',
  styleUrls: ['widget-list.component.css'],
})
export class WidgetListComponent implements OnInit, OnChanges {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('copyModal')
  private copyModal: CustomModalComponent;
  @Input() pageId: number;
  @Input() newPageDisabled: boolean;
  @Input() editable: boolean;
  @Output() submitNewPage = new EventEmitter();
  widgets: any = [];
  pageContents: any = [];
  clientID: number | string;
  pageID: number;
  assignMode = false;
  allClientPages: any = [];

  sortBy = '';
  filterString = '';

  constructor(
    private widgetService: WidgetService,
    private clientService: ClientService,
    private pageService: PageService,
    private router: Router,
    private utils: Utils
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any): void {
    if (changes.pageId) {
      this.pageID = changes.pageId.currentValue;
      if (
        this.pageID &&
        this.clientService.getCurrentClient() &&
        this.clientService.getCurrentClient().id
      ) {
        this.clientID = this.clientService.getCurrentClient().id;
        this.reload();
      }
    }
  }

  private reload() {
    this.widgetService.getAllByClient(this.clientID)
      .subscribe(content => {
        if (content) {
          this.widgets = content;
          this.widgetService.getPageAttachedWidgets(this.clientID, this.pageID)
            .subscribe(result => {
              if (result) {
                this.pageContents = result;
                this.widgets.sort(this.compareWidgetsByOrder.bind(this));
              }
            });
        }
      });
  }

  deleteWidget(id: number): void {
    this.widgetService.delete(this.clientID, id).subscribe(() => {
      // update user list after delete
      this.reload();
    });
    this.modal.closeModal();
  }

  saveReorderingWidgets() {
    const widgetsIds = this.getAssignedIds();
    if (widgetsIds && widgetsIds.length > 0) {
      this.widgetService.assignToPageContent(this.clientService.getCurrentClient().id, this.pageID, widgetsIds).subscribe(content => {
          this.utils.displayGrowlMessage('success', 'lists.changesSaved', '');
          this.reload();
        }, error => {
         this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
         this.reload();
      });
    }
  }

  reassign(event, widget, widgetId) {
    const widgetsIds = this.getAssignedIds();
    if (event.target.checked) {
      widgetsIds.push(widgetId);
    } else {
      const index = widgetsIds.indexOf(widgetId);
      widgetsIds.splice(index, 1);
    }
    this.widgetService.assignToPageContent(this.clientID, this.pageID, widgetsIds).subscribe(content => {
        this.utils.displayGrowlMessage('success', 'lists.changesSaved', '');
        this.reload();
      }, error => {
       this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
       this.reload();
    });
  }

  getAssignedIds() {
    const widgetsIds = new Array();
    for (const widget of this.widgets) {
      if (this.isAssigned(widget)) {
        widgetsIds.push(widget.id);
      }
    }
    return widgetsIds;
  }

  isAssigned(widget: Widget) {
    return this.pageContents.some(pc => pc.content.id === widget.id);
  }

  getReference(param, widget: Widget) {
    return typeof widget[param] === 'object' ? widget[param]._ : widget[param];
  }

  addWidgetRedirect() {
    if (!this.newPageDisabled && !this.pageId) {
      this.submitNewPage.emit();
    } else {
      this.router.navigate([
        '/widgets/add/' + this.pageId,
        { 'pageWidgetElements': (this.widgets && this.widgets.length) ? this.widgets.length : 0 }
      ]);
    }
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  private compareWidgetsByOrder(widget1, widget2) {
    if (this.isAssigned(widget1) && this.isAssigned(widget2)) {
      return this.getWidgetSortOrder(widget1) - this.getWidgetSortOrder(widget2);
    } else if (this.isAssigned(widget1) && !this.isAssigned(widget2)) {
      return -1;
    } else if (this.isAssigned(widget2) && !this.isAssigned(widget1)) {
      return 1;
    }
    return 0;
  }

  private getWidgetSortOrder(widget: Widget) {
    return this.pageContents.find(pc => pc.content.id === widget.id).sortOrder;
  }

  openDeleteModal(widget: any) {
    if (this.getReference('type', widget.value) === 'feedback') {
      return this.showModal('lists.feedback.deleteMessage', widget);
    } else {
      const pageContents = this.widgetService.getAllPageContentByWidget(this.clientID, widget.id).subscribe(contents => {
        if (contents && contents.length > 0) {
          let pagesTitles = '';
          for (const content of contents) {
            if (pagesTitles === '') {
              pagesTitles = pagesTitles.concat(this.getReference('title', content.page.value));
            } else {
              pagesTitles = pagesTitles.concat(', ' + this.getReference('title', content.page.value));
            }
          }
          return this.showModal(this.getTranslation('lists.widgets.assigned-remove-message') + pagesTitles, widget);
        } else {
          return this.showModal('forms.leave-page-alert-title', widget);
        }
      });
    }
  }

  private showModal(deleteMessage, widget) {
      this.modal.showModal(deleteMessage, this.getTranslation('confirmation.delete') +
        this.getReference('title', widget.value) + ' ' + this.getTranslation('confirmation.widget'), widget, true);
  }

  openCopyModal(widget: any) {
    this.pageService.getAll(this.clientID).subscribe(content => {
      this.allClientPages = content ? content : new Array();
      this.copyModal.showModal('', this.getTranslation('lists.widgets.copy') + '  ' +
        this.getReference('title', widget.value), widget, false);
    });
  }

  copyWidget(widget: any) {
    const pageId = parseInt((<HTMLInputElement>document.getElementById('copyPage')).value, 10);
    this.widgetService.create(this.clientID, pageId, widget.value).subscribe(
      res => {
        this.widgetService.attachToPageContent(this.clientID, pageId, res.id).subscribe(content => {
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          if (pageId === this.pageId) {
            this.widgets.push(res);
            this.pageContents.push(content);
          }
          this.copyModal.closeModal();
        }, error => {
           this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
           this.copyModal.closeModal();
        });
      }, error => {
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
        this.copyModal.closeModal();
      }
    );
  }

  sortListByType() {
    const s = 'value.type';
    this.sortBy = this.sortBy === '' ? `+${s}` : this.sortBy.substr(0, 1) === '+' ? `-${s}` : '';
  }

  resetFilters() {
    this.sortBy = '';
    this.filterString = '';
  }
}
