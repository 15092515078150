import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Utils } from '../../shared/utils';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Category } from '../../models';
import { PoiService, LanguageService } from '../../services';

@Component({
  selector: 'app-poi-category-add-edit',
  templateUrl: 'poi-category-add-edit.component.html',
  styleUrls: ['poi-category-add-edit.component.css']
})
export class PoiCategoryAddEditComponent implements OnInit {
  poiCategory: Category = new Category(0, '');
  selectedLanguageKey = '_';
  sub: Subscription;
  isNew: boolean;
  errorMessage = '';
  @Input()
  isInOverlay: boolean;
  @Input()
  overlay: any;
  @Output()
  saved = new EventEmitter<boolean>();
  @ViewChild('languageSelector') languageSelector;
  @ViewChild('poiCategoryForm') poiCategoryForm;
  constructor(
    private poiService: PoiService,
    private clientService: ClientService,
    private utils: Utils,
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      if (params['id']) {
        this.isNew = false;
        if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
          this.poiService.getCategoryByID(this.clientService.getCurrentClient().id, params['id']).subscribe(cat => {
            this.poiCategory = cat;
          });
        }
        this.clientService.currentClient$.subscribe(client => {
          this.poiService.getCategoryByID(client.id, params['id']).subscribe(cat => {
            this.poiCategory = cat;
          });
        });
      } else {
        this.isNew = true;
      }
    });
  }

  getI18NAttribute(dottedName: string) {
    return this.utils.getI18NAttribute(this, dottedName);
  }

  setI18NAttribute(dottedName, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
  }

  private synchronizeDefault(param) {
    this.utils.synchronizeDefault(this, param);
  }

  getPlaceholder(dottedName, i18nKey): string {
    return this.utils.getPlaceholder(this, dottedName, i18nKey);
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  close(canceled: boolean) {
    if (!this.isInOverlay) {
      this.router.navigate(['/poi/categories']);
    } else if (this.overlay && this.overlay.closeModal) {
      this.overlay.closeModal();
    }
    if (canceled) {
      this.saved.next(false);
    }
    this.poiCategory.id = null;
    this.poiCategory.label = '';
  }

  onSubmit() {
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.poiCategoryForm.form.valid) {
        this.submit();
      }
    }, 500);
  }


  submit() {
    if (this.isNew) {
      this.poiService.createCategory(this.clientService.getCurrentClient().id, this.poiCategory).subscribe(
        success => {
          this.close(false);
          this.saved.next(true);
        },
        err => {
          this.errorMessage = err;
        }
      );
    } else {
      this.poiService.updateCategory(this.clientService.getCurrentClient().id, this.poiCategory).subscribe(
        success => {
          this.close(false);
          this.saved.next(true);
        },
        err => {
          this.errorMessage = err;
        }
      );
    }
  }

}
