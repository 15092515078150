import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-group',
  templateUrl: 'device-group.component.html',
  styleUrls: ['device-group.component.css']
})
export class DeviceGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
