import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { UserComponent } from './user.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { userRouting } from './user.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    userRouting,
    FormsModule,
    BrowserModule,
    TranslateModule,
    CustomModalModule,
    SharedModule
  ],
  declarations: [UserComponent, UserListComponent, UserAddEditComponent, UserDetailComponent],
  exports: [UserComponent],
  providers: [AuthGuard, Utils]
})
export class UserModule {}
