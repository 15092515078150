import { Component, OnInit } from '@angular/core';
import { AuthService, UserCredentials, UserService } from '@b4m/b4m-frontend-core';


class ResultMessage {
  constructor(
    message: string,
    type: string
  ) { }
}

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  active = true;
  submitted = false;
  user: UserCredentials = new UserCredentials('', '');
  resultMessage: ResultMessage;
  constructor(private authService: AuthService,
    private userService: UserService) { }

  ngOnInit() {
    if (
      (this.authService.token || localStorage.getItem('token')) &&
      (this.authService['amazonAwsBucketName'] || localStorage.getItem('amazonAwsBucketName'))
    ) {
      // when login page is called from new tab directly, the authService doesn't have token stored
      this.authService.redirect();
    }
  }

  onSubmit() {
    this.submitted = true;
    this.authService.login(this.user).subscribe(
      token => {
        this.resultMessage = new ResultMessage('Login complete :-)', 'success');
        this.submitted = false;
        this.authService.redirect();
      },
      error => this.resultMessage = new ResultMessage(error.status + ': ' + error.statusText, 'danger')
    );
    this.userService.setCurrentUser();
  }

}
