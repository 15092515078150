import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '@app/services';
import { DeviceService } from './../../../../../services/device.service';
import { DeviceGroupService } from './../../../../../services/device-group.service';
import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { CustomModalComponent } from '@app/custom-modal/custom-modal.component';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Utils } from '@app/shared/utils';

class Filter {
  constructor(public client, public deviceGroup, public device) { }
}

@Component({
  selector: 'app-google-analytics-filter-modal',
  templateUrl: './google-analytics-filter-modal.component.html',
  styleUrls: ['./google-analytics-filter-modal.component.css']
})
export class GoogleAnalyticsFilterModalComponent implements OnInit {
  @Output() refreshData = new EventEmitter<any>();
  @ViewChild('filterModal') private filterModal: CustomModalComponent;

  clients: any;
  deviceGroupsData = [];
  devicesData = [];
  dateRange: Date[] = [null, null];
  filter: Filter = new Filter(null, null, null);
  tempFilter: Filter = new Filter(null, null, null);

  constructor(
    private clientService: ClientService,
    private utils: Utils,
    private utilsService: UtilsService,
    private deviceGroupService: DeviceGroupService,
    private deviceService: DeviceService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getClients();
  }

  private getClients() {
    this.clientService.getClients().subscribe(clients => {
      this.clients = clients;
    });
  }

  openFilterModal() {
    this.tempFilter = this.utilsService.clone(this.filter);
    this.filterModal.showModal('', this.utils.getTranslation('dashboard.google-analytics.filter-title'), null, false);
  }

  getDeviceGroupByClientId() {
    this.tempFilter.deviceGroup = null;
    this.tempFilter.device = null;
    if (this.tempFilter.client && !this.findDeviceGroupByClientId(this.tempFilter.client.id)) {
      this.deviceGroupService.getAll(this.tempFilter.client.id).subscribe(deviceGroups => {
        this.deviceGroupsData.push({ clientId: this.tempFilter.client.id, deviceGroups: deviceGroups });
      });
    }
  }

  findDeviceGroupByClientId(clientId) {
    return this.deviceGroupsData.find(v => v.clientId === clientId);
  }

  getDeviceByClientId() {
    this.tempFilter.device = null;
    if (this.tempFilter.client && this.tempFilter.deviceGroup &&
      !this.findDevicesByClientId(this.tempFilter.client.id, this.tempFilter.deviceGroup.id)) {
      this.deviceService.getAllByClientId(this.tempFilter.client.id).subscribe(devices => {
        this.devicesData.push({ clientId: this.tempFilter.client.id, devices: devices });
      });
    }
  }

  compareDefaultFilter(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  findDevicesByClientId(clientId, deviceGroupId) {
    const data = this.devicesData.find(v => v.clientId === clientId);
    if (data) {
      return { clientId: clientId, devices: data.devices.filter(v => v.deviceGroupId === deviceGroupId) };
    } else {
      return null;
    }
  }

  // Filter: All
  // Filter: Client: XXX, Device Group: XXX, Device: XXX
  getFilterName(): string {
    const prefixKey = 'dashboard.google-analytics.';
    const separator = ': ';
    let out = this.translateService.instant(prefixKey + 'filter') + separator;
    if (this.isValidId(this.filter.client) && this.filter.client.id !== '') {
      out += this.translateService.instant(prefixKey + 'client');
      out += separator + this.bold(this.filter.client.name);
      if (this.isValidId(this.filter.deviceGroup) && this.filter.deviceGroup.id !== '') {
        out += ', ' + this.translateService.instant(prefixKey + 'device-group');
        out += separator + this.bold(this.filter.deviceGroup.description);
        if (this.isValidId(this.filter.device) && this.filter.device.id !== '') {
          out += ', ' + this.translateService.instant(prefixKey + 'device');
          out += separator + this.bold(this.filter.device.authenticationKey);
        }
      }
    } else {
      out += this.bold(this.translateService.instant(prefixKey + 'all'));
    }
    return out;
  }

  private bold(value) {
    return '<b>' + value + '</b>';
  }

  resetFilter() {
    this.filter = new Filter(null, null, null);
    this.tempFilter = new Filter(null, null, null);
  }

  setFilters() {
    this.filter = this.utilsService.clone(this.tempFilter);
    this.refreshData.emit();
    this.filterModal.closeModal();
  }

  getFilterIds() {
    const client = this.filter.client;
    const deviceGroup = this.filter.deviceGroup;
    const device = this.filter.device;
    return {
      clientId: client ? client.id : null,
      deviceGroupId: deviceGroup ? deviceGroup.id : null,
      deviceId: device ? device.id : null
    };
  }

  isValidId(value) {
    return value && value.id;
  }
}
