import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiService} from '@b4m/b4m-frontend-core';
import {Observable} from 'rxjs/Observable';
import {RefreshableProperties} from '@app/models/refreshable-properties';

@Injectable({
  providedIn: 'root'
})
export class PropsSettingsService {
  private baseURL = environment.propsSettingsService;

  constructor(private api: ApiService) {
  }

  public getRefreshableProperties(): Observable<any> {
    return this.api.getData(this.baseURL);
  }

  public saveRefreshableProperties(props: RefreshableProperties[]): Observable<any> {
    return this.api.postData(this.baseURL + '?refreshProps=true', props);
  }
}
