import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poi',
  templateUrl: 'poi.component.html',
  styleUrls: ['poi.component.css']
})
export class PoiComponent implements OnInit {
  constructor() { }

  ngOnInit() {}

}
