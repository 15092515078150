import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService, WidgetService, AclService } from '../../services';
import { ClientService, AuthService } from '@b4m/b4m-frontend-core';
import { Feedback, Content } from '../../models';
import { CustomModalComponent } from '@app/custom-modal/custom-modal.component';
import { LoadingSpinnerComponent } from '@app/shared/loading-spinner/loading-spinner.component';
import { Utils } from '@app/shared/utils';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';

class FeedbackExtended {
  constructor(
    public feedback: Feedback,
    public result: any,
    public numberOfQuestions: any
  ) {}
}

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.css']
})
export class FeedbackListComponent implements OnInit {

  feedbackList: FeedbackExtended[] = [];
  feedbackWidgetList: Content[] = [];
  clientID: string | number;
  selectedFeedback: Feedback;
  aclUserAuthorities: any;
  @ViewChild('detailsModal')
  private modal: CustomModalComponent;
  @ViewChild('loadingSpinner')
  private loadingSpinner: LoadingSpinnerComponent;
  sortBy = '-feedback.date';

  constructor(
    private feedbackService: FeedbackService,
    private clientService: ClientService,
    private widgetService: WidgetService,
    private aclService: AclService,
    private authService: AuthService,
    private translate: TranslateService,
    private utils: Utils,
    private router: Router
  ) { }

  ngOnInit() {
    this.clientID = this.clientService.getCurrentClient().id;
    this.feedbackService.getFeedbackByClientId(this.clientID).subscribe((result) => {
      this.feedbackList = result.map(f => new FeedbackExtended(f, this.getResult(f), this.getQuestionNumber(f)));
      this.recursiveGetFeedbackWidgets(0);
      this.aclService.getUserAuthorities(this.authService.getUsername()).subscribe(authorities => {
        if (authorities) {
          this.aclUserAuthorities = authorities;
        }
      });
    });
  }

  private recursiveGetFeedbackWidgets(counter: number) {
    if (counter < this.feedbackList.length) {
      const feedbackExtended = this.feedbackList[counter];
      const index = this.feedbackWidgetList.findIndex(w => w.id === feedbackExtended.feedback.widgetId);
      if (index === -1) {
        this.widgetService.getByID(this.clientID, feedbackExtended.feedback.widgetId).subscribe((widget) => {
          this.feedbackWidgetList.push(widget);
          feedbackExtended.feedback.widgetName =
            typeof widget.value['title'] === 'object' ? widget.value['title']._ : widget.value['title'];
          this.recursiveGetFeedbackWidgets(++counter);
        });
      } else {
        const widget = this.feedbackWidgetList[index];
        feedbackExtended.feedback.widgetName =
          typeof widget.value['title'] === 'object' ? widget.value['title']._ : widget.value['title'];
        this.recursiveGetFeedbackWidgets(++counter);
      }
    }
  }

  private getQuestionNumber(feedback: Feedback) {
    let number = 0;
    for (const questions of Object.keys(feedback.questionAnswer)) {
      if (questions !== 'choiceQuestion') {
        number++;
      }
    }
    return number;
  }

  private getResult(feedback: Feedback) {
    let sum = 0;
    let quantity = 0;
    for (const question of Object.keys(feedback.questionAnswer)) {
      const rate = feedback.questionAnswer[question];
      if (rate && typeof rate === 'number') {
        quantity++;
        sum = sum + rate;
      }
    }
    return quantity === 0 ? '-' : (Math.round(sum / quantity * 100) / 100).toFixed(1);
  }

  showDetails(feedback: Feedback) {
    this.selectedFeedback = feedback;
    this.modal.showModal('', 'lists.feedback.detailedAnswers', '', true, false);
  }

  getQuestionAnswer(questionAnswer: any) {
    const questionAnswers = new Array();
    if (questionAnswer) {
      for (const key in questionAnswer) {
        if (questionAnswer.hasOwnProperty(key) && key !== 'choiceQuestion') {
          const temp = {question: key, answer: questionAnswer[key]};
          questionAnswers.push(temp);
        }
      }
    }
    return questionAnswers;
  }

  checkViewEditRight(widgetId: number) {
    const pageId = this.getPageId(this.feedbackWidgetList.find(f => f.id === widgetId));
    if (this.checkAclRights('PAGE_VIEW_' + pageId) || this.checkAclRights('PAGE_EDIT_' + pageId)) {
      this.router.navigate(['/widgets/edit/' + widgetId + '/' + pageId]);
    } else {
      this.utils.displayGrowlMessage('danger', 'lists.acl.noRights', '');
    }
  }

  checkAclRights(authority: string) {
    if (this.aclUserAuthorities && this.aclUserAuthorities.aclAuthorities) {
      return this.aclUserAuthorities.aclAuthorities.indexOf(authority) > -1 || this.utils.isUserRightsAdmin() || this.utils.isAdmin();
    } else {
      return false;
    }
  }

  getPageId(widget: Content) {
    return widget.key.split('.').pop();
  }

  downloadExcelDocument() {
    const columnHeaderNames = [
      'lists.feedback.widgetName',
      'lists.feedback.questionsNumber',
      'lists.feedback.result',
      'lists.feedback.roomNumber',
      'lists.feedback.date',
      'lists.feedback.questionAndResult',
      'lists.feedback.additionalText'
    ];

    this.translate.get(columnHeaderNames).subscribe(res => {
      const columnHeaders = [
        '#',
        ...columnHeaderNames.map(x => res[x])
      ];
      const names = ['lists.feedback.exported', 'lists.feedback.titleList'];
      this.translate.get(names).subscribe(texts => {
        const clientName = this.clientService.getCurrentClient().name;
        const filename = `${texts[names[0]]}_Feedback_${clientName}_${formatDate(new Date(), 'yyyy-MM-dd', 'en')}`;
        const sheetName = texts[names[1]];
        this.loadingSpinner.upload();
        this.feedbackService.getFeedbacksAsExcelDoc(this.clientID, columnHeaders, filename, sheetName).subscribe(doc => {
          this.loadingSpinner.finish();
          FileSaver.saveAs(doc.content, doc.filename);
        });
      });
    });
  }

  getAdditionalFeedbackText(feedback: Feedback): string {
    if (!this.isValid(feedback) || !this.isValid(feedback.additionalText) || !this.isValid(feedback.additionalText.answer)) {
      return '';
    }
    const label = feedback.additionalText.labelName ? `<i>${feedback.additionalText.labelName}</i>: <br/>` : '';
    return `${label}${feedback.additionalText.answer.replaceAll('\n', '<br/>')}`;
  }

  private isValid(value: any) {
    return value !== undefined && value !== null && value !== '';
  }

  sortListBy(sorter: string) {
    let direction = this.sortBy.substr(0, 1);
    const curSortString = this.sortBy.substr(1);
    // new sorting string came in
    if (curSortString !== sorter) {
      this.sortBy = '+' + sorter;
    } else {
      if (direction === '+') {
        direction = '-';
      } else {
        direction = '+';
      }
      this.sortBy = direction + curSortString;
    }
  }
}
