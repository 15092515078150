import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { Content, Page } from '../models';

@Injectable()
export class PageService {
  private baseURL = environment.contentService;
  constructor(private http: HttpClient, private api: ApiService, private authService: AuthService) {}

  getAll(clientID: number | string): Observable<Content[]> {
    return this.api.getData(this.baseURL + '/' + clientID + '?key=' + this.createKey(clientID));
  }

  getByID(clientID: number | string, id: number): Observable<Content> {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + id);
  }

  create(clientID: number | string, page: Page): Observable<Content> {
    return this.store(clientID, false, page);
  }

  update(clientID: number | string, page: Page, pageID: number): Observable<Content> {
    return this.store(clientID, true, page, pageID);
  }

  assign(clientID: number | string, deviceGroupID: number, pageIds: number[]): Observable<string> {
    const url =
      this.baseURL +
      '/' +
      clientID +
      '/assign?deviceGroupId=' +
      deviceGroupID +
      '&contentIds=' +
      pageIds +
      '&keyContains=pages.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options);
  }

  attach(clientID: number | string, deviceGroupID: number, pageId: number): Observable<string> {
    const url =
      this.baseURL + '/' + clientID + '/attach?deviceGroupId=' + deviceGroupID + '&contentId=' + pageId + '&keyContains=pages.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options);
  }

  delete(clientID: number | string, id: number) {
    const url = this.baseURL + '/' + clientID;
    return this.api.deleteData(url, id);
  }

  private store(clientID: number | string, update: boolean, page: Page, pageID: number = null): Observable<Content> {
    const url = this.baseURL + '/' + clientID;
    const bodyObj = {
      key: this.createKey(clientID),
      value: page
    };
    if (pageID) {
      bodyObj['id'] = pageID;
    }
    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  private createKey(clientID: number | string) {
    return 'pages.' + clientID;
  }
}
