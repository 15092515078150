import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nPipe } from './pipes/i18n.pipe';
import { FilterPoiCategoriesPipe } from './pipes/filter-poi-categories.pipe';
import { FilterIconGroupsPipe } from './pipes/filter-icon-groups.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './pagination/pagination.component';
import { EqualValidator } from './directives/equal-validator.directive';
import { DateFormatUTCPipe } from './pipes/date-format-utc.pipe';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [
    I18nPipe,
    FilterPoiCategoriesPipe,
    FilterIconGroupsPipe,
    FilterPipe,
    OrderByPipe,
    KeysPipe,
    PaginationComponent,
    EqualValidator,
    DateFormatUTCPipe,
    DateFormatterPipe,
    LoadingSpinnerComponent
  ],
  exports: [
    I18nPipe,
    FilterPoiCategoriesPipe,
    FilterIconGroupsPipe,
    FilterPipe,
    OrderByPipe,
    KeysPipe,
    PaginationComponent,
    EqualValidator,
    DateFormatUTCPipe,
    DateFormatterPipe,
    LoadingSpinnerComponent
  ]
})
export class SharedModule {}
