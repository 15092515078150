import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MakiIconsService } from '../services';

/**
 * This component creates a list of icons from the maki icon font.
 * By selecting an icon it emits the icon as output to use it in
 * another component
 */
@Component({
  selector: 'app-maki-icons',
  templateUrl: 'maki-icons.component.html',
  styleUrls: ['maki-icons.component.css']
})
export class MakiIconsComponent implements OnInit {
  icons: any;
  @Input()
  selectedIcon: string;
  @Output()
  selectedIconChange = new EventEmitter<string>();
  constructor(private makiIconsService: MakiIconsService) {}

  ngOnInit() {
    this.makiIconsService.getIcons().subscribe(icons => {
      this.icons = icons;
    });
  }

  /**
   * Triggers when an icon is clicked. Emits the selected icon
   * to the selected icon event emitter.
   * @param {string} icon The clicked icon
   * @return {void}
   */
  select(icon: string) {
    this.selectedIcon = icon;
    this.selectedIconChange.emit(icon);
  }
}
