import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Router } from '@angular/router';
import { DeviceGroupService, PageService, LanguageService, AclService } from '../../services';
import { AuthService } from '@b4m/b4m-frontend-core';
import { Page, UserAclAuthorities } from '../../models';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.css']
})
export class PageListComponent implements OnInit, OnDestroy {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  pages: any;
  aclUserAuthorities: any;
  clientSelected = true;
  private currentLanguage$: any;
  private currentClient$: any;
  private currentDeviceGroup$: any;
  assignMode = false;
  private deviceGroupID: number = null;
  private maxAssignedOrder = 0;
  @ViewChild('deviceGroupSelector') deviceGroupSelector;
  constructor(
    private router: Router,
    private pageService: PageService,
    private aclService: AclService,
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private languageService: LanguageService,
    private authService: AuthService,
    private utils: Utils
  ) { }

  ngOnInit() {
    // if client and device group are set on initalization, load pages
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientSelected = true;
      this.pageService.getAll(this.clientService.getCurrentClient().id).subscribe(content => {
        if (content) {
          this.pages = content;
          this.calculateMaxAssignedOrder(this.pages);
          this.pages.sort(this.comparePagesByOrder.bind(this));
        }
      });
      this.aclService.getUserAuthorities(this.authService.getUsername()).subscribe(authorities => {
        if (authorities) {
          this.aclUserAuthorities = authorities;
        }
      });
    } else {
      this.clientSelected = false;
    }

    this.currentLanguage$ = this.languageService.currentLanguage$.subscribe(lang => {
      if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
        this.reload(this.clientService.getCurrentClient().id);
      }
    });

    // Subscribe to current client to reload on change
    this.currentClient$ = this.clientService.currentClient$.subscribe(client => {
      if (client) {
        this.reload(client.id);
      }
    });

    // Subscribe to current device to reload on change
    this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
      this.deviceGroupID = dt.id;
      if (this.pages) {
        this.pages.sort(this.comparePagesByOrder.bind(this));
      }
    });
  }

  private reload(clientID: number | string, keepAssignMode?: boolean) {
    if (!keepAssignMode) {
      this.assignMode = false;
    }
    if (clientID) {
      this.clientSelected = true;
      this.pageService.getAll(clientID).subscribe(p => {
        if (p) {
          this.pages = p;
          this.calculateMaxAssignedOrder(this.pages);
          this.pages.sort(this.comparePagesByOrder.bind(this));
        } else {
          this.pages = new Array();
          this.calculateMaxAssignedOrder(this.pages);
        }
      });
    } else {
      this.clientSelected = false;
    }
  }

  private isAssigned(page: any): boolean {
    return this.getPageOrder(page).assigned;
  }

  private getPageOrder(page: any): any {
    const dtId = this.getDeviceGroupId();
    if (dtId) {
      if (page.pageContents && page.pageContents.length !== 0) {
        for (const pageContent of page.pageContents) {
          if (pageContent.page_id === dtId) {
            return { 'sortOrder': pageContent.sortOrder, 'assigned': true };
          }
        }
        for (const pageContent of page.pageContents) {
          if (!pageContent.page_id) {
            return { 'sortOrder': this.maxAssignedOrder + pageContent.sortOrder, 'assigned': false };
          }
        }
      }
    }
    for (const pageContent of page.pageContents) {
      if (!pageContent.page_id) {
        return { 'sortOrder': this.maxAssignedOrder + pageContent.sortOrder, 'assigned': false };
      }
    }
    return { 'sortOrder': this.maxAssignedOrder + 1, 'assigned': false };
  }

  saveReorderingPages() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id &&
      this.deviceGroupSelector.currentDeviceGroup.id && this.assignMode) {
      const pageIds: number[] = [];
      for (const page of this.pages) {
        if ((this.isAssigned(page) && !page['isDetachCandidate']) || page['isAttachCandidate']) {
          pageIds.push(page.id);
        }
      }
      this.pageService.assign(
        this.clientService.getCurrentClient().id,
        this.deviceGroupSelector.currentDeviceGroup.id,
        pageIds
      ).subscribe(res => {
        this.reload(this.clientService.getCurrentClient().id, true);
      });
    }
  }

  private comparePagesByOrder(page1, page2) {
    if (this.isAssigned(page1) && this.isAssigned(page2)) {
      const p1 = this.getPageOrder(page1).sortOrder;
      const p2 = this.getPageOrder(page2).sortOrder;
      return (p1 - p2);
    } else if (this.isAssigned(page1)) {
      return -1;
    } else if (this.isAssigned(page2)) {
      return 1;
    } else {
      return 0;
    }
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  reassign(page: Page) {
    if (this.isAssigned(page)) {
      page['isDetachCandidate'] = true;
    } else {
      page['isAttachCandidate'] = true;
    }
    this.saveReorderingPages();
  }

  deletePage(id: number) {
    this.modal.closeModal();
    this.pageService.delete(this.clientService.getCurrentClient().id, id).subscribe(
      result => {
        // if http request returns reload pages
        this.pageService.getAll(this.clientService.getCurrentClient().id).subscribe(pages => {
          this.pages = pages;
          this.calculateMaxAssignedOrder(this.pages);
          this.pages.sort(this.comparePagesByOrder.bind(this));
        });
      },
      error => {
        console.error(error);
      }
    );
  }

  getReference(param, page: Page) {
    return typeof page[param] === 'object' ? page[param]._ : page[param];
  }

  getPageReferenceInfo(page): String {
    if (page.pageContents.length > 1) {
      return this.utils.getTranslation('confirmation.multiple-references');
    } else {
      return '';
    }
  }

  private calculateMaxAssignedOrder(pages) {
    this.maxAssignedOrder = 0;
    const dtId = this.getDeviceGroupId();
    if (dtId) {
      for (const page of pages) {
        if (page.pageContents && page.pageContents.length !== 0) {
          for (const pageContent of page.pageContents) {
            if (pageContent.page_id === dtId && pageContent.sortOrder > this.maxAssignedOrder) {
              this.maxAssignedOrder = pageContent.sortOrder;
            }
          }
        }
      }
    }
  }

  private getDeviceGroupId() {
    if (this.deviceGroupSelector.currentDeviceGroup.id) {
      let dtId = this.deviceGroupSelector.currentDeviceGroup.id;
      if (this.deviceGroupID !== null) {
        dtId = this.deviceGroupID;
      }
      return dtId;
    }
    return null;
  }

  checkViewRight(authority: string, pageId: number) {
    if (this.checkAclRights(authority)) {
      this.router.navigate(['/pages/edit/' + pageId]);
    } else {
      this.utils.displayGrowlMessage('danger', 'lists.acl.noRights', '');
    }
  }

  checkDeleteRight(authority: string, globalAuthority: string) {
    return this.checkAclRights(authority) || this.checkAclRights(globalAuthority);
  }

  checkAclRights(authority: string) {
    if (this.aclUserAuthorities && this.aclUserAuthorities.aclAuthorities) {
      return this.aclUserAuthorities.aclAuthorities.indexOf(authority) > -1 || this.utils.isUserRightsAdmin() || this.utils.isAdmin();
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.currentLanguage$) {
      this.currentLanguage$.unsubscribe();
    }
    if (this.currentClient$) {
      this.currentClient$.unsubscribe();
    }
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
