import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ng2-dnd';
import { TimepickerModule } from 'ngx-bootstrap';
import { SharedModule } from '../shared/shared.module';

import { mainPageRouting } from './main-page.routing';
import { MainPageService } from '../services/main-page.service';
import { DeviceGroupModule } from '../device-group/device-group.module';
import { MapModule } from '../map/map.module';
import { LanguageModule } from '../language/language.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { ImageCropUploaderModule } from '../image-crop-uploader/image-crop-uploader.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { PreviewService } from './content-preview/preview.service';
import { LocationSettingsComponent } from './location-settings/location-settings.component';
import { LogoSettingsComponent } from './logo-settings/logo-settings.component';
import { ColorSettingsComponent } from './color-settings/color-settings.component';
import { ScreensaverSettingsComponent } from './screensaver-settings/screensaver-settings.component';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { DeviceGroupService, UploadService } from '../services';
import { DashheadModule } from '@app/dashhead/dashhead.module';
import { DisplayTypeComponent } from './display-type/display-type.component';

@NgModule({
  imports: [CommonModule,
    mainPageRouting,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    DeviceGroupModule,
    TranslateModule,
    DndModule.forRoot(),
    MapModule,
    LanguageModule,
    CustomModalModule,
    ImageCropUploaderModule,
    ColorPickerModule,
    TimepickerModule.forRoot(),
    AccordionModule.forRoot(),
    SharedModule, DashheadModule],
  declarations: [LocationSettingsComponent, LogoSettingsComponent,
    ColorSettingsComponent, ScreensaverSettingsComponent, LanguageSettingsComponent, DisplayTypeComponent],
  exports: [],
  providers: [MainPageService, Utils, DeviceGroupService, PreviewService, UploadService]
})
export class MainPageModule { }
