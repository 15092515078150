import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Pageable } from '../shared/pagination/pagination.component';
import { Observable } from 'rxjs';

@Injectable()
export class LocalWatchdogService {
  // TODO: remove this and fix the core one
  private baseURL = environment.watchdogActivityLogService;
  private watchdogRecipientURL = '/watchdogrecipient';
  constructor(
    private api: ApiService,
  ) {}

  getWatchdogActivityLogs(accountPrincipal: any, pageable: Pageable, optionalParamUrl?: string) {
    let url = this.baseURL;
    if (accountPrincipal) {
      url = url + '/' + accountPrincipal;
    } else {
      if (optionalParamUrl && optionalParamUrl !== '') {
        url = url + optionalParamUrl;
      } else {
        throw new Error('Exception: Required account principal or optionalParamUrl');
      }
    }

    return this.api.getData(url,
      new RequestParam('page', pageable.page.toString()),
      new RequestParam('size', '20'),
      new RequestParam('sort', pageable.sort + ',' + pageable.direction));
  }

  deleteWatchdogRecipientsList(recipientsList: Array<number>): Observable<Response> {
    const param = new RequestParam('ids', recipientsList.toString());
    return this.api.deleteData(this.watchdogRecipientURL + '/delete/list', null, param);
  }

  saveWatchdogRecipientsList(watchdogRecipients: Array<any>): Observable<Response> {
    return this.api.postData(this.watchdogRecipientURL + '/add/list', watchdogRecipients);
  }
}
