import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils, I18NSearchObj } from '../../shared/utils';
import { DishSetService, LanguageService } from '../../services';
import { DishSet, Dish } from '../../models';

@Component({
  selector: 'app-dish-set-list',
  templateUrl: './dish-set-list.component.html',
  styleUrls: ['./dish-set-list.component.css']
})
export class DishSetListComponent implements OnInit, OnDestroy {
  tmpDishSet = new DishSet(true, '', [null, null], []);
  tmpDish = new Dish('', 0, false);
  dishSets = new Array<DishSet>();
  clientSelected = false;
  selectedLanguageKey = '_';
  @ViewChild('languageSelector')
  languageSelector;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('deleteOutdatedDishsetsModal')
  private deleteOutdatedDishsetsModal: CustomModalComponent;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('xlsBrowse')
  xlsBrowse;

  constructor(
    private clientService: ClientService,
    private dishSetService: DishSetService,
    private utils: Utils,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
      this.selectedLanguageKey = this.languageService.currentLanguage;
    }
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientSelected = true;
      this.getAll(this.clientService.getCurrentClient().id);
    } else {
      this.clientSelected = false;
    }

    // Subscribe to current client to reload on change
    this.clientService.currentClient$.takeUntil(this.ngUnsubscribe).subscribe(client => {
      this.clientSelected = true;
      this.getAll(client.id);
    });
  }

  private getAll(clientId: string | number) {
    this.dishSetService.getAll(clientId).subscribe((dishSets: DishSet[]) => {
      this.dishSets = dishSets;
      this.dishSets.sort(this.compareDishSetsByOrder.bind(this));
    });
  }

  private compareDishSetsByOrder(dishSet1, dishSet2) {
    const p1 = this.getDishSetOrder(dishSet1) === -1 ? 1024 : this.getDishSetOrder(dishSet1);
    const p2 = this.getDishSetOrder(dishSet2) === -1 ? 1024 : this.getDishSetOrder(dishSet2);

    if (p1 < p2) {
      return -1;
    }
    if (p1 > p2) {
      return 1;
    }
    return 0;
  }

  private getDishSetOrder(language: any): number {
    const dtId = null;

    if (language.pageContents && language.pageContents.length !== 0) {
      for (const pageContent of language.pageContents) {
        if (pageContent.page_id === dtId || !pageContent.page_id) {
          return pageContent.sortOrder;
        }
      }
    }
    return -1;
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  handleXlsBrowseClick() {
    this.xlsBrowse.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    const reader = new FileReader();
    const extension = files[0].name
      .split('.')
      .pop()
      .toLowerCase();
    if (extension === 'xls' || extension === 'xlsx' || extension === 'ods' || extension === 'xlsm') {
      this.handleSpreadsheetFile(files, reader);
    }
  }

  private handleSpreadsheetFile(files: FileList, reader: FileReader) {
    reader.readAsBinaryString(files[0]);
    reader.onload = e => {
      const data = e.target['result'];
      const wb: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
      const sheet: XLSX.WorkSheet = wb.Sheets['LobbitImport'];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });
      const tmpDishSets: Array<DishSet> = [];
      if (rows.length > 1 && rows[0]['length'] >= 4) {
        let rowIndex = 0;
        for (const row of rows) {
          if (rowIndex >= 1 && row['length'] >= 4) {
            this.updateTmpDishSet(tmpDishSets, row);
          }
          this.tmpDishSet = new DishSet(true, '', [null, null], []);
          this.tmpDish = new Dish('', 0, false);
          rowIndex++;
        }
      }
      if (tmpDishSets.length > 0) {
        this.storeDishSets(tmpDishSets).then(resolve => {
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          this.dishSetService.getAll(this.clientService.getCurrentClient().id).subscribe(dishSets => {
            this.dishSets = dishSets;
            this.dishSets.sort(this.compareDishSetsByOrder.bind(this));
            this.saveReorderingDishSets();
          });
        });
      } else {
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
      }
    };
  }

  private updateTmpDishSet(tmpDishSets: Array<DishSet>, row: any) {
    const date: string = row[0];
    const dishSetName: string = row[1];
    const dishName: string = row[2];
    const isMainDish: string = row[3];
    this.tmpDishSet = this.getDishSetByDateAndName(tmpDishSets, date, dishSetName);
    if (!this.tmpDishSet) {
      this.tmpDishSet = new DishSet(true, '', [null, null], []);
      this.setI18NAttribute('tmpDishSet.name', true, dishSetName);
      this.tmpDishSet.dateRange = [new Date(date), new Date(date)];
      tmpDishSets.push(this.tmpDishSet);
    }
    if (this.tmpDishSet.dateRange[0].toString() === 'Invalid Date') {
      return;
    }
    this.setI18NAttribute('tmpDish.name', true, dishName);
    this.tmpDish.order = this.tmpDishSet.dishes.length;
    this.tmpDish.isMainDish = isMainDish === '1';
    this.tmpDishSet.dishes.push(this.tmpDish);
  }

  private getDishSetByDateAndName(tmpDishSets: Array<DishSet>, date: string, dishSetName: string) {
    return tmpDishSets.filter(tmpDishSet => {
      if (
        moment(tmpDishSet.dateRange[0]).isSame(new Date(date), 'day') &&
        this.getReference('name', tmpDishSet) === dishSetName
      ) {
        return true;
      }
      return false;
    })[0];
  }

  private storeDishSets(dishSets: Array<DishSet>): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      const me = this;
      this.storeNextDishSet(0, me, resolve, dishSets);
    });
    return promise;
  }

  private storeNextDishSet(i: number, me: any, resolve: any, dishSets: Array<DishSet>) {
    if (i < dishSets.length) {
      const dishSet = dishSets[i];

      me.dishSetService.store(me.clientService.getCurrentClient().id, false, dishSet, null).subscribe(
        res => {
          this.storeNextDishSet(i + 1, me, resolve, dishSets);
        },
        error => { }
      );
    } else {
      resolve(true);
    }
  }

  private showDeleteOutdatedDishsetsModal() {
    this.deleteOutdatedDishsetsModal.showModal(
      'forms.leave-page-alert-title',
      this.getTranslation('confirmation.delete-outdated-dishsets'),
      null,
      true);
  }

  private removeOutdatedDishsets() {
    this.deleteOutdatedDishsetsModal.closeModal();
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      const clientID = this.clientService.getCurrentClient().id;
      this.dishSetService
      .deleteOutdatedDishsets(clientID)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          this.getAll(clientID);
        },
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
        }
      );
    }

  }

  saveReorderingDishSets() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      const dishSetIds: number[] = [];
      for (const dishSet of this.dishSets) {
        dishSetIds.push(dishSet.id);
      }
      this.dishSetService.assign(this.clientService.getCurrentClient().id, dishSetIds).subscribe(res => {
        this.getAll(this.clientService.getCurrentClient().id);
      });
    }
  }

  removeDishSet(dishSetId: number) {
    this.modal.closeModal();
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.dishSetService
        .delete(this.clientService.getCurrentClient().id, dishSetId) //
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          res => {
            this.getAll(this.clientService.getCurrentClient().id);
          },
          error => {
            this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
          }
        );
    }
  }

  getReference(param, dishSet: DishSet) {
    return typeof dishSet[param] === 'object' ? dishSet[param]._ : dishSet[param];
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  setI18NAttribute(dottedName, setDefault, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
    if (setDefault) {
      const searchObj = new I18NSearchObj(this, dottedName);
      searchObj.container[searchObj.attr]._ = event;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
