import { BannerAction, BannerType } from './../banner-types';
import { ImageCropUploaderComponent } from './../../../image-crop-uploader/image-crop-uploader.component';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImgCropSettings } from '@app/image-crop-uploader/img-cropper/img-cropper.model';
import { Utils } from '@app/shared/utils';

@Component({
  selector: 'app-advertising-banner-settings',
  templateUrl: './advertising-banner-settings.component.html',
  styleUrls: ['./advertising-banner-settings.component.css']
})
export class AdvertisingBannerSettingsComponent implements OnInit {
  @Input() type: BannerType;
  @Input() ad = { active: false, image: null, websiteUrl: '' };
  @Input() size: any = { height: 126, width: 1920 };
  @Input() clientID: number | string;
  @Output() adOutput = new EventEmitter<any>();
  @Output() imageOutput = new EventEmitter<{ action, data, type }>();

  name = '';
  cropper: ImageCropUploaderComponent;
  @ViewChild('cropper') set content(content: ImageCropUploaderComponent) {
    if (content) {
      this.cropper = content;
    }
  }
  currentImage = '';
  imgCropSetting: ImgCropSettings = {
    transformToPixels: this.size,
    transformToAttribute: 'heightWidth',
    allowSmallBoundingBox: false,
    squareBox: false
  };

  private isLoadedCropper = true;

  constructor(
    public utils: Utils
  ) { }

  ngOnInit(): void {
    this.imgCropSetting.transformToPixels = this.size;
    this.name = this.type;
  }

  verifyContent() {
    const result = this.ad && this.ad.active;
    if (result && this.isLoadedCropper && this.cropper) {
      setTimeout(() => {
        if (this.isLoadedCropper) {
          this.isLoadedCropper = false;
          if (this.ad.image !== null) {
            this.currentImage = this.utils.buildAwsImageURL(this.clientID, this.ad.image);
          }
          this.cropper.setSavedImage(this.currentImage);
        }
      }, 0);
    }
    return result;
  }

  onFileChange(imageResult: any) {
    if (this.cropper && this.cropper.imgIsPresent) {
      const fileBlob = imageResult.src;
      this.currentImage = fileBlob;
      this.imageOutput.emit({ data: fileBlob, action: BannerAction.ADD, type: this.type });
    }
  }

  deleteImage() {
    this.imageOutput.emit({ data: this.currentImage, action: BannerAction.ADD_REMOVE, type: this.type });
    this.currentImage = '';
    this.cropper.setSavedImage(this.currentImage);
    this.cropper.deleteLogo();
    if (this.ad.image) {
      this.imageOutput.emit({ data: this.ad.image, action: BannerAction.DELETE, type: this.type });
      this.ad.image = null;
      this.updateParent();
    }
  }

  updateParent() {
    this.adOutput.emit(this.ad);
  }

  setBannerActive(event: any) {
    if (this.ad) {
      this.ad.active = event.target.checked;
    } else {
      this.ad = { active: event.target.checked, image: null, websiteUrl: null };
    }
    if (event.target.checked) {
      this.isLoadedCropper = true;
    }
    this.updateParent();
  }
}
