import {Component, OnInit, ViewChild} from '@angular/core';
import {PropsSettingsService} from '@app/services/props-settings.service';
import {RefreshableProperties} from '@app/models/refreshable-properties';
import {CustomModalComponent} from '@app/custom-modal/custom-modal.component';
import {Utils} from '@app/shared/utils';

@Component({
  selector: 'app-props-config-panel',
  templateUrl: './props-settings-panel.component.html',
  styleUrls: ['./props-settings-panel.component.css']
})
export class PropsSettingsPanelComponent implements OnInit {
  props: RefreshableProperties[] = [];
  constProps: RefreshableProperties[] = [];

  @ViewChild('modal') private modal: CustomModalComponent;

  constructor(private propsSettingsService: PropsSettingsService,
              private utils: Utils
  ) {}

  ngOnInit() {
    this.loadData();
  }

  public loadData() {
    this.propsSettingsService.getRefreshableProperties().subscribe(data => {
      const d = data.sort((a, b) => a.key.localeCompare(b.key));
      d.forEach(val => this.props.push(Object.assign({}, val)));
      d.forEach(val => this.constProps.push(Object.assign({}, val)));
    });
  }

  public cancelModifications() {
    this.props = [];
    this.constProps.forEach(val => this.props.push(Object.assign({}, val)));
  }

  public saveRefreshableProperties() {
    this.propsSettingsService.saveRefreshableProperties(this.props).subscribe(_ => {
      this.loadData();
    });
  }

  public addProp() {
    this.props.push(new RefreshableProperties('', ''));
  }

  public removeProp(index: number) {
    this.modal.closeModal();
    this.props.splice(index, 1);
  }

  public getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }
}
