import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UploadService, MainPageService, DeviceGroupService, LanguageService } from '../../services';
import { DeviceGroup, MainPage, CoordsWithName, UploadResponse, UploadProgressType } from '../../models';
import { Client } from '@b4m/b4m-frontend-core';
import { Utils } from '../../shared/utils';
import { Subscription } from 'rxjs/Subscription';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';

@Component({
  selector: 'app-logo-settings',
  templateUrl: './logo-settings.component.html',
  styleUrls: ['./logo-settings.component.css']
})
export class LogoSettingsComponent implements OnInit, OnDestroy {
  currentDeviceGroup$: Subscription;
  logoURL: string;
  mainPage: MainPage;
  mainPageId: number;
  logoBlob: string;
  selectedLanguageKey = '_';
  defaultUsed = false;
  progress = 0;
  imgChanged = false;
  private deletedImageIds: number[] = [];
  @ViewChild('languageSelector')
  languageSelector;
  @ViewChild('cropper')
  cropper;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  private client: Client;
  private deviceGroup: DeviceGroup;
  constructor(
    public uploadService: UploadService,
    private route: ActivatedRoute,
    private mainPageService: MainPageService,
    private utils: Utils,
    private deviceGroupService: DeviceGroupService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.client = data[0].client;
      this.deviceGroup = data[0].deviceGroup;
      this.loadMainPage();
      // Subscribe to current device to reload on change
      this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
        if (this.client && this.client.id && dt.id) {
          this.deviceGroup = dt;
          this.loadMainPage();
        }
      });
    });
  }

  cropperResult(e) {
    this.imgChanged = true;
  }

  loadMainPage(newMainPage?: any) {
    console.log('loading');

    if (newMainPage) {
      this.mainPage = newMainPage.value;
      this.mainPage.id = newMainPage.id;
      this.mainPageId = newMainPage.id;
      const logoFileName = this.utils.transformI18nValue(this.mainPage.logo);
      if (!logoFileName) {
        this.defaultUsed = false;
        this.logoURL = '';
        this.cropper.setSavedImage(this.logoURL);
      } else {
        this.setLogoData(logoFileName);
      }
    } else {
      this.mainPageService.get(this.client.id, this.deviceGroup.id).subscribe(content => {
        if (content) {
          this.mainPage = content.value;
          this.mainPage.id = content.id;
          this.mainPageId = content.id;
          const logoFileName = this.utils.transformI18nValue(this.mainPage.logo);
          this.setLogoData(logoFileName);
        } else {
          this.mainPage = new MainPage(
            '',
            '',
            this.mainPageService.newThemeColors,
            new CoordsWithName('', 0, 0),
            '',
            []
          );
          this.mainPageId = null;
        }
      });
    }
  }

  setLogoData(logoFileName: string) {
    this.defaultUsed = false;
    if (
      !logoFileName &&
      this.selectedLanguageKey !== '_' &&
      this.languageSelector.languages[0].key !== this.selectedLanguageKey
    ) {
      logoFileName = this.utils.getDefaultIfExists(this.mainPage.logo);
      this.defaultUsed = true;
      console.log('default should be used');
    }
    if (logoFileName) {
      this.logoURL = this.utils.buildAwsImageURL(this.client.id, logoFileName);
    } else {
      this.logoURL = null;
    }
    this.cropper.setSavedImage(this.logoURL);
  }

  switchLanguage(lang: string) {
    this.cropper.deleteLogo();
    this.selectedLanguageKey = lang;
    const logoFileName = this.utils.transformI18nValue(this.mainPage.logo);
    this.setLogoData(logoFileName);
  }

  imageChanged(htmlImageElement: HTMLImageElement) {
    const blob = this.uploadService.createImageBlob(htmlImageElement);
    if (blob) {
      this.logoBlob = blob;
    }
  }

  onSubmit() {
    if (this.cropper.data.image) {
      if (this.imgChanged) {
        this.logoBlob = this.cropper.uploadFileManually();
        if (this.logoBlob) {
          if (this.deletedImageIds.length > 0) {
            for (const deletedImageId of this.deletedImageIds) {
              if (deletedImageId !== -1) {
                this.uploadService.delete(this.client.id, deletedImageId).subscribe(() => {});
              }
            }
          }
          this.uploadService.saveFile(this.logoBlob, this.client.id).subscribe((result: UploadResponse) => {
            if (result.resType === UploadProgressType.BODY) {
              this.progress = 100;
              if (!this.mainPage.logo) {
                this.mainPage.logo = {};
              }
              this.logoURL = result.content[0].url;
              if (this.mainPageId) {
                let loc_default = false;
                if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
                  loc_default = true;
                }
                this.mainPageService
                  .updateProperty(this.mainPage, 'logo', result.content[0].key, true, loc_default)
                  .subscribe(newMainPage => {
                    this.loadMainPage(newMainPage);
                    this.utils.displayGrowlMessage('success', 'forms.submitted', '');
                  });
              } else {
                const logo = new Object();
                logo['_'] = result.content[0].key;
                logo[this.selectedLanguageKey] = result.content[0].key;
                this.mainPage.logo = logo;
                this.mainPageService.create(this.client.id, this.deviceGroup.id, this.mainPage).subscribe(newMainPage => {
                  this.loadMainPage(newMainPage);
                  this.utils.displayGrowlMessage('success', 'forms.submitted', '');
                });
              }
            } else if (result.resType === UploadProgressType.PROGRESS) {
              this.progress = result.content;
            }
            this.cropper.isUploading = false;
            this.imgChanged = false;
          });
        } else {
          throw new Error('Error: No file to upload selected.');
        }
      }
    } else {
      this.deleteAndUpdate();
    }
  }

  deleteLogo() {
    this.deletedImageIds.push(this.mainPage.logo[this.selectedLanguageKey]);
    this.modal.closeModal();
    this.logoURL = '';
    this.cropper.deleteLogo();
  }

  private deleteAndUpdate() {
    if (this.defaultUsed) {
      this.logoURL = '';
      this.cropper.deleteLogo();
    } else {
      const imageToDelete = this.mainPage.logo[this.selectedLanguageKey];
      this.uploadService.delete(this.client.id, imageToDelete).subscribe(deleted => {
        if (deleted.status !== 200) {
          this.utils.displayGrowlMessage('danger', 'Old image coulnd`t be removed from the server', '');
        } else {
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
        }
      });
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey || this.selectedLanguageKey === '_') {
        if (this.mainPage.logo._) {
          this.mainPage.logo._ = null;
          this.mainPage.logo[this.languageSelector.languages[0].key] = null;
        }
      }
      if (this.mainPage.logo[this.selectedLanguageKey]) {
        this.mainPage.logo[this.selectedLanguageKey] = null;
      }
      this.cropper.deleteLogo();

      this.mainPageService.update(this.client.id, this.deviceGroup.id, this.mainPage, this.mainPageId).subscribe(res => {
        // set logo url to null, if main page update is successfull
        this.logoURL = null;
        console.log(res);
        this.loadMainPage(res);
      });
    }
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    window.location.reload();
  }

  showModal() {
    this.modal.showModal('menus.settings.logo-image-delete', 'forms.leave-page-alert-title',
      '', true, false);
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
