import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TranslateModule } from '@ngx-translate/core';

import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { EventsAddEditComponent } from './events-add-edit/events-add-edit.component';
import { EventsComponent } from './events.component';
import { EventsListComponent } from './events-list/events-list.component';
import { eventsRouting } from './events.routing';
import { LanguageModule } from '../language/language.module';
import { SharedModule } from '../shared/shared.module';
import { EventsService } from '../services';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    CustomModalModule,
    eventsRouting,
    FormsModule,
    LanguageModule,
    SharedModule,
    TimepickerModule.forRoot(),
    TranslateModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  declarations: [EventsComponent, EventsAddEditComponent, EventsListComponent],
  providers: [EventsService]
})
export class EventsModule { }
