import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { ApiService } from '@b4m/b4m-frontend-core';
import { Device } from '../models';

@Injectable()
export class DeviceService {

  private deviceURL: string = environment.deviceService;
  constructor(
    private api: ApiService,
  ) { }

  getAll(): Observable<Device[]> {
    return this.api.getData(this.deviceURL);
  }

  getAllByClientId(clientID: number | string): Observable<Device[]> {
    return this.api.getData(this.deviceURL + '/' + clientID);
  }

  getByID(id: number, clientID: number | string): Observable<Device> {
    return this.api.getData(this.deviceURL + '/' + clientID + '/' + id);
  }

  create(device: Device, clientID: number | string): Observable<Device> {
    return this.api.postData(this.deviceURL + '/' + clientID, device);
  }

  update(device: Device, clientID: number | string): Observable<Device> {
    return this.api.putData(this.deviceURL + '/' + clientID, device);
  }

  delete(id: number, clientID: number | string): Observable<null> {
    const url = this.deviceURL + '/' + clientID + '/';
    return this.api.deleteData(url, id);
  }

}


