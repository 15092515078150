import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiService, AuthService, RequestParam } from '@b4m/b4m-frontend-core';
import { DishContent, DishSet } from '../models';
import { WidgetType } from '@app/models/widget-type';

@Injectable()
export class DishSetService {
  private baseURL = environment.contentService;
  constructor(private api: ApiService, private authService: AuthService) { }

  getAll(clientID: number | string) {
    return this.api
      .getData(this.baseURL + '/' + clientID + '?key=' + this.createKey(clientID)).pipe(
        map(this.extractDishSets, this),
        map(dishSets => dishSets.sort(this.compareFn))
      );
  }

  getByID(clientID: number | string, dishSetID: number) {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + dishSetID).pipe(
      map(this.extractDishSet, this)
    );
  }

  create(clientID: number | string, dishSet: DishSet) {
    return this.store(clientID, false, dishSet);
  }

  update(clientID: number | string, dishSet: DishSet, dishSetID: number) {
    return this.store(clientID, true, dishSet, dishSetID);
  }

  assign(clientID: number | string, dishSetIds: number[]) {
    const url = this.baseURL + '/' + clientID + '/assign?' + 'contentIds=' + dishSetIds + '&keyContains=' + this.createKey(clientID);
    return this.api.putData(url, null);
  }

  delete(clientID: number | string, id: number) {
    const url = this.baseURL + '/' + clientID;
    return this.api.deleteData(url, id);
  }

  deleteOutdatedDishsets(clientID: number | string) {
    const url = this.baseURL + '/' + clientID + '/expired';
    const requestParam = new RequestParam('key', this.createKey(clientID));
    return this.api.deleteData(url, null, requestParam);
  }

  store(clientID: number | string, update: boolean, dishSet: DishSet, dishSetID: number = null): Observable<Response> {
    const url = this.baseURL + '/' + clientID;
    const token: string = this.authService.token;
    const bodyObj = {
      key: this.createKey(clientID),
      value: dishSet
    };
    if (dishSetID) {
      bodyObj['id'] = dishSetID;
    }

    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  private extractDishSets(res: DishContent[]): DishSet[] {
    const dishSetList: DishSet[] = [];
    res.map((content: DishContent) => {
      const dishSet = this.extractDishSet(content);
      if (dishSet) {
        dishSetList.push(dishSet);
      }
    });
    return dishSetList;
  }

  private extractDishSet(content: DishContent): DishSet {
    const { id, value } = content;
    return new DishSet(value.activated, value.name, value.dateRange, value.dishes, id);
  }

  private createKey(clientId: number | string): string {
    return WidgetType.DISH_SET.type.replace('-', '') + 's.' + clientId;
  }

  // Function to compare two objects by comparing their `unwrappedName` property.
  private compareFn = (a, b: DishSet) => {
    if (a.dateRange[0] < b.dateRange[0]) {
      return -1;
    }
    if (a.dateRange[0] > b.dateRange[0]) {
      return 1;
    }
    return 0;
  }
}
