import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { Category, Coords, Poi, Content } from '../models';

@Injectable()
export class PoiService {
  private contentUrl: string;
  private placesUrl: string;
  constructor(private api: ApiService, private authService: AuthService, private http: HttpClient) {
    this.contentUrl = environment.contentService;
    this.placesUrl = '/places';
  }

  create(clientID: number | string, poi: Poi): Observable<Content> {
    const content = new Content(this.createPoiKey(clientID), poi);
    this.coordsToGeoJson(content);
    return this.api.postData(this.contentUrl + '/' + clientID, content);
  }

  update(clientID: number | string, poi: Poi, poiID: number): Observable<Content> {
    const content = new Content(this.createPoiKey(clientID), poi);
    content['id'] = poiID;
    this.coordsToGeoJson(content);
    return this.api.putData(this.contentUrl + '/' + clientID, content);
  }

  private createPoiKey(clientID: number | string) {
    return 'poi.' + clientID;
  }

  assign(clientID: number | string, deviceGroupID: number, poiIds: number[]) {
    const url =
      this.contentUrl +
      '/' +
      clientID +
      '/assign?deviceGroupId=' +
      deviceGroupID +
      '&contentIds=' +
      poiIds +
      '&keyContains=poi.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers };

    return this.http.put(url, null, options);
  }

  attach(clientID: number | string, deviceGroupID: number, poiId: number) {
    const url =
      this.contentUrl + '/' + clientID + '/attach?deviceGroupId=' + deviceGroupID + '&contentId=' + poiId + '&keyContains=poi.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers };

    return this.http.put(url, null, options);
  }

  getAll(clientID: number | string): Observable<Poi[]> {
    let url = this.contentUrl;
    if (clientID) {
      url = url + '/' + clientID + '?key=poi.' + clientID;
    }
    return this.api.getData(url).pipe(
      map(this.extractPois, this)
    );
  }

  getByID(clientID: number | string, poiID: number): Observable<Poi> {
    return this.api.getData(this.contentUrl + '/' + clientID + '/' + poiID).pipe(
      map(this.extractPoi)
    );
  }

  remove(clientID: number | string, poiID: number) {
    const url = this.contentUrl + '/' + clientID;
    return this.api.deleteData(url, poiID);
  }

  getCategories(clientID: number | string): Observable<Array<Category>> {
    return this.api
      .getData(this.contentUrl + '/' + clientID + '?key=' + this.createPoiCategoryKey(clientID)).pipe(
        map(this.extractPoiCategories)
      );
  }

  getCategoryByID(clientID: number | string, poiCatID: number): Observable<Category> {
    return this.api.getData(this.contentUrl + '/' + clientID + '/' + poiCatID).pipe(
      map(this.extractPoiCategory)
    );
  }

  createCategory(clientID: number | string, category: Category): Observable<Content> {
    const content = new Content(this.createPoiCategoryKey(clientID), category);
    return this.api.postData(this.contentUrl + '/' + clientID, content);
  }

  updateCategory(clientID: number | string, category: Category): Observable<Content> {
    const content = new Content(this.createPoiCategoryKey(clientID), category);
    content['id'] = category.id;
    return this.api.putData(this.contentUrl + '/' + clientID, content);
  }

  private createPoiCategoryKey(clientID: number | string) {
    return 'poiCategory.' + clientID;
  }

  removeCategory(clientID: number | string, poiCatID: number): Observable<string> {
    const url = this.contentUrl + '/' + clientID;
    return this.api.deleteData(url, poiCatID);
  }

  private extractPois = (res: Content[]): Poi[] => {
    return res.map(content => {
      return this.extractPoi(content);
    });
  }

  private extractPoi = (res: Content): Poi => {
    const coords = this.arrayToCoords(res.value.geometry.coordinates);
    const newPoi: Poi = new Poi(res.value.type, res.value.geometry, res.value.properties, res.id, res.pageContents);
    newPoi.geometry.coordinates = coords;
    return newPoi;
  }

  private arrayToCoords(coordsArray: number[]): Coords {
    return new Coords(+coordsArray[0], +coordsArray[1]);
  }

  private coordsToGeoJson = (poi: any) => {
    const coords = poi.value.geometry.coordinates;
    if (!(coords instanceof Array)) {
      poi.value.geometry.coordinates = this.coordsToArray(coords);
    }
  }

  private coordsToArray(coords: Coords): number[] {
    const result: number[] = [0, 0];
    result[0] = +coords.longitude;
    result[1] = +coords.latitude;
    return result;
  }

  private extractPoiCategories(res: any): Category[] {
    const respAsJson: any = res;
    const newCats = [];
    respAsJson.forEach(cat => {
      const newCat: Category = new Category(cat.id, cat.value.label);
      newCats.push(newCat);
    });
    return newCats;
  }

  private extractPoiCategory(res: any): Category {
    let respAsJson: any = res;
    // Check if array
    if (Object.prototype.toString.call(respAsJson) === '[object Array]') {
      respAsJson = respAsJson[0];
    }
    if (!respAsJson.id) {
      throw new Error('Exception: Missing ID on POI Category.');
    }
    return new Category(respAsJson.id, respAsJson.value.label);
  }

  getNearbyPlaces(places: any): Observable<Poi[]> {
    return this.api.putData(this.placesUrl + '/nearby', places).pipe(
      map(this.extractPois, this)
    );
  }

  getPlacesTypes() {
    return this.api.getData(this.placesUrl + '/types');
  }
}
