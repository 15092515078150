export class ServerSSHData {
  constructor(
    public name: string,
    public host: string,
    public publicKey: string
  ) {
  }
}

export class DeviceSSHData {
  constructor(
    public deviceId: number,
    public serverSSHDataId: number,
    public port: string,
    public username: string,
    public status: any,
    public publicKey: string
  ) {
  }
}
