export class Screensaver {
  constructor(
    public activated: boolean = false,
    public widgetId: number = null,
    public idle: number = 15,
    public timeFrom: string = 'Wed Dec 06 2017 08:00:00 GMT+0100 (CET)',
    public timeTo: string = 'Wed Dec 06 2017 20:00:00 GMT+0100 (CET)',
    public ads = { activated: true }
  ) { }
}
