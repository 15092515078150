import { CustomModalModule } from './../custom-modal/custom-modal.module';
import { ChartsModule } from 'ng2-charts';
import { GoogleAnalyticsComponent } from './google-analytics.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAnalyticsDashboardComponent } from './google-analytics-dashboard/google-analytics-dashboard.component';
import { googleAnalyticsRouting } from './google-analytics.routing';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { GoogleAnalyticsDashboardSelectorComponent
 } from './google-analytics-dashboard/google-analytics-dashboard-selector/google-analytics-dashboard-selector.component';
import { GoogleAnalyticsPanelAudienceComponent
 } from './google-analytics-dashboard/panels/google-analytics-panel-audience/google-analytics-panel-audience.component';
import { GoogleAnalyticsPanelDeviceComponent
} from './google-analytics-dashboard/panels/google-analytics-panel-device/google-analytics-panel-device.component';
import { GoogleAnalyticsPanelActiveUsersComponent
} from './google-analytics-dashboard/panels/google-analytics-panel-active-users/google-analytics-panel-active-users.component';
import { GoogleAnalyticsFilterModalComponent
} from './google-analytics-dashboard/panels/components/google-analytics-filter-modal/google-analytics-filter-modal.component';
import { GoogleAnalyticsFilterDateComponent
} from './google-analytics-dashboard/panels/components/google-analytics-filter-date/google-analytics-filter-date.component';

@NgModule({
  imports: [
    CommonModule,
    googleAnalyticsRouting,
    TranslateModule,
    FormsModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ChartsModule,
    CustomModalModule,
  ],
  declarations: [
    GoogleAnalyticsComponent,
    GoogleAnalyticsDashboardComponent,
    GoogleAnalyticsDashboardSelectorComponent,
    GoogleAnalyticsPanelAudienceComponent,
    GoogleAnalyticsPanelDeviceComponent,
    GoogleAnalyticsPanelActiveUsersComponent,
    GoogleAnalyticsFilterModalComponent,
    GoogleAnalyticsFilterDateComponent,
  ]
})
export class GoogleAnalyticsModule { }
