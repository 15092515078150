import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { PageComponent } from './page.component';
import { PageListComponent } from './page-list/page-list.component';
import { PageAddEditComponent } from './page-add-edit/page-add-edit.component';
import { pageRouting } from './page.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { PageService, UploadService } from '../services';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetModule } from '../widget/widget.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { SharedModule } from '../shared/shared.module';

// For Device selector
import { DeviceGroupModule } from '../device-group/device-group.module';

import { DndModule } from 'ng2-dnd';
import { LanguageModule } from '../language/language.module';
import { Utils } from '../shared/utils';
import { ImageUploadModule } from 'ng2-imageupload';
import { DashheadModule } from '@app/dashhead/dashhead.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    pageRouting,
    TranslateModule,
    DeviceGroupModule,
    WidgetModule,
    CustomModalModule,
    DndModule.forRoot(),
    LanguageModule,
    SharedModule,
    ImageUploadModule,
    DashheadModule
  ],
  declarations: [PageComponent, PageListComponent, PageAddEditComponent],
  exports: [PageComponent, PageListComponent],
  providers: [AuthGuard, PageService, Utils, UploadService]
})
export class PageModule { }
