import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DeviceGroupComponent } from './device-group.component';
import { DeviceGroupListComponent } from './device-group-list/device-group-list.component';
import { DeviceGroupAddEditComponent } from './device-group-add-edit/device-group-add-edit.component';
import { DeviceGroupSelectorComponent } from './device-group-selector/device-group-selector.component';
import { deviceGroupRouting } from './device-group.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule,
    deviceGroupRouting,
    FormsModule,
    BrowserModule,
    TranslateModule,
    CustomModalModule,
    SharedModule],
  declarations: [DeviceGroupComponent, DeviceGroupListComponent,
    DeviceGroupAddEditComponent, DeviceGroupSelectorComponent],
  exports: [DeviceGroupComponent, DeviceGroupSelectorComponent],
  providers: [AuthGuard, Utils]
})
export class DeviceGroupModule { }
