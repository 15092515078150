import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { AclComponent } from './acl.component';
import { AclListComponent } from './acl-list/acl-list.component';
import { ClientResolve } from '../client-guard.service';

const aclRoutes: Routes = [
  {
    path: 'acl',
    component: AclComponent,
    resolve: [ClientResolve],
    data: { roles: ['USER_RIGHTS_ADMIN'] },
    children: [
      {
        path: 'list',
        component: AclListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        data: { roles: ['USER_RIGHTS_ADMIN'] }
      }
    ]
  }
];

export const aclRouting = RouterModule.forChild(aclRoutes);
