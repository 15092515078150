import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Pageable } from '../shared/pagination/pagination.component';
import { ServerSSHData, DeviceSSHData } from '../models';

@Injectable()
export class RemoteAccessService {
  private sshServerURL = environment.sshServerService;
  private sshDeviceURL = environment.sshDeviceService;
  constructor(
    private api: ApiService
  ) {}

  getAllServerSSHData() {
    return this.api.getData(this.sshServerURL);
  }

  getServerSSHDataById(serverSSHDataIdID: number) {
    return this.api.getData(this.sshServerURL + '/' + serverSSHDataIdID);
  }

  storeServerSSHData(serverSSHData: ServerSSHData, update: boolean) {
    if (update) {
      return this.api.putData(this.sshServerURL, serverSSHData);
    } else {
      return this.api.postData(this.sshServerURL, serverSSHData);
    }
  }

  deleteServerSSHData(id: number) {
    return this.api.deleteData(this.sshServerURL, id);
  }

  getAllDeviceSSHData(pageable: Pageable, size?: string) {
    return this.api.getData(this.sshDeviceURL,
      new RequestParam('page', pageable.page.toString()),
      new RequestParam('size', size ? size : '30'),
      new RequestParam('sort', pageable.sort + ',' + pageable.direction));
  }

  getDeviceSSHDataByDeviceId(deviceID: number) {
    return this.api.getData(this.sshDeviceURL + '/' + deviceID);
  }

  checkPortAvailability(serverSSHDataID: number, port: string) {
    return this.api.getData(this.sshDeviceURL + '/port/' + serverSSHDataID + '/' + port);
  }

  storeDeviceSSHData(deviceSSHData: DeviceSSHData) {
    return this.api.postData(this.sshDeviceURL, deviceSSHData);
  }

  assignDeviceSSHData(deviceID: number) {
    return this.api.putData(this.sshDeviceURL + '/assign/' + deviceID, null);
  }

  unassignDeviceSSHData(deviceID: number) {
    return this.api.putData(this.sshDeviceURL + '/unassign/' + deviceID, null);
  }

  deleteDeviceSSHData(deviceID: number) {
    return this.api.deleteData(this.sshDeviceURL, deviceID);
  }

}


