import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { Content, Advertising } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AdvertisingService {

  private baseURL = environment.contentService;
  private baseGlobalURL = environment.contentGlobalService;
  constructor(private http: HttpClient, private api: ApiService, private authService: AuthService) { }

  getAll(clientID: number | string): Observable<Content[]> {
    return this.api.getData(this.baseURL + '/' + clientID + '?key=' + 'ads.' + clientID);
  }

  getByID(clientID: number | string, id: number): Observable<Content> {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + id);
  }

  create(clientID: number | string, advertising: Advertising): Observable<Content> {
    return this.store(clientID, false, advertising);
  }

  update(clientID: number | string, advertising: Advertising, advertisingID: number): Observable<Content> {
    return this.store(clientID, true, advertising, advertisingID);
  }

  assign(clientID: number | string, deviceGroupID: number, advertisingIds: number[]): Observable<string> {
    const url =
      this.baseURL +
      '/' +
      clientID +
      '/assign?deviceGroupId=' +
      deviceGroupID +
      '&contentIds=' +
      advertisingIds +
      '&keyContains=ads.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options);
  }

  attach(clientID: number | string, deviceGroupID: number, advertisingId: number): Observable<string> {
    const url =
      this.baseURL + '/' + clientID + '/attach?deviceGroupId=' + deviceGroupID + '&contentId=' + advertisingId + '&keyContains=ads.';
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, responseType: 'text' as 'text' };
    return this.http.put(url, null, options);
  }

  delete(clientID: number | string, id: number) {
    const url = this.baseURL + '/' + clientID;
    return this.api.deleteData(url, id);
  }

  getAllGlobal(): Observable<Content[]> {
    return this.api.getData(this.baseGlobalURL + '/all/0' + '?key=' + 'ads');
  }

  getGlobalByID(id: number): Observable<Content> {
    return this.api.getData(this.baseGlobalURL + '/' + id);
  }

  createGlobal(advertising: Advertising): Observable<Content> {
    return this.storeGlobal(false, advertising);
  }

  updateGlobal(advertising: Advertising, advertisingID: number): Observable<Content> {
    return this.storeGlobal(true, advertising, advertisingID);
  }

  deleteGlobal(id: number) {
    return this.api.deleteData(this.baseGlobalURL, id);
  }

  private store(clientID: number | string, update: boolean, advertising: Advertising, advertisingID: number = null): Observable<Content> {
    const url = this.baseURL + '/' + clientID;
    const bodyObj = {
      key: 'ads.' + clientID,
      value: advertising
    };
    if (advertisingID) {
      bodyObj['id'] = advertisingID;
    }
    if (update) {
      return this.api.putData(url, bodyObj);
    } else {
      return this.api.postData(url, bodyObj);
    }
  }

  private storeGlobal(update: boolean, advertising: Advertising, advertisingID: number = null): Observable<Content> {
    const bodyObj = {
      key: 'ads',
      value: advertising
    };
    if (advertisingID) {
      bodyObj['id'] = advertisingID;
    }
    if (update) {
      return this.api.putData(this.baseGlobalURL, bodyObj);
    } else {
      return this.api.postData(this.baseGlobalURL, bodyObj);
    }
  }
}
