import {
  Component, Output, OnChanges, Input,
  EventEmitter, ViewChild, OnInit
} from '@angular/core';
import { Utils } from '../shared/utils';
import { ImgCropSettings } from './img-cropper/img-cropper.model';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'image-crop-uploader',
  templateUrl: 'image-crop-uploader.component.html',
  styleUrls: ['image-crop-uploader.component.css']
})
export class ImageCropUploaderComponent implements OnChanges, OnInit {
  // inputs
  @Input() savedImage: any;
  @Output() savedImageChanged: EventEmitter<HTMLImageElement> = new EventEmitter<HTMLImageElement>();
  @Input() progress: number;
  @Input() maxHeight: number;
  @Input() imgCropSetting: ImgCropSettings = {
    transformToPixels: 128,
    transformToAttribute: 'height',
    allowSmallBoundingBox: false,
    squareBox: false
  };
  // output
  @Output() result = new EventEmitter<any>();
  // html nodes
  @ViewChild('croppedImage') croppedImage;
  @ViewChild('browse') browse;
  // vars
  data: any;
  currentProfileImage: any;
  maximumFileSizeInBytes = 1e+6;
  isUploading = false;
  isVisibleToCrop = false;
  imgIsPresent = false;
  croppedWidth: number;
  croppedHeight: number;
  croppedImageSize: number;
  showDeleteUnsaved = false;
  imageToCrop: any;


  progressSuccess = 0;
  supportedFileTypes: string[] = ['image/png', 'image/jpeg', 'image/gif', 'image/svg+xml'];


  constructor(
    private utils: Utils,
  ) {
    this.data = {};

  }

  /**
   * Helper method, fire when cropping was canceled to hide the cropper
   */
  croppingCanceled() {
    this.isVisibleToCrop = false;
    this.resetFileInput();
  }

  ngOnInit() {
    this.setSavedImage(this.savedImage);
  }

  /**
   * This method gets called after the cropper emited the cropped image.
   * The new image will be displayed.
   * @param event event data from the cropper
   */
  setImage(event) {
    this.croppedHeight = event.height;
    this.croppedWidth = event.width;
    this.data.image = event.src;
    this.isVisibleToCrop = false;
    this.imgIsPresent = true;
    this.showDeleteUnsaved = true;
    this.result.emit(event);
  }

  /**
   * Method to keep track of the upload progress.
   * TODO: Need to check if the method is still used.
   * @param changes progress change
   */
  ngOnChanges(changes) {
    if (changes.progress !== null && this.data.image) {
      const currentProgress = Math.round((this.progress / this.croppedImageSize) * 100);
      if (currentProgress > 100) {
        this.progressSuccess = 100;
      } else {
        this.progressSuccess = currentProgress;
      }
    }
  }

  setSavedImage(image: string) {
    if (image) {
      const that = this;
      this.data.image = image;
      const i = new Image();
      i.onload = function () {
        that.croppedWidth = i.width;
        that.croppedHeight = i.height;
      };
      i.src = this.data.image;
    } else {
      this.data.image = null;
    }
  }


  uploadFileManually() {
    this.isUploading = true;
    this.progress = 0;
    const canvas = <HTMLCanvasElement>document.createElement('CANVAS');
    canvas.setAttribute('width', String(this.croppedWidth));
    canvas.setAttribute('height', String(this.croppedHeight));
    const context = <CanvasRenderingContext2D>canvas.getContext('2d');
    context.drawImage(this.croppedImage.nativeElement, 0, 0, this.croppedWidth, this.croppedHeight);
    this.croppedImageSize = canvas.toDataURL().length;
    return canvas.toDataURL();
  }

  /**
   * Method to handle the dragged image and activate the cropper
   * @param acceptedFile file
   */
  dragFileAccepted(acceptedFile: any) {
    const image: any = new Image();
    const that = this;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      image.src = fileReader.result;
      this.isVisibleToCrop = true;
      this.imageToCrop = image;
    };
    fileReader.readAsDataURL(acceptedFile);
  }

  /**
   * Validation function to check if image or upload criterias are respected
   * @param image image file to check
   */
  private validateHeight(image) {
    if (this.maxHeight !== undefined && image.height > this.maxHeight) {
      this.utils.displayGrowlMessage('danger', 'forms.upload-failed-summary', 'forms.upload-failed-details-height');
      throw new Error('File is too height');
    }
  }

  /**
   * Error method to display an error if image filesize or type are not correct.
   * @param reason reason code
   */
  dragFileRejected(reason: string) {
    if (reason === 'fileType') {
      this.utils.displayGrowlMessage('danger', 'forms.upload-failed-summary', 'forms.upload-failed-details-type');
    } else if (reason === 'fileSize') {
      this.utils.displayGrowlMessage('danger', 'forms.upload-failed-summary', 'forms.upload-failed-details-size');
    } else {
    }
  }

  /**
   * This function handles the drop event
   * @param event UploadEvend
   */
  public dropped(event: UploadEvent) {
    if (event.files && event.files[0]) {
      const file = event.files[0].fileEntry as FileSystemFileEntry;
      file.file(info => {
        if (!this.supportedFileTypes.includes(info.type)) {
          this.dragFileRejected('fileType');
          return;
        } else if (info.size > this.maximumFileSizeInBytes) {
          this.dragFileRejected('fileSize');
          return;
        }
        this.dragFileAccepted(info);
      });
    }
  }

  public fileOver(event) {

  }

  public fileLeave(event) {

  }

  /**
   * Helper method to catch the browse click event.
   */
  handleBrowseClick() {
    this.browse.nativeElement.click();
  }

  /**
   * Fired after the drop or the browse click
   * @param event any
   */
  onFileChange(event) {
    const that = this;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (this.supportedFileTypes.indexOf(file.type) === -1) {
        this.utils.displayGrowlMessage('danger', 'forms.upload-failed-summary', 'forms.upload-failed-details-type');
        return;
      }
      if (file.size > this.maximumFileSizeInBytes) {
        this.utils.displayGrowlMessage('danger', 'forms.upload-failed-summary', 'forms.upload-failed-details-size');
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const image: any = new Image();
        image.src = fileReader.result;
        that.imageToCrop = image;
        this.isVisibleToCrop = true;
      };
      fileReader.readAsDataURL(file);
    }
  }

  /**
   * Method to remove the logo.
   */
  deleteLogo() {
    this.data.image = '';
    this.showDeleteUnsaved = false;
    this.resetFileInput();
  }

  /**
   * this works only on newer browsers!
   */
  resetFileInput() {
    this.browse.nativeElement.value = '';
  }

}
