import { Client } from '@b4m/b4m-frontend-core/';
import { Component, OnInit } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { AclService, PageService, LanguageService } from '../../services';
import { UserAclAuthorities, Page, Content } from '../../models';
import { Utils } from '../../shared/utils';
import { UserRightsType } from '../../models/user-rigts-type';

@Component({
  selector: 'app-acl-list',
  templateUrl: './acl-list.component.html',
  styleUrls: ['./acl-list.component.css']
})
export class AclListComponent implements OnInit {
  aclUsersAuthorities: Array<UserAclAuthorities> = [];
  pages: Array<Content> = [];
  editedUsers: Array<string> = [];
  clientSelected = true;
  isUserRightsAdmin: boolean;
  currentClient: Client;
  currentUserRightsType = UserRightsType.PagesRights;
  UserRightsType = UserRightsType;

  constructor(
    private aclService: AclService,
    private pageService: PageService,
    private languageService: LanguageService,
    private clientService: ClientService,
    private utils: Utils
  ) {
    this.currentClient = this.clientService.getCurrentClient();
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    if (this.currentClient && this.currentClient.id) {
      this.clientSelected = true;
      this.isUserRightsAdmin = this.utils.isUserRightsAdmin();
      this.getACLUsers();
      this.getPages();
    } else {
      this.clientSelected = false;
    }
  }

  private getACLUsers() {
    this.aclService.getAllClientUsersAclAuthorities(this.currentClient.id).subscribe(aclUsers => {
      this.aclUsersAuthorities = aclUsers;
    });
  }

  private getPages() {
    this.pageService.getAll(this.currentClient.id).subscribe(result => {
      if (result) {
        this.pages = result;
      }
    });
  }

  checkboxChange(e, page, login) {
    if (this.editedUsers.indexOf(login) === -1) {
      this.editedUsers.push(login);
    }
    const aclUserIndex = this.aclUsersAuthorities.findIndex(a => a.userLogin === login);
    if (e.target.checked) {
      this.aclUsersAuthorities[aclUserIndex].aclAuthorities.push(e.target.name);
    } else {
      const authorityIndex = this.aclUsersAuthorities[aclUserIndex].aclAuthorities.indexOf(e.target.name);
      this.aclUsersAuthorities[aclUserIndex].aclAuthorities.splice(authorityIndex, 1);
    }
  }

  save() {
    const aclAuthoritiesFilter: Array<UserAclAuthorities>  = [];
     for (const editedUser of this.editedUsers) {
       aclAuthoritiesFilter.push(this.aclUsersAuthorities.find(a => a.userLogin === editedUser));
     }
    this.aclService.updateAuthorities(aclAuthoritiesFilter).subscribe(
       res => {
         this.handleServerResponse(true);
       },
       error => {
         this.handleServerResponse(false);
       }
     );
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  private handleServerResponse(success: boolean, message?: string) {
    if (success) {
      this.utils.displayGrowlMessage('success', 'lists.changesSaved', '');
    } else {
      if (message === undefined) {
        message = 'error';
      }
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', message);
    }
  }

  setCurrentUserRightsType(userRightsType: UserRightsType) {
    this.currentUserRightsType = userRightsType;
  }
}
