import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ClientService } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroupService } from '../../services';
import { DeviceGroup } from '../../models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-selector',
  templateUrl: 'client-selector.component.html',
  styleUrls: ['client-selector.component.css']
})
export class ClientSelectorComponent implements OnInit {
  clients: Observable<Client[]>;
  clientLength: number;
  selectedClientName: string;
  constructor(
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.clients = this.clientService.getClients();
    this.clients.subscribe(clients => {
      this.clientLength = clients.length;
    });
    this.clientService.currentClient$.subscribe(client => {
      this.selectedClientName = client.name;
    });

    this.clientService.clients$.subscribe(clients => {
      this.clients = this.clientService.getClients();
      this.clientLength = clients.length;
    });

    this.clientService.switchClientPermission$.subscribe(res => {
      this.setClient(res);
    });
  }

  requestClientSwitch(clientId) {
    const activatedRoute: any = this.getActivatedRoute(this.route.snapshot);
    if (activatedRoute.routeConfig.canDeactivate) {
      this.clientService.requestClientSwitch(clientId);
    } else {
      this.setClient(clientId);
    }
  }

  private getActivatedRoute(obj: any): any {
    let sub = obj;
    if (sub.children && sub.children[0]) {
      sub = this.getActivatedRoute(sub.children[0]);
    }
    return sub;
  }

  setClient(clientId) {
    this.clientService.getClientbyId(clientId).subscribe(
      client => {
        this.clientService.setCurrentClient(client);
        localStorage.setItem('client', '' + client.id);
        this.deviceGroupService.getAll(clientId).subscribe(deviceGroups => {
          if (deviceGroups[0]) {
            this.deviceGroupService.setCurrentDeviceGroup(deviceGroups[0]);
          } else {
            this.deviceGroupService.setCurrentDeviceGroup(new DeviceGroup(true, null, '', null, [], ''));
          }
        });
      }
    );
  }

}
