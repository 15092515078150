import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private baseURL = environment.googleAnalyticsService;

  constructor(private api: ApiService) { }

  public getReportsData(dimension: string, metric: string, startDateRange?: Date, endDateRange?: Date,
    filter?: {clientId, deviceGroupId, deviceId}): Observable<any> {
    const params = [];
    if (startDateRange) {
      params.push(new RequestParam('startDateRange', startDateRange.toDateString()));
    }
    if (endDateRange) {
      params.push(new RequestParam('endDateRange', endDateRange.toDateString()));
    }
    if (filter) {
      if (filter.clientId && filter.clientId !== '') {
        params.push(new RequestParam('clientId', filter.clientId));
      }
      if (filter.deviceGroupId && filter.deviceGroupId !== '') {
        params.push(new RequestParam('deviceGroupId', filter.deviceGroupId));
      }
      if (filter.deviceId && filter.deviceId !== '') {
        params.push(new RequestParam('deviceId', filter.deviceId));
      }
    }
    params.push(new RequestParam('dimension', dimension));
    params.push(new RequestParam('metric', metric));
    return this.api.getData(this.baseURL, ...params);
  }

  public getDimensionByType(googleAnalyticsReports: any, type: string): any[] {
    const out: any[] = [];
    if (googleAnalyticsReports && googleAnalyticsReports.reports) {
      googleAnalyticsReports.reports.forEach(report => {
        if (report.data) {
          report.data.forEach(d => {
            if (d.dimensions) {
              d.dimensions.forEach(dimension => {
                if (dimension && dimension.names.join(',').includes(type)) {
                  out.push(dimension);
                }
              });
            }
          });
        }
      });
    }
    return out;
  }

  public getMetricByDimensionAndType(dimension: any, type: string): any {
    let out = null;
    if (dimension && dimension.metrics) {
      dimension.metrics.forEach(metric => {
        if (metric && metric.name === type) {
          out = metric;
        }
      });
    }
    return out;
  }
}
