import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { WidgetComponent } from './widget.component';
import { WidgetListComponent } from './widget-list/widget-list.component';
import { WidgetAddEditComponent } from './widget-add-edit/widget-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const widgetRoutes: Routes = [
  {
    path: 'widgets',
    component: WidgetComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      {
        path: 'add/:pageId',
        component: WidgetAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'edit/:id/:pageId',
        component: WidgetAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'list/:pageId',
        component: WidgetListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
    ]
  }
];

export const widgetRouting = RouterModule.forChild(widgetRoutes);
