import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { DeviceGroupComponent } from './device-group.component';
import { DeviceGroupListComponent } from './device-group-list/device-group-list.component';
import { DeviceGroupAddEditComponent } from './device-group-add-edit/device-group-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const deviceGroupRoutes: Routes = [
  {
    path: 'device-group',
    component: DeviceGroupComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'add',
        component: DeviceGroupAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'edit/:id',
        component: DeviceGroupAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      { path: 'list', component: DeviceGroupListComponent, canActivate: [AuthGuard], resolve: [ClientResolve], data: { roles: ['ADMIN'] } }
    ]
  }
];

export const deviceGroupRouting = RouterModule.forChild(deviceGroupRoutes);
