import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MapService } from '../services';
import { MapComponent } from './map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../environments/environment';
import { StaticMapComponent } from './static-map/static-map.component';

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    TranslateModule,
    AgmCoreModule,
  ],
  declarations: [MapComponent, StaticMapComponent],
  exports: [MapComponent, StaticMapComponent],
  providers: [MapService]
})
export class MapModule { }
