export class Dish {
  constructor(public name: string = '', public order: number = 0, public isMainDish: boolean = false) {}
}

export class DishSet {
  constructor(
    public activated: boolean = true,
    public name: string = '',
    public dateRange: Date[] = [null, null],
    public dishes: Dish[] = [],
    public id?: number
  ) {}
}

export class DishContent {
  constructor(public id: number, public key: string, public value: DishSet) {}
}
