export class UserRightsType {
  private static All: UserRightsType[] = [];

  static readonly PagesRights = new UserRightsType('PagesRights', 'VIEW_PAGES_G', 'CREATE_PAGES_G', 'DELETE_PAGES_G');
  static readonly PointsOfInterest = new UserRightsType('PointsOfInterest', 'VIEW_POI', '', '');
  static readonly Events = new UserRightsType('Events', 'VIEW_EVENTS', '', '');
  static readonly DishSets = new UserRightsType('DishSets', 'VIEW_DISH_SETS', '', '');
  static readonly Feedback = new UserRightsType('Feedback', 'VIEW_FEEDBACK', '', '');
  static readonly AdvertisingPartners = new UserRightsType('AdvertisingPartners', 'VIEW_ADVERTISING_PARTNERS', '', '');
  static readonly Location = new UserRightsType('Location', 'VIEW_LOCATION', '', '');
  static readonly Logo = new UserRightsType('Logo', 'VIEW_LOGO', '', '');
  static readonly Colors = new UserRightsType('Colors', 'VIEW_COLORS', '', '');
  static readonly Languages = new UserRightsType('Languages', 'VIEW_LANGUAGES', '', '');
  static readonly Screensaver = new UserRightsType('Screensaver', 'VIEW_SCREENSAVER', '', '');
  static readonly DisplayType = new UserRightsType('DisplayType', 'VIEW_DISPLAY_TYPE', '', '');

  private constructor(
    public readonly key: string,
    public readonly view: string,
    public readonly create: string,
    public readonly remove: string
  ) {
    UserRightsType.All.push(this);
  }

  public static parse(key: string): UserRightsType {
    return UserRightsType.All.find((t) => t.key === key);
  }

  public static all(): UserRightsType[] {
    return UserRightsType.All;
  }

  public static getContentsNav(): UserRightsType[] {
    return [
      UserRightsType.PagesRights,
      UserRightsType.PointsOfInterest,
      UserRightsType.Events,
      UserRightsType.DishSets,
      UserRightsType.Feedback,
      UserRightsType.AdvertisingPartners
    ];
  }

  public static getSettingsNav(): UserRightsType[] {
    return [
      UserRightsType.Location,
      UserRightsType.Logo,
      UserRightsType.Colors,
      UserRightsType.Languages,
      UserRightsType.Screensaver,
      UserRightsType.DisplayType
    ];
  }
}
