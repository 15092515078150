import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { CanComponentDeactivate } from '@b4m/b4m-frontend-core';
import { Utils } from '../../shared/utils';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import { Language } from '../../models';
import { MainPageService, LanguageService } from '../../services';


@Component({
  selector: 'app-language-add-edit',
  templateUrl: 'language-add-edit.component.html',
  styleUrls: ['language-add-edit.component.css']
})
export class LanguageAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {

  isNew = false;
  locales: any;
  languages: any;
  language: Language = new Language('', '', '', false);
  private selectedID: number;
  private selectedKey: string;
  private sub: Subscription;
  @ViewChild('languageForm') languageForm;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  private contentModified = false;
  languageCategories: string[] = [
    '',
    'languages-only'
  ];
  languageCategory = '';
  private refererDeviceGroupId: number;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private mainPageService: MainPageService,
    private languageService: LanguageService,
    private utils: Utils) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (params['refererDeviceGroupId'] !== undefined) {
        this.refererDeviceGroupId = params['refererDeviceGroupId'];
      }
      this.selectedID = params['id'];
      if (!this.selectedID) {
        this.isNew = true;
      }

      if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
        this.reload(this.clientService.getCurrentClient().id);
      }

      // Subscribe to current client to reload on change
      this.clientService.currentClient$.takeUntil(this.ngUnsubscribe).subscribe(client => {
        this.reload(client.id);
      });

      this.languageService.setLocaleList();
      this.languageService.locales$.subscribe(locales => {
        this.locales = locales;
        this.locales.sort(this.compareLocales);
      });

      this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
        this.canDeactivate().then(resolve => {
          if (resolve) {
            this.clientService.clientSwitchPermission(res);
          }
        });
      });

    });
  }

  private reload(clientID) {
    this.languageForm.form.reset();
    this.contentModified = false;
    if (clientID) {
      this.languageService.getAll(clientID).takeUntil(this.ngUnsubscribe).subscribe(languages => {
        this.language = new Language('', '', '', false);
        if (languages) {
          this.languages = languages;
          let lang;
          if (this.selectedID) {
            lang = languages.filter(language => language.id === this.selectedID)[0];
          }
          if (lang) {
            this.language = lang.value;
            this.selectedKey = lang.value.key;
          } else if (this.selectedID) {
            this.language = new Language('', '', '', false);
          }
        } else {
          this.languages = null;
        }
      });
    }
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (this.languageForm.form.dirty || this.contentModified) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  onSubmit() {
    if (!this.languages) {
      this.languages = [];
    }

    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      if (!this.isNew && this.languages.filter(language => language.value.key === this.language.key)[0] && this.selectedID) {
        this.languageService.update(
          this.clientService.getCurrentClient().id,
          this.language, this.selectedID
        ).takeUntil(this.ngUnsubscribe).subscribe(
          res => {
            this.handleServerResponse(true);
          },
          error => {
            this.handleServerResponse(false);
          }
          );
      } else if (this.isNew && !this.languages.filter(language => language.value.key === this.language.key)[0] && !this.selectedID) {
        this.languageService.create(this.clientService.getCurrentClient().id, this.language).takeUntil(this.ngUnsubscribe).subscribe(
          res => {
            if (this.refererDeviceGroupId) {
              this.languageService.attach(
                this.clientService.getCurrentClient().id,
                this.refererDeviceGroupId,
                res.id
              ).takeUntil(this.ngUnsubscribe).subscribe(attached => {
                this.handleServerResponse(true);
              });
            } else {
              this.handleServerResponse(true);
            }
          },
          error => {
            this.handleServerResponse(false);
          }
        );
      }
    }
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.languageForm.form.reset();
      this.contentModified = false;
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/settings/languages']);
    } else {
      this.utils.displayGrowlMessage('error', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  localeExists(languageKey: string): boolean {
    if (this.languages) {
      for (const language of this.languages) {
        if (languageKey === language.value.key && this.selectedKey !== language.value.key) {
          return true;
        }
      }
    }
    return false;
  }

  filterCategory(languageKey: string): boolean {
    if (this.languageCategory === 'languages-only') {
      if (languageKey.length === 2) {
        return true;
      }
      return false;
    }
    return true;
  }

  localeSwitched(value) {
    this.contentModified = true;
    this.selectedKey = value;
    const locale = this.locales.filter(l => l.key === value)[0];
    this.language.description = locale.nativeName;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.switchClientRequest$.unsubscribe();

    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private compareLocales(locale1, locale2) {
    if (locale1.key < locale2.key) {
      return -1;
    }
    if (locale1.key > locale2.key) {
      return 1;
    }
    return 0;
  }

}
