import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TranslateModule } from '@ngx-translate/core';

import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { googleCalendarIntegrationRouting } from './google-calendar-integration.routing';
import { LanguageModule } from '../language/language.module';
import { SharedModule } from '../shared/shared.module';
import { GoogleCalendarIntegrationComponent } from './google-calendar-integration.component';
import { IntegrationRedirectComponent } from './integration-redirect/integration-redirect.component';

@NgModule({
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    googleCalendarIntegrationRouting,
    FormsModule,
    LanguageModule,
    SharedModule,
    TimepickerModule.forRoot(),
    TranslateModule,
    CustomModalModule
  ],
  declarations: [GoogleCalendarIntegrationComponent, IntegrationRedirectComponent],
  providers: []
})
export class GoogleCalendarIntegrationModule { }
