import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { PoiService } from '../../../services';
import { Poi, Widget, Category } from '../../../models';
import { CustomModalComponent } from '../../../custom-modal/custom-modal.component';
import { I18nPipe } from '../../../shared/pipes/i18n.pipe';

@Component({
  selector: 'app-widget-poi-type',
  templateUrl: './widget-poi-type.component.html',
  styleUrls: ['../widget-add-edit.component.css']
})
export class WidgetPoiTypeComponent implements OnInit {

  poiCategories: Category[];
  pois: Poi[] = [];
  clientID = null;

  @Input() editable;
  @Input() widget: Widget;
  @Input() selectedLanguageKey;
  @Input() languageSelector;

  @ViewChild('poiPicker')
  private poiPicker: CustomModalComponent;

  constructor(
    public clientService: ClientService,
    private poiService: PoiService,
    private i18nPipe: I18nPipe
  ) { }

  ngOnInit() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientID = this.clientService.getCurrentClient().id;
      this.poiService.getAll(this.clientID).subscribe(pois => {
        this.pois = pois;
      });

      this.poiService.getCategories(this.clientID).subscribe(categories => {
        this.poiCategories = categories;
      });
    }
  }

  getLocationTitle(widget): string {
    let title = '';
    if (widget) {
      const poiCategory = widget.poiCategory;
      const poiID = widget.location;

      if (
        widget.poiCategory &&
        widget.poiCategory !== null &&
        widget.poiCategory !== '' &&
        widget.poiCategory !== 'null'
      ) {
        if (poiID && poiID !== null && poiID !== '' && poiID !== 'null') {
          for (const poi of this.pois) {
            if (+poiID === +poi['id']) {
              title = this.i18nPipe.transform(poi.properties.title, this.selectedLanguageKey);
            }
          }
        } else if (this.poiCategories && this.poiCategories.length > 0) {
          for (const pC of this.poiCategories) {
            if (+widget.poiCategory === +pC['id']) {
              title = this.i18nPipe.transform(pC.label, this.selectedLanguageKey);
            }
          }
        }
      }
    }
    return title;
  }

  public poiCategoryChanged(poiCategoryId) {
    this.widget.location = null;
  }
}
