import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { DeviceComponent } from './device.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceAddEditComponent } from './device-add-edit/device-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const deviceRoutes: Routes = [
  {
    path: 'device',
    component: DeviceComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN'] },
    children: [
      {
        path: 'add',
        component: DeviceAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      {
        path: 'edit/:id',
        component: DeviceAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN'] }
      },
      { path: 'list', component: DeviceListComponent, canActivate: [AuthGuard], resolve: [ClientResolve], data: { roles: ['ADMIN'] } }
    ]
  }
];

export const deviceRouting = RouterModule.forChild(deviceRoutes);
