import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { AuthService, ClientService } from '@b4m/b4m-frontend-core';
import { TranslateService } from '@ngx-translate/core';
import { supportedLanguages } from '../shared/supported-languages';
import { Utils } from '../shared/utils';
import { PreviewService, PreviewStates } from '../main-page/content-preview/preview.service';
import * as moment from 'moment';

@Component({
  selector: 'app-top-bar',
  templateUrl: 'top-bar.component.html',
  styleUrls: ['top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  currentUserName: string;
  isAdmin: boolean;
  selectedLang: string;
  langList: string[] = supportedLanguages;
  constructor(
    private authService: AuthService,
    private bsLocaleService: BsLocaleService,
    private router: Router,
    private clientService: ClientService,
    private translate: TranslateService,
    private utils: Utils,
    private previewService: PreviewService ) {
  }

  ngOnInit() {
    this.selectedLang = this.translate.currentLang;
    this.currentUserName = this.authService.getUsername();
    // this.isAdmin = this.authService.isAdmin();
    this.isAdmin = this.utils.isAdmin();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    moment.locale(lang);
    this.bsLocaleService.use(lang);
    this.selectedLang = lang;
    localStorage.setItem('backend_language', lang);
  }

  logout() {
    // clients will be reloaded with the next login. Otherwise selectedCLient will point at selectedCLient from the previous user session
    this.previewService.closeObservable().subscribe(data => {
      this.clientService.reloadClients = true;
      this.authService.logout();
      this.router.navigate(['login']);
    });
  }
}
