import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatter',
  pure: false
})
export class DateFormatterPipe implements PipeTransform {
  static readonly bsFormatterEN = 'MM-DD-YYYY';
  static readonly bsFormatterDE = 'DD-MM-YYYY';
  private readonly dateFormatterEN = 'MM-DD-YYYY, h:mm:ss A';
  private readonly dateFormatterDE = 'DD-MM-YYYY, H:mm';

  public static bsConfigFormatter(lang: any) {
    switch (lang) {
        case 'en': {
          return this.bsFormatterEN;
        }
        case 'de':
        default:
          return this.bsFormatterDE;
      }
  }

  constructor(private translateService: TranslateService) {
  }

  transform(date: any, showTime?: boolean): any {
    return date && date !== '' ? moment(new Date(date)).format(this.getDateFormatter(showTime)) : '';
  }

  private getDateFormatter(showTime?: boolean) {
    return this.convertDateFormatter(this.getDateFormatterByLang(), showTime);
  }

  private getDateFormatterByLang(): string {
    let lang = this.translateService.currentLang;
    lang = lang ? lang.toLowerCase() : '';
    switch (lang) {
      case 'en': {
        return this.dateFormatterEN;
      }
      case 'de':
      default:
        return this.dateFormatterDE;
    }
  }

  private convertDateFormatter(dateFormatter: string, showTime?: boolean): string {
    if (showTime) {
      return dateFormatter;
    } else {
      return dateFormatter.slice(0, 10);
    }
  }
}
