import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { ClientComponent } from './client.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientAddEditComponent } from './client-add-edit/client-add-edit.component';
import { ClientResolve } from '../client-guard.service';

const clientRoutes: Routes = [
  {
    path: 'client',
    component: ClientComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      { path: 'add', component: ClientAddEditComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard], data: { roles: ['ADMIN'] } },
      {
        path: 'edit/:id',
        component: ClientAddEditComponent, canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        data: { roles: ['ADMIN'] }
      },
      { path: 'list', component: ClientListComponent, canActivate: [AuthGuard], data: { roles: ['ADMIN', 'USER'] } }
    ]
  }
];

export const clientRouting = RouterModule.forChild(clientRoutes);
