import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { PoiComponent } from './poi.component';
import { PoiListComponent } from './poi-list/poi-list.component';
import { PoiAddEditComponent } from './poi-add-edit/poi-add-edit.component';
import { ClientResolve } from '../client-guard.service';
import { PoiCategoryListComponent } from './poi-category-list/poi-category-list.component';
import { PoiCategoryAddEditComponent } from './poi-category-add-edit/poi-category-add-edit.component';

const poiRoutes: Routes = [
  {
    path: 'poi',
    component: PoiComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      {
        path: 'add',
        component: PoiAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'edit/:id',
        component: PoiAddEditComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'list',
        component: PoiListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'categories/edit/:id',
        component: PoiCategoryAddEditComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'categories/add',
        component: PoiCategoryAddEditComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'categories',
        component: PoiCategoryListComponent,
        canActivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      }
    ]
  }
];

export const poiRouting = RouterModule.forChild(poiRoutes);
