import { BannerType, BannerAction } from './banner-types';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';

import { ClientService } from '@b4m/b4m-frontend-core';
import { Utils } from '../../shared/utils';
import { UploadService, AdvertisingService } from '@app/services';
import { Advertising, UploadResponse, UploadProgressType, AdvertisingType } from '../../models';
import { NgForm } from '@angular/forms';
import { DateFormatterPipe } from '@app/shared/pipes/date-formatter.pipe';

@Component({
  selector: 'app-advertising-add-edit',
  templateUrl: './advertising-add-edit.component.html',
  styleUrls: ['./advertising-add-edit.component.css']
})
export class AdvertisingAddEditComponent implements OnInit {
  public get bannerType(): typeof BannerType {
    return BannerType;
  }
  advertisingType: AdvertisingType;
  clientID: number | string;
  private sub: Subscription;
  editId: number = null;
  deviceGroupId;
  currentAdvertising = new Advertising(
    true, '', [null, null], null, null, null, '', { active: false },
    { active: false, image: null, websiteUrl: null },
    { active: false, image: null }
  );
  @ViewChild('advertisingForm') advertisingForm: NgForm;
  DateFormatterPipe = DateFormatterPipe;
  imageToDelete: number[] = [];
  imageToSave = [];

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    public utils: Utils,
    private uploadService: UploadService,
    private advertisingService: AdvertisingService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initAdType();
    this.sub = this.route.params.subscribe(params => {
      this.editId = params['id'];
      this.deviceGroupId = params['refererDeviceGroupId'];
      if (this.editId) {
        switch (this.advertisingType) {
          case AdvertisingType.COMMON_CLIENT:
            if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
              const clientID = this.clientService.getCurrentClient().id;
              this.advertisingService.getByID(clientID, this.editId).subscribe(ad => {
                this.currentAdvertising = ad.value;
              });
            }
            break;
          case AdvertisingType.GLOBAL:
            this.advertisingService.getGlobalByID(this.editId).subscribe(ad => {
              this.currentAdvertising = ad.value;
            });
            break;
        }
      }
    });
  }

  private initAdType() {
    if (this.router.url.includes('global')) {
      this.advertisingType = AdvertisingType.GLOBAL;
      this.clientID = 0;
    } else {
      this.advertisingType = AdvertisingType.COMMON_CLIENT;
      this.clientID = this.clientService.getCurrentClient().id;
    }
  }

  async onSubmit() {
    if (this.imageToSave && this.imageToSave.length > 0) {
      for (const f of this.imageToSave) {
        const result: UploadResponse = await this.uploadService.saveFile(f.data, this.clientID).toPromise();
        if (result.resType === UploadProgressType.BODY) {
          switch (f.type) {
            case BannerType.LOWER_BANNER: {
              this.currentAdvertising.image = result.content[0].key;
              break;
            }
            case BannerType.MAP_BANNER: {
              this.currentAdvertising.mapBanner.image = result.content[0].key;
              break;
            }
            case BannerType.SCREENSAVER: {
              this.currentAdvertising.screensaver.image = result.content[0].key;
              break;
            }
          }
        }
      }
      if (this.advertisingForm.value['name']) {
        if (this.editId) {
          this.updateOnSubmit();
        } else {
          this.saveOnSubmit();
        }
      }
    } else {
      if (this.editId) {
        this.updateOnSubmit();
      } else {
        this.saveOnSubmit();
      }
    }

    if (this.imageToDelete && this.imageToDelete.length > 0) {
      this.imageToDelete.forEach(data => {
        this.uploadService.delete(this.clientID, data).subscribe(() => { });
      });
    }
  }

  private saveOnSubmit() {
    switch (this.advertisingType) {
      case AdvertisingType.COMMON_CLIENT:
        this.advertisingService.create(this.clientID, this.currentAdvertising).subscribe(
          res => {
            if (this.deviceGroupId) {
              this.advertisingService.attach(this.clientID, this.deviceGroupId, res.id).subscribe(() => this.handleResponse(true));
            }
          },
          error => this.handleResponse(false)
        );
        break;
      case AdvertisingType.GLOBAL:
        this.advertisingService.createGlobal(this.currentAdvertising).subscribe(
          res => this.handleResponse(true),
          error => this.handleResponse(false)
        );
        break;
    }
  }

  private updateOnSubmit() {
    switch (this.advertisingType) {
      case AdvertisingType.COMMON_CLIENT:
        this.advertisingService.update(this.clientID, this.currentAdvertising, this.editId).subscribe(
          res => this.handleResponse(true),
          error => this.handleResponse(false)
        );
        break;
      case AdvertisingType.GLOBAL:
        this.advertisingService.updateGlobal(this.currentAdvertising, this.editId).subscribe(
          res => this.handleResponse(true),
          error => this.handleResponse(false)
        );
        break;
    }
  }

  private handleResponse(succeeded: boolean) {
    if (succeeded) {
      this.advertisingForm.form.reset();
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(this.getAdListUrl());
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  clearTimeRange() {
    this.currentAdvertising.timeFrom = null;
    this.currentAdvertising.timeTo = null;
  }

  validateTimeRange(): boolean {
    return (this.currentAdvertising.timeFrom === null && this.currentAdvertising.timeTo === null) ||
      (this.currentAdvertising.timeFrom !== null && this.currentAdvertising.timeTo !== null);
  }

  setLowerBanner(event: any) {
    this.currentAdvertising.lowerBanner = { active: event.active };
    this.currentAdvertising.image = event.image;
    this.currentAdvertising.websiteUrl = event.websiteUrl;
  }

  setMapBanner(event: any) {
    this.currentAdvertising.mapBanner = {
      active: event.active,
      image: event.image,
      websiteUrl: event.websiteUrl
    };
  }

  setScreensaver(event: any) {
    this.currentAdvertising.screensaver = {
      active: event.active,
      image: event.image
    };
  }

  isValidLowerBanner() {
    if (this.currentAdvertising.lowerBanner && this.currentAdvertising.lowerBanner.active) {
      return this.isValidBannerImage(this.currentAdvertising.image, BannerType.LOWER_BANNER) &&
        this.utils.validateURL(this.currentAdvertising.websiteUrl, false);
    } else {
      return true;
    }
  }

  isValidMapBanner() {
    if (this.currentAdvertising.mapBanner && this.currentAdvertising.mapBanner.active) {
      return this.isValidBannerImage(this.currentAdvertising.mapBanner.image, BannerType.MAP_BANNER) &&
        this.utils.validateURL(this.currentAdvertising.mapBanner.websiteUrl, false);
    } else {
      return true;
    }
  }

  isValidScreensaver() {
    if (this.currentAdvertising.screensaver && this.currentAdvertising.screensaver.active) {
      return this.isValidBannerImage(this.currentAdvertising.screensaver.image, BannerType.SCREENSAVER);
    } else {
      return true;
    }
  }

  manageImage(event: any) {
    switch (event.action) {
      case BannerAction.ADD: {
        this.imageToSave.push({ data: event.data, type: event.type });
        break;
      }
      case BannerAction.DELETE: {
        this.imageToDelete.push(event.data);
        break;
      }
      case BannerAction.ADD_REMOVE: {
        this.imageToSave.splice(event.data, 1);
        break;
      }
    }
  }

  private isValidBannerImage(image: any, type: BannerType): boolean {
    return image || this.imageToSave.some(i => i.type === type);
  }

  getAdListUrl() {
    return [`/${this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments[0].path}/list`];
  }
}
