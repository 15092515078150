import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatUTC'
})
export class DateFormatUTCPipe implements PipeTransform {

  transform(value: Date[], args?: any): any {
    if (!this.isValid(value) || value.some(v => !this.isValid(v))) {
      return value;
    }
    const converted = value.map(date => {
      return this.isValidDate(new Date(date.toLocaleString())) ? new Date(date.toLocaleString()) : new Date(date.toString());
    });
    return converted.length === 1 ? converted[0] : converted;
  }

  private isValid(value: any): boolean {
    return value !== undefined && value !== null;
  }

  private isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime());
  }
}
