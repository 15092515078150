import { DeviceGroupService } from './../../services/device-group.service';
import { Widget } from './../../models/widget';
import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@app/shared/utils';
import { MainPageService } from '@app/services';
import { ThemeColors } from '@app/models';
import {ClientService} from '@b4m/b4m-frontend-core';

enum WidgetContentType {
  STANDARD, SIMPLE, EXTENDED, MAP
}

class WidgetContent {
  private static All: WidgetContent[] = [];

  static readonly STANDARD = new WidgetContent(WidgetContentType.STANDARD, [
    'web', 'normal', 'weather', 'forecast', 'feedback',
    'slideshow', 'sms', 'email', 'events', 'dish-set'
  ]);
  static readonly SIMPLE = new WidgetContent(WidgetContentType.SIMPLE, ['static']);
  static readonly EXTENDED = new WidgetContent(WidgetContentType.EXTENDED, ['flip']);
  static readonly MAP = new WidgetContent(WidgetContentType.MAP, ['map']);

  constructor(
    public readonly contentType: WidgetContentType,
    public readonly types: string[]) {
    WidgetContent.All.push(this);
  }

  public static all(): WidgetContent[] {
    return WidgetContent.All;
  }

  public static getContentType(type: string): WidgetContent {
    return this.all().find(v => v.types.includes(type));
  }
}

@Component({
  selector: 'app-widget-live-preview',
  templateUrl: './widget-live-preview.component.html',
  styleUrls: ['./widget-live-preview.component.css']
})
export class WidgetLivePreviewComponent implements OnInit {

  @Input() widgetData: any;
  WidgetContentType = WidgetContentType;
  widget: Widget;
  widgetContent: WidgetContent;

  private colors: ThemeColors;
  private imageUrl: string;
  private selectedLanguageKey = '_';

  constructor(
    private clientService: ClientService,
    private utils: Utils,
    private mainPageService: MainPageService,
    private deviceGroupService: DeviceGroupService,
  ) { }

  ngOnInit() {
    if (this.widgetData && this.widgetData.value) {
      this.widget = this.widgetData.value;
      this.widgetContent = WidgetContent.getContentType(this.widget.type);
      this.setImage();
      this.getMainPage();
    }
  }

  getBackground() {
    return {
      'background-image': this.getBackgroundImage(),
      'background-position': this.getBackgroundPosition(),
      'height': '100%',
    };
  }

  private getMainPage() {
    this.mainPageService.get(this.clientService.getCurrentClient().id,
      this.deviceGroupService.currentDeviceGroup.id).subscribe(c => {
        this.colors = c && c.value ? c.value.colors : null;
      });
  }

  private setImage() {
    let image;
    this.imageUrl = '';
    if (typeof this.widget.image !== 'object') {
      image = this.widget.image;
    } else {
      image = this.widget.image._;
      if (this.widget.image[this.selectedLanguageKey] && this.widget.image[this.selectedLanguageKey] != null) {
        image = this.widget.image[this.selectedLanguageKey];
      }
    }
    if (image) {
      this.imageUrl = this.utils.buildAwsImageURL(this.clientService.getCurrentClient().id, image);
    }
  }

  private getBackgroundImage(): string {
    if (this.imageUrl && this.imageUrl !== '') {
      return 'url(' + this.imageUrl + ')';
    }
    return '';
  }

  private getBackgroundPosition() {
    if (this.widget && this.widget['centerImage']) {
      return 'center';
    }
    return 'left top';
  }

  getExtendedDescription() {
    if (this.widget.description) {
      return this.widget.description;
    } else if (this.widget.shortDescription) {
      return this.widget.shortDescription;
    }
  }

  setSimpleFontStyle() {
    return this.colors ? {
      'color': this.colors.fontLight,
    } : {};
  }

  setStandardFontStyle() {
    return this.colors ? {
      'color': this.colors.main,
    } : {};
  }

  setExtendedFontStyle() {
    return this.colors ? {
      'background-color': this.colors.main,
      'color': this.colors.fontLight,
    } : {};
  }

  setMapFontStyle() {
    return this.colors ? {
      'background-color': this.colors.main,
      'color': this.colors.fontLight,
    } : {};
  }
}
