import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService, WidgetService, PageService, AclService } from '../../services';
import { ClientService, AuthService } from '@b4m/b4m-frontend-core';
import { Feedback, Content } from '../../models';
import { Utils } from '../../shared/utils';
import { DateFormatterPipe } from '@app/shared/pipes/date-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';

export class SortedFeedback {
  constructor(
    public widgetId: number,
    public widgetKey: string,
    public widgetName: any,
    public questions: any[],
    public feedbackList: Feedback[],
    public choiceQuestion: any
  ) { }
}

@Component({
  selector: 'app-feedback-analytics',
  templateUrl: './feedback-analytics.component.html',
  styleUrls: ['./feedback-analytics.component.css']
})
export class FeedbackAnalyticsComponent implements OnInit {

  sortedFeedbackList: SortedFeedback[] = [];
  filteredSortedFeedbackList: SortedFeedback[] = [];
  dateRange = null;
  clientID: string | number;
  selectedSortedFeedback: SortedFeedback;
  aclUserAuthorities: any;
  choiceChartData = [];
  chartOptions = {
    plugins: {
      labels: {
        render: 'value'
      }
    }
  };
  DateFormatterPipe = DateFormatterPipe;

  constructor(
    private feedbackService: FeedbackService,
    private clientService: ClientService,
    private widgetService: WidgetService,
    private pageService: PageService,
    private aclService: AclService,
    private authService: AuthService,
    private utils: Utils,
    private router: Router,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.clientID = this.clientService.getCurrentClient().id;
    this.widgetService.getAllByType(this.clientID, 'feedback').subscribe(widgets => {
      if (widgets) {
        this.feedbackService.getFeedbackByClientId(this.clientID).subscribe((result) => {
          const groupedFeedbacks = this.groupFeedbackByWidgets(result, widgets);
          this.sortedFeedbackList = groupedFeedbacks;
          this.filteredSortedFeedbackList = groupedFeedbacks;
          this.setupPieChartData();
        });

        this.aclService.getUserAuthorities(this.authService.getUsername()).subscribe(authorities => {
          if (authorities) {
            this.aclUserAuthorities = authorities;
          }
        });
      }
    });
  }

  private groupFeedbackByWidgets(feedbackList: Feedback[], feedbackWidgetList: any[]) {
    const result = [];
    for (const widget of feedbackWidgetList) {
      const sortedFeedBack = new SortedFeedback(
        widget.id,
        widget.key,
        typeof widget.value['title'] === 'object' ? widget.value['title']._ : widget.value['title'],
        widget.value['questions'],
        feedbackList.filter(feedback => feedback && feedback.widgetId === widget.id),
        widget.value['choiceQuestion'] ? widget.value['choiceQuestion'] : null
      );
      result.push(sortedFeedBack);
    }
    return result;
  }

  private getFilteredFeedbackList() {
    if (this.dateRange) {
      const result = [];
      for (const sortedFeedbacks of this.sortedFeedbackList) {
        const feedbackListResult = sortedFeedbacks.feedbackList.filter(feedback => {
          const date = feedback.date;
          const startDate = new Date(this.dateRange[0]).setHours(0, 0, 0, 0);
          const endDate = new Date(this.dateRange[1]).setHours(23, 59, 59, 999);
          return date >= startDate && date <= endDate;
        });
        const sortedFeedbackResult = new SortedFeedback(sortedFeedbacks.widgetId, sortedFeedbacks.widgetKey,
          sortedFeedbacks.widgetName, sortedFeedbacks.questions, feedbackListResult, sortedFeedbacks.choiceQuestion);
        result.push(sortedFeedbackResult);
      }
      return result;
    } else {
      return this.sortedFeedbackList;
    }
  }

  private setupPieChartData() {
    this.choiceChartData = [];
    const sortedFeedbacks = this.getFilteredFeedbackList();
    for (const sortedFeedback of sortedFeedbacks) {
      if (sortedFeedback.choiceQuestion && sortedFeedback.choiceQuestion.active) {
        const choices = sortedFeedback.choiceQuestion.choices;
        for (const choice of choices) {
          const filteredFeedbacks = sortedFeedback.feedbackList.filter(feedback => {
            if (feedback.questionAnswer.choiceQuestion && feedback.questionAnswer.choiceQuestion.answer._ === choice._
              && feedback.questionAnswer.choiceQuestion.question._ === sortedFeedback.choiceQuestion.question._) {
              return true;
            }
            return false;
          });
          this.choiceChartData.push({widgetId: sortedFeedback.widgetId, label: choice._,
            result: filteredFeedbacks.length});
        }
      }
    }
  }

  onDateChange() {
    this.filteredSortedFeedbackList = this.getFilteredFeedbackList();
    this.setupPieChartData();
  }

  resetDateRange() {
    this.dateRange = null;
    this.onDateChange();
  }

  getQuestionAnswered(sortedFeedback: SortedFeedback) {
    const questionAnswers = new Array();
    for (const feedback of sortedFeedback.feedbackList) {
      if (feedback.questionAnswer) {
        for (const key in feedback.questionAnswer) {
          if (feedback.questionAnswer.hasOwnProperty(key) && key !== 'choiceQuestion' && feedback.questionAnswer[key]) {
            const temp = {question: key, answer: feedback.questionAnswer[key]};
            questionAnswers.push(temp);
          }
        }
      }
    }
    return questionAnswers.length;
  }

  getAverageResult(sortedFeedback: SortedFeedback) {
    let sum = 0;
    let quantity = 0;
    for (const feedback of sortedFeedback.feedbackList) {
      for (const question of sortedFeedback.questions) {
        const rate = feedback.questionAnswer[typeof question === 'object' ? question._ : question];
        if (rate) {
          quantity++;
          sum = sum + rate;
        }
      }
    }
    return quantity === 0 ? '-' : (Math.round(sum / quantity * 100) / 100).toFixed(2);
  }

  getTopRatedQuestions(sortedFeedback: SortedFeedback) {
    const questionsRate = this.getQuestionsRateArray(sortedFeedback);
    questionsRate.sort(this.compareValues('rate', 'desc'));
    return questionsRate.length > 3 ? questionsRate.slice(0, 3) : questionsRate;
  }

  getWorstRatedQuestions(sortedFeedback: SortedFeedback) {
    const questionsRate = this.getQuestionsRateArray(sortedFeedback);
    questionsRate.sort(this.compareValues('rate', 'asc'));
    return questionsRate.length > 3 ? questionsRate.slice(0, 3) : questionsRate;
  }

  getQuestionsRateArray(sortedFeedback: SortedFeedback) {
    const questionsRate = new Array();
    for (const question of sortedFeedback.questions) {
      let sum = 0;
      let quantity = 0;
      for (const feedback of sortedFeedback.feedbackList) {
        const rate = feedback.questionAnswer[typeof question === 'object' ? question._ : question];
        if (rate) {
          quantity++;
          sum = sum + rate;
        }
      }
      if (quantity > 0) {
        questionsRate.push({question: typeof question === 'object' ? question._ : question,
          rate: (Math.round(sum / quantity * 100) / 100).toFixed(2)});
      }
    }
    return questionsRate;
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  checkViewEditRight(sortedFeedBack: SortedFeedback) {
    this.widgetService.getAllPageContentByWidget(this.clientID, sortedFeedBack.widgetId).subscribe(pageContents => {
      console.log(pageContents);
      const pageContentsWithRight = new Array();
      for (const pageContent of pageContents) {
        if (this.checkAclRights('PAGE_VIEW_' + pageContent.page.id) || this.checkAclRights('PAGE_EDIT_' + pageContent.page.id)) {
          pageContentsWithRight.push(pageContent);
        }
      }
      if (pageContentsWithRight.length > 0) {
        this.router.navigate(['/widgets/edit/' + sortedFeedBack.widgetId + '/' + pageContentsWithRight[0].page.id]);
      } else {
        this.utils.displayGrowlMessage('danger', 'lists.acl.noRights', '');
      }
    });
  }

  checkAclRights(authority: string) {
    if (this.aclUserAuthorities && this.aclUserAuthorities.aclAuthorities) {
      return this.aclUserAuthorities.aclAuthorities.indexOf(authority) > -1 || this.utils.isUserRightsAdmin() || this.utils.isAdmin();
    } else {
      return false;
    }
  }

  getChoiceChartData(widgetId) {
    const result = [];
    for (const data of this.choiceChartData) {
      if (data.widgetId === widgetId) {
        result.push(data.result);
      }
    }
    return result;
  }

  getChoiceChartLabels(widgetId) {
    const result = [];
    for (const data of this.choiceChartData) {
      if (data.widgetId === widgetId) {
        result.push(data.label);
      }
    }
    return result;
  }

  checkDataAllZeros(widgetId) {
    const array = this.getChoiceChartData(widgetId);
    return array.every(item => item === 0) || array === [];
  }

}
