import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MakiIconsComponent } from './maki-icons.component';
import { SharedModule } from '../shared/shared.module';
import { MakiIconsService } from '../services';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [MakiIconsComponent],
  exports: [MakiIconsComponent],
  providers: [MakiIconsService]
})
export class MakiIconsModule {}
