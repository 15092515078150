import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { CustomModalComponent } from '../../../custom-modal/custom-modal.component';
import { Utils } from '../../../shared/utils';
import { Pageable } from '../../../shared/pagination/pagination.component';
import { RemoteAccessService } from '../../../services';

@Component({
  selector: 'app-device-ssh-list',
  templateUrl: 'device-ssh-list.component.html',
  styleUrls: ['device-ssh-list.component.css']
})
export class DeviceSshListComponent implements OnInit, OnDestroy {

  deviceSSHDataList: any[];
  private pageable = new Pageable(0, 'status.status', 'asc');

  @ViewChild('modal')
  private modal: CustomModalComponent;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private remoteAccessService: RemoteAccessService, private utils: Utils) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.remoteAccessService.getAllDeviceSSHData(this.pageable).subscribe(res => {
      this.deviceSSHDataList = res;
    });
  }

  updateSorting(sort: string) {
    if (sort !== this.pageable.sort) {
      this.pageable.sort = sort;
      this.pageable.direction = 'asc';
    } else {
      this.switchDirection();
    }
    this.pageable.page = 0;
    this.getAll();
  }

  updatePage(page: number) {
    this.pageable.page = page;
    this.getAll();
  }

  removeDeviceSSHData(deviceSSHDataId: number) {
    this.modal.closeModal();
    this.remoteAccessService.deleteDeviceSSHData(deviceSSHDataId).takeUntil(this.ngUnsubscribe).subscribe(res => {
      this.getAll();
    },
    error => {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
    });
  }

  private switchDirection() {
    this.pageable.direction = this.pageable.direction === 'asc' ? 'desc' : 'asc';
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
