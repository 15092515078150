export const cards = {
    c1: {
        datasets: [{ data: [], label: '' }],
        labels: [],
        dimension: 'ga:date;ga:date;ga:date;ga:date',
        metric: 'ga:1dayUsers;ga:7dayUsers;ga:14dayUsers;ga:28dayUsers',
        type: 'line',
        legend: true,
        colors: [
            {
                borderColor: 'rgb(179, 0, 0, 0.55)',
                backgroundColor: 'rgba(255,255,255,0.0)',
            },
            {
                borderColor: 'rgb(0, 0, 102, 0.55)',
                backgroundColor: 'rgba(255,255,255,0.0)',
            },
            {
                borderColor: 'rgb(230, 0, 172, 0.55)',
                backgroundColor: 'rgba(255,255,255,0.0)',
            },
            {
                borderColor: 'rgb(0, 179, 0, 0.55)',
                backgroundColor: 'rgba(255,255,255,0.0)',
            },
        ],
        options: {
            elements: {
                line: {
                    tension: 0
                }
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    },
};
