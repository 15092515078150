import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageListComponent } from './page/page-list/page-list.component';
import { PoiComponent } from './poi/poi.component';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { ClientResolve } from './client-guard.service';
import { LoginComponent } from './login/login.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'poi', component: PoiComponent, canActivate: [AuthGuard], resolve: [ClientResolve], data: { roles: ['ADMIN', 'USER'] } },
  { path: '', component: PageListComponent, canActivate: [AuthGuard], resolve: [ClientResolve], data: { roles: ['ADMIN', 'USER'] } },
  { path: '**', component: PageNotFoundComponent }
];

export const appRoutingProviders: any[] = [

];

export const routing = RouterModule.forRoot(appRoutes);
