import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Content, Events, EventContent } from '../models';
import { WidgetType } from '@app/models/widget-type';

@Injectable()
export class EventsService {
  private baseURL = environment.contentService;
  private eventContentURL = environment.eventContent;

  constructor(private api: ApiService) { }

  getAll(clientID: number | string): Observable<EventContent[]> {
    return this.api.getData(this.eventContentURL + '?clientId=' + clientID);
  }

  getByID(clientID: number | string, eventID: number): Observable<Content> {
    return this.api.getData(this.baseURL + '/' + clientID + '/' + eventID);
  }

  create(clientID: number | string, event: Events): Observable<Content> {
    const url = this.baseURL + '/' + clientID;
    const bodyObj = {
      key: this.createKey(clientID),
      value: event
    };
    return this.api.postData(url, bodyObj);
  }

  update(clientID: number | string, event: Events, eventID: number, isGoogleEvent: boolean): Observable<Content> {
    const url = this.eventContentURL + '?clientId=' + clientID + '&isGoogleEvent=' + isGoogleEvent;
    const bodyObj = {
      key: this.createKey(clientID),
      value: event
    };
    if (eventID) {
      bodyObj['id'] = eventID;
    }
    return this.api.putData(url, bodyObj);
  }

  delete(clientID: number | string, id: number, isGoogleEvent: boolean): Observable<null> {
    const url = this.eventContentURL;
    const param: RequestParam[] = [ new RequestParam('clientId', JSON.stringify(clientID)),
      new RequestParam('isGoogleEvent', JSON.stringify(isGoogleEvent))];
    return this.api.deleteData(url, id, ...param);
  }

  store(clientID: number | string, update: boolean, event: Events, eventID: number = null, isGoogleEvent: boolean): Observable<Content> {
    if (update) {
      return this.update(clientID, event, eventID, isGoogleEvent);
    } else {
      return this.create(clientID, event);
    }
  }

  deleteOutdatedEvents(clientID: number | string) {
    const url = this.eventContentURL + '/' + clientID + '/expired';
    const requestParam = new RequestParam('key', this.createKey(clientID));
    return this.api.deleteData(url, null, requestParam);
  }

  private createKey(clientId: number | string): string {
    return WidgetType.EVENTS.type + '.' + clientId;
  }
}
