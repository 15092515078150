import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';

@Injectable({
  providedIn: 'root'
})
export class GoogleCalendarService {

  private oAuth2URL = environment.googleOAuth2;
  private integrationUrl = environment.googleIntegrationUrl;
  private googleCalendarsUrl = environment.googleCalendars;
  private googleCalendarsRegisteredUrl = environment.googleCalendarsRegistered;

  constructor( private api: ApiService ) { }

  public getGoogleAuthUrl(): Observable<any>  {
    return this.api.getData(this.oAuth2URL);
  }

  public getGoogleCalendars(clientID, integrationId): Observable<any>  {
    const param: RequestParam[] = [ new RequestParam('clientId', clientID), new RequestParam('integrationId', integrationId) ];
    return this.api.getData(this.googleCalendarsUrl, ...param);
  }

  public getIntegrations(clientID): Observable<any>  {
    const param: RequestParam[] = [ new RequestParam('clientId', clientID) ];
    return this.api.getData(this.integrationUrl, ...param);
  }

  public integrateAccount(clientID, code, name): Observable<any> {
    const url = this.integrationUrl + '/integrate';
    const param: RequestParam[] = [ new RequestParam('code', code), new RequestParam('clientId', clientID),
      new RequestParam('name', name) ];
    return this.api.postData(url, {}, ...param);
  }

  public disintegrateAccount(integrationId, clientID) {
    const url = this.integrationUrl + '/' + integrationId + '/disintegrate';
    const param: RequestParam[] = [ new RequestParam('clientId', clientID) ];
    return this.api.postData(url, {}, ...param);
  }

  public registerCalendar(calendarId, clientID, integrationId) {
    const url = this.googleCalendarsRegisteredUrl + '/register';
    const param: RequestParam[] = [ new RequestParam('calendarId', encodeURIComponent(calendarId)), new RequestParam('clientId', clientID),
      new RequestParam('integrationId', integrationId) ];
    return this.api.postData(url, {}, ...param);
  }

  public unregisterCalendar(id, calendarId, clientID, integrationId): Observable<any> {
    const url = this.googleCalendarsRegisteredUrl + '/' + id + '/unregister';
    const param: RequestParam[] = [ new RequestParam('calendarId', calendarId), new RequestParam('clientId', clientID),
      new RequestParam('integrationId', integrationId) ];
    return this.api.postData(url, {}, ...param);
  }

  public unregisterAllCalendars(integrationId, clientID): Observable<any> {
    const url = this.googleCalendarsRegisteredUrl + '/unregister/integration';
    const param: RequestParam[] = [ new RequestParam('clientId', clientID), new RequestParam('integrationId', integrationId) ];
    return this.api.postData(url, {}, ...param);
  }
}
