export class Slide {
  constructor(
    public key: string = '',
    public hotspotX1: number = 0,
    public hotspotY1: number = 0,
    public hotspotX2: number = 100,
    public hotspotY2: number = 100,
    public hotspotWidgetId: number = null
  ) { }
}
