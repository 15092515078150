import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services';
import { ApiService, AuthService } from '@b4m/b4m-frontend-core';
import { JwtHelperService } from '@auth0/angular-jwt';

export class I18NSearchObj {
  container: any;
  containerPath: Array<string>;
  attr: string;
  value: any;
  constructor(root: any, dottedName: string) {
    let obj: any = root;
    const path: Array<string> = dottedName.split('.');
    let container: any;
    this.containerPath = path.slice(0, path.length - 1);
    let attr: string;
    path.forEach(part => {
      if (part in obj) {
        container = obj;
        attr = part;
        obj = obj[part];
      } else {
        container = attr = obj = null;
      }
    });
    this.container = container;
    this.attr = attr;
    this.value = obj;
  }
}

@Injectable()
export class Utils {

  displayGrowlMessage$: EventEmitter<any> = new EventEmitter();

  private urlPattern = new RegExp('^(https?:\/\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\:\\d+)?(\/[-a-z\\d%#_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#\/{0,1}[-a-z\\d_]*)?$', 'i'); // fragment locater

  private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private translate: TranslateService,
              private api: ApiService,
              private authService: AuthService,
              private languageService: LanguageService
              ) { }

  /**
	 * getTranslation
	 * returns translated content in selected language for provided key or keys value if didn't found any translation
	 * @param key : key to translated content
	 */
  getTranslation(key: string) {
    let translation = '';
    if (key && key !== '') {
      this.translate.get(key).subscribe((res: string) => {
        translation = res;
      });
    }
    return translation;
  }

  displayGrowlMessage(severity: string, summary: string, detail: string) {
    this.displayGrowlMessage$.emit({ severity: severity, summary: this.getTranslation(summary), detail: this.getTranslation(detail) });
  }

  buildAwsImageURL(clientID: number | string, key: string) {
    return 'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/' + clientID + '/' + key;
  }

  validateURL(str, required?: boolean): boolean {
    if ((!required && (!str || str === '')) || this.urlPattern.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  keepAlive(): Promise<any> {
    return this.api.getData('/account/whoami').toPromise();
  }

  getHueFromString(str: string) {
    const maxSafeInt = 1049168324;
    let hash = 0;
    str += 'x';
    for (let i = 0; i < str.length; i++) {
      if (hash > maxSafeInt) {
        hash = parseInt(hash / 8585085 + '', 10);
      }
      hash = hash + (131 * str.charCodeAt(i));
    }
    return hash % 359;
  }

  transformI18nValue(value: any, args?: any): any {
    /** Get current language */
    let lang = '';
    if ((!args || typeof args !== 'string') && !this.languageService.currentLanguage) {
      throw new Error('Exception: Missing or Wrong language key to get current lang value.');
    } else if (args && typeof args === 'string') {
      lang = args;
    } else if (this.languageService.currentLanguage) {
      lang = this.languageService.currentLanguage;
    }

    /** Check if value is a string */
    if (value && typeof value === 'string') {
      return value;
    }

    /** Check if value is an image object */
    if (value && typeof value !== 'undefined' && value[lang] && value !== '' && value[lang] !== '' && value[lang].value) {
      return value[lang].value;
    } else if (value && value['_'] && value['_'].value) {
      return value['_'].value;
    }

    /** Continue checking if value is an i18n object */
    if (value && typeof value !== 'undefined' && value[lang] && value !== '' && value[lang] !== '') {
      return value[lang];
    } else if (value && value['_']) {
      return value['_'];
    } else if (value && typeof value === 'string') {
      return value;
    }

    return '';
  }

  getDefaultIfExists(value: any) {
    if (value && value['_']) {
      return value['_'];
    } else {
      return '';
    }
  }

  getI18NAttribute(context, dottedName: string) {
    const searchObj = new I18NSearchObj(context, dottedName);
    let result = searchObj.value;
    if (searchObj.attr && searchObj.container[searchObj.attr]) {
      this.synchronizeDefault(context, searchObj.container[searchObj.attr]);
      if (result && typeof result === 'object') {
        if (searchObj.container[searchObj.attr][context.selectedLanguageKey] !== '') {
          result = searchObj.container[searchObj.attr][context.selectedLanguageKey];
        } else {
          if (context.languageSelector.languages[0].key === context.selectedLanguageKey) {
            result = searchObj.container[searchObj.attr]._;
          } else {
            result = '';
          }
        }
      }
    }
    if (!result) {
      result = '';
    }
    return result;
  }

  setI18NAttribute(context, dottedName, event) {
    const searchObj = new I18NSearchObj(context, dottedName);
    if (typeof searchObj.container[searchObj.attr] !== 'object') {

      searchObj.container[searchObj.attr] = {};
    }
    searchObj.container[searchObj.attr][context.selectedLanguageKey] = event;
    if (context.languageSelector.languages[0].key === context.selectedLanguageKey) {
      searchObj.container[searchObj.attr]._ = event;
    }
    this.setObj(context, searchObj.containerPath, searchObj.container);
  }

  private setObj(context, path, value) {
    let obj = context;
    for (let i = 0; i < path.length - 1; i++) {
      const part = path[i];
      if (part in obj) {
        obj = obj[part];
      }
    }
    obj[path[path.length - 1]] = value;
  }

  synchronizeDefault(context, param) {
    if (context.selectedLanguageKey !== '_' && context.languageSelector.languages[0].key === context.selectedLanguageKey) {
      if (typeof param === 'object') {
        if ((!param[context.selectedLanguageKey] || param[context.selectedLanguageKey] === '') && param._ && param._ !== '') {
          param[context.selectedLanguageKey] = param._;
        } else if (
          param[context.selectedLanguageKey] &&
          param[context.selectedLanguageKey] !== '' &&
          param[context.selectedLanguageKey] !== param._
        ) {
          param._ = param[context.selectedLanguageKey];
        }
      } else {
        if ((!param[context.selectedLanguageKey] || param[context.selectedLanguageKey] === '') && param && param !== '') {
          const value = param;
          param = {};
          param[context.selectedLanguageKey] = value;
          param._ = value;
        }
      }
    }
  }

  getPlaceholder(context, dottedName, i18nKey): string {
    let placeholder = '';
    const searchObj = new I18NSearchObj(context, dottedName);
    placeholder = searchObj.value;
    if (placeholder && typeof placeholder === 'object') {
      placeholder = searchObj.container[searchObj.attr]._;
    }
    if (!placeholder || placeholder === '') {
      this.translate.get(i18nKey).subscribe((res: string) => {
        placeholder = res;
      });
    }
    return placeholder;
  }

  isAdmin() {
    return this.jwtHelper.decodeToken(this.authService.token).auth.split(',').indexOf('ADMIN') !== -1;
  }

  isUserRightsAdmin() {
    return this.jwtHelper.decodeToken(this.authService.token).auth.split(',').indexOf('USER_RIGHTS_ADMIN') !== -1;
  }

  isSystemAdmin() {
    return this.jwtHelper.decodeToken(this.authService.token).auth.split(',').indexOf('SYSTEM_ADMIN') !== -1;
  }

}

