import { GoogleAnalyticsFilterDateComponent } from './../components/google-analytics-filter-date/google-analytics-filter-date.component';
import { GoogleAnalyticsService, UtilsService } from '@app/services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { cards } from './google-analytics-panel-audience.data';
import { GoogleAnalyticsFilterModalComponent } from '../components/google-analytics-filter-modal/google-analytics-filter-modal.component';

@Component({
  selector: 'app-google-analytics-panel-audience',
  templateUrl: './google-analytics-panel-audience.component.html',
  styleUrls: [
    './google-analytics-panel-audience.component.css',
    '../../google-analytics-dashboard.component.css'
  ]
})
export class GoogleAnalyticsPanelAudienceComponent implements OnInit {
  @ViewChild(GoogleAnalyticsFilterModalComponent) filterModal: GoogleAnalyticsFilterModalComponent;
  @ViewChild(GoogleAnalyticsFilterDateComponent) filterDate: GoogleAnalyticsFilterDateComponent;
  gaReports = null;
  cards = cards;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    public utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.convertCardsC4();
    this.loadData();
  }

  loadData() {
    const metric = this.cards.c1.metric + ',' + this.cards.c2.metric + ',' + this.cards.c3.metric + ',' + this.cards.c4.metric;
    this.googleAnalyticsService.getReportsData(this.cards.c1.dimension, metric,
      this.filterDate.dateRange[0], this.filterDate.dateRange[1], this.filterModal.getFilterIds()).subscribe(reports => {
        this.gaReports = reports;
        for (const i of [1, 2, 3, 4]) {
          const card = this.cards['c' + i];
          card.data = this.getData('c' + i);
          card.labels = this.getLabels('c' + i);
          if (card.dataType === 'double') {
            card.data = this.roundData(card.data);
          }
        }
      });
  }

  private getData(c: string) {
    const dimensions = this.googleAnalyticsService.getDimensionByType(this.utilsService.clone(this.gaReports), this.cards[c].dimension);
    if (dimensions && dimensions.length > 0) {
      const metric = this.googleAnalyticsService.getMetricByDimensionAndType(dimensions[0], this.cards[c].metric);
      this.cards[c].metricData = metric;
      return metric.values;
    } else {
      return [];
    }
  }

  private getLabels(c: string) {
    const dimensions = this.googleAnalyticsService.getDimensionByType(this.utilsService.clone(this.gaReports), this.cards[c].dimension);
    if (dimensions && dimensions.length > 0) {
      if (dimensions[0].values && dimensions[0].values.length > 0) {
        return dimensions[0].values.map(date => {
          const y = date[0].substring(0, 4);
          const m = date[0].substring(4, 6);
          const d = date[0].substring(6, 8);
          return d + '/' + m + '/' + y;
        });
      }
    }
    return [];
  }

  private roundData(metricValues) {
    return metricValues.map(v => this.utilsService.round(v, 2));
  }

  resetFilter() {
    this.filterModal.resetFilter();
    this.loadData();
  }

  convertDateValue(value): string {
    const v = this.utilsService.convertSecondsToTime(value);
    if (!v || v === '') {
      return '0s';
    } else {
      return v;
    }
  }

  private convertCardsC4() {
    const ticks = {
      beginAtZero: true,
      callback: label => `${this.utilsService.convertSecondsToTime(label)}`
    };
    const tooltips = {
      callbacks: {
        label: (tooltipItem, data) => {
          return this.utilsService.convertSecondsToTime(data['datasets'][0]['data'][tooltipItem['index']]);
        },
      },
    };
    this.cards.c4.options.scales.yAxes[0].ticks = ticks;
    this.cards.c4.options.tooltips = tooltips;
  }
}
