import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DeviceGroupService } from '../../services';
import { DeviceGroup } from '../../models';
import { ClientService } from '@b4m/b4m-frontend-core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-device-group-selector',
  templateUrl: 'device-group-selector.component.html',
  styleUrls: ['device-group-selector.component.css']
})
export class DeviceGroupSelectorComponent implements OnInit {
  deviceGroups: Observable<DeviceGroup[]>;
  currentDeviceGroup: DeviceGroup = new DeviceGroup(true, null, '', null, [], '');
  deviceGroupsLength = 0;
  constructor(
    private deviceGroupService: DeviceGroupService,
    private clientService: ClientService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    // Subscribe to current client to be informed on updates.
    this.clientService.currentClient$.subscribe(client => {
      this.deviceGroups = this.getDeviceGroupsObservable(client.id);
    });

    // If there is still a current client set it in the component.
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.deviceGroups = this.getDeviceGroupsObservable(this.clientService.getCurrentClient().id);
    }

    // Subscribe to device group service to get informed on updates.
    this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {

      this.currentDeviceGroup = dt;
    });

    // If there is a current device group selected set it in the component.
    if (this.deviceGroupService.currentDeviceGroup) {
      this.currentDeviceGroup = this.deviceGroupService.currentDeviceGroup;
    }

    this.deviceGroupService.switchDeviceGroupPermission$.subscribe(res => {
      this.changeDeviceGroup(res);
    });
  }

  requestDeviceGroupSwitch(deviceGroupId) {
    if (this.route.routeConfig.canDeactivate) {
      this.deviceGroupService.requestDeviceGroupSwitch(deviceGroupId);
    }
    this.changeDeviceGroup(deviceGroupId);
  }

  changeDeviceGroup(id: number) {
    // get device group and set it in the service to inform all subscribers for change.
    this.deviceGroupService.getByID(id, this.clientService.getCurrentClient().id).subscribe(
      dt => {
        this.deviceGroupService.setCurrentDeviceGroup(dt);
      }
    );
  }

  private getDeviceGroupsObservable(clientID: number | string) {
    const obs = this.deviceGroupService.getAll(clientID);
    obs.subscribe(dts => {
      this.deviceGroupsLength = dts.length;
      if (dts[0]) {
        this.deviceGroupService.setCurrentDeviceGroup(dts[0]);
      } else {
        this.deviceGroupService.setCurrentDeviceGroup(new DeviceGroup(true, null, '', null, [], ''));
      }
    });
    return obs;
  }

}
