import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { DeviceComponent } from './device.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceAddEditComponent } from './device-add-edit/device-add-edit.component';
import { deviceRouting } from './device.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { WatchdogModule } from '../watchdog/watchdog.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';
import { DeviceService } from '../services';

@NgModule({
  imports: [CommonModule,
    deviceRouting,
    FormsModule,
    BrowserModule,
    TranslateModule,
    CustomModalModule,
    WatchdogModule,
    SharedModule],
  declarations: [DeviceComponent, DeviceListComponent, DeviceAddEditComponent],
  exports: [DeviceComponent],
  providers: [AuthGuard, DeviceService, Utils]
})
export class DeviceModule { }
