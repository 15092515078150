import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  public clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  public round(value: string, precision: number): string {
    const multiplier = Math.pow(10, precision);
    return ((Math.round(Number(value) * multiplier)) / multiplier ).toFixed(precision);
  }

  public convertSecondsToTime(value: string): string {
    return this.convertSecondsArrayToTime([value])[0];
  }

  public convertSecondsArrayToTime(values: string[]): string[] {
    return values.map(metric => {
      const d = Number(metric);
      const h = Math.floor(d / 3600);
      const m = Math.floor(d % 3600 / 60);
      const s = Math.floor(d % 3600 % 60);

      const hDisplay = h > 0 ? h + 'h ' : '';
      const mDisplay = m > 0 ? m + 'm ' : '';
      const sDisplay = s > 0 ? s + 's' : '';

      return hDisplay + mDisplay + sDisplay;
    });
  }
}
