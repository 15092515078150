import { Component, Input } from '@angular/core';
import { Widget } from '../../../models';

@Component({
  selector: 'app-widget-feedback-type',
  templateUrl: './widget-feedback-type.component.html',
  styleUrls: ['../widget-add-edit.component.css']
})
export class WidgetFeedbackTypeComponent {

  private EMAIL_REGEXP = new RegExp(
    [
      '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:.',
      '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:',
      '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+',
      '[a-z0-9](?:[a-z0-9-]*[a-z0-9])?'
    ].join('')
  );

  tempQuestion = '';
  editQuestion = false;
  editQuestionIndex = 0;

  tempChoice = '';
  editChoice = false;
  editChoiceIndex = 0;

  tempFeedbackRecipient = '';
  emailValid = true;

  @Input() editable;
  @Input() widget: Widget;
  @Input() selectedLanguageKey;
  @Input() languageSelector;

  constructor() { }

  private addQuestion() {
    if (this.tempQuestion !== '') {
      const question = this.questionSetReference(this.tempQuestion);
      this.widget.questions.push(question);
      this.tempQuestion = '';
    }
  }

  private questionSetReference(rawQuestion) {
    const question = {};
    question[this.selectedLanguageKey] = rawQuestion;
    if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      question['_'] = rawQuestion;
    }
    return question;
  }

  private removeQuestion(id: number) {
    this.widget.questions.splice(id, 1);
  }

  private startEditQuestion(id: number) {
    this.editQuestion = true;
    this.editQuestionIndex = id;
    const question = this.widget.questions[this.editQuestionIndex];
    this.tempQuestion =
      question[this.selectedLanguageKey] && question[this.selectedLanguageKey] !== ''
        ? question[this.selectedLanguageKey]
        : question._;
  }

  private abortEditQuestion() {
    this.editQuestion = false;
    this.editQuestionIndex = 0;
    this.tempQuestion = '';
  }

  private finishEditQuestion() {
    if (this.tempQuestion !== '') {
      const question = this.widget.questions[this.editQuestionIndex];
      question[this.selectedLanguageKey] = this.tempQuestion;
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
        question._ = this.tempQuestion;
      }
      this.widget.questions[this.editQuestionIndex] = question;
      this.tempQuestion = '';
      this.editQuestion = false;
      this.editQuestionIndex = 0;
    }
  }

  private addChoiceQuestion(value: any) {
    if (typeof this.widget.choiceQuestion.question !== 'object') {
      this.widget.choiceQuestion.question = {};
    }
    this.widget.choiceQuestion.question[this.selectedLanguageKey] = value;
    if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      this.widget.choiceQuestion.question._ = value;
    }
  }

  private addChoice() {
    if (this.tempChoice !== '') {
      const question = this.questionSetReference(this.tempChoice);
      this.widget.choiceQuestion.choices.push(question);
      this.tempChoice = '';
    }
  }

  private finishEditChoice() {
    if (this.tempChoice !== '') {
      const choice = this.widget.choiceQuestion.choices[this.editChoiceIndex];
      choice[this.selectedLanguageKey] = this.tempChoice;
      if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
        choice._ = this.tempChoice;
      }
      this.widget.choiceQuestion.choices[this.editChoiceIndex] = choice;
      this.tempChoice = '';
      this.editChoice = false;
      this.editChoiceIndex = 0;
    }
  }

  private startEditChoice(id: number) {
    this.editChoice = true;
    this.editChoiceIndex = id;
    const choice = this.widget.choiceQuestion.choices[this.editChoiceIndex];
    this.tempChoice =
      choice[this.selectedLanguageKey] && choice[this.selectedLanguageKey] !== ''
        ? choice[this.selectedLanguageKey]
        : choice._;
  }

  private abortEditChoice() {
    this.editChoice = false;
    this.editChoiceIndex = 0;
    this.tempChoice = '';
  }

  private removeChoice(id: number) {
    this.widget.choiceQuestion.choices.splice(id, 1);
  }

  private setChoiceQuestionActive(event: any) {
    if (this.widget.choiceQuestion) {
      this.widget.choiceQuestion.active = event.target.checked;
    } else {
      this.widget.choiceQuestion = { active: event.target.checked, question: '', choices: [] };
    }
  }

  setAdditionalTextFormActive(event: any) {
    if (this.widget.additionalTextForm) {
      this.widget.additionalTextForm.active = event.target.checked;
    } else {
      this.widget.additionalTextForm = { active: event.target.checked, labelName: '' };
    }
  }

  private addFeedbackRecipient() {
    if (this.validateEmail(this.tempFeedbackRecipient)) {
      this.widget.feedbackRecipients.push(this.tempFeedbackRecipient);
      this.tempFeedbackRecipient = '';
    } else {
      this.emailValid = false;
    }
  }

  private removeFeedbackRecipient(id: number) {
    this.widget.feedbackRecipients.splice(id, 1);
  }

  private setQuestionRequired(event: any, question: any) {
    question.notRequired = !event.target.checked;
  }

  private validateEmail(email): boolean {
    if (!this.EMAIL_REGEXP.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  private onFocusFeedbackRecipientInput() {
    this.emailValid = true;
  }

  setAdditionalTextLabelName(value) {
    if (typeof this.widget.additionalTextForm.labelName !== 'object') {
      this.widget.additionalTextForm.labelName = {};
    }
    this.widget.additionalTextForm.labelName[this.selectedLanguageKey] = value;
    if (this.languageSelector.languages[0].key === this.selectedLanguageKey) {
      this.widget.additionalTextForm.labelName._ = value;
    }
  }
}
