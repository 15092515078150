import { Observable } from 'rxjs/Observable';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Injectable } from '@angular/core';
import { environment } from '..';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DeviceGroupContentService {
  private baseURL = environment.deviceGroupContentService;

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  manageByFieldAssigned(body: Array<{ deviceGroupId, contentId, assigned, sortOrder }>): Observable<any> {
    return this.api.putData(this.baseURL, body);
  }

  saveAllByContentIds(contentIds: Array<number>, key: string): Observable<any> {
    const params = [new RequestParam('key', key)];
    return this.api.postData(`${this.baseURL}/all`, contentIds, ...params);
  }

  deleteAllByContentIds(contentIds: Array<number>): Observable<any> {
    const options = { body: contentIds, headers: this.getHeaders(), observe: 'response' as 'response', responseType: 'text' as 'text' };
    return this.http.delete(`${this.baseURL}/all`, options);
  }

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('token');
    if (token) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });
    } else {
      return new HttpHeaders({
        'Content-Type': 'application/json'
      });
    }
  }
}
