import { AdvertisingModule } from './../advertising/advertising.module';
import { GlobalAdvertisingClientsComponent } from './global-advertising-clients/global-advertising-clients.component';
import { GlobalAdvertisingListComponent } from './global-advertising-list/global-advertising-list.component';
import { GlobalAdvertisingComponent } from './global-advertising.component';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropUploaderModule } from '../image-crop-uploader/image-crop-uploader.module';
import { FormsModule } from '@angular/forms';

import { globalAdvertisingRouting } from './global-advertising.routing';
import { DeviceGroupModule } from '../device-group/device-group.module';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { DndModule } from 'ng2-dnd';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import { DeviceGroupContentService } from '@app/services/device-group-content.service';

@NgModule({
  imports: [
    CommonModule,
    globalAdvertisingRouting,
    TranslateModule,
    ImageCropUploaderModule,
    FormsModule,
    DeviceGroupModule,
    SharedModule,
    CustomModalModule,
    AdvertisingModule,
    BrowserAnimationsModule,
    // NoopAnimationsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DndModule.forRoot()
  ],
  declarations: [GlobalAdvertisingComponent, GlobalAdvertisingListComponent, GlobalAdvertisingClientsComponent],
  providers: [DeviceGroupContentService]
})
export class GlobalAdvertisingModule { }
