import { Injectable } from '@angular/core';
import * as AcrossTabs from 'across-tabs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../environments/environment';
import { ClientService } from '@b4m/b4m-frontend-core';
import { DeviceGroupService } from '../../services/device-group.service';
import { Observable } from 'rxjs/Observable';
import { DeviceService } from '../../services';
import { Device } from '../../models';
import { Utils } from '../../shared/utils';
export interface PreviewPayload {
  type: string;
  body: string;
}

export enum PreviewStates {
  OPEN,
  REFRESH,
  DELETE,
  CLOSED
}


// constant for preview device prefix
const PREVIEW = '__preview__';

@Injectable()
export class PreviewService {
  private previewChannel: AcrossTabs;
  _status: BehaviorSubject<PreviewStates> = new BehaviorSubject(PreviewStates.CLOSED);
  // don't make subjects accessable from outside so only export the observable state
  status$: Observable<PreviewStates> = this._status.asObservable();
  status: PreviewStates = PreviewStates.CLOSED;
  private sessionId: string;
  private deviceId: number;
  constructor(
    private deviceService: DeviceService,
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private utils: Utils
  ) {
    this.previewChannel = new AcrossTabs.default.Parent({
      onHandshakeCallback: (data) => { this.onHandshakeCallback(data); },
      onChildCommunication: (data) => { this.onChildCommunication(data); },
      onPollingCallback: this.polling,
      onChildDisconnect: (data) => { this.onChildDisconnect(data); }
    });
  }

  /**
   * callback fired after a child connected
   * @param data callbackData
   */
  private onHandshakeCallback(data: any) {
    if (data && data.id && this.clientService.getCurrentClient() && this.deviceGroupService.currentDeviceGroup.id) {
      const device: Device = new Device(true, PREVIEW + data.id, this.deviceGroupService.currentDeviceGroup.id, 'panel');
      delete device.password;
      this.deviceService.create(device, this.clientService.getCurrentClient().id).subscribe(res => {
        this.setState(PreviewStates.OPEN);
        this.deviceId = res['id'];
        this.sessionId = data.id;
        const env = environment.previewUrl;
        const tempUrl = env + '/auth.html?mac=' + PREVIEW + this.sessionId + '&local=true';
        const payload: PreviewPayload = {
          type: 'url',
          body: tempUrl
        };
        this.previewChannel.broadCastTo(data.id, payload);

      });
    }

  }

  /**
   * callback fired after a child has send data
   * @param data callbackData
   */
  private onChildCommunication(data: any) {
    if (data && data.id === this.sessionId && data.msg === 'UPDATED') {
      setTimeout( () => {
        this.setState(PreviewStates.OPEN);
      }, 1500);
    }
  }

  /**
   * callback fired after every poll
   * can be used to update data
   * @param data void
   */
  private polling(data: any) {
  }

  /**
   * callback fired after a child disconnected
   * @param data callbackData
   */
  private onChildDisconnect(data: any) {
    if (data.id === this.sessionId && this.deviceId) {
      this.setState(PreviewStates.DELETE);
      this.deviceService.delete(this.deviceId, this.clientService.getCurrentClient().id).subscribe(res => {
        this.setState(PreviewStates.CLOSED);
      });
    }
  }

  /**
   * service function to force a refresh in the preview pane
   */
  refresh() {
    if (this.deviceId && this.status === PreviewStates.OPEN && this.sessionId) {
      this.setState(PreviewStates.REFRESH);
      const env = environment.previewUrl;
      const tempUrl = env + '/auth.html?mac=' + PREVIEW + this.sessionId + '&local=true';
      const payload: PreviewPayload = {
        type: 'refresh',
        body: tempUrl
      };
      this.previewChannel.broadCastTo(this.sessionId, payload);
    }
  }

  /**
   * service function to kill a preview session
   */
  close() {
    if (this.deviceId && this.status === PreviewStates.OPEN && this.sessionId) {
      const payload: PreviewPayload = {
        type: 'close',
        body: ''
      };
      this.previewChannel.broadCastTo(this.sessionId, payload);
    }
  }

  /**
   * service observable function to kill a preview session
   */
  closeObservable(): Observable<any> {
    const observable = new Observable(observer => {
      if (this.deviceId && this.status === PreviewStates.OPEN && this.sessionId) {
        this.close();
        setTimeout( () => {
          observer.next(true);
        }, 200);
      } else {
        observer.next(false);
      }
    });
    return observable;
  }

  /**
   * set the new state
   */
  setState(state: number) {
    this._status.next(state);
    this.status = state;
  }

  /**
   * create a preview pane and opens the session
   */
  createPreview() {
    if (this.clientService.getCurrentClient() && this.deviceGroupService.currentDeviceGroup.id) {
      const config = {
        url: '/assets/preview/preview.html',
        windowName: 'Preview',
        windowFeatures: ''
      };
      this.previewChannel.openNewTab(config);
    } else {
      this.utils.displayGrowlMessage('danger', 'preview.error_device', '');
    }
  }

}
