import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ClientService, CanComponentDeactivate, WatchdogService } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { Observable } from 'rxjs/Observable';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';


@Component({
  selector: 'app-client-add-edit',
  templateUrl: 'client-add-edit.component.html',
  styleUrls: ['client-add-edit.component.css']
})
export class ClientAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  isNew = false;
  client: Client = new Client(null, '', true);
  sub: Subscription;
  selectedID: number | string;
  clientsList$: Observable<Array<Client>>;
  clientsList: Array<Client>;
  activateParam: boolean;
  @ViewChild('userForm') userForm;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private watchdogService: WatchdogService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.selectedID = params['id'];
      if (this.selectedID) {
        this.clientService.getClientbyId(this.selectedID).subscribe(client => {
          this.client = client;
        });
      } else {
        this.isNew = true;
      }
    });
    this.loadClientsListOnInit();

    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });
  }

  private loadClientsListOnInit() {
    this.clientsList$ = this.clientService.getClients();
    this.clientsList$.subscribe(clients => {
      this.clientsList = clients;
    });
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (this.userForm.form.dirty) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  onSubmit() {
    if (this.isNew) {
      this.clientService.saveClient(this.client).subscribe(
        res => {
          this.clientService.getClients().subscribe(clients => {
            this.clientService.refreshClients(clients);
          });
          this.handleServerResponse(true);
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    } else {
      this.clientService.updateClient(this.client).subscribe(
        res => {
          this.clientService.getClients().subscribe(clients => {
            this.clientService.refreshClients(clients);
          });
          this.handleServerResponse(true);
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    }
    this.userForm.form.reset();
    this.watchdogService.switchWatchdogSettingsActivated('?clientId=' + this.selectedID, this.activateParam).subscribe(
      res => {
        this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      }
    );
  }

  switchWatchdogSettingsActivated(activate: boolean) {
    this.activateParam = activate;
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.userForm.form.reset();
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/client/list']);
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.switchClientRequest$.unsubscribe();
  }

  checkIfClientNameIsUnique() {
    let isUnique = true;
    this.clientsList.forEach((client) => {
      if (client.name === this.client.name && client.id !== this.client.id) {
        isUnique = false;
      }
    });
    return isUnique;
  }

}
