import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Observer } from 'rxjs/Observer';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { DeviceGroup } from '../models';

@Injectable()
export class DeviceGroupService {
  deviceTypes: string[] = ['panel', 'android', 'windows10'];
  currentDeviceGroup$: Observable<any>;
  // currentDeviceGroup$ : Subject<any>;
  currentDeviceGroup: DeviceGroup;
  private _currentDeviceGroupObserver: Observer<DeviceGroup>;
  private _currentDeviceGroupSubject: Subject<DeviceGroup> = new Subject();
  private dtURL = environment.deviceGroupService;
  switchDeviceGroupRequest$: EventEmitter<any> = new EventEmitter();
  switchDeviceGroupPermission$: EventEmitter<any> = new EventEmitter();
  constructor(private api: ApiService) {
    /*this.currentDeviceGroup$ = Observable.create(observer => {
      this._currentDeviceGroupObserver = observer;
    }); */
    this.currentDeviceGroup$ = this._currentDeviceGroupSubject.asObservable();
  }

  setCurrentDeviceGroup(c: DeviceGroup): void {
    if (this._currentDeviceGroupSubject && this._currentDeviceGroupSubject.next) {
      if (this.currentDeviceGroup && c.id !== this.currentDeviceGroup.id) {
        this._currentDeviceGroupSubject.next(c);
      }
    }
    this.currentDeviceGroup = c;
  }

  requestDeviceGroupSwitch(deviceGroupId: number) {
    this.switchDeviceGroupRequest$.emit(deviceGroupId);
  }

  deviceGroupSwitchPermission(deviceGroupId: number) {
    this.switchDeviceGroupPermission$.emit(deviceGroupId);
  }

  getAll(clientID: number | string): Observable<DeviceGroup[]> {
    return this.api.getData(this.dtURL + '/' + clientID);
  }

  getAllDeviceGroups(returnContent: boolean, key: string): Observable<DeviceGroup[]> {
    const param: RequestParam[] = [
      new RequestParam('returnContent', String(returnContent)),
      new RequestParam('key', key)
    ];
    return this.api.getData(this.dtURL, ...param);
  }

  getByID(id: number, clientID: number | string): Observable<DeviceGroup> {
    return this.api.getData(this.dtURL + '/' + clientID + '/' + id);
  }

  create(deviceGroup: DeviceGroup, clientID: number | string): Observable<DeviceGroup> {
    return this.api.postData(this.dtURL + '/' + clientID, deviceGroup);
  }

  update(deviceGroup: DeviceGroup, clientID: number | string): Observable<DeviceGroup> {
    return this.api.putData(this.dtURL + '/' + clientID, deviceGroup);
  }

  delete(id: number, clientID: number | string): Observable<null> {
    const url = this.dtURL + '/' + clientID;
    return this.api.deleteData(url, id);
  }
}
