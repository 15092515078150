import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { ApiService, RequestParam } from '@b4m/b4m-frontend-core';
import { Feedback } from '../models';
import { ResponseContentType } from '@angular/http/src/enums';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private baseUrl = '/feedback';
  constructor(
    private api: ApiService
  ) { }

  getFeedbackByClientId(clientID: number | string): Observable<Feedback[]> {
    return this.api.getData(this.baseUrl + '/list/' + clientID);
  }

  getFeedbackByWidgetId(widgetID: number | string): Observable<Feedback[]> {
    return this.api.getData(this.baseUrl + '/widget/' + widgetID);
  }

  getFeedbacksAsExcelDoc(clientID: number | string, columnHeaders: Array<string>, filename: string, sheetName: string): Observable<any> {
    const params = [new RequestParam('filename', filename), new RequestParam('sheetName', sheetName)];
    return this.api.postDataFileContent(this.baseUrl + '/documents/' + clientID + '/csv', columnHeaders, ...params);
  }
}
