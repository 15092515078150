import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MapService } from '../services';

declare const google;
@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.css']
})
export class MapComponent implements OnInit {
  locationChanged = false;
  mapLoaded = false;
  private geocoder;

  @Input()
  currentLocation: number[] = [0, 0];
  @Output()
  currentLocationChange: EventEmitter<any> = new EventEmitter();

  styles = [
    {
     'featureType': 'poi',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'poi.school',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    },
    {
     'featureType': 'transit',
     'elementType': 'labels.icon',
     'stylers': [
       {
         'visibility': 'off'
       }
     ]
    }
  ];

  constructor (
    private mapService: MapService,
    private mapsAPILoader: MapsAPILoader ) {
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder;
      this.mapLoaded = true;
      if (this.currentLocation[0] === 0 && this.currentLocation[1] === 0 && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.initCoords.bind(this), this.errorCoords.bind(this));
      }
    });
  }

  initCoords(position) {
    if (this.currentLocation[0] === 0 && this.currentLocation[1] === 0) {
      this.currentLocationChange.emit([position.coords.longitude, position.coords.latitude]);
      this.locationChanged = true;
    }
  }

  errorCoords(error) {
    console.error(error);
  }

  /**
   * setCurrentLocation
   * sets `currentLocation` and marker point at location provided by `coordinates` parameter and centers map on that place
   * @param coordinates : number[] : location of the place, at which `currentLocation` will be set
   */
  setCurrentLocation(longitude: any, latitude: any) {
    this.currentLocation = [longitude, latitude];
  }

  getCurrentLocation(): number[] {
    return this.currentLocation;
  }

  markerDragEnd($event: MouseEvent) {
    const longitude = $event.coords.lng;
    const latitude = $event.coords.lat;
    this.currentLocationChange.emit([longitude, latitude]);
    this.locationChanged = true;
  }

  getLocation(searchAddress: string) {
    if (searchAddress !== '') {
      this.searchLocation(searchAddress).then(result => {
        this.currentLocationChange.emit(result);
        this.locationChanged = true;
      }, error => {
        console.error(error);
      });
    }
  }

  searchLocation(searchAddress: string) {
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({'address': searchAddress}, function(results, status) {
        if (results && results[0]) {
          const lng = results[0].geometry.location.lng();
          const lat = results[0].geometry.location.lat();
          resolve([lng, lat]);
        }
      });
    });
  }
}
