import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services';
import { Utils } from '../utils';

/**
 * This Pipe handles the translation of text and images
 */
@Pipe({
  name: 'i18n',
  pure: false
})
export class I18nPipe implements PipeTransform {
  constructor(
    private languageService: LanguageService,
    private utils: Utils
  ) { }
  transform(value: any, args?: any): string {
    return this.utils.transformI18nValue(value, args);
  }

}
