import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { LanguageService, DeviceGroupService } from '../../services';
import { Language, DeviceGroup } from '../../models';
import { Client } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-language-settings',
  templateUrl: './language-settings.component.html',
  styleUrls: ['./language-settings.component.css']
})
export class LanguageSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('modal')
  private modal: CustomModalComponent;
  languages: Language[];
  client: Client;
  deviceGroups: DeviceGroup[];
  locales: any[] = [];
  defaultLocale: string;
  selectedLocales: any = {};
  langForm: FormGroup;
  currentDeviceGroup$: Subscription;
  deletingLang: Array<number>;

  constructor(
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public deviceGroupService: DeviceGroupService,
    private utils: Utils
  ) { }

  ngOnInit() {
   this.loadData();
  }

  private loadData() {
    this.route.data.subscribe((data: { client: Client }) => {
      this.client = data[0].client;
      this.transformLangs();
    });
    this.deletingLang = [];
  }

  /**
   * main initialization method
   * joins the locales with the devicegroups for easier data handling
   * and better display possibilities.
   * call it whenever data are coming from the server
   */
  transformLangs() {
    this.currentDeviceGroup$ = this.deviceGroupService.getAll(this.client.id).subscribe((deviceGroups: DeviceGroup[]) => {
      this.languageService.getAll(this.client.id).subscribe(loc => {
        loc.map(l => {
          const dts = deviceGroups.map(a => ({ ...a }));
          dts.map(dt => {
            const active = l['pageContents'].some(pc => {
              return pc.page_id === dt.id;
            });
            dt['active'] = active;
            return dt;
          });

          return l['deviceGroups'] = dts;
        });
        this.locales = loc.sort((a: any, b: any) => {
          if (a.pageContents.length === 0) {
            a.pageContents.push({ sortOrder: 1024 });
          }
          if (b.pageContents.length === 0) {
            b.pageContents.push({ sortOrder: 1024 });
          }
          if (a.pageContents[a.pageContents.length - 1].sortOrder <=
            b.pageContents[b.pageContents.length - 1].sortOrder) {
            return -1;
          } else {
            return 1;
          }
        });
      });
    });
  }

  /**
   * deletes a language
   * @param id id of the language
   */
  deleteLanguage(id: number) {
    this.modal.closeModal();
    this.locales.splice(this.locales.findIndex(l => l.id === id), 1);
    this.deletingLang.push(id);
  }

  /**
   * checks wheather a language is assigned to a devicegroup
   * @param pc pageContents
   * @param id language id
   */
  isAssigned(pc, id: string | number): boolean {
    let ret = false;
    pc.some(cId => {
      if (cId.page_id === id) {
        ret = true;
        return true;
      }
    });
    return ret;
  }

  /**
   * sets the language active or deactivates it
   * @param loc language object
   * @param activate the desired status
   */
  activateLang(loc: any, activate: boolean) {
    const lang: Language = loc.value;
    lang.active = activate;
  }

  /**
   * saves the changes made to the languages
   */
  save() {
    // generate page - lang map
    const langMap = this.generatePageLang();
    this.languageService.batchAssign(this.client.id, langMap).subscribe(res => {
      const ok = res.map((status: any) => status.ok);
      if (ok.some(entry => entry === false)) {
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
      }
    });
    for (let i = 0; i < this.locales.length; i++) {
      const loc = this.locales[i];
      this.languageService.update(this.client.id, loc.value, loc.id).subscribe(res => {
        if (this.locales.length === (i + 1)) {
          this.runDeletingLang();
        }
        if (!res.id) {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
        } else {
          this.utils.displayGrowlMessage('success', 'forms.submitted-description', '');
        }
      });
    }
  }

  runDeletingLang() {
    if (this.deletingLang.length && this.deletingLang.length > 0) {
      for (let i = 0; i < this.deletingLang.length; i++) {
        this.languageService.delete(this.client.id, this.deletingLang[i]).subscribe(result => {
            if (this.deletingLang.length === (i + 1)) {
              this.loadData();
            }
          },
          error => {
            console.error(error);
          }
        );
      }
    } else {
      this.loadData();
    }
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  /**
   * Generator function for the data send to the server
   * Returns a Map
   */
  generatePageLang(): Map<string, any[]> {
    const retMap = new Map<string, any[]>();
    this.locales.forEach(loc => {
      loc['deviceGroups'].forEach(dt => {
        let entries = [];
        if (retMap.has(dt.id)) {
          entries = retMap.get(dt.id);
        }
        if (dt.active) {
          entries.push(loc.id);
        }
        retMap.set(dt.id, entries);
      });
    });
    return retMap;
  }

  getLanguageReferenceInfo(language): String {
    if (language.pageContents.length > 1) {
      return this.utils.getTranslation('confirmation.multiple-references');
    } else {
      return '';
    }
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }

}
