import { Component, AfterViewChecked, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { GoogleCalendarService } from '../../services';
import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';

@Component({
  selector: 'app-integration-redirect',
  templateUrl: './integration-redirect.component.html'
})
export class IntegrationRedirectComponent implements AfterViewChecked {

  private sub: Subscription;
  private tokenKey = 'Google-Access-Token';
  private code = null;
  private accountName = '';

  @ViewChild('googleAccountName')
  private googleAccountName: CustomModalComponent;

  constructor( private route: ActivatedRoute, private router: Router, private googleCalendarService: GoogleCalendarService,
    private clientService: ClientService, private cdRef: ChangeDetectorRef) { }

  ngAfterViewChecked() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.googleAccountName.showModal('lists.google-calendar.enter-name', 'lists.google-calendar.name', '', false, false);
      this.cdRef.detectChanges();
    });
  }

  public integrateAccount() {
    const clientId = this.clientService.getCurrentClient().id;
    this.googleCalendarService.integrateAccount(clientId, this.code, this.accountName).subscribe(
      res => {
        this.router.navigate(['/google-calendar']);
      },
      error => {
        console.log(error);
        this.router.navigate(['/google-calendar']);
    });
  }
}
