import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { FeedbackComponent } from './feedback.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { FeedbackAnalyticsComponent } from './feedback-analytics/feedback-analytics.component';
import { ClientResolve } from '../client-guard.service';

const feedbackRoutes: Routes = [
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
    children: [
      {
        path: 'list',
        component: FeedbackListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      },
      {
        path: 'analytics',
        component: FeedbackAnalyticsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
        resolve: [ClientResolve],
        data: { roles: ['ADMIN', 'USER'] }
      }
    ]
  }
];

export const feedbackRouting = RouterModule.forChild(feedbackRoutes);
