import { Pipe, PipeTransform } from '@angular/core';
import { Poi } from '../../models';


@Pipe({
  name: 'filterPoiCategories'
})
export class FilterPoiCategoriesPipe implements PipeTransform {

  transform(pois: any, categoryID: number): any {

    if (categoryID) {
      const result: Poi[] = [];
      pois.forEach(poi => {
        if (categoryID === null || (poi.properties && poi.properties.category === categoryID)) {
          result.push(poi);
        }
      });
      return result;
    }
  }

}
