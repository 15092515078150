import { Component, OnInit, ViewChild } from '@angular/core';

import { ClientService } from '@b4m/b4m-frontend-core';
import { AdvertisingService, UploadService } from '@app/services';
import { Advertising } from '../../models';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-advertising-list',
  templateUrl: './advertising-list.component.html',
  styleUrls: ['./advertising-list.component.css']
})
export class AdvertisingListComponent implements OnInit {

  advertisements: any;
  clientSelected = false;
  private currentDeviceGroup$: any;
  assignMode = false;
  private deviceGroupID: number = null;
  private maxAssignedOrder = 0;
  @ViewChild('deviceGroupSelector')
  deviceGroupSelector;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(
    private clientService: ClientService,
    private advertisingService: AdvertisingService,
    private uploadService: UploadService,
    public utils: Utils
  ) { }

  ngOnInit() {
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientSelected = true;
      this.advertisingService.getAll(this.clientService.getCurrentClient().id).subscribe(content => {
        if (content) {
          this.advertisements = content;
          this.calculateMaxAssignedOrder(this.advertisements);
          this.advertisements.sort(this.compareAdsByOrder.bind(this));
        }
      });
    } else {
      this.clientSelected = false;
    }
  }

  private reload(clientID: number | string, keepAssignMode?: boolean) {
    if (!keepAssignMode) {
      this.assignMode = false;
    }
    if (clientID) {
      this.clientSelected = true;
      this.advertisingService.getAll(clientID).subscribe(a => {
        if (a) {
          this.advertisements = a;
          this.calculateMaxAssignedOrder(this.advertisements);
          this.advertisements.sort(this.compareAdsByOrder.bind(this));
        } else {
          this.advertisements = new Array();
          this.calculateMaxAssignedOrder(this.advertisements);
        }
      });
    } else {
      this.clientSelected = false;
    }
  }

  private compareAdsByOrder(ad1, ad2) {
    const a1 = this.getAdsOrder(ad1).sortOrder;
    const a2 = this.getAdsOrder(ad2).sortOrder;
    return (a1 - a2);
  }

  private isAssigned(ad: any): boolean {
    return this.getAdsOrder(ad).assigned;
  }

  private getAdsOrder(ad: any): any {
    const dtId = this.getDeviceGroupId();
    if (dtId) {
      if (ad.pageContents && ad.pageContents.length !== 0) {
        for (const pageContent of ad.pageContents) {
          if (pageContent.page_id === dtId) {
            return { 'sortOrder': pageContent.sortOrder, 'assigned': true };
          }
        }
        for (const pageContent of ad.pageContents) {
          if (!pageContent.page_id) {
            return { 'sortOrder': this.maxAssignedOrder + pageContent.sortOrder, 'assigned': false };
          }
        }
      }
    }
    for (const pageContent of ad.pageContents) {
      if (!pageContent.page_id) {
        return { 'sortOrder': this.maxAssignedOrder + pageContent.sortOrder, 'assigned': false };
      }
    }
    return { 'sortOrder': this.maxAssignedOrder + 1, 'assigned': false };
  }

  private getDeviceGroupId() {
    if (this.deviceGroupSelector.currentDeviceGroup.id) {
      let dtId = this.deviceGroupSelector.currentDeviceGroup.id;
      if (this.deviceGroupID !== null) {
        dtId = this.deviceGroupID;
      }
      return dtId;
    }
    return null;
  }

  private calculateMaxAssignedOrder(pages) {
    this.maxAssignedOrder = 0;
    const dtId = this.getDeviceGroupId();
    if (dtId) {
      for (const page of pages) {
        if (page.pageContents && page.pageContents.length !== 0) {
          for (const pageContent of page.pageContents) {
            if (pageContent.page_id === dtId && pageContent.sortOrder > this.maxAssignedOrder) {
              this.maxAssignedOrder = pageContent.sortOrder;
            }
          }
        }
      }
    }
  }

  saveReorderingAds() {
    const clientID = this.clientService.getCurrentClient().id;
    if (clientID && this.deviceGroupSelector.currentDeviceGroup.id && this.assignMode) {
      const adIds: number[] = [];
      for (const ad of this.advertisements) {
        if ((this.isAssigned(ad) && !ad['isDetachCandidate']) || ad['isAttachCandidate']) {
          adIds.push(ad.id);
        }
      }
      this.advertisingService.assign(clientID, this.deviceGroupSelector.currentDeviceGroup.id, adIds).subscribe(res => {
        this.reload(clientID, true);
      });
    }
  }

  reassign(ad: Advertising) {
    if (this.isAssigned(ad)) {
      ad['isDetachCandidate'] = true;
    } else {
      ad['isAttachCandidate'] = true;
    }
    this.saveReorderingAds();
  }

  deleteAd(id: number, image: number) {
    this.modal.closeModal();
    const clientID = this.clientService.getCurrentClient().id;
    this.advertisingService.delete(clientID, id).subscribe(
      result => {
        this.uploadService.delete(clientID, image).subscribe(() => {});
        // if http request returns reload pages
        this.reload(this.clientService.getCurrentClient().id, false);
      },
      error => {
        console.error(error);
      }
    );
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }
}
