import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LogoComponent } from './logo.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LogoComponent],
  exports: [LogoComponent]
})
export class LogoModule { }
