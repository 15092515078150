import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import {
  routing,
  appRoutingProviders
} from './app.routing';

import { NavigationModule } from './navigation/navigation.module';
import { UserModule } from './user/user.module';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { ClientModule } from './client/client.module';
import { DeviceModule } from './device/device.module';
import { EventsModule } from './events/events.module';
import { DishSetModule } from './dish-set/dish-set.module';
import { RemoteAccessModule } from './remote-access/remote-access.module';
import { MainPageModule } from './main-page/main-page.module';
import { PageModule } from './page/page.module';
import { DeviceGroupService, LanguageService, PoiService, MakiIconsService } from './services';
import { PoiModule } from './poi/poi.module';
import { ClientResolve } from './client-guard.service';
import { LoginComponent } from './login/login.component';
import { CustomModalModule } from './custom-modal/custom-modal.module';
import { LanguageModule } from './language/language.module';
import { LogoModule } from './logo/logo.module';
import { GoogleCalendarIntegrationModule } from './google-calendar-integration/google-calendar-integration.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import { environment } from '../environments/environment';
import { B4mCoreModule, B4mCoreModuleConfig } from '@b4m/b4m-frontend-core';
import { Observable } from 'rxjs/Observable';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AclModule } from './acl/acl.module';
import { FeedbackModule } from './feedback/feedback.module';
import { AgmCoreModule } from '@agm/core';
import { AdvertisingModule } from './advertising/advertising.module';
import { ChartsModule } from 'ng2-charts';
import { GlobalAdvertisingModule } from './global-advertising/global-advertising.module';
import { GoogleAnalyticsModule } from './google-analytics/google-analytics.module';
import {PropsSettingsModule} from '@app/props-settings/props-settings.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class ApiBackend implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/') && !request.url.startsWith('/assets')) {
      request = request.clone({
        url: environment.serviceBaseURL + environment.apiPath + request.url    // prefix base url
      });
    }
    return next.handle(request);
  }
}

export const authorities: string[] = [
  'ROLE_SYSTEM_ADMIN',
  'ROLE_ADMIN',
  'ROLE_SSH_DEVICE',
  'ROLE_USER'
];

const config: B4mCoreModuleConfig = {
  httpXhrConfig: { provide: HTTP_INTERCEPTORS, useClass: ApiBackend, multi: true },
  authorities: authorities
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    B4mCoreModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    routing,
    NavigationModule,
    UserModule,
    PageNotFoundModule,
    ClientModule,
    DeviceModule,
    DishSetModule,
    RemoteAccessModule,
    EventsModule,
    MainPageModule,
    PageModule,
    PoiModule,
    LanguageModule,
    CustomModalModule,
    ModalModule.forRoot(),
    LogoModule,
    GoogleCalendarIntegrationModule,
    AclModule,
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    FeedbackModule,
    AdvertisingModule,
    GlobalAdvertisingModule,
    GoogleAnalyticsModule,
    PropsSettingsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    }),
    ChartsModule
  ],
  declarations: [
    AppComponent,
    LoginComponent
  ],
  providers: [
    appRoutingProviders,
    PoiService,
    DeviceGroupService,
    ClientResolve,
    LanguageService,
    MakiIconsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
