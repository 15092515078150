import { Component, Input, Output, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { ImgCropSettings } from './img-cropper.model';

import Cropper from 'cropperjs';


/**
 * app-img-cropper
 * This component expects two inputs:
 *
 * image: an image to crop
 * settings: an settings object type. The following options must be supplied
 *  transformToPixels:      this value specifies the crooped value in length or height or both (depends on the transformAttribute)
 *  transformAttribute:     this value specifies if the max int specified above is used on the horizontal or vertical axis
 *                          or on both axis, but keep in mind cropperjs tries to keep a ratio. So if there are height and
 *                          width specified the values are more like a max. value than the strict cropping relation.
 *  allowSmallBoundingBox:  this attribute specifies if the cropping box may be smaller than the cropping value.
 *  squareBox:              this value defines if only a box can be cropped (usefull for quadratic images) if true the controls
 *                          for changing the ratio will be hidden.
 *
 * Two events will be emitted if any changes are made:
 *  canceled:               the cropping was canceled no image will be emitted.
 *  imageCropped:           the resulting image after cropping.
 *
 * Special changes may require the study of the cropperjs docs:
 * https://github.com/fengyuanchen/cropperjs/blob/master/README.md
 */

@Component({
  selector: 'app-img-cropper',
  templateUrl: 'img-cropper.component.html',
  styleUrls: ['img-cropper.component.css']
})
export class ImgCropperComponent implements OnChanges {
  // inputs
  @Input() image: HTMLImageElement = new Image();
  @Input() settings: ImgCropSettings = {
    transformToPixels: 128,
    transformToAttribute: 'height',
    allowSmallBoundingBox: true
  };
  // outputs
  @Output() canceled = new EventEmitter<void>();
  @Output() imageCropped = new EventEmitter<any>();
  // html nodes
  @ViewChild('cropzone') cropzone;
  @ViewChild('cr') cr;
  @ViewChild('displayed') displayBox;
  fixedBox = false;

  // the cropper object
  cropper: any;
  // for keeping track of the aspect ratio
  aspectRatio = 0;

  constructor() { }

  /**
   * ngOnChanges
   * if any changes coming in from the inputs reinitialize the creopper and show the controls
   */
  ngOnChanges() {
    this.cropzone.nativeElement.src = this.image.src;
    this.cropper = new Cropper(this.cropzone.nativeElement, {
      aspectRatio: this.aspectRatio,
      ready: () => {
        // if the cropper is ready set the box to the size of the image
        if (this.settings.squareBox) {
          this.aspectRatio = 1;
          this.cropper.setAspectRatio(1);
        }
        if (this.settings.transformToAttribute === 'heightWidth') {
          const attrWidth: number = this.settings.transformToPixels['width'];
          const attrHeight: number = this.settings.transformToPixels['height'];
          this.aspectRatio = attrWidth / attrHeight;
          this.setAspectRatio(attrWidth / attrHeight);
          this.fixedBox = true;
        }
        this.cropper.setCropBoxData({
          left: this.cropper.getCanvasData().left,
          top: this.cropper.getCanvasData().top,
          width: this.cropper.getImageData().width,
          height: this.cropper.getImageData().height
        });
      },
      crop: (e) => {
        // if there are any changes to the cropbox handle the data and display it in the preview
        // transform the image to the specified dimensions from the settings
        switch (this.settings.transformToAttribute) {
          case 'height': {
            this.cr.nativeElement.src = this.cropper.getCroppedCanvas({
              height: this.settings.transformToPixels,
              imageSmoothingQuality: 'high'
            }).toDataURL('image/png');
            break;
          }
          case 'width': {
            this.cr.nativeElement.src = this.cropper.getCroppedCanvas({
              width: this.settings.transformToPixels,
              imageSmoothingQuality: 'high'
            }).toDataURL('image/png');
            break;
          }
          case 'heightWidth': {
            if (typeof this.settings.transformToPixels === 'object') {
              this.cr.nativeElement.src = this.cropper.getCroppedCanvas({
//                 height: this.settings.transformToPixels.height,
                width: this.settings.transformToPixels.width,
                imageSmoothingQuality: 'high'
              }).toDataURL('image/png');
              break;
            }
            this.cr.nativeElement.src = this.cropper.getCroppedCanvas({
              height: this.settings.transformToPixels,
              width: this.settings.transformToPixels,
              imageSmoothingQuality: 'high'
            }).toDataURL('image/png');
            break;
          }
          default: {
            this.cr.nativeElement.src = this.cropper.getCroppedCanvas({
              height: this.cr.nativeElement.height,
              width: this.cr.nativeElement.width
            }).toDataURL('image/png');
          }
        }
      },
      minCropBoxHeight: this.settings.allowSmallBoundingBox ? 0 : <number>this.settings.transformToPixels,
    });
  }

  /**
   * This method is a helper to set the ratio for the cropping box.
   * @param ratio the desired ratio (as a float or as fraction e.g: 1.6666666 | 1:6 )
   */
  setAspectRatio(ratio: number) {
    this.aspectRatio = ratio;
    if (ratio === 0) {
      this.cropper.setAspectRatio(NaN);
    } else {
      this.cropper.setAspectRatio(ratio);
    }
  }

  /**
   * This function is a helper to evaluate if a given ratio is active.
   * @param ratio the desired ratio (as a float or as fraction e.g: 1.6666666 | 1:6 )
   */
  getAspectRatioBool(ratio: number): boolean {
    return this.aspectRatio === ratio;
  }

  /**
   * This method cancels the cropper and emits the cancel event.
   */
  onCancel() {
    this.cropper = null;
    this.cropzone.nativeElement.src = null;
    this.cr.nativeElement.src = null;
    this.canceled.emit();
  }

  /**
   * This method emits the image to the parent.
   */
  onOk() {
    this.imageCropped.emit(this.cr.nativeElement);
  }

}
