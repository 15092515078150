import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-advertising',
  templateUrl: './global-advertising.component.html',
  styleUrls: ['./global-advertising.component.css']
})
export class GlobalAdvertisingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
