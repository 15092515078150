import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetComponent } from './widget.component';
import { WidgetListComponent } from './widget-list/widget-list.component';
import { WidgetAddEditComponent } from './widget-add-edit/widget-add-edit.component';
import { widgetRouting } from './widget.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { WidgetService, LanguageService, EventsService } from '../services';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DndModule } from 'ng2-dnd';
import { LanguageModule } from '../language/language.module';
import { DeviceGroupModule } from '../device-group/device-group.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';
import { ImageCropUploaderModule } from '../image-crop-uploader/image-crop-uploader.module';
import { ImageUploadModule } from 'ng2-imageupload';
import { I18nPipe } from '../shared/pipes/i18n.pipe';
import { DashheadModule } from '@app/dashhead/dashhead.module';
import { WidgetFeedbackTypeComponent } from './widget-add-edit/widget-feedback-type/widget-feedback-type.component';
import { WidgetSlideshowTypeComponent } from './widget-add-edit/widget-slideshow-type/widget-slideshow-type.component';
import { WidgetEventTypeComponent } from './widget-add-edit/widget-event-type/widget-event-type.component';
import { WidgetDishsetTypeComponent } from './widget-add-edit/widget-dishset-type/widget-dishset-type.component';
import { WidgetPoiTypeComponent } from './widget-add-edit/widget-poi-type/widget-poi-type.component';
import { WidgetLivePreviewComponent } from './widget-live-preview/widget-live-preview.component';

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    widgetRouting,
    FormsModule,
    BrowserModule,
    ImageCropUploaderModule,
    TranslateModule,
    CustomModalModule,
    DndModule.forRoot(),
    LanguageModule,
    DeviceGroupModule,
    SharedModule,
    ImageUploadModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    DashheadModule
  ],
  declarations: [WidgetComponent, WidgetListComponent, WidgetAddEditComponent, WidgetFeedbackTypeComponent,
    WidgetSlideshowTypeComponent, WidgetEventTypeComponent, WidgetDishsetTypeComponent, WidgetPoiTypeComponent, WidgetLivePreviewComponent],
  exports: [WidgetComponent, WidgetListComponent, WidgetAddEditComponent],
  providers: [AuthGuard, WidgetService, Utils, I18nPipe, LanguageService, EventsService]
})
export class WidgetModule { }
