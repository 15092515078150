import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '@b4m/b4m-frontend-core';
import { Subject } from 'rxjs/Subject';
import { UploadResponse } from '../models';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class UploadService {
  public progress: number;
  private url = environment.fileService;
  constructor(private authService: AuthService, private http: HttpClient) {}

  saveFile(file: string, clientID: number | string): Observable<UploadResponse> {
    const url = this.url + '/' + clientID;
    const token: string = this.authService.token;
    const bodyObj: any = {
      file: file
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + token });
    const req = new HttpRequest('POST', url, bodyObj, {
      headers,
      reportProgress: true
    });
    const result = new Subject<UploadResponse>();
    this.http.request(req).subscribe(event => {
      // Via this API, you get access to the raw event stream.
      // Look for upload progress events.
      if (event.type === HttpEventType.UploadProgress) {
        // This is an upload progress event. Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        result.next(<UploadResponse>{
          resType: 1,
          content: percentDone
        });
      } else if (event instanceof HttpResponse) {
        const images = [];
        for (const key of <string[]>event.body) {
          const image: any = {
            key: key,
            url: 'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/' + clientID + '/' + key
          };
          images.push(image);
        }
        result.next(<UploadResponse>{
          resType: 0,
          content: images
        });
        result.complete();
      }
    });
    return result.asObservable();
  }

  getFileAsBase64(file: File): FileReader {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return reader;
  }

  createImageBlob(imageElement: HTMLImageElement): string {
    this.progress = 0;
    const canvas = <HTMLCanvasElement>document.createElement('CANVAS');
    const context = <CanvasRenderingContext2D>canvas.getContext('2d');
    context.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);
    return canvas.toDataURL();
  }

  delete(clientID: number | string, id: number | string): Observable<HttpResponse<Object>> {
    const url = this.url + '/' + clientID;
    const token: string = this.authService.token;
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const options = { headers: headers, observe: 'response' as 'response' };
    return this.http.delete(url + '/' + id, options);
  }
}
