import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashhead',
  templateUrl: './dashhead.component.html',
  styleUrls: ['./dashhead.component.css']
})
export class DashheadComponent {
  @Input() headerTitle: string;
  @Input() subTitle: string;
}
