import { UserRightsType } from '../../models/user-rigts-type';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-acl-selector',
  templateUrl: './acl-selector.component.html',
  styleUrls: ['./acl-selector.component.css']
})
export class AclSelectorComponent {
  userRightsTypes = UserRightsType.all();
  currentUserRights = UserRightsType.PagesRights;
  @Output() currentUserRightsType = new EventEmitter<UserRightsType>();

  changeUserRights(userRightsType: UserRightsType) {
    this.currentUserRights = userRightsType;
    this.currentUserRightsType.emit(userRightsType);
  }
}
