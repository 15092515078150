import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { GoogleCalendarIntegrationComponent } from './google-calendar-integration.component';
import { IntegrationRedirectComponent } from './integration-redirect/integration-redirect.component';
import { ClientResolve } from '../client-guard.service';

const googleCalendarIntegrationRoutes: Routes = [
  {
    path: 'google-calendar',
    component: GoogleCalendarIntegrationComponent,
    canActivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] },
  },
  {
    path: 'google-integration',
    component: IntegrationRedirectComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    resolve: [ClientResolve],
    data: { roles: ['ADMIN', 'USER'] }
  }
];

export const googleCalendarIntegrationRouting = RouterModule.forChild(googleCalendarIntegrationRoutes);
