import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroupService, MainPageService } from '../../services';
import {
  MainPage,
  CoordsWithName,
  DeviceGroup
} from '../../models';
import { Utils } from '../../shared/utils';
import { Subscription } from 'rxjs/Subscription';
import { MapComponent } from '../../map/map.component';

@Component({
  selector: 'app-location',
  templateUrl: './location-settings.component.html',
  styleUrls: ['./location-settings.component.css']
})
export class LocationSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('locationMap') mapComponent: MapComponent;
  currentDeviceGroup$: Subscription;
  deviceGroupSelected = true;
  mainPageId: number;
  mainPage: MainPage = null;
  coordinates: number[] = [0, 0];
  private client: Client;
  private deviceGroup: DeviceGroup;
  constructor(
    private deviceGroupService: DeviceGroupService,
    private mainPageService: MainPageService,
    private route: ActivatedRoute,
    private utils: Utils
  ) { }

  ngAfterViewInit() {
    this.loadData();
  }

  private loadData() {
    this.route.data
      .subscribe((data: { client: Client }) => {
        this.client = data[0].client;
        this.deviceGroup = data[0].deviceGroup;
        this.loadMainPage();

        // Subscribe to current device to reload on change
        this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
          if (this.client && this.client.id && dt.id) {
            this.deviceGroup = dt;
            this.loadMainPage();
          }
        });
      });
  }

  // loadMainPage loads the main page from the server and
  // sets it and the current coordinates of the main page in the component
  loadMainPage(newMainPage?: any) {
    if (newMainPage) {
      this.mainPage = newMainPage.value;
      this.mainPage.id = newMainPage.id;
      this.mainPageId = newMainPage.id;
      this.coordinates = [this.mainPage.location.lng, this.mainPage.location.lat];
      this.mapComponent.setCurrentLocation(this.mainPage.location.lng, this.mainPage.location.lat);
      this.mapComponent.locationChanged = false;

    } else {
      this.mainPageService.get(
        this.client.id,
        this.deviceGroup.id
      ).subscribe(content => {
        if (content) {
          this.mainPage = content.value;
          this.mainPage.id = content.id;
          this.mainPageId = content.id;
          this.coordinates = [this.mainPage.location.lng, this.mainPage.location.lat];


          this.coordinates = [this.mainPage.location.lng, this.mainPage.location.lat];
          this.mapComponent.setCurrentLocation(this.mainPage.location.lng, this.mainPage.location.lat);
          this.mapComponent.locationChanged = false;

        } else {
          this.mainPage = new MainPage('', '', this.mainPageService.newThemeColors, new CoordsWithName('', 0, 0), '', []);
          this.mainPageId = null;
        }
      });
    }
  }

  onSubmit() {
    const coords = new CoordsWithName('', this.coordinates[0], this.coordinates[1]);
    if (this.mainPageId) {
      this.mainPageService.updateProperty(this.mainPage, 'location', coords).subscribe(res => {
        this.loadMainPage(res);
        this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      });
    } else {
      this.mainPage.location.lng = this.coordinates[0];
      this.mainPage.location.lat = this.coordinates[1];
      this.mainPageService.create(this.client.id, this.deviceGroup.id, this.mainPage).subscribe(newMainPage => {
        this.loadMainPage(newMainPage);
        this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      });
    }
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }

}
