import {CustomModalModule} from './../custom-modal/custom-modal.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {propsSettingsRouting} from './props-settings.routing';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '@app/shared/shared.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {PropsSettingsPanelComponent} from '@app/props-settings/props-settings-panel/props-settings-panel.component';
import {PropsSettingsComponent} from '@app/props-settings/props-settings.component';

@NgModule({
  imports: [
    CommonModule,
    propsSettingsRouting,
    TranslateModule,
    FormsModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CustomModalModule,
  ],
  declarations: [
    PropsSettingsComponent,
    PropsSettingsPanelComponent,
  ]
})
export class PropsSettingsModule {
}
