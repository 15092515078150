import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Client } from '@b4m/b4m-frontend-core';
import { DeviceGroupService, MainPageService } from '../../services';
import { MainPage, DeviceGroup, CoordsWithName, DisplayTypeEnum } from '../../models';
import { Subscription } from 'rxjs/Subscription';
import { Utils } from '../../shared/utils';

@Component({
  selector: 'app-display-type',
  templateUrl: './display-type.component.html',
  styleUrls: ['./display-type.component.css']
})
export class DisplayTypeComponent implements OnInit, OnDestroy {

  @ViewChild('deviceGroupSelector') deviceGroupSelector;
  currentDeviceGroup$: Subscription;
  mainPageId: number;
  mainPage: MainPage = null;
  private client: Client;
  private deviceGroup: DeviceGroup;
  isVertical = false;

  constructor(
    private deviceGroupService: DeviceGroupService,
    private mainPageService: MainPageService,
    private route: ActivatedRoute,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.route.data
      .subscribe((data: { client: Client }) => {
        this.client = data[0].client;
        this.deviceGroup = data[0].deviceGroup;
        this.loadMainPage();

        // Subscribe to current device to reload on change
        this.currentDeviceGroup$ = this.deviceGroupService.currentDeviceGroup$.subscribe(dt => {
          if (this.client && this.client.id && dt.id) {
            this.deviceGroup = dt;
            this.loadMainPage();
          }
        });
      });
  }

  // loadMainPage loads the main page from the server and
  // sets it and the current coordinates of the main page in the component
  loadMainPage() {
    this.mainPageService.get(this.client.id, this.deviceGroup.id).subscribe(content => {
      if (content) {
        this.mainPage = content.value;
        this.mainPage.id = content.id;
        this.mainPageId = content.id;
        this.isVertical = this.mainPage.displayType && this.mainPage.displayType === DisplayTypeEnum.VERTICAL;
      } else {
        this.mainPage = new MainPage('', '', this.mainPageService.newThemeColors, new CoordsWithName('', 0, 0), '', []);
        this.mainPageId = null;
      }
    });
  }

  loadMainPageResponse(newMainPage: any) {
    this.mainPage = newMainPage.value;
    this.mainPage.id = newMainPage.id;
    this.mainPageId = newMainPage.id;
    this.isVertical = this.mainPage.displayType && this.mainPage.displayType === DisplayTypeEnum.VERTICAL;
  }

  onSubmit() {
    const type = this.isVertical ? DisplayTypeEnum.VERTICAL : DisplayTypeEnum.HORIZONTAL;
    if (this.mainPageId) {
      this.mainPageService.updateProperty(this.mainPage, 'displayType', type).subscribe(res => {
        this.loadMainPageResponse(res);
        this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      });
    } else {
      this.mainPage.displayType = this.isVertical ? DisplayTypeEnum.VERTICAL : DisplayTypeEnum.HORIZONTAL;
      this.mainPageService.create(this.client.id, this.deviceGroup.id, this.mainPage).subscribe(newMainPage => {
        this.loadMainPageResponse(newMainPage);
        this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      });
    }
  }

  cancel() {
    this.utils.displayGrowlMessage('danger', 'lists.changesCancel', '');
    this.loadData();
  }

  ngOnDestroy() {
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
