import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentUpdaterComponent } from './content-updater.component';
import { TranslateModule } from '@ngx-translate/core';
import { MainPageService } from '../services';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ContentUpdaterComponent],
  exports: [ContentUpdaterComponent],
  providers: [MainPageService]
})
export class ContentUpdaterModule {}
