import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { DeviceGroupService } from '../../services';
import { DeviceGroup } from '../../models';
import { ClientService, CanComponentDeactivate, WatchdogService } from '@b4m/b4m-frontend-core';
import { Client } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils } from '../../shared/utils';


@Component({
  selector: 'app-device-group-add-edit',
  templateUrl: 'device-group-add-edit.component.html',
  styleUrls: ['device-group-add-edit.component.css']
})
export class DeviceGroupAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  isNew = false;
  deviceGroups: string[];
  deviceGroup: DeviceGroup = new DeviceGroup(true, null, '', null, [], '');
  clients: Client[];
  selectedID: number;
  activateParam: boolean = null;
  activateParamChange = false;
  private sub: Subscription;
  @ViewChild('deviceGroupForm') deviceGroupForm;
  private switchClientRequest$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private deviceGroupService: DeviceGroupService,
    private clientService: ClientService,
    private watchdogService: WatchdogService,
    private utils: Utils
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.selectedID = params['id'];
      if (this.selectedID) {
        if (this.clientService.getCurrentClient()) {
          this.deviceGroupService.getByID(this.selectedID, this.clientService.getCurrentClient().id).subscribe(deviceGroup => {
            this.deviceGroup = deviceGroup;
          });
        }
      } else {
        this.isNew = true;
      }

      // Subscribe to current client to reload on change
      this.clientService.currentClient$.subscribe(client => {
        this.reload(client.id, this.selectedID);
      });

      this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
        this.canDeactivate().then(resolve => {
          if (resolve) {
            this.clientService.clientSwitchPermission(res);
          }
        });
      });
    });

    this.clientService.getClients().subscribe(clients => {
      this.clients = clients;
    });
  }

  private reload(clientID: number | string, deviceGroupID: number) {
    this.deviceGroupForm.form.reset();
    if (clientID && deviceGroupID) {
      this.deviceGroupService.getByID(deviceGroupID, clientID).subscribe(deviceGroup => {
        this.deviceGroup = deviceGroup;
      });
    }
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      if (this.deviceGroupForm.form.dirty || this.activateParamChange) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  onSubmit() {
    if (this.isNew) {
      this.deviceGroupService.create(this.deviceGroup, this.clientService.getCurrentClient().id).subscribe(
        result => {
          this.handleServerResponse(true);
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    } else {
      this.deviceGroupService.update(this.deviceGroup, this.clientService.getCurrentClient().id).subscribe(
        result => {
          if (this.activateParamChange) {
            this.watchdogService.switchWatchdogSettingsActivated('?deviceGroupId=' + this.selectedID, this.activateParam).subscribe(
              res => {
                this.handleServerResponse(true);
              },
              error => {
                this.handleServerResponse(false);
              }
            );
          } else {
            this.handleServerResponse(true);
          }
        },
        error => {
          this.handleServerResponse(false);
        }
      );
    }
  }

  switchWatchdogSettingsActivated(activate: boolean) {
    this.activateParam = activate;
    this.activateParamChange = true;
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.deviceGroupForm.form.reset();
      this.activateParamChange = false;
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/device-group/list']);
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  checkIdleTimeValid(): boolean {
    return typeof this.deviceGroup.idleTime === 'string'
      ? !this.deviceGroup.idleTime || (parseInt(this.deviceGroup.idleTime, 10) >= 1
        && Number.isInteger(parseInt(this.deviceGroup.idleTime, 10)))
      : !this.deviceGroup.idleTime || (this.deviceGroup.idleTime >= 1 && !isNaN(this.deviceGroup.idleTime)
        && Number.isInteger(this.deviceGroup.idleTime));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.switchClientRequest$.unsubscribe();
  }
}
