enum FieldType {
  DESCRIPTION = 'description',
  SHORT_DESCRIPTION = 'shortDescription',
  IMAGE = 'image',
  CENTER_IMAGE = 'centerImage',
  CONTENT = 'content',
  POPUP_TITLE = 'popupTitle',
  OPACITY = 'opacity',
  WIDE_OVERLAY = 'wideOverlay',
  POI_CATEGORY = 'poiCategory',
  LOCATION = 'location',
  URL = 'url',
  FEEDBACK = 'feedback',
  SLIDESHOW = 'slideshow',
  SMS_NUMBER = 'smsNumber',
  SMS_TYPE = 'smsType',
  EMAIL = 'email',
  EMAIL_TYPE = 'emailType',
  EMAIL_SUBJECT = 'emailSubject',
  EVENT = 'event',
  DISH_SET = 'dishSet',
}

const defaultFieldMapLess = [FieldType.DESCRIPTION, FieldType.IMAGE, FieldType.OPACITY];
const defaultFieldMap = [FieldType.DESCRIPTION, FieldType.IMAGE, FieldType.OPACITY, FieldType.CENTER_IMAGE];
const fieldsMap = {
  normal: [...defaultFieldMap, FieldType.CONTENT, FieldType.POPUP_TITLE, FieldType.WIDE_OVERLAY],
  flip: [
    FieldType.DESCRIPTION, FieldType.SHORT_DESCRIPTION, FieldType.IMAGE, FieldType.CONTENT,
    FieldType.POPUP_TITLE, FieldType.POI_CATEGORY, FieldType.LOCATION, FieldType.WIDE_OVERLAY
  ],
  web: [...defaultFieldMap, FieldType.POPUP_TITLE, FieldType.URL],
  static: [FieldType.DESCRIPTION, FieldType.SHORT_DESCRIPTION, FieldType.IMAGE, FieldType.CENTER_IMAGE],
  maps: [FieldType.IMAGE, FieldType.POI_CATEGORY],
  weather: [FieldType.IMAGE, FieldType.CENTER_IMAGE],
  forecast: [FieldType.IMAGE, FieldType.CENTER_IMAGE, FieldType.OPACITY],
  feedback: [...defaultFieldMap, FieldType.EMAIL_SUBJECT, FieldType.FEEDBACK],
  slideshow: [...defaultFieldMap, FieldType.SLIDESHOW],
  sms: [...defaultFieldMapLess, FieldType.CONTENT, FieldType.POPUP_TITLE, FieldType.SMS_NUMBER, FieldType.SMS_TYPE],
  email: [
    ...defaultFieldMapLess, FieldType.CONTENT, FieldType.POPUP_TITLE,
    FieldType.EMAIL, FieldType.EMAIL_TYPE, FieldType.EMAIL_SUBJECT
  ],
  events: [...defaultFieldMap, FieldType.EVENT],
  dishSet: [...defaultFieldMap, FieldType.DISH_SET]
};

export class WidgetType {
  private static All: WidgetType[] = [];

  static readonly NORMAL = new WidgetType('normal', fieldsMap.normal);
  static readonly FLIP = new WidgetType('flip', fieldsMap.flip);
  static readonly WEB = new WidgetType('web', fieldsMap.web);
  static readonly STATIC = new WidgetType('static', fieldsMap.static);
  static readonly MAP = new WidgetType('map', fieldsMap.maps);
  static readonly WEATHER = new WidgetType('weather', fieldsMap.weather);
  static readonly FORECAST = new WidgetType('forecast', fieldsMap.forecast);
  static readonly FEEDBACK = new WidgetType('feedback', fieldsMap.feedback);
  static readonly SLIDESHOW = new WidgetType('slideshow', fieldsMap.slideshow);
  static readonly SMS = new WidgetType('sms', fieldsMap.sms);
  static readonly EMAIL = new WidgetType('email', fieldsMap.email);
  static readonly EVENTS = new WidgetType('events', fieldsMap.events);
  static readonly DISH_SET = new WidgetType('dish-set', fieldsMap.dishSet);

  private constructor(
    public readonly type: string,
    public readonly fields: string[],
  ) {
    WidgetType.All.push(this);
  }

  public static parse(type: string): WidgetType {
    return WidgetType.All.find((t) => t.type === type);
  }

  public static all(): WidgetType[] {
    return WidgetType.All;
  }

  toString() {
    return this.type;
  }
}
