import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/takeUntil';

import { CustomModalComponent } from '../../../custom-modal/custom-modal.component';
import { Utils } from '../../../shared/utils';
import { RemoteAccessService } from '../../../services';
import { ServerSSHData } from '../../../models';

@Component({
  selector: 'app-server-ssh-add-edit',
  templateUrl: './server-ssh-add-edit.component.html',
  styleUrls: ['./server-ssh-add-edit.component.css']
})
export class ServerSshAddEditComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  currentServerSSHData = new ServerSSHData('', '', '');
  editId: number = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('serverSshForm') serverSshForm;
  @ViewChild('modal')
  private modal: CustomModalComponent;

  constructor(private remoteAccessService: RemoteAccessService, private route: ActivatedRoute,
              private router: Router, private utils: Utils) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.editId = params['id'];
      if (this.editId) {
        this.reload(this.editId);
      }
    });
  }

  private reload(serverSshID: number) {
    this.serverSshForm.form.reset();
    if (serverSshID) {
      this.remoteAccessService.getServerSSHDataById(this.editId) //
      .takeUntil(this.ngUnsubscribe).subscribe(serverSSHData => {
        if (serverSSHData) {
          this.currentServerSSHData = serverSSHData;
        } else {
          this.router.navigate(['/remote-access/server/list/']);
        }
      });
    }
  }

  onSubmit() {
    if (this.serverSshForm.form.valid) {
      this.remoteAccessService.storeServerSSHData(this.currentServerSSHData, this.editId ? true : false) //
      .takeUntil(this.ngUnsubscribe).subscribe(
        res => {
          this.serverSshForm.form.reset();
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          this.router.navigate(['/remote-access/server/list/']);
        },
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
        }
      );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

}
