import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() pageable: Pageable;
  @Input() totalPages: number;

  // tslint:disable-next-line:no-output-rename
  @Output('updatePage') updatePage$ = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  private updatePage(page: number) {
    this.updatePage$.emit(page);
  }

}

export class Pageable {
  constructor(
    public page: number,
    public sort: string,
    public direction: string
  ) {}
}
