import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Utils } from '../shared/utils';

class BeforeContent {
  modalBody: string;
  iframeSrc: SafeResourceUrl;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'custom-modal',
  templateUrl: 'custom-modal.component.html',
  styleUrls: ['custom-modal.component.css']
})
export class CustomModalComponent {
  @Input() modalWidth: string;

  modalTitle: string;
  modalBody: string;
  modalTarget: string;
  modalTargetTitle: string;
  cancel: boolean;
  ok: boolean;
  item: any;
  iframeSrc: SafeResourceUrl;
  beforeContent: BeforeContent = new BeforeContent;

  @ViewChild('modal') modal;
  @Output() resultEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  getTranslation(key: string) { }

  constructor(public router: Router, private sanitizer: DomSanitizer, private utils: Utils) {
    this.getTranslation = utils.getTranslation;
  }

  /**
	 * showModal
	 * this function opens modal dialog with content defined by parameters
	 * @param modalBody : content of modal dialog which is injected as `innerHTML`
	 * @param modalTitle : title of modal dialog
	 * @param item : represents item, for which action modal dialog is opened.
	 * Useful to pass eg. id of page which is going to be deleted in order to get
	 * that value as function parameter of injected ng-content element
	 * @param cancel : optional flag which determinates if cancel button is going to be displayed
	 * @param ok : optional flag which determinates if ok button is going to be displayed
	 */
  showModal(
    modalBody: string,
    modalTitle: string,
    item: any,
    cancel?: boolean,
    ok?: boolean
  ) {
    if (modalBody !== undefined) {
      this.modalTitle = modalTitle;
      this.beforeContent.modalBody = modalBody;
      if (modalTitle && modalTitle !== '') {
        this.modalTitle = this.utils.getTranslation(modalTitle);
      }
      if (modalBody && modalBody !== '') {
        this.beforeContent.modalBody = this.utils.getTranslation(modalBody);
      }
      this.item = item;
      this.cancel = false;
      this.ok = false;
      if (cancel) {
        this.cancel = cancel;
      }
      if (ok) {
        this.ok = ok;
      }
      this.modal.show();
    }
  }

  /**
	 * setContent
	 * sets the content for modal dialog
	 */
  setContent() {
    this.modalBody = this.beforeContent.modalBody;
    this.iframeSrc = this.beforeContent.iframeSrc;
  }

  /**
	 * reset
	 * clears the content of modal dialog when the modal is closed
	 */
  reset() {
    this.modalTitle = '';
    this.modalBody = '';
    this.cancel = false;
    this.ok = false;
    this.item = null;
    this.iframeSrc = null;
  }

  showIframeModal(iframeSrc, modalTitle) {
    this.modalTitle = modalTitle;
    this.beforeContent.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(iframeSrc);
  }

  /**
	 * closeModal
	 * emits `false` through `resultEmitter` and closes modal dialog
	 */
  closeModal() {
    this.resultEmitter.emit(false);
    this.modal.hide();
  }

  /**
	 * onOk
	 * emits `true` through `resultEmitter` and closes modal dialog
	 */
  onOk() {
    this.resultEmitter.emit(true);
    this.modal.hide();
  }

}
