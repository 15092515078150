import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PoiComponent } from './poi.component';
import { PoiAddEditComponent } from './poi-add-edit/poi-add-edit.component';
import { PoiListComponent } from './poi-list/poi-list.component';
import { PoiCategoryListComponent } from './poi-category-list/poi-category-list.component';
import { PoiCategoryAddEditComponent } from './poi-category-add-edit/poi-category-add-edit.component';
import { poiRouting } from './poi.routing';
import { AuthGuard } from '@b4m/b4m-frontend-core';
import { CustomModalModule } from '../custom-modal/custom-modal.module';
import { MapModule } from '../map/map.module';
import { LanguageModule } from '../language/language.module';
import { DeviceGroupModule } from '../device-group/device-group.module';
import { MaterialIconsService } from '../material-icons/material-icons.service';
import { MakiIconsModule } from '../maki-icons/maki-icons.module';
import { Utils } from '../shared/utils';
import { SharedModule } from '../shared/shared.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { PoiService, MainPageService } from '../services';
import { DashheadModule } from '@app/dashhead/dashhead.module';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    poiRouting,
    FormsModule,
    BrowserModule,
    TranslateModule,
    CustomModalModule,
    MapModule,
    MakiIconsModule,
    LanguageModule,
    DeviceGroupModule,
    SharedModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    DashheadModule,
    AgmCoreModule
  ],
  declarations: [
    PoiComponent,
    PoiCategoryAddEditComponent,
    PoiCategoryListComponent,
    PoiAddEditComponent,
    PoiListComponent,
  ],
  exports: [PoiComponent, PoiListComponent],
  providers: [AuthGuard, MaterialIconsService, Utils, PoiService, MainPageService]
})
export class PoiModule { }
