import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterIconGroups',
  pure: false
})
export class FilterIconGroupsPipe implements PipeTransform {

  transform(icons: any, group: any): any {
    const result: any[] = [];
    icons.forEach(icon => {
      if (icon.group_id === group) {
        result.push(icon);
      }
    });
    return result;
  }

}
