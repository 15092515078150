import { Component } from '@angular/core';
import { MainPageService } from '../services';
import { ClientService } from '@b4m/b4m-frontend-core';
import { DeviceGroupService } from '../services';
import { Utils } from '../shared/utils';

/**
 * This Component updates the clients version number by pressing an update Button.
 * The Component is resonsible for the logic as well as for the button design and behavior.
 */
@Component({
  selector: 'app-content-updater',
  templateUrl: 'content-updater.component.html',
  styleUrls: ['content-updater.component.css']
})
export class ContentUpdaterComponent {
  /** Is Update request running? */
  running = false;
  /** Is Update request ready? */
  ready = false;
  /** Is an error occured during update? */
  error = false;
  /** Error message, if an error occures */
  errorMessage: string;
  constructor(
    private mainPageService: MainPageService,
    private clientService: ClientService,
    private deviceGroupService: DeviceGroupService,
    private utils: Utils
  ) {}

  /**
   * This function increases the main page version number
   * return {void}
   */
  update() {
    this.running = true;
    this.ready = false;
    this.error = false;
    this.errorMessage = '';
    this.mainPageService
      .get(this.clientService.getCurrentClient().id, this.deviceGroupService.currentDeviceGroup.id)
      .subscribe(mp => {
        try {
          if (mp && mp.value) {
            const mainPage = mp.value;
            // Check if current page has version or not
            if (mainPage.version) {
              mainPage.version = parseInt(mainPage.version, 10) + 1;
            } else {
              mainPage.version = 2;
            }
            // Update new main page
            this.mainPageService
              .update(
                this.clientService.getCurrentClient().id,
                this.deviceGroupService.currentDeviceGroup.id,
                mainPage,
                mp.id
              )
              .subscribe(resp => {
                // Updated successfully
                this.running = false;
                this.ready = true;
                this.utils.displayGrowlMessage('success', 'updated-content', '');
                this.resetState();
              });
          }
        } catch (error) {
          this.running = false;
          this.error = true;
          this.errorMessage = '' + error;
          this.utils.displayGrowlMessage('danger', 'update-content-failed-summary', error);
          throw new Error(error);
        }
      });
  }

  /**
   * This function resets the button states after some time.
   */
  private resetState() {
    setTimeout(() => {
      this.running = false;
      this.ready = false;
    }, 5000);
  }
}
