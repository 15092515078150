import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';
import * as ICAL from 'ical.js';
import * as XLSX from 'xlsx';

import { ClientService } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { Utils, I18NSearchObj } from '../../shared/utils';
import { Events, Content, EventContent } from '../../models';
import { EventsService, LanguageService, UploadService } from '../../services';

ICAL.design.icalendar.property['x-clipstart'] = {
  defaultType: 'date-time', // [required] The default type from ICAL.design.value
  allowedTypes: ['date-time'] // [optional] Valid value types this property can have (currently unused)
};

ICAL.design.icalendar.property['x-clipend'] = {
  defaultType: 'date-time', // [required] The default type from ICAL.design.value
  allowedTypes: ['date-time'] // [optional] Valid value types this property can have (currently unused)
};

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit, OnDestroy {
  tmpEvent = new Events(true, '', [null, null], null, null, '', '', []);
  events = new Array<EventContent>();
  clientSelected = false;
  selectedLanguageKey = '_';
  progress = 0;
  processing = false;
  step = '';
  @ViewChild('languageSelector') languageSelector;
  @ViewChild('modal') private modal: CustomModalComponent;
  @ViewChild('deleteOutdatedEventsModal') private deleteOutdatedEventsModal: CustomModalComponent;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('csvBrowse') csvBrowse;

  constructor(
    private clientService: ClientService,
    private eventsService: EventsService,
    private utils: Utils,
    private languageService: LanguageService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
      this.selectedLanguageKey = this.languageService.currentLanguage;
    }
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.clientSelected = true;
      this.getAll(this.clientService.getCurrentClient().id);
    } else {
      this.clientSelected = false;
    }

    // Subscribe to current client to reload on change
    this.clientService.currentClient$.takeUntil(this.ngUnsubscribe).subscribe(client => {
      this.clientSelected = true;
      this.getAll(client.id);
    });
  }

  private getAll(clientId: string | number) {
    this.eventsService.getAll(clientId).subscribe(events => {
      this.events = events;
      this.events.sort(this.compareEvents);
    });
  }

  private compareEvents(event1, event2) {
    if (event1.value.dateRange && event2.value.dateRange) {
      if ((event1.value.dateRange[0] < event2.value.dateRange[0]) || !event1.value.dateRange[0]) {
        return -1;
      }
      if ((event1.value.dateRange[0] > event2.value.dateRange[0]) || !event2.value.dateRange[0]) {
        return 1;
      }
    }
    return 0;
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  handleCsvBrowseClick() {
    this.csvBrowse.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    const reader = new FileReader();
    const extension = files[0].name
      .split('.')
      .pop()
      .toLowerCase();
    if (extension === 'xls' || extension === 'xlsx' || extension === 'ods' || extension === 'csv') {
      this.handleSpreadsheetFile(files, reader);
    } else if (extension === 'ical' || extension === 'ics') {
      this.handleICalFile(files, reader);
    }
  }

  private handleSpreadsheetFile(files: FileList, reader: FileReader) {
    reader.readAsBinaryString(files[0]);
    reader.onload = e => {
      this.processing = true;
      this.progress = 0;
      const data = e.target['result'];
      const wb: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const sheet: XLSX.WorkSheet = wb.Sheets[wsname];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const tmpEvents: Array<Events> = [];
      if (rows.length > 1 && rows[0]['length'] === 6) {
        let rowIndex = 0;
        for (const row of rows) {
          if (rowIndex >= 2 && row['length'] === 6) {
            row[1] = row[1].replace(/â/g, '"').replace(/â/g, '"');
            row[4] = row[4].replace(/â/g, '"').replace(/â/g, '"');
            row[5] = row[5].replace(/â/g, '"').replace(/â/g, '"');

            this.setI18NAttribute('tmpEvent.title', true, row[1]);
            this.tmpEvent.dateRange = [new Date(row[2]), new Date(row[3])];
            if (
              this.tmpEvent.dateRange[0].toString() === 'Invalid Date' ||
              this.tmpEvent.dateRange[1].toString() === 'Invalid Date'
            ) {
              this.tmpEvent = new Events(true, '', [null, null], null, null, '', '', []);
              continue;
            }
            this.tmpEvent.timeFrom = new Date(row[2]);
            this.tmpEvent.timeTo = new Date(row[3]);
            this.setI18NAttribute('tmpEvent.description', true, row[4]);
            this.setI18NAttribute('tmpEvent.location', true, row[5]);
            tmpEvents.push(this.tmpEvent);
          }
          this.tmpEvent = new Events(true, '', [null, null], null, null, '', '', []);
          rowIndex++;
        }
      }
      if (tmpEvents.length > 0) {
        this.storeEvents(tmpEvents).then(resolve => {
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          this.getAll(this.clientService.getCurrentClient().id);
        });
      } else {
        this.processing = false;
        this.progress = 0;
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
      }
    };
  }

  private handleICalFile(files: FileList, reader: FileReader) {
    this.processing = true;
    this.progress = 0;
    const iCalendarData = reader.readAsText(files[0]);
    reader.onload = e => {
      const data = e.target['result'];
      const jcalData = ICAL.parse(data);
      const vcalendar = new ICAL.Component(jcalData);
      const vevents = vcalendar.getAllSubcomponents('vevent');
      const tmpEvents: Array<Events> = [];
      vevents.forEach(vevent => {
        const event = new ICAL.Event(vevent);
        const iter = event.iterator();
        if (iter) {
          let next = iter.next();
          for (; next; next = iter.next()) {
            const occurrence = event.getOccurrenceDetails(next);

            const summary = occurrence.item.summary;
            const description = occurrence.item.description;
            const location = occurrence.item.location;
            const start = occurrence.startDate;
            const end = occurrence.endDate;

            this.setI18NAttribute('tmpEvent.title', true, summary);
            this.setI18NAttribute('tmpEvent.description', true, description);
            this.setI18NAttribute('tmpEvent.location', true, location);
            if (start && end) {
              this.tmpEvent.dateRange = [new Date(start), new Date(end)];
              this.tmpEvent.timeFrom = new Date(start);
              this.tmpEvent.timeTo = new Date(end);
            }
            if (
              this.tmpEvent.dateRange[0].toString() !== 'Invalid Date' &&
              this.tmpEvent.dateRange[1].toString() !== 'Invalid Date'
            ) {
              tmpEvents.push(this.tmpEvent);
            }
            this.tmpEvent = new Events(true, '', [null, null], null, null, '', '', []);
          }
        } else {
          const summary = event.summary;
          const description = event.description;
          const location = event.location;
          const start = event.dtstart;
          const end = event.dtend;

          this.setI18NAttribute('tmpEvent.title', true, summary);
          this.setI18NAttribute('tmpEvent.description', true, description);
          this.setI18NAttribute('tmpEvent.location', true, location);
          if (start && end) {
            this.tmpEvent.dateRange = [new Date(start), new Date(end)];
            this.tmpEvent.timeFrom = new Date(start);
            this.tmpEvent.timeTo = new Date(end);
          }
          if (
            this.tmpEvent.dateRange[0].toString() !== 'Invalid Date' &&
            this.tmpEvent.dateRange[1].toString() !== 'Invalid Date'
          ) {
            tmpEvents.push(this.tmpEvent);
          }
          this.tmpEvent = new Events(true, '', [null, null], null, null, '', '', []);
        }
      });
      if (tmpEvents.length > 0) {
        this.storeEvents(tmpEvents).then(resolve => {
          this.utils.displayGrowlMessage('success', 'forms.submitted', '');
          this.getAll(this.clientService.getCurrentClient().id);
        });
        // this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      } else {
        this.processing = true;
        this.progress = 0;
        this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', '');
      }
    };
  }

  getTranslation(key: string): string {
    return this.utils.getTranslation(key);
  }

  private splitTimeString(timeString: string) {
    const timeArray = timeString.split(':');
    if (timeArray.length >= 2) {
      return { hours: timeArray[0], minutes: timeArray[1] };
    }
    return { hours: 0, minutes: 0 };
  }

  private splitDateString(dateString: string) {
    const date = new Date();
    const dateArray = dateString.split('.');
    if (dateArray.length >= 3) {
      return { year: dateArray[0], month: '' + (+dateArray[1] - 1), day: dateArray[2] };
    }
    return { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
  }

  private splitTagsString(tagsString: string): string[] {
    const tags = tagsString.split(',');
    tags.forEach(tag => (tag = tag.trim()));
    return tags;
  }

  private storeEvents(events: Array<Events>): Promise<boolean> {
    this.progress = 0;
    this.processing = true;
    const promise = new Promise<boolean>(resolve => {
      const me = this;
      this.storeNextEvent(0, me, resolve, events);
    });
    return promise;
  }

  private storeNextEvent(i: number, me: any, resolve: any, events: Array<Events>) {
    if (i < events.length) {
      const event = events[i];
      this.progress = Math.ceil((100 / events.length) * i);
      me.eventsService.store(me.clientService.getCurrentClient().id, false, event, null).subscribe(
        res => {
          this.storeNextEvent(i + 1, me, resolve, events);
        },
        error => {}
      );
    } else {
      this.processing = false;
      resolve(true);
    }
  }

  private showDeleteOutdatedEventsModal() {
    this.deleteOutdatedEventsModal.showModal(
      'forms.leave-page-alert-title',
      this.getTranslation('confirmation.delete-outdated-events'),
      null,
      true);
  }

  private removeOutdatedEvents() {
    this.deleteOutdatedEventsModal.closeModal();
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      const clientID = this.clientService.getCurrentClient().id;
      this.eventsService
      .deleteOutdatedEvents(clientID)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          this.getAll(clientID);
        },
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
        }
      );
    }
  }

  removeEvent(eventId: number, isGoogleEvent) {
    this.modal.closeModal();
    const client = this.clientService.getCurrentClient();
    if (client && client.id) {
      this.removeEventImages(eventId, client.id);
      this.eventsService
        .delete(client.id, eventId, isGoogleEvent) //
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          res => {
            this.getAll(client.id);
          },
          error => {
            this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
          }
        );
    }
  }

  private removeEventImages(eventId: number, clientID: string | number) {
    const event = this.events.find(function (e) {
      return e.id === eventId;
    });
    const domParser = new DOMParser();
    const docElement = domParser.parseFromString(event.value.description['_'], 'text/html').documentElement;
    const imageTags = docElement.getElementsByTagName('img');
    for (let i = 0; i < imageTags.length; i++) {
      const imageId = imageTags[i].src.substring(imageTags[i].src.lastIndexOf('/') + 1);
      this.uploadService.delete(clientID, imageId).subscribe(() => {});
    }
  }

  getReference(param, event: Event) {
    return typeof event[param] === 'object' ? event[param]._ : event[param];
  }

  cancelEvent(flag: boolean, event: Events, eventId: number, isGoogleEvent: boolean) {
    if (event) {
      event.canceled = flag;
      this.eventsService.update(this.clientService.getCurrentClient().id, event, eventId, isGoogleEvent).subscribe(
        res => {},
        error => {
          this.utils.displayGrowlMessage('danger', 'forms.submit-failed-detail', '');
        }
      );
    }
  }

  setI18NAttribute(dottedName, setDefault, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
    if (setDefault) {
      const searchObj = new I18NSearchObj(this, dottedName);
      searchObj.container[searchObj.attr]._ = event;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
