import { GoogleAnalyticsDashboardTypes } from '../../models';
import { Component } from '@angular/core';

@Component({
  selector: 'app-google-analytics-dashboard',
  templateUrl: './google-analytics-dashboard.component.html',
  styleUrls: ['./google-analytics-dashboard.component.css']
})
export class GoogleAnalyticsDashboardComponent {
  currentGADashboardType = GoogleAnalyticsDashboardTypes.Audience;
  GoogleAnalyticsDashboardTypes = GoogleAnalyticsDashboardTypes;

  public checkCurrentGADashboardType(type: GoogleAnalyticsDashboardTypes): boolean {
    return GoogleAnalyticsDashboardTypes[this.currentGADashboardType] === GoogleAnalyticsDashboardTypes[type];
  }

  setCurrentGAsDashboardType(type: GoogleAnalyticsDashboardTypes) {
    this.currentGADashboardType = type;
  }
}
