export const dimensionDate = 'ga:date';
const options = {
    elements: {
        line: {
            tension: 0
        }
    },
    scales: {
        xAxes: [{
            gridLines: {
                display: false
            },
        }],
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }]
    }
};
export const cards = {
    c1: {
        data: [],
        labels: [],
        dataType: 'integer',
        dimension: dimensionDate,
        metric: 'ga:users',
        metricData: undefined,
        type: 'line',
        legend: false,
        colors: [{
            borderColor: 'rgb(179, 0, 0, 0.55)',
            backgroundColor: 'rgba(255,255,255,0.0)',
        }],
        options: options
    },
    c2: {
        data: [],
        labels: [],
        dataType: 'integer',
        dimension: dimensionDate,
        metric: 'ga:sessions',
        metricData: undefined,
        type: 'line',
        legend: false,
        colors: [{
            borderColor: 'rgb(0, 0, 102, 0.55)',
            backgroundColor: 'rgba(255,255,255,0.0)',
        }],
        options: options
    },
    c3: {
        data: [],
        labels: [],
        dataType: 'double',
        dimension: dimensionDate,
        metric: 'ga:bounceRate',
        metricData: undefined,
        type: 'line',
        legend: false,
        colors: [{
            borderColor: 'rgb(230, 0, 172, 0.55)',
            backgroundColor: 'rgba(255,255,255,0.0)',
        }],
        options: {
            elements: {
                line: {
                    tension: 0
                }
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        return data['datasets'][0]['data'][tooltipItem['index']] + '%';
                    },
                },
            },
            lineTension: 0,
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    },
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: label => `${label}%`
                    }
                }]
            }
        }
    },
    c4: {
        data: [],
        labels: [],
        dimension: dimensionDate,
        dataType: 'double',
        metric: 'ga:avgSessionDuration',
        metricData: undefined,
        type: 'line',
        legend: false,
        colors: [{
            borderColor: 'rgb(0, 179, 0, 0.55)',
            backgroundColor: 'rgba(255,255,255,0.0)',
        }],
        options: {
            elements: {
                line: {
                    tension: 0
                }
            },
            lineTension: 0,
            tooltips: {},
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    },
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }
    }
};
