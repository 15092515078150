import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ClientService } from '@b4m/b4m-frontend-core';
import { LanguageService } from '../../services';
import { Content } from '../../models';

@Component({
  selector: 'app-language-selector',
  templateUrl: 'language-selector.component.html',
  styleUrls: ['language-selector.component.css']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  languages: any = [{ key: '_', description: 'Default language', icon: '', active: true }];

  @Input()
  selectedLanguageKey = '_';
  @Output()
  languageSwitched = new EventEmitter();

  private currentClient$: any;
  private currentDeviceGroup$: any;

  constructor(private clientService: ClientService, private languageService: LanguageService) {}

  ngOnInit() {
    if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
      this.selectedLanguageKey = this.languageService.currentLanguage;
    }
    if (this.clientService.getCurrentClient() && this.clientService.getCurrentClient().id) {
      this.reload(this.clientService.getCurrentClient().id);
    }

    // Subscribe to current client to reload on change
    this.currentClient$ = this.clientService.currentClient$.subscribe(client => {
      this.reload(client.id);
    });
  }

  private reload(clientID: number | string) {
    this.languages = [
      { key: '_', description: 'Default language', icon: '', active: true, pageContents: [{ sortOrder: -1 }] }
    ];
    this.languageService.getAll(clientID).subscribe((contents: Content[]) => {
      if (contents && contents.length > 0) {
        const languages: any[] = [];
        for (const content of contents) {
          content.value.pageContents = content['pageContents'];
          languages.push(content.value);
        }
        this.languages = languages.sort(this.compareLanguagesByOrder.bind(this));
        if (
          this.languageService.currentLanguage &&
          this.languageService.currentLanguage !== '_' && //
          this.languages.filter(lang => lang.key === this.languageService.currentLanguage)[0]
        ) {
          this.setLanguage(this.languageService.currentLanguage);
        } else {
          this.setLanguage(this.languages[0].key);
        }
      }
    });
  }

  private compareLanguagesByOrder(language1, language2) {
    const p1 = this.getLanguageOrder(language1) === -1 ? 1024 : this.getLanguageOrder(language1);
    const p2 = this.getLanguageOrder(language2) === -1 ? 1024 : this.getLanguageOrder(language2);

    if (p1 < p2) {
      return -1;
    }
    if (p1 > p2) {
      return 1;
    }
    return 0;
  }

  private getLanguageOrder(language: any): number {
    if (language.pageContents && language.pageContents.length !== 0) {
      for (const pageContent of language.pageContents) {
        if (!pageContent.page_id) {
          return pageContent.sortOrder;
        }
      }
    }
    return -1;
  }

  setLanguage(languageKey: string) {
    if (this.selectedLanguageKey !== languageKey) {
      this.selectedLanguageKey = languageKey;
      this.languageSwitched.emit(languageKey);
      this.languageService.setCurrentLanguage(languageKey);
    }
  }

  getLabel(key: string) {
    if (key === '_') {
      return 'default';
    } else if (key === this.languages[0].key) {
      return this.languages[0].description + ' (default)';
    }
    for (const k in this.languages) {
      if (k) {
        if (key === this.languages[k].key) {
          return this.languages[k].description;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.currentClient$) {
      this.currentClient$.unsubscribe();
    }
    if (this.currentDeviceGroup$) {
      this.currentDeviceGroup$.unsubscribe();
    }
  }
}
