import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { ClientService, AuthService, CanComponentDeactivate } from '@b4m/b4m-frontend-core';
import { CustomModalComponent } from '../../custom-modal/custom-modal.component';
import { MapComponent } from '../../map/map.component';
import { MainPageService, DeviceGroupService, UploadService, WidgetService, PoiService, LanguageService } from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../../shared/utils';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/takeUntil';

import { environment } from '../../../environments/environment';
import { Category, Poi, GeometryObj, PoiProperties, Coords, UploadResponse, UploadProgressType } from '../../models';

@Component({
  selector: 'app-poi-add-edit',
  templateUrl: 'poi-add-edit.component.html',
  styleUrls: ['poi-add-edit.component.css']
})
export class PoiAddEditComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  isNew = false;
  categories: Category[];
  selectedLanguageKey = '_';
  poi: Poi = new Poi(
    'Feature',
    new GeometryObj('Point', new Coords(0, 0)),
    new PoiProperties('', '', '', true, '', '')
  );
  editorOptions = {
    width: '500',
    language: 'de',
    key: environment.froalaKey,
    events: {
      'froalaEditor.file.beforeUpload': function(event, editor, file) {
        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = e => {
          editor.file.insert(reader.result);
        };
        return false;
      }
    },
    imageDefaultWidth: 0,
    tableMultipleStyles: false,
    tableStyles: {
      'froala-table-no-vertical-border': 'No vertical border',
      'froala-table-no-border': 'No border',
      'froala-table-alternating-rows': 'Alternating rows',
      'froala-table-alternating-rows-no-horizontal-border': 'Alternating rows with no horizontal border'
    }
  };
  @ViewChild('mapComponent')
  private mapComponent: MapComponent;
  private selectedID: number;
  private sub: Subscription;
  @ViewChild('poiForm')
  poiForm;
  @ViewChild('languageSelector')
  languageSelector;
  private switchClientRequest$: any;
  private currentClient$: any;
  @ViewChild('modal')
  private modal: CustomModalComponent;
  @ViewChild('iconsModal')
  private iconsModal: CustomModalComponent;
  icons: any = [];
  private contentModified = false;
  private refererDeviceGroupId: number;
  private contentEditor: any = null;
  displayEditor = false;
  currentLocation = [0, 0];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private poiService: PoiService,
    private clientService: ClientService,
    private mainPageService: MainPageService,
    private deviceGroupService: DeviceGroupService,
    private translate: TranslateService,
    public utils: Utils,
    private authService: AuthService,
    private uploadService: UploadService,
    private widgetService: WidgetService,
    private languageService: LanguageService
  ) {
    this.editorOptions.language = this.translate.currentLang;

    const that = this;
    this.editorOptions.events['froalaEditor.image.beforeUpload'] = function(event, editor, image) {
      const reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = e => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = function() {
          let width = img.width;
          let height = img.height;

          const resizeMaxWidth = 490;
          const resizeMaxHeight = 600;

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > resizeMaxWidth) {
              height = Math.round((height *= resizeMaxWidth / width));
              width = resizeMaxWidth;
            }
          } else {
            if (height > resizeMaxHeight) {
              width = Math.round((width *= resizeMaxHeight / height));
              height = resizeMaxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          that.uploadService
            .saveFile(canvas.toDataURL('image/jpeg', 0.95), that.clientService.getCurrentClient().id)
            .subscribe((result: UploadResponse) => {
              if (result.resType === UploadProgressType.BODY) {
                editor.image.insert(result.content[0].url);
              }
            });
        };
        img.src = <string>reader.result;
      };
      return false;
    };
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (this.languageService.currentLanguage && this.languageService.currentLanguage !== '_') {
        this.selectedLanguageKey = this.languageService.currentLanguage;
      }
      this.selectedID = params['id'];
      if (params['refererDeviceGroupId'] !== undefined) {
        this.refererDeviceGroupId = params['refererDeviceGroupId'];
      }
      if (this.selectedID) {
        if (this.clientService.getCurrentClient()) {
          this.poiService
            .getByID(this.clientService.getCurrentClient().id, this.selectedID)
            .takeUntil(this.ngUnsubscribe)
            .subscribe( (poi: Poi) => {
              this.poi = poi;
              this.currentLocation = [this.poi.geometry.coordinates.longitude, this.poi.geometry.coordinates.latitude];
//               this.mapComponent.initializeMap([
//                 this.poi.geometry.coordinates.longitude,
//                 this.poi.geometry.coordinates.latitude
//               ]);
              this.assignAwsBucket(this.poi);
              this.displayEditor = true;
            });
        }
      } else {
        this.isNew = true;
        this.displayEditor = true;
        if (this.deviceGroupService.currentDeviceGroup) {
          this.mainPageService
            .get(this.clientService.getCurrentClient().id, this.deviceGroupService.currentDeviceGroup.id)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(p => {
              if (p && p['value']) {
                this.currentLocation = [p['value'].location.lng, p['value'].location.lat];
//                 this.mapComponent.initializeMap([p['value'].location.lng, p['value'].location.lat]);
              }
            });
        }
      }
    });

    if (this.clientService.getCurrentClient()) {
      this.poiService
        .getCategories(this.clientService.getCurrentClient().id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(categories => {
          this.categories = categories;
        });
    }

    // Subscribe to current client to reload on change
    this.currentClient$ = this.clientService.currentClient$.subscribe(client => {
      if (this.clientService.getCurrentClient()) {
        this.reload(this.clientService.getCurrentClient().id, this.selectedID);
        this.poiService
          .getCategories(this.clientService.getCurrentClient().id)
          .takeUntil(this.ngUnsubscribe)
          .subscribe(categories => {
            this.categories = categories;
          });
      }
    });

    this.switchClientRequest$ = this.clientService.switchClientRequest$.subscribe(res => {
      this.canDeactivate().then(resolve => {
        if (resolve) {
          this.clientService.clientSwitchPermission(res);
        }
      });
    });

    this.translate.onLangChange.subscribe(() => {
      this.updateFroalaLanguage();
    });
  }

  froalaInit(value) {
    this.assignAwsBucket(this.poi);
    this.contentEditor = value;
    this.contentEditor.initialize();
  }

  updateFroalaLanguage() {
    this.editorOptions.language = this.translate.currentLang;
    this.displayEditor = false;
    setTimeout(() => {
      this.displayEditor = true;
    }, 0);
  }

  private reload(clientID: number | string, poiID: number) {
    this.poiForm.form.reset();
    this.contentModified = false;
    if (clientID && poiID) {
      this.poiService
        .getByID(clientID, poiID)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(poi => {
          if (poi) {
            this.poi = poi['value'];
            this.currentLocation = [this.poi.geometry.coordinates.longitude, this.poi.geometry.coordinates.latitude];
//             this.mapComponent.initializeMap([
//               this.poi.geometry.coordinates.longitude,
//               this.poi.geometry.coordinates.latitude
//             ]);
          }
        });
    }
  }

  selectIcon(param, value) {
    this.setI18NAttribute(param, value);
    this.iconsModal.closeModal();
    this.contentModified = true;
  }

  canDeactivate(): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      if (this.poiForm.form.dirty || (this.mapComponent && this.mapComponent.locationChanged) || this.contentModified) {
        this.modal.showModal('forms.leave-page-alert-body', 'forms.leave-page-alert-title', '', true, true);
        this.modal.resultEmitter.subscribe(result => {
          if (result === true) {
            if (this.contentEditor) {
              this.contentEditor.destroy();
            }
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        if (this.contentEditor) {
          this.contentEditor.destroy();
        }
        resolve(true);
      }
    });
    return promise;
  }

  updateCategories(isSaved: boolean) {
    if (isSaved) {
      this.poiService
        .getCategories(this.clientService.getCurrentClient().id)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(categories => {
          this.categories = categories;
        });
    }
  }

  submit() {
    this.switchLanguage(this.languageSelector.languages[0].key);
    setTimeout(() => {
      if (this.poiForm.form.valid) {
        const location: number[] = this.mapComponent.getCurrentLocation();
        this.poi.geometry.coordinates.longitude = location[0];
        this.poi.geometry.coordinates.latitude = location[1];
        this.sanitizePoiContent(this.poi).then(res => {
          if (this.isNew) {
            this.saveNewPoi();
          } else {
            this.updatePoi();
          }
        });
      }
    }, 500);
  }

  private sanitizePoiContent(poi: Poi): Promise<boolean> {
    const promise = new Promise<boolean>(resolve => {
      const propertyNames: string[] = [];
      if (typeof this.poi.properties.description === 'object') {
        for (const propertyName in <Object>this.poi.properties.description) {
          if (this.poi.properties.description.hasOwnProperty(propertyName)) {
            propertyNames.push(propertyName);
          }
        }
        if (propertyNames.length > 0) {
          const me = this;
          this.sanitizeNextContent(0, me, propertyNames, resolve, poi);
        } else {
          resolve(true);
        }
      } else {
        this.widgetService.sanitize(this.poi.properties.description).subscribe(
          res => {
            this.poi.properties.description = res.replace(
              this.getAwsBucketUrlRegex(this.authService['amazonAwsBucketName']),
              'https://' + environment.amazonAwsBucketNamePlaceholder + '.s3.amazonaws.com/img/'
            );
          },
          error => {
            resolve(false);
          }
        );
        resolve(true);
      }
    });
    return promise;
  }

  private sanitizeNextContent(i: number, me: any, propertyNames: string[], resolve: any, poi: Poi) {
    if (i < propertyNames.length) {
      const propertyName = propertyNames[i];
      me.widgetService.sanitize(me.poi.properties.description[propertyName]).subscribe(
        res => {
          this.poi.properties.description[propertyName] = res.replace(
            me.getAwsBucketUrlRegex(me.authService['amazonAwsBucketName']),
            'https://' + environment.amazonAwsBucketNamePlaceholder + '.s3.amazonaws.com/img/'
          );
          this.sanitizeNextContent(i + 1, me, propertyNames, resolve, poi);
        },
        error => {
          resolve(false);
        }
      );
    } else {
      resolve(true);
    }
  }

  saveNewPoi() {
    this.poiService
      .create(this.clientService.getCurrentClient().id, this.poi)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (this.refererDeviceGroupId) {
            this.poiService
              .attach(this.clientService.getCurrentClient().id, this.refererDeviceGroupId, res.id)
              .takeUntil(this.ngUnsubscribe)
              .subscribe(attached => {
                this.handleServerResponse(true);
              });
          } else {
            this.handleServerResponse(true);
          }
        },
        error => {
          this.handleServerResponse(false);
        }
      );
  }

  updatePoi() {
    this.poiService
      .update(this.clientService.getCurrentClient().id, this.poi, this.selectedID)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          this.handleServerResponse(true);
        },
        error => {
          this.handleServerResponse(false);
        }
      );
  }

  private assignAwsBucket(poi: Poi) {
    if (typeof poi.properties.description === 'object') {
      for (const propertyName in <Object>poi.properties.description) {
        if (poi.properties.description.hasOwnProperty(propertyName)) {
          poi.properties.description[propertyName] = poi.properties.description[propertyName].replace(
            this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
            'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
          );
        }
      }
    } else {
      poi.properties.description = poi.properties.description.replace(
        this.getAwsBucketUrlRegex(environment.amazonAwsBucketNamePlaceholder),
        'https://' + this.authService['amazonAwsBucketName'] + '.s3.amazonaws.com/img/'
      );
    }
  }

  private getAwsBucketUrlRegex(bucket: string) {
    const oldValue = 'https://' + bucket + '.s3.amazonaws.com/img/';
    return new RegExp(oldValue, 'g');
  }

  private handleServerResponse(success: boolean) {
    if (success) {
      this.poiForm.form.reset();
      this.contentModified = false;
      this.mapComponent.locationChanged = false;
      this.utils.displayGrowlMessage('success', 'forms.submitted', '');
      this.router.navigate(['/poi/list']);
    } else {
      this.utils.displayGrowlMessage('danger', 'forms.submit-failed-summary', 'forms.submit-failed-detail');
    }
  }

  ngOnDestroy() {
    this.currentClient$.unsubscribe();
    this.sub.unsubscribe();
    this.switchClientRequest$.unsubscribe();

    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  switchLanguage(languageKey) {
    this.selectedLanguageKey = languageKey;
  }

  getI18NAttribute(dottedName: string) {
    return this.utils.getI18NAttribute(this, dottedName);
  }

  setI18NAttribute(dottedName, event) {
    this.utils.setI18NAttribute(this, dottedName, event);
  }

  private synchronizeDefault(param) {
    this.utils.synchronizeDefault(this, param);
  }

  getPlaceholder(dottedName, i18nKey): string {
    return this.utils.getPlaceholder(this, dottedName, i18nKey);
  }
}
